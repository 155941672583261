// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { TValidationResults } from "../util/AType";

export const CURRENT_YEAR = new Date().getFullYear();

interface ICheckYear {
  value: any;
  message: { label: string };
  range: { min: number; max: number };
}

export function CheckYear(props: ICheckYear): TValidationResults {
  if (props.value !== "") {
    var valYear = props.value.$y || props.value;
    if (valYear > props.range.max || valYear < props.range.min) {
      return {
        status: true,
        message:
          props.message.label +
          " (" +
          props.range.min +
          " - " +
          props.range.max +
          ")",
      };
    }
  }
  return { status: false, message: "" };
}
