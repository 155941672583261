import { AppSettings } from "./Settings";

// ? Checked: Okay
// TODO: To test
export const UserCredentials = (uname: string) =>
  AppSettings.SERVER + "/user/" + uname;

// ? Checked: Okay
// TODO: To test
export const LogCount = (username: string) =>
  AppSettings.SERVER +
  "/count/" +
  username +
  "/gen_userlog/Username/" +
  username;

// ? Checked: Okay
// TODO: To test
export const LogUser = (username: string) =>
  AppSettings.SERVER + "/insert/" + username + "/gen_userlog";

// ? Checked: Okay
// TODO: To test
export const APPVERSION = (username: string) =>
  AppSettings.SERVER + "/read/" + username + "/gen_settings/key0/version";

// ? Checked: Okay
// TODO: To test
export const NOTIFICATION = (username: string) =>
  AppSettings.SERVER + "/notif/" + username;

// ! Tocheck
export const ReadProfile = (username: string, dbcd: string, name: string) =>
  AppSettings.SERVER + "/read/" + username + "/data2_" + dbcd + '_profile/sP1Name/' + name;

export const UploadProfile = (dbcd: string) =>
  AppSettings.SERVER + "/upprofile/" + dbcd;

export const ReadMachine = (username: string, dbcd: string, qr: string) =>
  AppSettings.SERVER + "/read/" + username + "/data2_" + dbcd + '_machine/sM1QRCode/' + qr;

export const UploadMachine = (dbcd: string) =>
  AppSettings.SERVER + "/upmachine/" + dbcd;

export function UpdateMachineImage(dbcd: string, column: string, data: string) {
  return (
    AppSettings.SERVER +
    "/editl/data2_" +
    dbcd +
    "_machine/" +
    column +
    "/" +
    data
  );
}

export const ReadImplement = (username: string, dbcd: string, qr: string) =>
  AppSettings.SERVER + "/read/" + username + "/data2_" + dbcd + '_implement/sI1QRCode/' + qr;

export const UploadImplement = (dbcd: string) =>
  AppSettings.SERVER + "/upimplement/" + dbcd;

export function UpdateImplementImage(
  dbcd: string,
  column: string,
  data: string
) {
  return (
    AppSettings.SERVER +
    "/editl/data2_" +
    dbcd +
    "_implement/" +
    column +
    "/" +
    data
  );
}

// /////////////////////////////////////////////////////////////////////////////

// https://127.0.0.1:4433/editl/data_test_machine/id/1

export const ReadRows = (dbcd: string, fron: number, to: number) => {
  return AppSettings.SERVER + "/getrows/" + dbcd + "/" + fron + "/" + to;
};

export const ReadAllRowsButImgs = (dbcd: string) => {
  return AppSettings.SERVER + "/getallrowsbutimgs/" + dbcd;
};

export const GetImage = (
  dbcd: string,
  key: string,
  value: string,
  target: string
) => {
  return (
    AppSettings.SERVER +
    "/read/" +
    dbcd +
    "/" +
    key +
    "/" +
    value +
    "/" +
    target
  );
};
