import { GET, SET } from "../util/AType";
import { TPosition } from "../util/AType";
import { useEffect, useState } from "react";
import { REQUIRED, Validate } from "../valid/Validations";
import { avBackup, IAVRequired } from "./AVGeneral";
import { InputAdornment, TextField } from "@mui/material";
import { assistBorderRadius, assistWidth } from "../util/AFunc";
import { safeRead } from "../../app/GenFunctions";

export interface IAVFieldProps extends IAVRequired {
  /**  */
  rows?: number;
  /**  */
  disabled?: boolean;
  /**  */
  position?: TPosition;
  /**  */
  borderRadius?: number;
  /**  */
  open?: boolean;
  /**  */
  unitPosition?: "start" | "end";
  /**  */
  width?: number;
  /**  */
  id?: string;
  /**  */
  // max?: number;
  /**  */
  // free?: boolean;
  /**  */
  // className?: string;
  /**  */
  // width?: number;
  /**  */
  // onFocus?: (e: any) => void;
  /**  */
  // ! Check if this is being used, remove if not
  // onChange?: (e: any, value: any) => void;
  /**  */
  // ! Check if this is being used, remove if not
  // onInputChange?: (e: any, value: any) => void;
  /**  */
  // group?: (option: any) => string;
}

export function AVField(props: IAVFieldProps) {
  const [errorStatus, setErrorStatus] = useState(false);
  const [helperText, setHelperText] = useState(props.text.helperText);
  const [focus] = useState(props.open || false);

  const onChange = (value: any) => {
    if (value === undefined) value = "";
    props.value[SET]?.(value);
    avBackup(props.backup, JSON.stringify(value));
    if (props.validation) {
      Validate({
        value: value,
        validation: props.validation,
        setStatus: setErrorStatus,
        setText: setHelperText,
      });
    }
  };

  // TODO May cause logging
  useEffect(() => {
    onChange(props.value[GET]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (props.validation?.required) {
      if (safeRead(props.value[GET]) === "") {
        setErrorStatus(true);
        setHelperText(REQUIRED);
      } else if (
        props.validation.condition === "none" &&
        safeRead(props.value[GET]) !== ""
      ) {
        setErrorStatus(false);
        setHelperText(props.text.helperText);
      }
    }
  }, [
    props.text.helperText,
    props.validation.condition,
    props.validation.message.label,
    props.validation?.required,
    props.value,
  ]);

  return (
    <TextField
      fullWidth
      size="small"
      id={props.id}
      rows={props.rows}
      error={errorStatus}
      helperText={helperText}
      value={props.value[GET]}
      label={props.text.label}
      disabled={props.disabled}
      placeholder={props.text.placeholder}
      multiline={props.rows ? true : false}
      InputLabelProps={{ shrink: props.open }}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        endAdornment: props.unitPosition == null && focus && (
          <InputAdornment position="end">{props.text.unit}</InputAdornment>
        ),
        startAdornment: props.unitPosition === "start" && focus && (
          <InputAdornment position="start">{props.text.unit}</InputAdornment>
        ),
        style: {
          borderRadius: assistBorderRadius({
            borderRadius: props.borderRadius,
            horPosition: props.position,
          }),
        },
      }}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
      }}
    />
  );
}
