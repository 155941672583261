// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

import "./VerifyPage.css";
import { Suspense, useState } from "react";
// import DataArrayIcon from "@mui/icons-material/DataArray";
// import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import CopyrightIcon from "@mui/icons-material/Copyright";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";

import axios from "axios";
import { sha512 } from "crypto-hash";
import { Await, LoaderFunctionArgs } from "react-router-dom";
import {
  ReadAllRowsButImgs,
  ReadRows,
  UserCredentials,
} from "../../app/Server";
import { fxdRead, fxdUser, tmpGen, tmpRead, tmpWrite } from "../../app/Storage";
import { AUTH_PROVIDER } from "../../hooks/auth/auth";
import { AVPopup } from "../../arv/com/AVPopup";
import { AvzRoute, gotoLogin, requireLoadProperly } from "../../app/Routes";
import React from "react";

import m3dasLogo from "../../assets/img/icon/m3das/logo512.png";
import { VBar } from "../../arv/box/VBar";

import TX from "../../../package.json";
// import GenerateTable from "./M3Table";
import GenerateTable from "./M3Table";
import { ProfileData } from "../profile/ProfilePage";
import { MachineData } from "../machine/MachinePage";
import { ImplementData } from "../implement/ImplementPage";
import { CircularProgress } from "@mui/material";

// /////////////////////////////////////////////////////////////////////////////
// THE PROFILE PAGE ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

enum IDCode {
  profile = "profile",
  machine = "machine",
  implement = "implement",
}

export function VerifyPageLoader({ request }: LoaderFunctionArgs) {
  var check = gotoLogin(request.url);
  if (check) return check;
  return null;
}

export default function VerifyPage() {
  const verified = tmpRead(tmpGen.verified) === "true";
  const def = <h1 style={{ color: "white" }}> </h1>;
  const ver = <VerificationUI />;
  const [content, setContent] = React.useState(verified ? ver : def);

  if (requireLoadProperly(AvzRoute.verify) === null) {
    if (!verified) {
      AVPopup({
        icon: "warning",
        input: "password",
        title: "Authentication Required",
        text: "Kindly enter your password to proceed.",
        confirmButtonText: "Verify",
        allowOutsideClick: true,
      }).then(async (code) => {
        if (code.isConfirmed) {
          var uname = fxdRead(fxdUser.name);
          if (uname !== null) {
            await axios.get(UserCredentials(uname)).then(async (res) => {
              if (res.data.Password === (await sha512(String(code.value)))) {
                tmpWrite(tmpGen.verified, "true");
                // setContent(ver);
                // Set ver as setContent
                setContent(<VerificationUI />);
              } else {
                AVPopup({
                  type: "error",
                  title: "Failed to Authenticate",
                  text:
                    "Incorrect password. " +
                    "Kindly retry to open web verification app.",
                  confirmButtonText: "Retry",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = AvzRoute.home;
                  }
                });
              }
            });
          } else {
            AVPopup({
              type: "error",
              title: "Failed to Authenticate",
              text:
                "Missing some user credentials. " +
                "Kindly re-login to open web verification app.",
              confirmButtonText: "Logout",
            }).then((result) => {
              if (result.isConfirmed) {
                AUTH_PROVIDER.signout().then(() => {
                  window.location.reload();
                });
              }
            });
          }
        }
      });
    }
  }

  // const [database, setDatabase] = useState("");

  function VerificationUI() {
    const [open, setopen] = useState(true);
    const [visArea, setVisArea] = useState(false);

    const toggleOpen = () => {
      setopen(!open);
      if (open) setVisArea(false);
    };

    // const [db, setDB] = useState(database);
    // useEffect(() => {
    //   setDB(database);
    // });

    const [ct, setCT] = useState<JSX.Element>(<div></div>);
    // function setCTFunction(table: JSX.Element) {
    //   setCT(table);
    // }

    const LogoBig = (props: { open: boolean }) => {
      var fname = fxdRead(fxdUser.frNm);
      var lname = fxdRead(fxdUser.lsNm);
      var name = fname + " " + lname;
      var off = fxdRead(fxdUser.offc);
      var pos = fxdRead(fxdUser.role);

      return (
        <div hidden={!props.open} className="user-info">
          <img
            alt="m3das-logo"
            src={m3dasLogo}
            className="big-icon"
            onClick={() => (window.location.href = AvzRoute.home)}
          />
          <p className="text-center">{name.toUpperCase()}</p>
          <p className="text-center">{off + " " + pos}</p>
        </div>
      );
    };

    const LogoSmall = (props: { open: boolean }) => (
      <div hidden={props.open} className="user-info">
        <img
          alt="m3das-logo"
          src={m3dasLogo}
          className="small-icon"
          onClick={() => (window.location.href = AvzRoute.home)}
        />
      </div>
    );

    const Copyright = (props: { open: boolean }) => {
      return props.open ? (
        <p className="text-copyright">
          Copyright © 2024
          <br /> All rights reserved <br /> UPLB BIOMECH M3DAS
        </p>
      ) : (
        <button
          className={"btn-side"}
          onClick={() => {
            // APopup version
            AVPopup({
              icon: "info",
              text: "M3DAS Web App Version " + TX.version,
              confirmButtonText: "Close",
            });
          }}
        >
          <CopyrightIcon />
          <span className={"link-text"}></span>
        </button>
      );
    };

    const Areas = (props: { open: boolean; title: IArea[] }) => {
      return (
        <VBar in={props.open}>
          <VBar in={props.open}>
            <a className="btn-side category" href="#profiles">
              <span className="link-text">Profiles</span>
            </a>
            <a className="btn-side category" href="#machines">
              <span className="link-text">Machines</span>
            </a>
            <a className="btn-side category" href="#implements">
              <span className="link-text">Implements</span>
            </a>
          </VBar>
          <hr />
          {props.title.map((area) => (
            <Area key={area.text} area={area} />
          ))}
        </VBar>
      );
    };

    const Area = (props: { area: IArea }) => {
      // let id = "#" + props.area.text.replace(/\s/g, "");
      // const [open, setOpen] = useState(false);

      // const toggleOpen = () => {
      //   setOpen(!open);
      // };

      return (
        <div>
          <nav
            className={"btn-side areas nav nav-pills"}
            onClick={() => {
              var prf = props.area.db + IDCode.profile;
              var mch = props.area.db + IDCode.machine;
              var imp = props.area.db + IDCode.implement;
              setCT(
                <>
                  <div id="profiles" className="table-box">
                    <h4 style={{ textAlign: "center" }}>
                      {(props.area.text + " PROFILES").toUpperCase()}
                    </h4>
                    <p></p>
                    {
                      <Suspense fallback={<CircularProgress />}>
                        <Await
                          resolve={fetchData(prf)}
                          errorElement={<div>Failed to load data</div>}
                          children={(res: M3Database) => (
                            <GenerateTable
                              key={Math.random()}
                              res={res}
                              db={prf}
                            />
                          )}
                        ></Await>
                      </Suspense>
                    }
                  </div>
                  <div id="machines" className="table-box">
                    <h4 style={{ textAlign: "center" }}>
                      {(props.area.text + " MACHINES").toUpperCase()}
                    </h4>
                    <p></p>
                    {
                      <Suspense fallback={<CircularProgress />}>
                        <Await
                          resolve={fetchData(mch)}
                          errorElement={<div>Failed to load data</div>}
                          children={(res: M3Database) => (
                            <GenerateTable
                              key={Math.random()}
                              res={res}
                              db={mch}
                            />
                          )}
                        ></Await>
                      </Suspense>
                    }
                  </div>
                  <div id="implements" className="table-box">
                    <h4 style={{ textAlign: "center" }}>
                      {(props.area.text + " IMPLEMENTS").toUpperCase()}
                    </h4>
                    <p></p>
                    {
                      <Suspense fallback={<CircularProgress />}>
                        <Await
                          resolve={fetchData(imp)}
                          errorElement={<div>Failed to load data</div>}
                          children={(res: M3Database) => (
                            <GenerateTable
                              key={Math.random()}
                              res={res}
                              db={imp}
                            />
                          )}
                        ></Await>
                      </Suspense>
                    }
                  </div>
                </>
                // <div>
                //   <div className="table-box">
                //     <h4>Profiles</h4>
                //     {prfTbl}
                //   </div>
                //   <div className="table-box">
                //     <h4>Machines</h4>
                //     {mchTbl}
                //   </div>
                //   <div className="table-box">
                //     <h4>Implements</h4>
                //     {impTbl}
                //   </div>
                // </div>
              );
            }}
          >
            {/* <a className="nav-link" href={id + IDCode.profile}> */}
            <button>
              <span className="link-text area">{props.area.text}</span>
              {/* <ArrowDown hidden={false} /> */}
            </button>
          </nav>
          {/* <Data open={open} area={props.area} /> */}

          {/* <Category open={open} /> */}
        </div>
      );
    };

    // const Category = (props: { open: boolean }) => {
    //   const [, setOpen] = useState(false);
    //   useEffect(() => {
    //     setOpen(props.open);
    //   }, [props.open]);
    //   // let id = "#" + props.area.text.replace(/\s/g, "").toLowerCase();

    //   return (
    //     <VBar in={props.open}>
    //       <a className="btn-side category" href="#profiles">
    //         <span className="link-text">Profiles</span>
    //       </a>
    //       <a className="btn-side category" href="#machines">
    //         <span className="link-text">Machines</span>
    //       </a>
    //       <a className="btn-side category" href="#implements">
    //         <span className="link-text">Implements</span>
    //       </a>
    //     </VBar>
    //     // <VBar in={open}>
    //     //   {/* <nav className="nav nav-pills flex-column"> */}
    //     //   {/* <a className="nav-link" href="#item-1">
    //     //   Item 1
    //     // </a> */}
    //     //   <nav className="nav nav-pills flex-column">
    //     //     <a
    //     //       className="btn-side data nav-link ml-3 my-1"
    //     //       onClick={() => {
    //     //         var database = props.area.db + IDCode.profile;
    //     //         // setCT(
    //     //         //   <GenerateTable
    //     //         //     key={database + Math.random()}
    //     //         //     database={database}
    //     //         //   />
    //     //         // );
    //     //         // console.log(database);
    //     //       }}
    //     //     >
    //     //       Profiles
    //     //     </a>
    //     //     <a
    //     //       className="btn-side data nav-link ml-3 my-1"
    //     //       onClick={() => {
    //     //         var database = props.area.db + IDCode.machine;
    //     //         // setCT(
    //     //         //   <GenerateTable
    //     //         //     key={database + Math.random()}
    //     //         //     database={database}
    //     //         //   />
    //     //         // );
    //     //         // console.log(database);
    //     //       }}
    //     //     >
    //     //       Machines
    //     //     </a>
    //     //     <a
    //     //       className="btn-side data nav-link ml-3 my-1"
    //     //       onClick={() => {
    //     //         var database = props.area.db + IDCode.implement;
    //     //         setCT(
    //     //           // <Await
    //     //           //   resolve={fetchData(database)}
    //     //           //   errorElement={<div>Could not load user details 😬</div>}
    //     //           //   children={(database: M3Database) => (
    //     //           //     <GenerateTable key={Math.random()} database={database} />
    //     //           //   )}
    //     //           // ></Await>
    //     //           <div>HEYHEYHEY</div>
    //     //         );
    //     //         // console.log(database);
    //     //       }}
    //     //     >
    //     //       Implements
    //     //     </a>
    //     //   </nav>
    //     //   {/* </nav> */}

    //     //   {/* <button className={"btn-side data"}>
    //     //   <span className={"link-text"}>Profiles</span>
    //     // </button>
    //     // <button className={"btn-side data"}>
    //     //   <span className={"link-text"}>Machines</span>
    //     // </button>
    //     // <button className={"btn-side data"}>
    //     //   <span className={"link-text"}>Implements</span>
    //     // </button> */}
    //     // </VBar>
    //   );
    // };

    return (
      <div className="verify-page">
        <div
          className={`main-bg-animated side-nav ${
            open ? "" : "side-nav-close"
          }`}
        >
          <div>
            <LogoBig open={open} />
            <LogoSmall open={open} />
            {open ? <p></p> : null}
            <div className="btn-side">
              <button
                className={`btn-menu ${open ? "" : "btn-menu-close"}`}
                onClick={toggleOpen}
              >
                {open ? (
                  <KeyboardDoubleArrowLeftIcon />
                ) : (
                  <KeyboardDoubleArrowRightIcon />
                )}
                {open ? <p className="link-text">Hide</p> : null}
              </button>
            </div>
            <button className="btn-side" onClick={() => setVisArea(!visArea)}>
              <BarChartIcon />
              <span hidden={!open} className="link-text">
                Verify
              </span>
              <ArrowDown hidden={!open} />
            </button>
            <Areas open={visArea} title={AreasList} />
            <a className="btn-side" href="#settings">
              <SettingsIcon />
              <span hidden={!open} className="link-text">
                Settings
              </span>
            </a>
          </div>
          <Copyright open={open} />
        </div>
        <div className={`main-pane ${open ? "" : "main-pane-wide"}`}>
          <div className="table-set">{ct}</div>
        </div>
      </div>
    );
  }

  return requireLoadProperly(AvzRoute.verify) ?? content;
}

// /////////////////////////////////////////////////////////////////////////////

export type M3Database = (
  | (ProfileData & { id: string })
  | (MachineData & { id: string })
  | (ImplementData & { id: string })
)[];

export async function fetchData(database: string) {
  try {
    var data = await axios.get(ReadAllRowsButImgs(database));
    var keys = await axios.get(ReadRows(database, 0, 1));
    return { data: data, keys: keys };
  } catch (error: any) {
    console.error(error.response.data);
    return null;
  }
}

// /////////////////////////////////////////////////////////////////////////////

interface IArea {
  text: string;
  db: string;
}

var AreasList: IArea[] = [
  { text: "Ormoc", db: "data_ley_" },
  { text: "Negros Oriental", db: "data_ner_" },
  { text: "Bacolod", db: "data_nob_" },
  { text: "La Granja", db: "data_nol_" },
  // { text: "TEST", db: "data_test_" },
];

const ArrowDown = (props: { hidden: boolean }) => (
  <span hidden={props.hidden} className="arrow">
    {" "}
    ⌄
  </span>
);
