import { ReactNode } from "react";
import { collapseAssist } from "./Box";

// /////////////////////////////////////////////////////////////////////////////

export function HBar(props: {
  id?: string;
  class?: string;
  gap?: number;
  children?: ReactNode;
  /** Transition IN */
  in?: boolean;
  force?: boolean;
}) {
  const bar = (
    <div
      id={props.id}
      className={props.class}
      style={{ display: "flex", gap: props.gap }}
    >
      {props.children}
    </div>
  );

  return props.in != null ? collapseAssist(bar, props.in, props.force) : bar;
}
