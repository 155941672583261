// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import "./ErrorPage.css";
import TX from "../../pages/app/App.json";
import { AvzRoute } from "../../app/Routes";

// !!! Add clear storage and special key support

// /////////////////////////////////////////////////////////////////////////////
// Page Not Found //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ErrorPageProps {
  title: string;
  heading: string;
  message: string;
  button: string;
}

// A website for converting HTML to JSX
// https://magic.reactjs.net/htmltojsx.htm
export default function ErrorPage(props?: ErrorPageProps) {
  const str = TX.app.pagenotfound;

  return (
    <div className="error-page main-bg-form w-dvw h-dvh">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1 className="h1 title">{props?.heading ?? str.heading}</h1>
      <p className="message">{props?.message ?? str.message}</p>
      <button className="button">
        <a
          href={AvzRoute.home}
          style={{ textDecoration: "none", color: "#333" }}
        >
          {props?.button ?? str.button}
        </a>
      </button>
    </div>
  );
}
