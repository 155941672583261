// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { ToBeAcquired } from "../../app/GenEnums";
import { safeRead } from "../../app/GenFunctions";
import { TValidationResults } from "../util/AType";

interface ICheckContact {
  value: any;
  message: { label: string };
}

export function CheckContact(props: ICheckContact): TValidationResults {
  if (props.value !== "") {
    var value = safeRead(props.value);
    var valIsNotNumber = isNaN(value);
    var isToBeAcquired = String(value) === ToBeAcquired;

    if (!isToBeAcquired) {
      if (
        valIsNotNumber ||
        value.length < 10 || value.length > 12 ||
        (value.length === 10 && value.substring(0, 2) !== "02") ||
        (value.length === 11 && value.substring(0, 2) !== "09") ||
        (value.length === 12 && value.substring(0, 3) !== "639")
      ) {
        return { status: true, message: props.message.label };
      }
    }
  }
  return { status: false, message: "" };
}
