export const TX = {
  pop: {
    recover: {
      title: "Unsaved Profile Exists",
      message:
        "Would you like to continue with the unsaved profile? This may take a while.",
      yes: "Recover",
      no: "No",
      recovering: "Recovering . . .",
    },
    consent: {
      accept: "Accept",
    },
    saveErrors: {
      browserError: "Upgrade your browser.",
      missing: "Kindly fill out the missing fields above.",
      button: "Go to Error",
      saveIni: "Initializing for saving . . .",
      withError: "There are errors in the form. Correct them before saving.",
    },
    saveSuccess: {
      title: "Save Profile",
      content: "Profile saved locally.",
      button: "Close",
    },
  },
  question: {
    category: "Profile",
    tP1Title: "RESPONDENT",
    tP1Name: {
      label: "Complete Name",
      placeholder: "First Name MI. Last Name",
    },
    tP1Age: {
      label: "Age",
      placeholder: "0 (in years)",
    },
    tP1Sex: {
      label: "Sex",
      placeholder: "Choose an Option",
      options: [
        {
          label: "Male",
        },
        {
          label: "Female",
        },
      ],
    },
    tP1Educ: {
      label: "Educational Attainment",
      placeholder: "Choose an Option",
      options: [
        {
          label: "Primary (Elementary)",
        },
        {
          label: "Secondary (High School)",
        },
        {
          label: "Tertiary (College)",
        },
        {
          label: "Post-graduate",
        },
        {
          label: "Diploma",
        },
        {
          label: "Vocational Technical",
        },
        {
          label: "Not Applicable",
        },
      ],
    },
    tP1Cont1: {
      label: "Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    tP1Cont2: {
      label: "Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    tP1Prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    tP1Mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    tP1Brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    tP2Profile: {
      label: "Profile of Respondent",
      placeholder: "Select Role",
      options: [
        {
          label: "Owner",
          anchored: true,
        },
        {
          label: "Representative",
          anchored: true,
        },
      ],
    },
    tP2Scope: {
      label: "Scope of Ownership",
      options: [
        {
          label: "Farm",
          key: "farm",
          anchored: true,
        },
        {
          label: "Processing Plant",
          key: "plant",
        },
        {
          label: "Garage",
          key: "garage",
        },
        {
          label: "Transloading Zone",
          key: "zone",
        },
      ],
    },
    tP2Position: {
      label: "Position / Title",
      placeholder: "Select Position / Title",
      options: [
        {
          label: "Operator of Machine",
        },
        {
          label: "Manager of Transloading Zone",
        },
        {
          label: "Farm Manager",
        },
        {
          label: "Officer-in-charge",
        },
        {
          label: "Representative",
        },
      ],
    },
    tP3Title: "OWNER OR ORGANIZATION",
    tP3Name: {
      label: "Complete Name",
      placeholder: "First Name MI. Last Name",
    },
    tP3Class: {
      label: "Owner Classification",
      placeholder: "Choose an Option",
      options: [
        {
          label: "Private Individual / Business",
        },
        {
          label: "Organization / Association",
        },
        {
          label: "Local Government Unit (LGU)",
        },
      ],
    },
    tP3Cont1: {
      label: "Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    tP3Cont2: {
      label: "Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    tP3Prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    tP3Mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    tP3Brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    tP4Title: "FARM",
    tP4Name: {
      label: "Name of Organization",
      placeholder: "Complete Name of Organization",
    },
    tP4Accre: {
      label: "Is the farm accredited by SRA?",
      placeholder: "Choose an Option",
      options: [
        {
          label: "Yes",
        },
        {
          label: "No",
        },
      ],
    },
    tP4Area: {
      label: "Sugarcane Farm Area, ha",
      placeholder: "0.0",
      unit: "ha",
    },
    tP4Mach: {
      label: "Most Frequently Rented Machine",
      placeholder: "Select a Machine",
    },
    tP4Impl: {
      label: "Operation with Most Frequently Rented Implements",
      placeholder: "Select an Implement",
      options: [
        { label: "For Land Preparation" },
        { label: "For Planting" },
        { label: "For Crop Management" },
        { label: "For Harvesting" },
        { label: "For Hauling" },
        { label: "Not Applicable" },
      ],
    },
    tP4Mngmt: {
      label: "Type of Farm Management",
      placeholder: "Select a Management Practice",
      options: [
        { label: "Individual Management" },
        { label: "Collective Management" },
      ],
    },
    tP4IrrigTitle: "Type of Irrigation",
    tP4Irrig: {
      label: "Irrigation System",
      placeholder: "Select an Irrigation System",
      options: [
        { label: "Sprinkler Irrigation", anchored: true },
        { label: "Drip Irrigation", anchored: true },
        { label: "Furrow Irrigation", anchored: true },
        { label: "Relying Solely on Rainfall", anchored: true },
      ],
    },
    tP4Irrig1: {
      label: "First Irrigation",
    },
    tP4Irrig2: {
      label: "Second Irrigation",
    },
    tP4Irrig3: {
      label: "Third Irrigation",
    },
    tP4Varieties: "Varieties of Sugarcane",
    tP4PlntdVar: {
      label: "Planted Variety",
      placeholder: "Choose a Variety",
    },
    tP4PrefVar: {
      label: "Preferred Variety",
      placeholder: "Choose a Variety",
    },
    tP4Planting: "Planting Season",
    tP4PlntgStart: {
      label: "Start",
      placeholder: "Choose a Month",
    },
    tP4PlntgEnd: {
      label: "End",
      placeholder: "Choose a Month",
    },
    tP4Harvesting: "Harvesting Season",
    tP4Number: "Number of Months",
    tP4Plntg: {
      label: "Planting",
      placeholder: "0",
      unit: "months",
    },
    tP4Hrvstg: {
      label: "Harvesting",
      placeholder: "0",
      unit: "months",
    },
    tP4AvgYield: {
      label: "Ave. Yield of Planted Canes",
      field: {
        label: "Ave. Yield, tons/ha",
        placeholder: "0.00",
      },
    },
    tP4RtnMnths: {
      label: "No. of Months to Harvest Ratooned Crops",
      field: {
        label: "No. of Months",
        placeholder: "0",
      },
    },
    tP4RtnYield: {
      label: "Ave. Yield of Ratooned Canes",
      field: {
        label: "Ave. Yield, tons/ha",
        placeholder: "0.00",
        unit: "tons/ha",
      },
    },
    tP4RtnMax: {
      label: "Maximum No. of Ratooning",
      field: {
        label: "Maximum frequency",
        placeholder: "0",
      },
    },
    tP4SgrMills: {
      label: "At what sugar mills do you sell your harvested canes?",
      field: {
        label: "Sugar Mills",
        placeholder: "Select Sugar Mills",
      },
      options: [
        { label: "UNIVERSAL ROBINA CORP. (URCCARSUMCO), Region II" },
        { label: "CENTRAL AZUCARERA DE TARLAC, INC., Region III" },
        { label: "CENTRAL AZUCARERA DE BAIS INC., Region VII" },
        { label: "BISCOM INC., Region VI" },
        { label: "FIRST FARMERS HOLDING CORPORATION, Region VI" },
        { label: "HAWAIIAN-PHILIPPINE COMPANY, Region VI" },
        {
          label:
            "HDJ BAYAWAN AGRI-VENTURE CORPORATION (HDJ-BAYAWAN), Region VII",
        },
        { label: "UNIVERSAL ROBINA CORP. (URC-LA CARLOTA), Region VI" },
        { label: "LOPEZ SUGAR CORPORATION, Region VI" },
        { label: "UNIVERSAL ROBINA CORP. (URC-URSUMCO), Region VII" },
        { label: "SAGAY CENTRAL INC., Region VI" },
        { label: "UNIVERAL ROBINA CORP. (URC-SONEDCO), Region VI" },
        {
          label:
            "HDJ BAYAWAN AGRI-VENTURE CORPORATION (HDJ-TOLONG), Region VII",
        },
        { label: "VICTORIAS MILLING COMPANY, INC., Region VI" },
        {
          label:
            "ORGANIC PRODUCERS IN THE ISLAND OF NEGROS MULTI-PURPOSE COOPERATIVE (OPTION-MPC), Region VI",
        },
        { label: "HIDECO SUGAR MILLING CO., INC., Region VIII" },
        { label: "BUSCO SUGAR MILLING CO., INC., Region X" },
        { label: "COTABATO SUGAR CENTRAL COMPANY, INC., Region XII" },
        { label: "CRYSTAL SUGAR CO., INC., Region X" },
        { label: "DAVAO SUGAR CENTRAL CO., INC., Region XI" },
      ],
    },
    tP5Notes: {
      label: "Survey Notes",
      placeholder: "Enter some notes here",
    },
  },
  validation: {
    area: "Please input a valid area",
    yield: "Please input a valid yield.",
    months: "Please input valid number of months.",
    ratoon: "Please input a valid number of ratooning.",
  },
  action: {
    save: "Save Encoded Profile",
  },
};
