import "leaflet/dist/leaflet.css";
import "../../assets/scss/Forms.css";
import { TX } from "./MachinePageJSON";

import { fxdDelete, fxdGen, fxdUser } from "../../app/Storage";
import { fxdMachine, fxdRead, fxdWrite } from "../../app/Storage";
import { fxdMachineBackup, fxdProfile } from "../../app/Storage";
import { NavLink, redirect, useNavigate } from "react-router-dom";
import { LoaderFunctionArgs } from "react-router-dom";
import { DATA_STATUS } from "../../app/GenEnums";
import { gotoLogin } from "../../app/Routes";
import { AvzRoute, requireLoadProperly } from "../../app/Routes";
import { useEffect, useState } from "react";
import { TString, TValidation } from "../../arv/util/AType";
import { TSetAny, TSetString, TSetValidation } from "../../arv/util/AType";
import { Card } from "../../arv/box/Card";
import { VBar } from "../../arv/box/VBar";
import { HBar } from "../../arv/box/HBar";
import { TitleBar } from "../encode/EncodePage";
import { AVButton } from "../../arv/com/AVButton";
import { AVText } from "../../arv/com/AVText";
import { AVOVERRIDE, avScrollTo } from "../../arv/com/AVGeneral";
import { AVTick } from "../../arv/com/AVTick";
import { AVAlert } from "../../arv/com/AVPopup";
import { AVPopup } from "../../arv/com/AVPopup";
import WebCam from "../../arv/util/Camera";
import { GeoLocationPane } from "../../app/GeoLocation";
import { Address, CreateNewAddress } from "../../arv/util/Address";
import { Barangay, Municipality, Province } from "../../arv/util/Address";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { BrowserQRCodeReader } from "@zxing/library";
import { createZIP, safeRead } from "../../app/GenFunctions";
import L from "leaflet";
import { AppSettings } from "../../app/Settings";
import iMachine from "../../assets/img/icon/map/machine.png";
import { machines, oOBrands, oOModels } from "./MachineBrandsAndModels";
import { tCane, tHaul, tHrvt } from "./MachineBrandsAndModels";
import { tPump, tRGun, tTrc2, tTrc4 } from "./MachineBrandsAndModels";
import { ID } from "../../app/ElementID";
import {
  NameValidation,
  NoValidation,
  Rng,
  ValidateRequired,
  YearValidation,
} from "../../arv/valid/Validations";
import { ContactValidation } from "../../arv/valid/Validations";
import SaveIcon from "@mui/icons-material/Save";
import { Nav } from "react-bootstrap";
import { AVDropdown } from "../../arv/com/AVDropdown";
import { AVField } from "../../arv/com/AVField";

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const pop = TX.pop;
const vldtn = TX.validation;
const q = TX.question;
const action = TX.action;

const MCBKUP = fxdMachineBackup as Record<string, any>;
export async function MachinePageLoader({ request }: LoaderFunctionArgs) {
  if (fxdRead(fxdProfile.profiles) == null) {
    AVPopup({
      type: "error",
      title: pop.noProfile.title,
      text: pop.noProfile.message,
      confirmButtonText: pop.noProfile.button,
    });
    return redirect(AvzRoute.profile);
  } else {
    return gotoLogin(request.url) ?? null;
  }
}

// /////////////////////////////////////////////////////////////////////////////
// THE MACHINE PAGE ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export default function MachinePage() {
  // ///////////////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  const restoreFromLocalStorage = () => {
    var restoreFromBackup: Record<string, any> = {};
    Object.keys(MCBKUP).forEach((key) => {
      restoreFromBackup[key] = fxdRead(MCBKUP[key])
        ? JSON.parse(fxdRead(MCBKUP[key]) || "")
        : "";
    });
    return restoreFromBackup;
  };

  const isAllEmpty = (backup: Record<string, any>) => {
    return Object.values(backup).every((x) => safeRead(x) === "");
  };

  const backup = restoreFromLocalStorage();

  const restoreMachineBackups = (from: Record<string, any>) => {
    for (let i = 0; i < QUESTIONS.length; i++) {
      var val = from["b" + QUESTIONS[i].backup.slice(3)];
      if (val && typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(val);
      }
    }
  };

  function deleteMachineBackups() {
    Object.values(MCBKUP).forEach((val) => fxdDelete(val));
    fxdDelete(fxdMachine.backup);
  }

  useEffect(() => {
    console.log("M1");
    if (!isAllEmpty(backup)) {
      fxdWrite(fxdMachine.backup, JSON.stringify(backup));
    }

    var restored = JSON.parse(fxdRead(fxdMachine.backup) || "{}");
    var edit = JSON.parse(fxdRead(fxdGen.macEdit) || "{}");

    if (!isAllEmpty(restored) || fxdRead(fxdGen.macEdit)) {
      AVPopup({
        icon: "info",
        title: pop.backup.title,
        text: pop.backup.message,
        showConfirmButton: fxdRead(fxdGen.macEdit) ? true : false,
        confirmButtonText: pop.backup.deny,
        showDenyButton: fxdRead(fxdMachine.backup) ? true : false,
        denyButtonText: pop.backup.button,
        showCancelButton: true,
        allowOutsideClick: true,
      }).then((result) => {
        if (result.isConfirmed) {
          restoreMachineBackups(edit);
        } else if (result.isDenied) {
          restoreMachineBackups(restored);
        } else if (result.isDismissed) {
          deleteMachineBackups();
          fxdDelete(fxdGen.macEdit);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ///////////////////////////////////////////////////////////////////////////
  // STATES ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const [iM1ResName, setIM1ResName] = useState<any>("");
  const [eM1ResName, setEM1ResName] = useState(NoValidation);

  const [iM1QRCode, setIM1QRCode] = useState<any>("");
  const [eM1QRCode, setEM1QRCode] = useState(NoValidation);

  const [iM1FrontVw, setIM1FrontVw] = useState<any>("");
  const [eM1FrontVw, setEM1FrontVw] = useState(NoValidation);

  const [iM1SideVw, setIM1SideVw] = useState<any>("");
  const [eM1SideVw, setEM1SideVw] = useState(NoValidation);

  const [iM1SpecVw, setIM1SpecVw] = useState<any>("");
  const [eM1SpecVw, setEM1SpecVw] = useState(NoValidation);

  const [iM1Acc, setIM1Acc] = useState<any>("");
  const [eM1Acc, setEM1Acc] = useState(NoValidation);

  const [iM1Lat, setIM1Lat] = useState<any>("");
  const [eM1Lat, setEM1Lat] = useState(NoValidation);

  const [iM1Lng, setIM1Lng] = useState<any>("");
  const [eM1Lng, setEM1Lng] = useState(NoValidation);

  const [iM1Loc, setIM1Loc] = useState<any>("");
  const [eM1Loc, setEM1Loc] = useState(NoValidation);

  const [iM1Prov, setIM1Prov] = useState<any>("");
  const [eM1Prov, setEM1Prov] = useState(NoValidation);

  const [iM1Mun, setIM1Mun] = useState<any>("");
  const [eM1Mun, setEM1Mun] = useState(NoValidation);

  const [iM1Brgy, setIM1Brgy] = useState<any>("");
  const [eM1Brgy, setEM1Brgy] = useState(NoValidation);

  const [iM1Type, setIM1Type] = useState<any>("");
  const [eM1Type, setEM1Type] = useState(NoValidation);

  const [iM1Serial, setIM1Serial] = useState<any>("");
  const [eM1Serial, setEM1Serial] = useState(NoValidation);

  const [iM1CovHlng, setIM1CovHlng] = useState<any>("");
  const [eM1CovHlng, setEM1CovHlng] = useState(NoValidation);

  const [iM2Brand, setIM2Brand] = useState<any>("");
  const [eM2Brand, setEM2Brand] = useState(NoValidation);

  const [iM2Model, setIM2Model] = useState<any>("");
  const [eM2Model, setEM2Model] = useState(NoValidation);

  const tM2RtdPwr = Rng(0, 999, vldtn.power);
  const [iM2RtdPwr, setIM2RtdPwr] = useState<any>("");
  const [eM2RtdPwr, setEM2RtdPwr] = useState(tM2RtdPwr);

  const tM2TtlSrvArea = Rng(0, 99999, vldtn.area);
  const [iM2TtlSrvArea, setIM2TtlSrvArea] = useState<any>("");
  const [eM2TtlSrvArea, setEM2TtlSrvArea] = useState(tM2TtlSrvArea);

  const tM2AvgOpHrDy = Rng(0, 24, vldtn.value);
  const [iM2AvgOpHrDy, setIM2AvgOpHrDy] = useState<any>("");
  const [eM2AvgOpHrDy, setEM2AvgOpHrDy] = useState(tM2AvgOpHrDy);

  const tM2AvgOpDySsn = Rng(0, 366, vldtn.value);
  const [iM2AvgOpDySsn, setIM2AvgOpDySsn] = useState<any>("");
  const [eM2AvgOpDySsn, setEM2AvgOpDySsn] = useState(tM2AvgOpDySsn);

  const tM2LdCap = Rng(0, 99999, vldtn.capacity);
  const [iM2LdCap, setIM2LdCap] = useState<any>("");
  const [eM2LdCap, setEM2LdCap] = useState(tM2LdCap);

  const [iM2LdCapUnit, setIM2LdCapUnit] = useState<any>("");
  const [eM2LdCapUnit, setEM2LdCapUnit] = useState(NoValidation);

  const tM2LdCapDay = Rng(0, 9999, vldtn.load);
  const [iM2LdCapDay, setIM2LdCapDay] = useState<any>("");
  const [eM2LdCapDay, setEM2LdCapDay] = useState(tM2LdCapDay);

  const tM2TmCom1Ha = Rng(0, 99, vldtn.time);
  const [iM2TmCom1Ha, setIM2TmCom1Ha] = useState<any>("");
  const [eM2TmCom1Ha, setEM2TmCom1Ha] = useState(tM2TmCom1Ha);

  const tM2TmCom1Mi = Rng(0, 99, vldtn.yield);
  const [iM2SgrcnYld, setIM2SgrcnYld] = useState<any>("");
  const [eM2SgrcnYld, setEM2SgrcnYld] = useState(tM2TmCom1Mi);

  const [iM3TpTbWlls, setIM3TpTbWlls] = useState<any>("");
  const [eM3TpTbWlls, setEM3TpTbWlls] = useState(NoValidation);

  const [iM3Brand, setIM3Brand] = useState<any>("");
  const [eM3Brand, setEM3Brand] = useState(NoValidation);

  const [iM3Model, setIM3Model] = useState<any>("");
  const [eM3Model, setEM3Model] = useState(NoValidation);

  const tM3SzPmp = Rng(0, 99, vldtn.size);
  const [iM3SzPmp, setIM3SzPmp] = useState<any>("");
  const [eM3SzPmp, setEM3SzPmp] = useState(tM3SzPmp);

  const [iM3SrcPwr, setIM3SrcPwr] = useState<any>("");
  const [eM3SrcPwr, setEM3SrcPwr] = useState(NoValidation);

  const [iM3SrcBrand, setIM3SrcBrand] = useState<any>("");
  const [eM3SrcBrand, setEM3SrcBrand] = useState(NoValidation);

  const [iM3SrcModel, setIM3SrcModel] = useState<any>("");
  const [eM3SrcModel, setEM3SrcModel] = useState(NoValidation);

  const tM3RtdPwr = Rng(0, 999, vldtn.power);
  const [iM3RtdPwr, setIM3RtdPwr] = useState<any>("");
  const [eM3RtdPwr, setEM3RtdPwr] = useState(tM3RtdPwr);

  const tM3DisCap = Rng(0, 9999, vldtn.discharge);
  const [iM3DisCap, setIM3DisCap] = useState<any>("");
  const [eM3DisCap, setEM3DisCap] = useState(tM3DisCap);

  const [iM3DisCapUnit, setIM3DisCapUnit] = useState<any>("");
  const [eM3DisCapUnit, setEM3DisCapUnit] = useState(NoValidation);

  const tM3TtlSrvArea = Rng(0, 99999, vldtn.area);
  const [iM3TtlSrvArea, setIM3TtlSrvArea] = useState<any>("");
  const [eM3TtlSrvArea, setEM3TtlSrvArea] = useState(tM3TtlSrvArea);

  const tM3AvgOpHrDy = Rng(0, 12, vldtn.value);
  const [iM3AvgOpHrDy, setIM3AvgOpHrDy] = useState<any>("");
  const [eM3AvgOpHrDy, setEM3AvgOpHrDy] = useState(tM3AvgOpHrDy);

  const tM3AvgOpDySsn = Rng(0, 999, vldtn.value);
  const [iM3AvgOpDySsn, setIM3AvgOpDySsn] = useState<any>("");
  const [eM3AvgOpDySsn, setEM3AvgOpDySsn] = useState(tM3AvgOpDySsn);

  const [iM4Usage, setIM4Usage] = useState<any>("");
  const [eM4Usage, setEM4Usage] = useState(NoValidation);

  const [iM4Mode, setIM4Mode] = useState<any>("");
  const [eM4Mode, setEM4Mode] = useState(NoValidation);

  const [iM4Agency, setIM4Agency] = useState<any>("");
  const [eM4Agency, setEM4Agency] = useState(NameValidation);

  const [iM4YrAcqrd, setIM4YrAcqrd] = useState<any>("");
  const [eM4YrAcqrd, setEM4YrAcqrd] = useState(YearValidation);

  const [iM4ConAcqrd, setIM4ConAcqrd] = useState<any>("");
  const [eM4ConAcqrd, setEM4ConAcqrd] = useState(NoValidation);

  const [iM4PrsntCon, setIM4PrsntCon] = useState<any>("");
  const [eM4PrsntCon, setEM4PrsntCon] = useState(NoValidation);

  const tM4NoBrkdwn = Rng(0, 999, vldtn.breakdown);
  const [iM4NoBrkdwn, setIM4NoBrkdwn] = useState<any>("");
  const [eM4NoBrkdwn, setEM4NoBrkdwn] = useState(tM4NoBrkdwn);

  const [iM4CmnBrkdwn, setIM4CmnBrkdwn] = useState<any>("");
  const [eM4CmnBrkdwn, setEM4CmnBrkdwn] = useState(NoValidation);

  const [iM4YrUnused, setIM4YrUnused] = useState<any>("");
  const [eM4YrUnused, setEM4YrUnused] = useState(YearValidation);

  const [iM4RsnUnused, setIM4RsnUnused] = useState<any>("");
  const [eM4RsnUnused, setEM4RsnUnused] = useState(NoValidation);

  const [iM4UuOthers, setIM4UuOthers] = useState<any>("");
  const [eM4UuOthers, setEM4UuOthers] = useState(NoValidation);

  const [iM4YrUnserv, setIM4YrUnserv] = useState<any>("");
  const [eM4YrUnserv, setEM4YrUnserv] = useState(YearValidation);

  const [iM4RsnUnserv, setIM4RsnUnserv] = useState<any>("");
  const [eM4RsnUnserv, setEM4RsnUnserv] = useState(NoValidation);

  const [iM4UsOthers, setIM4UsOthers] = useState<any>("");
  const [eM4UsOthers, setEM4UsOthers] = useState(NoValidation);

  const [iM5SameOwn, setIM5SameOwn] = useState<any>("");
  const [eM5SameOwn, setEM5SameOwn] = useState(NoValidation);

  const [iM5BusCont1, setIM5BusCont1] = useState<any>("");
  const [eM5BusCont1, setEM5BusCont1] = useState(ContactValidation);

  const [iM5BusCont2, setIM5BusCont2] = useState<any>("");
  const [eM5BusCont2, setEM5BusCont2] = useState(ContactValidation);

  const [iM5Consent, setIM5Consent] = useState<any>("");
  const [eM5Consent, setEM5Consent] = useState(NoValidation);

  const [iM5Ope1, setIM5Ope1] = useState<any>("");
  const [eM5Ope1, setEM5Ope1] = useState(NoValidation);
  const tM5OpeRate = Rng(0, 9999999, vldtn.rate);
  const [iM5Ope1Rate, setIM5Ope1Rate] = useState<any>("");
  const [eM5Ope1Rate, setEM5Ope1Rate] = useState(tM5OpeRate);
  const [iM5Ope1Unit, setIM5Ope1Unit] = useState<any>("");
  const [eM5Ope1Unit, setEM5Ope1Unit] = useState(NoValidation);
  const tM5OpeFuel = Rng(0, 99, vldtn.fuel);
  const [iM5Ope1Fuel, setIM5Ope1Fuel] = useState<any>("");
  const [eM5Ope1Fuel, setEM5Ope1Fuel] = useState(tM5OpeFuel);

  const [iM5Ope2, setIM5Ope2] = useState<any>("");
  const [eM5Ope2, setEM5Ope2] = useState(NoValidation);
  const [iM5Ope2Rate, setIM5Ope2Rate] = useState<any>("");
  const [eM5Ope2Rate, setEM5Ope2Rate] = useState(tM5OpeRate);
  const [iM5Ope2Unit, setIM5Ope2Unit] = useState<any>("");
  const [eM5Ope2Unit, setEM5Ope2Unit] = useState(NoValidation);
  const [iM5Ope2Fuel, setIM5Ope2Fuel] = useState<any>("");
  const [eM5Ope2Fuel, setEM5Ope2Fuel] = useState(tM5OpeFuel);

  const [iM5Ope3, setIM5Ope3] = useState<any>("");
  const [eM5Ope3, setEM5Ope3] = useState(NoValidation);
  const [iM5Ope3Rate, setIM5Ope3Rate] = useState<any>("");
  const [eM5Ope3Rate, setEM5Ope3Rate] = useState(tM5OpeRate);
  const [iM5Ope3Unit, setIM5Ope3Unit] = useState<any>("");
  const [eM5Ope3Unit, setEM5Ope3Unit] = useState(NoValidation);
  const [iM5Ope3Fuel, setIM5Ope3Fuel] = useState<any>("");
  const [eM5Ope3Fuel, setEM5Ope3Fuel] = useState(tM5OpeFuel);

  const [iM5Ope4, setIM5Ope4] = useState<any>("");
  const [eM5Ope4, setEM5Ope4] = useState(NoValidation);
  const [iM5Ope4Rate, setIM5Ope4Rate] = useState<any>("");
  const [eM5Ope4Rate, setEM5Ope4Rate] = useState(tM5OpeRate);
  const [iM5Ope4Unit, setIM5Ope4Unit] = useState<any>("");
  const [eM5Ope4Unit, setEM5Ope4Unit] = useState(NoValidation);
  const [iM5Ope4Fuel, setIM5Ope4Fuel] = useState<any>("");
  const [eM5Ope4Fuel, setEM5Ope4Fuel] = useState(tM5OpeFuel);

  const [iM5Ope5, setIM5Ope5] = useState<any>("");
  const [eM5Ope5, setEM5Ope5] = useState(NoValidation);
  const [iM5Ope5Rate, setIM5Ope5Rate] = useState<any>("");
  const [eM5Ope5Rate, setEM5Ope5Rate] = useState(tM5OpeRate);
  const [iM5Ope5Unit, setIM5Ope5Unit] = useState<any>("");
  const [eM5Ope5Unit, setEM5Ope5Unit] = useState(NoValidation);
  const [iM5Ope5Fuel, setIM5Ope5Fuel] = useState<any>("");
  const [eM5Ope5Fuel, setEM5Ope5Fuel] = useState(tM5OpeFuel);

  const [iM5Ope6, setIM5Ope6] = useState<any>("");
  const [eM5Ope6, setEM5Ope6] = useState(NoValidation);
  const [iM5Ope6Rate, setIM5Ope6Rate] = useState<any>("");
  const [eM5Ope6Rate, setEM5Ope6Rate] = useState(tM5OpeRate);
  const [iM5Ope6Unit, setIM5Ope6Unit] = useState<any>("");
  const [eM5Ope6Unit, setEM5Ope6Unit] = useState(NoValidation);
  const [iM5Ope6Fuel, setIM5Ope6Fuel] = useState<any>("");
  const [eM5Ope6Fuel, setEM5Ope6Fuel] = useState(tM5OpeFuel);

  const [iM5SrvcLoc1P, setIM5SrvcLoc1P] = useState<any>("");
  const [eM5SrvcLoc1P, setEM5SrvcLoc1P] = useState(NoValidation);

  const [iM5SrvcLoc1M, setIM5SrvcLoc1M] = useState<any>("");
  const [eM5SrvcLoc1M, setEM5SrvcLoc1M] = useState(NoValidation);

  const [iM5SrvcLoc2P, setIM5SrvcLoc2P] = useState<any>("");
  const [eM5SrvcLoc2P, setEM5SrvcLoc2P] = useState(NoValidation);

  const [iM5SrvcLoc2M, setIM5SrvcLoc2M] = useState<any>("");
  const [eM5SrvcLoc2M, setEM5SrvcLoc2M] = useState(NoValidation);

  const [iM5SrvcLoc3P, setIM5SrvcLoc3P] = useState<any>("");
  const [eM5SrvcLoc3P, setEM5SrvcLoc3P] = useState(NoValidation);

  const [iM5SrvcLoc3M, setIM5SrvcLoc3M] = useState<any>("");
  const [eM5SrvcLoc3M, setEM5SrvcLoc3M] = useState(NoValidation);

  const tM6Total = Rng(0, 100, vldtn.operator);
  const [iM6Total, setIM6Total] = useState<any>("");
  const [eM6Total, setEM6Total] = useState(tM6Total);

  const tM6Operator = Rng(0, 10, vldtn.operator);
  const [iM6L2Formal, setIM6L2Formal] = useState<any>("");
  const [eM6L2Formal, setEM6L2Formal] = useState(tM6Operator);

  const [iM6L2NtFrml, setIM6L2NtFrml] = useState<any>("");
  const [eM6L2NtFrml, setEM6L2NtFrml] = useState(tM6Operator);

  const [iM6G2Formal, setIM6G2Formal] = useState<any>("");
  const [eM6G2Formal, setEM6G2Formal] = useState(tM6Operator);

  const [iM6G2NtFrml, setIM6G2NtFrml] = useState<any>("");
  const [eM6G2NtFrml, setEM6G2NtFrml] = useState(tM6Operator);

  const [iM7AirFltr, setIM7AirFltr] = useState<any>("");
  const [eM7AirFltr, setEM7AirFltr] = useState(NoValidation);

  const [iM7AirFltrUnit, setIM7AirFltrUnit] = useState<any>("");
  const [eM7AirFltrUnit, setEM7AirFltrUnit] = useState(NoValidation);

  const [iM7EngOil, setIM7EngOil] = useState<any>("");
  const [eM7EngOil, setEM7EngOil] = useState(NoValidation);

  const [iM7EngOilUnit, setIM7EngOilUnit] = useState<any>("");
  const [eM7EngOilUnit, setEM7EngOilUnit] = useState(NoValidation);

  const [iM7EngOilFltr, setIM7EngOilFltr] = useState<any>("");
  const [eM7EngOilFltr, setEM7EngOilFltr] = useState(NoValidation);

  const [iM7EngOilFltrUnit, setIM7EngOilFltrUnit] = useState<any>("");
  const [eM7EngOilFltrUnit, setEM7EngOilFltrUnit] = useState(NoValidation);

  const [iM7HydOil, setIM7HydOil] = useState<any>("");
  const [eM7HydOil, setEM7HydOil] = useState(NoValidation);

  const [iM7HydOilUnit, setIM7HydOilUnit] = useState<any>("");
  const [eM7HydOilUnit, setEM7HydOilUnit] = useState(NoValidation);

  const [iM7HydOilFltr, setIM7HydOilFltr] = useState<any>("");
  const [eM7HydOilFltr, setEM7HydOilFltr] = useState(NoValidation);

  const [iM7HydOilFltrUnit, setIM7HydOilFltrUnit] = useState<any>("");
  const [eM7HydOilFltrUnit, setEM7HydOilFltrUnit] = useState(NoValidation);

  const [iM7Tire, setIM7Tire] = useState<any>("");
  const [eM7Tire, setEM7Tire] = useState(NoValidation);

  const [iM7TireUnit, setIM7TireUnit] = useState<any>("");
  const [eM7TireUnit, setEM7TireUnit] = useState(NoValidation);

  const [iM7SrvcPrvdr, setIM7SrvcPrvdr] = useState<any>("");
  const [eM7SrvcPrvdr, setEM7SrvcPrvdr] = useState(NoValidation);

  const [iM7Prov, setIM7Prov] = useState<any>("");
  const [eM7Prov, setEM7Prov] = useState(NoValidation);

  const [iM7Mun, setIM7Mun] = useState<any>("");
  const [eM7Mun, setEM7Mun] = useState(NoValidation);

  const [iM7Brgy, setIM7Brgy] = useState<any>("");
  const [eM7Brgy, setEM7Brgy] = useState(NoValidation);

  const [iM7TimeRspnd, setIM7TimeRspnd] = useState<any>("");
  const [eM7TimeRspnd, setEM7TimeRspnd] = useState(NoValidation);

  const [iM7PrtsAvail, setIM7PrtsAvail] = useState<any>("");
  const [eM7PrtsAvail, setEM7PrtsAvail] = useState(NoValidation);

  const [iM8OthCrp, setIM8OthCrp] = useState<any>("");
  const [eM8OthCrp, setEM8OthCrp] = useState(NoValidation);

  const [iM8Rice, setIM8Rice] = useState<any>("");
  const [eM8Rice, setEM8Rice] = useState(NoValidation);

  const tM8RiceHa = Rng(0, 9999, vldtn.area);
  const [iM8RiceHa, setIM8RiceHa] = useState<any>("");
  const [eM8RiceHa, setEM8RiceHa] = useState(tM8RiceHa);

  const [iM8Corn, setIM8Corn] = useState<any>("");
  const [eM8Corn, setEM8Corn] = useState(NoValidation);

  const tM8CornHa = Rng(0, 9999, vldtn.area);
  const [iM8CornHa, setIM8CornHa] = useState<any>("");
  const [eM8CornHa, setEM8CornHa] = useState(tM8CornHa);

  const [iM8Othr1, setIM8Othr1] = useState<any>("");
  const [eM8Othr1, setEM8Othr1] = useState(NoValidation);

  const [iM8Othr2, setIM8Othr2] = useState<any>("");
  const [eM8Othr2, setEM8Othr2] = useState(NoValidation);

  const [iM8Othr3, setIM8Othr3] = useState<any>("");
  const [eM8Othr3, setEM8Othr3] = useState(NoValidation);

  const tM8Othr1Ha = Rng(0, 9999, vldtn.area);
  const [iM8Othr1Ha, setIM8Othr1Ha] = useState<any>("");
  const [eM8Othr1Ha, setEM8Othr1Ha] = useState(tM8Othr1Ha);

  const [iM8Othr2Ha, setIM8Othr2Ha] = useState<any>("");
  const [eM8Othr2Ha, setEM8Othr2Ha] = useState(tM8Othr1Ha);

  const [iM8Othr3Ha, setIM8Othr3Ha] = useState<any>("");
  const [eM8Othr3Ha, setEM8Othr3Ha] = useState(tM8Othr1Ha);

  const [iM9Notes, setIM9Notes] = useState<any>("");
  const [eM9Notes, setEM9Notes] = useState(NoValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // QUESTIONS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const _unit = (txt: string) => txt + " " + safeRead(q.tM7Unit.label);

  type TQuestion = {
    id: string;
    value: string | undefined;
    setValue: TSetString | TSetAny;
    errorMessage: TValidation;
    setErrorMessage: TSetValidation;
    backup: string;
    label: string;
  };

  const QUESTIONS: TQuestion[] = [
    {
      id: "m1ResName",
      value: iM1ResName,
      setValue: setIM1ResName,
      errorMessage: eM1ResName,
      setErrorMessage: setEM1ResName,
      backup: MCBKUP.bM1ResName,
      label: q.tM1ResName.label,
    },
    {
      id: "m1QRCode",
      value: iM1QRCode,
      setValue: setIM1QRCode,
      errorMessage: eM1QRCode,
      setErrorMessage: setEM1QRCode,
      backup: MCBKUP.bM1QRCode,
      label: q.tM1QRCode.label,
    },
    {
      id: "m1FrontVw",
      value: iM1FrontVw,
      setValue: setIM1FrontVw,
      errorMessage: eM1FrontVw,
      setErrorMessage: setEM1FrontVw,
      backup: MCBKUP.bM1FrontVw,
      label: q.tM1FrontVw.label,
    },
    {
      id: "m1SideVw",
      value: iM1SideVw,
      setValue: setIM1SideVw,
      errorMessage: eM1SideVw,
      setErrorMessage: setEM1SideVw,
      backup: MCBKUP.bM1SideVw,
      label: q.tM1SideVw.label,
    },
    {
      id: "m1SpecVw",
      value: iM1SpecVw,
      setValue: setIM1SpecVw,
      errorMessage: eM1SpecVw,
      setErrorMessage: setEM1SpecVw,
      backup: MCBKUP.bM1SpecVw,
      label: q.tM1SpecVw.label,
    },
    {
      id: "m1Acc",
      value: iM1Acc,
      setValue: setIM1Acc,
      errorMessage: eM1Acc,
      setErrorMessage: setEM1Acc,
      backup: MCBKUP.bM1Acc,
      label: q.tM1Acc.label,
    },
    {
      id: "m1Lat",
      value: iM1Lat,
      setValue: setIM1Lat,
      errorMessage: eM1Lat,
      setErrorMessage: setEM1Lat,
      backup: MCBKUP.bM1Lat,
      label: q.tM1Lat.label,
    },
    {
      id: "m1Lng",
      value: iM1Lng,
      setValue: setIM1Lng,
      errorMessage: eM1Lng,
      setErrorMessage: setEM1Lng,
      backup: MCBKUP.bM1Lng,
      label: q.tM1Lng.label,
    },
    {
      id: "m1Loc",
      value: iM1Loc,
      setValue: setIM1Loc,
      errorMessage: eM1Loc,
      setErrorMessage: setEM1Loc,
      backup: MCBKUP.bM1Loc,
      label: q.tM1Loc.label,
    },
    {
      id: "m1Prov",
      value: iM1Prov,
      setValue: setIM1Prov,
      errorMessage: eM1Prov,
      setErrorMessage: setEM1Prov,
      backup: MCBKUP.bM1Prov,
      label: q.tM1Prov.label,
    },
    {
      id: "m1Mun",
      value: iM1Mun,
      setValue: setIM1Mun,
      errorMessage: eM1Mun,
      setErrorMessage: setEM1Mun,
      backup: MCBKUP.bM1Mun,
      label: q.tM1Mun.label,
    },
    {
      id: "m1Brgy",
      value: iM1Brgy,
      setValue: setIM1Brgy,
      errorMessage: eM1Brgy,
      setErrorMessage: setEM1Brgy,
      backup: MCBKUP.bM1Brgy,
      label: q.tM1Brgy.label,
    },
    {
      id: "m1Type",
      value: iM1Type,
      setValue: setIM1Type,
      errorMessage: eM1Type,
      setErrorMessage: setEM1Type,
      backup: MCBKUP.bM1Type,
      label: q.tM1Type.label,
    },
    {
      id: "m1Serial",
      value: iM1Serial,
      setValue: setIM1Serial,
      errorMessage: eM1Serial,
      setErrorMessage: setEM1Serial,
      backup: MCBKUP.bM1Serial,
      label: q.tM1Serial.label,
    },
    {
      id: "m1CovHlng",
      value: iM1CovHlng,
      setValue: setIM1CovHlng,
      errorMessage: eM1CovHlng,
      setErrorMessage: setEM1CovHlng,
      backup: MCBKUP.bM1CovHlng,
      label: q.tM1CovHlng.label,
    },
    {
      id: "m2Brand",
      value: iM2Brand,
      setValue: setIM2Brand,
      errorMessage: eM2Brand,
      setErrorMessage: setEM2Brand,
      backup: MCBKUP.bM2Brand,
      label: q.tM2Brand.label,
    },
    {
      id: "m2Model",
      value: iM2Model,
      setValue: setIM2Model,
      errorMessage: eM2Model,
      setErrorMessage: setEM2Model,
      backup: MCBKUP.bM2Model,
      label: q.tM2Model.label,
    },
    {
      id: "m2RtdPwr",
      value: iM2RtdPwr,
      setValue: setIM2RtdPwr,
      errorMessage: eM2RtdPwr,
      setErrorMessage: setEM2RtdPwr,
      backup: MCBKUP.bM2RtdPwr,
      label: q.tM2RtdPwr.label,
    },
    {
      id: "m2TtlSrvArea",
      value: iM2TtlSrvArea,
      setValue: setIM2TtlSrvArea,
      errorMessage: eM2TtlSrvArea,
      setErrorMessage: setEM2TtlSrvArea,
      backup: MCBKUP.bM2TtlSrvArea,
      label: q.tM2TtlSrvArea.label,
    },
    {
      id: "m2AvgOpHrDy",
      value: iM2AvgOpHrDy,
      setValue: setIM2AvgOpHrDy,
      errorMessage: eM2AvgOpHrDy,
      setErrorMessage: setEM2AvgOpHrDy,
      backup: MCBKUP.bM2AvgOpHrDy,
      label: q.tM2AvgOpHrDy.label,
    },
    {
      id: "m2AvgOpDySsn",
      value: iM2AvgOpDySsn,
      setValue: setIM2AvgOpDySsn,
      errorMessage: eM2AvgOpDySsn,
      setErrorMessage: setEM2AvgOpDySsn,
      backup: MCBKUP.bM2AvgOpDySsn,
      label: q.tM2AvgOpDySsn.label,
    },
    {
      id: "m2LdCap",
      value: iM2LdCap,
      setValue: setIM2LdCap,
      errorMessage: eM2LdCap,
      setErrorMessage: setEM2LdCap,
      backup: MCBKUP.bM2LdCap,
      label: q.tM2LdCap.label,
    },
    {
      id: "m2LdCapUnit",
      value: iM2LdCapUnit,
      setValue: setIM2LdCapUnit,
      errorMessage: eM2LdCapUnit,
      setErrorMessage: setEM2LdCapUnit,
      backup: MCBKUP.bM2LdCapUnit,
      label: q.tM2LdCapUnit.label,
    },
    {
      id: "m2LdCapDay",
      value: iM2LdCapDay,
      setValue: setIM2LdCapDay,
      errorMessage: eM2LdCapDay,
      setErrorMessage: setEM2LdCapDay,
      backup: MCBKUP.bM2LdCapDay,
      label: q.tM2LdCapDay.label,
    },
    {
      id: "m2TmCom1Ha",
      value: iM2TmCom1Ha,
      setValue: setIM2TmCom1Ha,
      errorMessage: eM2TmCom1Ha,
      setErrorMessage: setEM2TmCom1Ha,
      backup: MCBKUP.bM2TmCom1Ha,
      label: q.tM2TmCom1Ha.label,
    },
    {
      id: "m2SgrcnYld",
      value: iM2SgrcnYld,
      setValue: setIM2SgrcnYld,
      errorMessage: eM2SgrcnYld,
      setErrorMessage: setEM2SgrcnYld,
      backup: MCBKUP.bM2SgrcnYld,
      label: q.tM2SgrcnYld.label,
    },
    {
      id: "m3TpTbWlls",
      value: iM3TpTbWlls,
      setValue: setIM3TpTbWlls,
      errorMessage: eM3TpTbWlls,
      setErrorMessage: setEM3TpTbWlls,
      backup: MCBKUP.bM3TpTbWlls,
      label: q.tM3TpTbWlls.label,
    },
    {
      id: "m3Brand",
      value: iM3Brand,
      setValue: setIM3Brand,
      errorMessage: eM3Brand,
      setErrorMessage: setEM3Brand,
      backup: MCBKUP.bM3Brand,
      label: q.tM3Brand.label,
    },
    {
      id: "m3Model",
      value: iM3Model,
      setValue: setIM3Model,
      errorMessage: eM3Model,
      setErrorMessage: setEM3Model,
      backup: MCBKUP.bM3Model,
      label: q.tM3Model.label,
    },
    {
      id: "m3SzPmp",
      value: iM3SzPmp,
      setValue: setIM3SzPmp,
      errorMessage: eM3SzPmp,
      setErrorMessage: setEM3SzPmp,
      backup: MCBKUP.bM3SzPmp,
      label: q.tM3SzPmp.label,
    },
    {
      id: "m3SrcPwr",
      value: iM3SrcPwr,
      setValue: setIM3SrcPwr,
      errorMessage: eM3SrcPwr,
      setErrorMessage: setEM3SrcPwr,
      backup: MCBKUP.bM3SrcPwr,
      label: q.tM3SrcPwr.label,
    },
    {
      id: "m3SrcBrand",
      value: iM3SrcBrand,
      setValue: setIM3SrcBrand,
      errorMessage: eM3SrcBrand,
      setErrorMessage: setEM3SrcBrand,
      backup: MCBKUP.bM3SrcBrand,
      label: q.tM3SrcBrand.label,
    },
    {
      id: "m3SrcModel",
      value: iM3SrcModel,
      setValue: setIM3SrcModel,
      errorMessage: eM3SrcModel,
      setErrorMessage: setEM3SrcModel,
      backup: MCBKUP.bM3SrcModel,
      label: q.tM3SrcModel.label,
    },
    {
      id: "m3RtdPwr",
      value: iM3RtdPwr,
      setValue: setIM3RtdPwr,
      errorMessage: eM3RtdPwr,
      setErrorMessage: setEM3RtdPwr,
      backup: MCBKUP.bM3RtdPwr,
      label: q.tM3RtdPwr.label,
    },
    {
      id: "m3DisCap",
      value: iM3DisCap,
      setValue: setIM3DisCap,
      errorMessage: eM3DisCap,
      setErrorMessage: setEM3DisCap,
      backup: MCBKUP.bM3DisCap,
      label: q.tM3DisCap.label,
    },
    {
      id: "m3DisCapUnit",
      value: iM3DisCapUnit,
      setValue: setIM3DisCapUnit,
      errorMessage: eM3DisCapUnit,
      setErrorMessage: setEM3DisCapUnit,
      backup: MCBKUP.bM3DisCapUnit,
      label: q.tM3DisCapUnit.label,
    },
    {
      id: "m3TtlSrvArea",
      value: iM3TtlSrvArea,
      setValue: setIM3TtlSrvArea,
      errorMessage: eM3TtlSrvArea,
      setErrorMessage: setEM3TtlSrvArea,
      backup: MCBKUP.bM3TtlSrvArea,
      label: q.tM3TtlSrvArea.label,
    },
    {
      id: "m3AvgOpHrDy",
      value: iM3AvgOpHrDy,
      setValue: setIM3AvgOpHrDy,
      errorMessage: eM3AvgOpHrDy,
      setErrorMessage: setEM3AvgOpHrDy,
      backup: MCBKUP.bM3AvgOpHrDy,
      label: q.tM3AvgOpHrDy.label,
    },
    {
      id: "m3AvgOpDySsn",
      value: iM3AvgOpDySsn,
      setValue: setIM3AvgOpDySsn,
      errorMessage: eM3AvgOpDySsn,
      setErrorMessage: setEM3AvgOpDySsn,
      backup: MCBKUP.bM3AvgOpDySsn,
      label: q.tM3AvgOpDySsn.label,
    },
    {
      id: "m4Usage",
      value: iM4Usage,
      setValue: setIM4Usage,
      errorMessage: eM4Usage,
      setErrorMessage: setEM4Usage,
      backup: MCBKUP.bM4Usage,
      label: q.tM4Usage.label,
    },
    {
      id: "m4Mode",
      value: iM4Mode,
      setValue: setIM4Mode,
      errorMessage: eM4Mode,
      setErrorMessage: setEM4Mode,
      backup: MCBKUP.bM4Mode,
      label: q.tM4Mode.label,
    },
    {
      id: "m4Agency",
      value: iM4Agency,
      setValue: setIM4Agency,
      errorMessage: eM4Agency,
      setErrorMessage: setEM4Agency,
      backup: MCBKUP.bM4Agency,
      label: q.tM4Agency.label,
    },
    {
      id: "m4YrAcqrd",
      value: iM4YrAcqrd,
      setValue: setIM4YrAcqrd,
      errorMessage: eM4YrAcqrd,
      setErrorMessage: setEM4YrAcqrd,
      backup: MCBKUP.bM4YrAcqrd,
      label: q.tM4YrAcqrd.label,
    },
    {
      id: "m4ConAcqrd",
      value: iM4ConAcqrd,
      setValue: setIM4ConAcqrd,
      errorMessage: eM4ConAcqrd,
      setErrorMessage: setEM4ConAcqrd,
      backup: MCBKUP.bM4ConAcqrd,
      label: q.tM4ConAcqrd.label,
    },
    {
      id: "m4PrsntCon",
      value: iM4PrsntCon,
      setValue: setIM4PrsntCon,
      errorMessage: eM4PrsntCon,
      setErrorMessage: setEM4PrsntCon,
      backup: MCBKUP.bM4PrsntCon,
      label: q.tM4PrsntCon.label,
    },
    {
      id: "m4YrUnused",
      value: iM4YrUnused,
      setValue: setIM4YrUnused,
      errorMessage: eM4YrUnused,
      setErrorMessage: setEM4YrUnused,
      backup: MCBKUP.bM4YrUnused,
      label: q.tM4YrUnused.label,
    },
    {
      id: "m4RsnUnused",
      value: iM4RsnUnused,
      setValue: setIM4RsnUnused,
      errorMessage: eM4RsnUnused,
      setErrorMessage: setEM4RsnUnused,
      backup: MCBKUP.bM4RsnUnused,
      label: q.tM4RsnUnused.label,
    },
    {
      id: "m4UuOthers",
      value: iM4UuOthers,
      setValue: setIM4UuOthers,
      errorMessage: eM4UuOthers,
      setErrorMessage: setEM4UuOthers,
      backup: MCBKUP.bM4UuOthers,
      label: q.tM4RsnUnused.label + " " + q.tM4UuOthers.label,
    },
    {
      id: "m4YrUnserv",
      value: iM4YrUnserv,
      setValue: setIM4YrUnserv,
      errorMessage: eM4YrUnserv,
      setErrorMessage: setEM4YrUnserv,
      backup: MCBKUP.bM4YrUnserv,
      label: q.tM4YrUnserv.label,
    },
    {
      id: "m4RsnUnserv",
      value: iM4RsnUnserv,
      setValue: setIM4RsnUnserv,
      errorMessage: eM4RsnUnserv,
      setErrorMessage: setEM4RsnUnserv,
      backup: MCBKUP.bM4RsnUnserv,
      label: q.tM4RsnUnserv.label,
    },
    {
      id: "m4UsOthers",
      value: iM4UsOthers,
      setValue: setIM4UsOthers,
      errorMessage: eM4UsOthers,
      setErrorMessage: setEM4UsOthers,
      backup: MCBKUP.bM4UsOthers,
      label: q.tM4RsnUnserv.label + " " + q.tM4UsOthers.label,
    },
    {
      id: "m4NoBrkdwn",
      value: iM4NoBrkdwn,
      setValue: setIM4NoBrkdwn,
      errorMessage: eM4NoBrkdwn,
      setErrorMessage: setEM4NoBrkdwn,
      backup: MCBKUP.bM4NoBrkdwn,
      label: q.tM4NoBrkdwn.label,
    },
    {
      id: "m4CmnBrkdwn",
      value: iM4CmnBrkdwn,
      setValue: setIM4CmnBrkdwn,
      errorMessage: eM4CmnBrkdwn,
      setErrorMessage: setEM4CmnBrkdwn,
      backup: MCBKUP.bM4CmnBrkdwn,
      label: q.tM4CmnBrkdwn.label,
    },
    {
      id: "m5SameOwn",
      value: iM5SameOwn,
      setValue: setIM5SameOwn,
      errorMessage: eM5SameOwn,
      setErrorMessage: setEM5SameOwn,
      backup: MCBKUP.bM5SameOwn,
      label: q.tM5SameOwn[0].label,
    },
    {
      id: "m5BusCont1",
      value: iM5BusCont1,
      setValue: setIM5BusCont1,
      errorMessage: eM5BusCont1,
      setErrorMessage: setEM5BusCont1,
      backup: MCBKUP.bM5BusCont1,
      label: q.tM5BusCont1.label,
    },
    {
      id: "m5BusCont2",
      value: iM5BusCont2,
      setValue: setIM5BusCont2,
      errorMessage: eM5BusCont2,
      setErrorMessage: setEM5BusCont2,
      backup: MCBKUP.bM5BusCont2,
      label: q.tM5BusCont2.label,
    },
    {
      id: "m5Consent",
      value: iM5Consent,
      setValue: setIM5Consent,
      errorMessage: eM5Consent,
      setErrorMessage: setEM5Consent,
      backup: MCBKUP.bM5Consent,
      label: q.tM5Consent[0].label,
    },
    {
      id: "m5Ope1",
      value: iM5Ope1,
      setValue: setIM5Ope1,
      errorMessage: eM5Ope1,
      setErrorMessage: setEM5Ope1,
      backup: MCBKUP.bM5Ope1,
      label: q.tM5Operation.first,
    },
    {
      id: "m5Ope1Rate",
      value: iM5Ope1Rate,
      setValue: setIM5Ope1Rate,
      errorMessage: eM5Ope1Rate,
      setErrorMessage: setEM5Ope1Rate,
      backup: MCBKUP.bM5Ope1Rate,
      label: q.tM5Operation.first + " " + q.tM5OpeRate.label,
    },
    {
      id: "m5Ope1Unit",
      value: iM5Ope1Unit,
      setValue: setIM5Ope1Unit,
      errorMessage: eM5Ope1Unit,
      setErrorMessage: setEM5Ope1Unit,
      backup: MCBKUP.bM5Ope1Unit,
      label:
        q.tM5Operation.first +
        " " +
        q.tM5OpeRate.label +
        " " +
        q.tM5OpeUnit.label,
    },
    {
      id: "m5Ope1Fuel",
      value: iM5Ope1Fuel,
      setValue: setIM5Ope1Fuel,
      errorMessage: eM5Ope1Fuel,
      setErrorMessage: setEM5Ope1Fuel,
      backup: MCBKUP.bM5Ope1Fuel,
      label: q.tM5Operation.first + " " + q.tM5OpeFuel.label,
    },
    {
      id: "m5Ope2",
      value: iM5Ope2,
      setValue: setIM5Ope2,
      errorMessage: eM5Ope2,
      setErrorMessage: setEM5Ope2,
      backup: MCBKUP.bM5Ope2,
      label: q.tM5Operation.second,
    },
    {
      id: "m5Ope2Rate",
      value: iM5Ope2Rate,
      setValue: setIM5Ope2Rate,
      errorMessage: eM5Ope2Rate,
      setErrorMessage: setEM5Ope2Rate,
      backup: MCBKUP.bM5Ope2Rate,
      label: q.tM5Operation.second + " " + q.tM5OpeRate.label,
    },
    {
      id: "m5Ope2Unit",
      value: iM5Ope2Unit,
      setValue: setIM5Ope2Unit,
      errorMessage: eM5Ope2Unit,
      setErrorMessage: setEM5Ope2Unit,
      backup: MCBKUP.bM5Ope2Unit,
      label:
        q.tM5Operation.second +
        " " +
        q.tM5OpeRate.label +
        " " +
        q.tM5OpeUnit.label,
    },
    {
      id: "m5Ope2Fuel",
      value: iM5Ope2Fuel,
      setValue: setIM5Ope2Fuel,
      errorMessage: eM5Ope2Fuel,
      setErrorMessage: setEM5Ope2Fuel,
      backup: MCBKUP.bM5Ope2Fuel,
      label: q.tM5Operation.second + " " + q.tM5OpeFuel.label,
    },
    {
      id: "m5Ope3",
      value: iM5Ope3,
      setValue: setIM5Ope3,
      errorMessage: eM5Ope3,
      setErrorMessage: setEM5Ope3,
      backup: MCBKUP.bM5Ope3,
      label: q.tM5Operation.third,
    },
    {
      id: "m5Ope3Rate",
      value: iM5Ope3Rate,
      setValue: setIM5Ope3Rate,
      errorMessage: eM5Ope3Rate,
      setErrorMessage: setEM5Ope3Rate,
      backup: MCBKUP.bM5Ope3Rate,
      label: q.tM5Operation.third + " " + q.tM5OpeRate.label,
    },
    {
      id: "m5Ope3Unit",
      value: iM5Ope3Unit,
      setValue: setIM5Ope3Unit,
      errorMessage: eM5Ope3Unit,
      setErrorMessage: setEM5Ope3Unit,
      backup: MCBKUP.bM5Ope3Unit,
      label:
        q.tM5Operation.third +
        " " +
        q.tM5OpeRate.label +
        " " +
        q.tM5OpeUnit.label,
    },
    {
      id: "m5Ope3Fuel",
      value: iM5Ope3Fuel,
      setValue: setIM5Ope3Fuel,
      errorMessage: eM5Ope3Fuel,
      setErrorMessage: setEM5Ope3Fuel,
      backup: MCBKUP.bM5Ope3Fuel,
      label: q.tM5Operation.third + " " + q.tM5OpeFuel.label,
    },
    {
      id: "m5Ope4",
      value: iM5Ope4,
      setValue: setIM5Ope4,
      errorMessage: eM5Ope4,
      setErrorMessage: setEM5Ope4,
      backup: MCBKUP.bM5Ope4,
      label: q.tM5Operation.first,
    },
    {
      id: "m5Ope4Rate",
      value: iM5Ope4Rate,
      setValue: setIM5Ope4Rate,
      errorMessage: eM5Ope4Rate,
      setErrorMessage: setEM5Ope4Rate,
      backup: MCBKUP.bM5Ope4Rate,
      label: q.tM5Operation.first + " " + q.tM5OpeRate.label,
    },
    {
      id: "m5Ope4Unit",
      value: iM5Ope4Unit,
      setValue: setIM5Ope4Unit,
      errorMessage: eM5Ope4Unit,
      setErrorMessage: setEM5Ope4Unit,
      backup: MCBKUP.bM5Ope4Unit,
      label:
        q.tM5Operation.first +
        " " +
        q.tM5OpeRate.label +
        " " +
        q.tM5OpeUnit.label,
    },
    {
      id: "m5Ope4Fuel",
      value: iM5Ope4Fuel,
      setValue: setIM5Ope4Fuel,
      errorMessage: eM5Ope4Fuel,
      setErrorMessage: setEM5Ope4Fuel,
      backup: MCBKUP.bM5Ope4Fuel,
      label: q.tM5Operation.first + " " + q.tM5OpeFuel.label,
    },
    {
      id: "m5Ope5",
      value: iM5Ope5,
      setValue: setIM5Ope5,
      errorMessage: eM5Ope5,
      setErrorMessage: setEM5Ope5,
      backup: MCBKUP.bM5Ope5,
      label: q.tM5Operation.second,
    },
    {
      id: "m5Ope5Rate",
      value: iM5Ope5Rate,
      setValue: setIM5Ope5Rate,
      errorMessage: eM5Ope5Rate,
      setErrorMessage: setEM5Ope5Rate,
      backup: MCBKUP.bM5Ope5Rate,
      label: q.tM5Operation.second + " " + q.tM5OpeRate.label,
    },
    {
      id: "m5Ope5Unit",
      value: iM5Ope5Unit,
      setValue: setIM5Ope5Unit,
      errorMessage: eM5Ope5Unit,
      setErrorMessage: setEM5Ope5Unit,
      backup: MCBKUP.bM5Ope5Unit,
      label:
        q.tM5Operation.second +
        " " +
        q.tM5OpeRate.label +
        " " +
        q.tM5OpeUnit.label,
    },
    {
      id: "m5Ope5Fuel",
      value: iM5Ope5Fuel,
      setValue: setIM5Ope5Fuel,
      errorMessage: eM5Ope5Fuel,
      setErrorMessage: setEM5Ope5Fuel,
      backup: MCBKUP.bM5Ope5Fuel,
      label: q.tM5Operation.second + " " + q.tM5OpeFuel.label,
    },
    {
      id: "m5Ope6",
      value: iM5Ope6,
      setValue: setIM5Ope6,
      errorMessage: eM5Ope6,
      setErrorMessage: setEM5Ope6,
      backup: MCBKUP.bM5Ope6,
      label: q.tM5Operation.third,
    },
    {
      id: "m5Ope6Rate",
      value: iM5Ope6Rate,
      setValue: setIM5Ope6Rate,
      errorMessage: eM5Ope6Rate,
      setErrorMessage: setEM5Ope6Rate,
      backup: MCBKUP.bM5Ope6Rate,
      label: q.tM5Operation.third + " " + q.tM5OpeRate.label,
    },
    {
      id: "m5Ope6Unit",
      value: iM5Ope6Unit,
      setValue: setIM5Ope6Unit,
      errorMessage: eM5Ope6Unit,
      setErrorMessage: setEM5Ope6Unit,
      backup: MCBKUP.bM5Ope6Unit,
      label:
        q.tM5Operation.third +
        " " +
        q.tM5OpeRate.label +
        " " +
        q.tM5OpeUnit.label,
    },
    {
      id: "m5Ope6Fuel",
      value: iM5Ope6Fuel,
      setValue: setIM5Ope6Fuel,
      errorMessage: eM5Ope6Fuel,
      setErrorMessage: setEM5Ope6Fuel,
      backup: MCBKUP.bM5Ope6Fuel,
      label: q.tM5Operation.third + " " + q.tM5OpeFuel.label,
    },
    {
      id: "m5SrvcLoc1P",
      value: iM5SrvcLoc1P,
      setValue: setIM5SrvcLoc1P,
      errorMessage: eM5SrvcLoc1P,
      setErrorMessage: setEM5SrvcLoc1P,
      backup: MCBKUP.bM5SrvcLoc1P,
      label: q.tM5Service,
    },
    {
      id: "m5SrvcLoc1M",
      value: iM5SrvcLoc1M,
      setValue: setIM5SrvcLoc1M,
      errorMessage: eM5SrvcLoc1M,
      setErrorMessage: setEM5SrvcLoc1M,
      backup: MCBKUP.bM5SrvcLoc1M,
      label: q.tM5Service,
    },
    {
      id: "m5SrvcLoc2P",
      value: iM5SrvcLoc2P,
      setValue: setIM5SrvcLoc2P,
      errorMessage: eM5SrvcLoc2P,
      setErrorMessage: setEM5SrvcLoc2P,
      backup: MCBKUP.bM5SrvcLoc2P,
      label: q.tM5Service,
    },
    {
      id: "m5SrvcLoc2M",
      value: iM5SrvcLoc2M,
      setValue: setIM5SrvcLoc2M,
      errorMessage: eM5SrvcLoc2M,
      setErrorMessage: setEM5SrvcLoc2M,
      backup: MCBKUP.bM5SrvcLoc2M,
      label: q.tM5Service,
    },
    {
      id: "m5SrvcLoc3P",
      value: iM5SrvcLoc3P,
      setValue: setIM5SrvcLoc3P,
      errorMessage: eM5SrvcLoc3P,
      setErrorMessage: setEM5SrvcLoc3P,
      backup: MCBKUP.bM5SrvcLoc3P,
      label: q.tM5Service,
    },
    {
      id: "m5SrvcLoc3M",
      value: iM5SrvcLoc3M,
      setValue: setIM5SrvcLoc3M,
      errorMessage: eM5SrvcLoc3M,
      setErrorMessage: setEM5SrvcLoc3M,
      backup: MCBKUP.bM5SrvcLoc3M,
      label: q.tM5Service,
    },
    {
      id: "m6Total",
      value: iM6Total,
      setValue: setIM6Total,
      errorMessage: eM6Total,
      setErrorMessage: setEM6Total,
      backup: MCBKUP.bM6Total,
      label: q.tM6TotalTitle,
    },
    {
      id: "m6L2Formal",
      value: iM6L2Formal,
      setValue: setIM6L2Formal,
      errorMessage: eM6L2Formal,
      setErrorMessage: setEM6L2Formal,
      backup: MCBKUP.bM6L2Formal,
      label: q.tM6TotalTitle,
    },
    {
      id: "m6L2NtFrml",
      value: iM6L2NtFrml,
      setValue: setIM6L2NtFrml,
      errorMessage: eM6L2NtFrml,
      setErrorMessage: setEM6L2NtFrml,
      backup: MCBKUP.bM6L2NtFrml,
      label: q.tM6TotalTitle,
    },
    {
      id: "m6G2Formal",
      value: iM6G2Formal,
      setValue: setIM6G2Formal,
      errorMessage: eM6G2Formal,
      setErrorMessage: setEM6G2Formal,
      backup: MCBKUP.bM6G2Formal,
      label: q.tM6TotalTitle,
    },
    {
      id: "m6G2NtFrml",
      value: iM6G2NtFrml,
      setValue: setIM6G2NtFrml,
      errorMessage: eM6G2NtFrml,
      setErrorMessage: setEM6G2NtFrml,
      backup: MCBKUP.bM6G2NtFrml,
      label: q.tM6TotalTitle,
    },
    {
      id: "m7AirFltr",
      value: iM7AirFltr,
      setValue: setIM7AirFltr,
      errorMessage: eM7AirFltr,
      setErrorMessage: setEM7AirFltr,
      backup: MCBKUP.bM7AirFltr,
      label: q.tM7AirFltr.label,
    },
    {
      id: "m7AirFltrUnit",
      value: iM7AirFltrUnit,
      setValue: setIM7AirFltrUnit,
      errorMessage: eM7AirFltrUnit,
      setErrorMessage: setEM7AirFltrUnit,
      backup: MCBKUP.bM7AirFltrUnit,
      label: _unit(q.tM7AirFltr.label),
    },
    {
      id: "m7EngOil",
      value: iM7EngOil,
      setValue: setIM7EngOil,
      errorMessage: eM7EngOil,
      setErrorMessage: setEM7EngOil,
      backup: MCBKUP.bM7EngOil,
      label: q.tM7EngOil.label,
    },
    {
      id: "m7EngOilUnit",
      value: iM7EngOilUnit,
      setValue: setIM7EngOilUnit,
      errorMessage: eM7EngOilUnit,
      setErrorMessage: setEM7EngOilUnit,
      backup: MCBKUP.bM7EngOilUnit,
      label: _unit(q.tM7EngOil.label),
    },
    {
      id: "m7EngOilFltr",
      value: iM7EngOilFltr,
      setValue: setIM7EngOilFltr,
      errorMessage: eM7EngOilFltr,
      setErrorMessage: setEM7EngOilFltr,
      backup: MCBKUP.bM7EngOilFltr,
      label: q.tM7EngOilFltr.label,
    },
    {
      id: "m7EngOilFltrUnit",
      value: iM7EngOilFltrUnit,
      setValue: setIM7EngOilFltrUnit,
      errorMessage: eM7EngOilFltrUnit,
      setErrorMessage: setEM7EngOilFltrUnit,
      backup: MCBKUP.bM7EngOilFltrUnit,
      label: _unit(q.tM7EngOilFltr.label),
    },
    {
      id: "m7HydOil",
      value: iM7HydOil,
      setValue: setIM7HydOil,
      errorMessage: eM7HydOil,
      setErrorMessage: setEM7HydOil,
      backup: MCBKUP.bM7HydOil,
      label: q.tM7HydOil.label,
    },
    {
      id: "m7HydOilUnit",
      value: iM7HydOilUnit,
      setValue: setIM7HydOilUnit,
      errorMessage: eM7HydOilUnit,
      setErrorMessage: setEM7HydOilUnit,
      backup: MCBKUP.bM7HydOilUnit,
      label: _unit(q.tM7HydOil.label),
    },
    {
      id: "m7HydOilFltr",
      value: iM7HydOilFltr,
      setValue: setIM7HydOilFltr,
      errorMessage: eM7HydOilFltr,
      setErrorMessage: setEM7HydOilFltr,
      backup: MCBKUP.bM7HydOilFltr,
      label: q.tM7HydOilFltr.label,
    },
    {
      id: "m7HydOilFltrUnit",
      value: iM7HydOilFltrUnit,
      setValue: setIM7HydOilFltrUnit,
      errorMessage: eM7HydOilFltrUnit,
      setErrorMessage: setEM7HydOilFltrUnit,
      backup: MCBKUP.bM7HydOilFltrUnit,
      label: _unit(q.tM7HydOilFltr.label),
    },
    {
      id: "m7Tire",
      value: iM7Tire,
      setValue: setIM7Tire,
      errorMessage: eM7Tire,
      setErrorMessage: setEM7Tire,
      backup: MCBKUP.bM7Tire,
      label: q.tM7Tire.label,
    },
    {
      id: "m7TireUnit",
      value: iM7TireUnit,
      setValue: setIM7TireUnit,
      errorMessage: eM7TireUnit,
      setErrorMessage: setEM7TireUnit,
      backup: MCBKUP.bM7TireUnit,
      label: _unit(q.tM7Tire.label),
    },
    {
      id: "m7SrvcPrvdr",
      value: iM7SrvcPrvdr,
      setValue: setIM7SrvcPrvdr,
      errorMessage: eM7SrvcPrvdr,
      setErrorMessage: setEM7SrvcPrvdr,
      backup: MCBKUP.bM7SrvcPrvdr,
      label: q.tM7SrvcPrvdr.label,
    },
    {
      id: "m7Prov",
      value: iM7Prov,
      setValue: setIM7Prov,
      errorMessage: eM7Prov,
      setErrorMessage: setEM7Prov,
      backup: MCBKUP.bM7Prov,
      label: q.tM7Prov.label,
    },
    {
      id: "m7Mun",
      value: iM7Mun,
      setValue: setIM7Mun,
      errorMessage: eM7Mun,
      setErrorMessage: setEM7Mun,
      backup: MCBKUP.bM7Mun,
      label: q.tM7Mun.label,
    },
    {
      id: "m7Brgy",
      value: iM7Brgy,
      setValue: setIM7Brgy,
      errorMessage: eM7Brgy,
      setErrorMessage: setEM7Brgy,
      backup: MCBKUP.bM7Brgy,
      label: q.tM7Brgy.label,
    },
    {
      id: "m7TimeRspnd",
      value: iM7TimeRspnd,
      setValue: setIM7TimeRspnd,
      errorMessage: eM7TimeRspnd,
      setErrorMessage: setEM7TimeRspnd,
      backup: MCBKUP.bM7TimeRspnd,
      label: q.tM7TimeRspnd.label,
    },
    {
      id: "m7PrtsAvail",
      value: iM7PrtsAvail,
      setValue: setIM7PrtsAvail,
      errorMessage: eM7PrtsAvail,
      setErrorMessage: setEM7PrtsAvail,
      backup: MCBKUP.bM7PrtsAvail,
      label: q.tM7PrtsAvail.label,
    },
    {
      id: "m8OthCrp",
      value: iM8OthCrp,
      setValue: setIM8OthCrp,
      errorMessage: eM8OthCrp,
      setErrorMessage: setEM8OthCrp,
      backup: MCBKUP.bM8OthCrp,
      label: q.tM8OthCrp.label,
    },
    {
      id: "m8Rice",
      value: iM8Rice,
      setValue: setIM8Rice,
      errorMessage: eM8Rice,
      setErrorMessage: setEM8Rice,
      backup: MCBKUP.bM8Rice,
      label: q.tM8Rice.option[0].label,
    },
    {
      id: "m8RiceHa",
      value: iM8RiceHa,
      setValue: setIM8RiceHa,
      errorMessage: eM8RiceHa,
      setErrorMessage: setEM8RiceHa,
      backup: MCBKUP.bM8RiceHa,
      label: q.tM8Rice.option[0].label + " " + q.tM8Label,
    },
    {
      id: "m8Corn",
      value: iM8Corn,
      setValue: setIM8Corn,
      errorMessage: eM8Corn,
      setErrorMessage: setEM8Corn,
      backup: MCBKUP.bM8Corn,
      label: q.tM8Corn.option[0].label,
    },
    {
      id: "m8CornHa",
      value: iM8CornHa,
      setValue: setIM8CornHa,
      errorMessage: eM8CornHa,
      setErrorMessage: setEM8CornHa,
      backup: MCBKUP.bM8CornHa,
      label: q.tM8Corn.option[0].label + " " + q.tM8Label,
    },
    {
      id: "m8Othr1",
      value: iM8Othr1,
      setValue: setIM8Othr1,
      errorMessage: eM8Othr1,
      setErrorMessage: setEM8Othr1,
      backup: MCBKUP.bM8Othr1,
      label: safeRead(iM8Othr1),
    },
    {
      id: "m8Othr1Ha",
      value: iM8Othr1Ha,
      setValue: setIM8Othr1Ha,
      errorMessage: eM8Othr1Ha,
      setErrorMessage: setEM8Othr1Ha,
      backup: MCBKUP.bM8Othr1Ha,
      label: safeRead(iM8Othr1) + " " + q.tM8Label,
    },
    {
      id: "m8Othr2",
      value: iM8Othr2,
      setValue: setIM8Othr2,
      errorMessage: eM8Othr2,
      setErrorMessage: setEM8Othr2,
      backup: MCBKUP.bM8Othr2,
      label: safeRead(iM8Othr2),
    },
    {
      id: "m8Othr2Ha",
      value: iM8Othr2Ha,
      setValue: setIM8Othr2Ha,
      errorMessage: eM8Othr2Ha,
      setErrorMessage: setEM8Othr2Ha,
      backup: MCBKUP.bM8Othr2Ha,
      label: safeRead(iM8Othr2) + " " + q.tM8Label,
    },
    {
      id: "m8Othr3",
      value: iM8Othr3,
      setValue: setIM8Othr3,
      errorMessage: eM8Othr3,
      setErrorMessage: setEM8Othr3,
      backup: MCBKUP.bM8Othr3,
      label: safeRead(iM8Othr3),
    },
    {
      id: "m8Othr3Ha",
      value: iM8Othr3Ha,
      setValue: setIM8Othr3Ha,
      errorMessage: eM8Othr3Ha,
      setErrorMessage: setEM8Othr3Ha,
      backup: MCBKUP.bM8Othr3Ha,
      label: safeRead(iM8Othr3) + " " + q.tM8Label,
    },
    {
      id: "m9Notes",
      value: iM9Notes,
      setValue: setIM9Notes,
      errorMessage: eM9Notes,
      setErrorMessage: setEM9Notes,
      backup: MCBKUP.bM9Notes,
      label: q.tM9Notes.label,
    },
  ];

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const m1Adrs: Address = CreateNewAddress();
  const m5Adrs1: Address = CreateNewAddress();
  const m5Adrs2: Address = CreateNewAddress();
  const m5Adrs3: Address = CreateNewAddress();
  const m7Adrs: Address = CreateNewAddress();

  // Camera ////////////////////////////////////////////////////////////////////

  const [camUser, setCamUser] = useState<CameraUsers>();

  enum CameraUsers {
    QRCode,
    FrontView,
    SideView,
    SpecView,
  }
  const [qrDevice, setQRDevice] = useState<any>("");
  const [qrVideos, setQRVideos] = useState([]);
  const [qrCameras, setQRCameras] = useState<
    { label: string; value: string }[]
  >([]);

  const [qrReader, setQRReader] = useState(new BrowserQRCodeReader());

  useEffect(() => {
    console.log("M2");
    setQRCameras(
      qrVideos.map((element: any) => ({
        label: element.label,
        value: element.deviceId,
      }))
    );
  }, [qrVideos]);

  function resetQR() {
    qrReader.reset();
    setIM1QRCode("");
  }

  function stopQR() {
    qrReader.reset();
    setQRReader(new BrowserQRCodeReader());
  }

  function againQR() {
    qrReader
      .getVideoInputDevices()
      .then((qrVideo) => {
        setupDevices(qrVideo);
      })
      .catch((err) => {
        console.error(err);
      });
    decodeOnce(qrReader, qrDevice);
  }

  function setupDevices(videos: any) {
    setQRDevice(videos[0].deviceId);
    if (videos.length >= 1) setQRVideos(videos);
  }

  function decodeOnce(codeReader: any, selectedDeviceId: any) {
    codeReader
      .decodeFromInputVideoDevice(selectedDeviceId, "macvideo")
      .then((result: any) => {
        let res = result!.getText();
        res = res.substring(res.lastIndexOf("/") + 1);
        if (
          res.charAt(6) === "M" &&
          res.length === 12 &&
          res.charAt(0) === "R"
        ) {
          setIM1QRCode(res);
        } else {
          AVPopup({
            title: pop.qrError.title,
            text: pop.qrError.message,
            denyButtonText: pop.qrError.button,
          });
          setTimeout(() => {
            resetQR();
            againQR();
          }, 5000);
        }
      })
      .catch((err: any) => {
        if (err.name === "NotFoundException") {
          // Video stream has ended before any code could be detected.
        } else console.error(err);
      });
  }

  useEffect(() => {
    console.log("M3");
    if (iM1QRCode.length > 0) stopQR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iM1QRCode]);

  // Geolocation ///////////////////////////////////////////////////////////////

  var gLat: any, gLong: any, gAcc: any;
  const str = (n: any) => String(n);
  const isNum = (n: any) => !isNaN(n);
  const num = (n: any) => Number(n);

  function loadMachineMap() {
    AVPopup({
      icon: "info",
      title: pop.geoLoc.init.title,
      text: pop.geoLoc.init.message,
      confirmButtonText: pop.geoLoc.init.button,
    });
    var map = L.map(ID.GeoLocationPaneMap).setView([14.16255, 121.248497], 6);
    // var lyr = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    var lyr = "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
    var osm = L.tileLayer(lyr);
    osm.addTo(map);

    var refreshMap: any;
    if (!navigator.geolocation) {
      AVPopup({
        icon: "error",
        title: pop.geoLoc.initError.title,
        text: pop.geoLoc.initError.message,
        confirmButtonText: pop.geoLoc.initError.button,
      });
    } else {
      refreshMap = setInterval(() => {
        if (isNum(gLat) && isNum(gLong) && isNum(gAcc)) {
          setIM1Lat(str(gLat));
          setIM1Lng(str(gLong));
          setIM1Acc(str(gAcc));
        }

        var dev = AppSettings.SERVER === AppSettings.DEVSERVER;
        var locValid = isNum(gLat) && isNum(gLong) && isNum(gAcc);
        var accValid = gAcc <= AppSettings.MIN_ACCURACY;

        if (locValid && (accValid || dev)) {
          clearInterval(refreshMap);
          AVPopup({
            icon: "success",
            title: pop.geoLoc.success.title,
            text:
              pop.geoLoc.success.lat +
              gLat +
              pop.geoLoc.success.lng +
              gLong +
              pop.geoLoc.success.acc +
              gAcc,
            confirmButtonText: pop.geoLoc.success.button,
          }).then((res) => {
            if (res.isConfirmed) {
              setIM1Acc(str(gAcc));
              setIM1Lat(str(gLat));
              setIM1Lng(str(gLong));
            }
          });
          setIM1Lat(str(gLat));
          setIM1Lng(str(gLong));
          setIM1Acc(str(gAcc));
        } else {
          navigator.geolocation.getCurrentPosition(getPosition);
        }
      }, 3000);
    }

    var marker: any, circle: any;
    function getPosition(position: any) {
      gLat = position.coords.latitude;
      gLong = position.coords.longitude;
      gAcc = position.coords.accuracy / 3;
      if (marker) map.removeLayer(marker);
      if (circle) map.removeLayer(circle);

      var machineIcon = L.icon({
        iconUrl: iMachine,
        iconSize: [32, 32],
        popupAnchor: [0, -16],
      });

      marker = L.marker([gLat, gLong], { icon: machineIcon }).addTo(map);
      circle = L.circle([gLat, gLong], { radius: gAcc });
      var featureGroup = L.featureGroup([circle]).addTo(map);
      map.fitBounds(featureGroup.getBounds());
    }
  }

  // Machine type based visibility /////////////////////////////////////////////

  // Common Specs Card
  const [vCSpecs, setVCSpecs] = useState(false);
  // WaterPump Specs Card
  const [vPSpecs, setVPSpecs] = useState(false);
  // Specifics
  const [vCnHlr, setVCnHlr] = useState(false);
  const [vHrv, setVHrv] = useState(false);
  const [vOCrops, setVOCrops] = useState(false);

  useEffect(() => {
    console.log("M4");
    var macType = iM1Type ? (iM1Type as any).label : iM1Type;
    const T = true,
      F = false;

    switch (macType) {
      case tTrc2.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: F, othCrops: T });
        break;
      case tTrc4.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: F, othCrops: T });
        break;
      case tCane.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, othCrops: F });
        break;
      case tHrvt.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: T, othCrops: F });
        break;
      case tPump.label:
        views({ cSpecs: F, pSpecs: T, cnHlr: F, hrv: F, othCrops: F });
        break;
      case tHaul.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, othCrops: F });
        break;
      case tRGun.label:
        views({ cSpecs: F, pSpecs: T, cnHlr: F, hrv: F, othCrops: F });
        break;
      default: {
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, othCrops: F });
        break;
      }
    }
  }, [iM1Type]);

  function views(p: {
    cSpecs: boolean;
    pSpecs: boolean;
    cnHlr: boolean;
    hrv: boolean;
    othCrops: boolean;
  }) {
    setVCSpecs(p.cSpecs);
    setVPSpecs(p.pSpecs);
    setVCnHlr(p.cnHlr);
    setVHrv(p.hrv);
    setVOCrops(p.othCrops);
  }

  // Brand and Model ///////////////////////////////////////////////////////////

  const [oBrands, setOBrands] = useState(oOBrands);
  const [oModels, setOModels] = useState(oOModels);

  const [dBrand, setDBrand] = useState(true);
  const [dModel, setDModel] = useState(true);

  const [kBrand, setKBrand] = useState(0);
  const [kModel, setKModel] = useState(10000);

  useEffect(() => {
    console.log("M5");
    var macType = safeRead(iM1Type);
    setOBrands(oOBrands.filter((o) => o.type.label === macType));

    if (macType === "") {
      setKBrand(kBrand + 1);
      setKModel(kModel + 1);
      setDBrand(true);
      setDModel(true);
    } else {
      setDBrand(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iM1Type]);

  useEffect(() => {
    console.log("M6");
    var brand = safeRead(iM2Brand);
    setOModels(oOModels.filter((o) => o.brand === brand));

    if (brand === "") {
      setKModel(kModel + 1);
      setDModel(true);
    } else {
      setDModel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iM2Brand]);

  // Operators /////////////////////////////////////////////////////////////////

  useEffect(() => {
    console.log("M7");
    if (iM6L2Formal && iM6L2NtFrml && iM6G2Formal && iM6G2NtFrml) {
      if (
        parseInt(iM6L2Formal) +
          parseInt(iM6L2NtFrml) +
          parseInt(iM6G2Formal) +
          parseInt(iM6G2NtFrml) !==
        parseInt(iM6Total)
      ) {
        AVPopup({
          icon: "error",
          title: pop.operator.title,
          text: pop.operator.message,
          confirmButtonText: pop.operator.button,
          showDenyButton: true,
          denyButtonText: pop.operator.deny,
        }).then((result) => {
          if (result.isDenied) {
            setIM6L2Formal("");
            setIM6L2NtFrml("");
            setIM6G2Formal("");
            setIM6G2NtFrml("");
            setIM6Total("");
          }
        });
      }
    }
  }, [iM6G2Formal, iM6G2NtFrml, iM6L2Formal, iM6L2NtFrml, iM6Total]);

  // ///////////////////////////////////////////////////////////////////////////

  const [isTractor, setIsTractor] = useState(false);

  useEffect(() => {
    console.log("M8");
    const test = (a: any, b: any) => safeRead(a) === safeRead(b);
    setIsTractor(test(tTrc2, iM1Type) || test(tTrc4, iM1Type));
  }, [iM1Type]);

  // Dropdown Logic ////////////////////////////////////////////////////////////

  useEffect(() => {
    console.log("M9");
    var options = q.tM4CmnBrkdwn.options;
    const lastSelected = Array.isArray(iM4CmnBrkdwn)
      ? iM4CmnBrkdwn[iM4CmnBrkdwn.length - 1]
      : null;

    if (lastSelected === options[7] || lastSelected === options[8]) {
      setIM4CmnBrkdwn([lastSelected]);
    } else if (
      Array.isArray(iM4CmnBrkdwn) &&
      (lastSelected !== options[7] || lastSelected !== options[8])
    ) {
      var newVal = iM4CmnBrkdwn.filter(
        (o) => o !== options[7] && o !== options[8]
      );
      setIM4CmnBrkdwn(newVal);
    }
  }, [iM4CmnBrkdwn]);

  useEffect(() => {
    console.log("M10");
    if (iM5SameOwn.length > 0 && iM5BusCont1 === "") {
      if (!iM1ResName) {
        AVPopup({
          type: "error",
          title: pop.noProfile.title,
          text: pop.noProfile.message2,
          confirmButtonText: pop.noProfile.button2,
        });
        setIM5SameOwn("");
      } else {
        var profiles = JSON.parse(fxdRead(fxdProfile.profiles) || "");
        var name, cont1: any, cont2: any;
        if (Array.isArray(profiles)) {
          var prof = profiles.find((o) => o.sP1Name === safeRead(iM1ResName));
          if (prof) {
            name = prof.sP1Name;
            cont1 = prof.sP1Cont1;
            cont2 = prof.sP1Cont2;
          }
        }

        var message = `${pop.loadContact.message1} ${cont1}`;
        if (cont2) message += `\n${pop.loadContact.message2} ${cont2}`;
        message += `\n${pop.loadContact.message3} ${name}`;
        message += `${pop.loadContact.message4}`;

        AVPopup({
          type: "info",
          title: pop.loadContact.title,
          text: message,
          confirmButtonText: pop.loadContact.button,
          showDenyButton: true,
          denyButtonText: pop.loadContact.deny,
        }).then((result) => {
          if (result.isConfirmed) {
            setIM5BusCont1(cont1);
            setIM5BusCont2(cont2);
          } else if (result.isDenied) {
            setIM5SameOwn("");
          }
        });
      }
    } else if (iM5SameOwn.length === 0 && iM5BusCont1 !== "") {
      setIM5BusCont1("");
      setIM5BusCont2("");
    }
  }, [iM1ResName, iM5BusCont1, iM5SameOwn]);

  // Hide and Show /////////////////////////////////////////////////////////////

  const hM1QRCodeVideo =
    camUser === CameraUsers.QRCode && String(iM1QRCode).length === 0;
  const hM1FrontVw = camUser === CameraUsers.FrontView;
  const hM1SideVw = camUser === CameraUsers.SideView;
  const hM1SpecVw = camUser === CameraUsers.SpecView;
  const hM1Coor = iM1Acc.length > 0;
  const hM1Serial = safeRead(iM1Type).length > 0;
  const hM1CovHlng = safeRead(iM1Type) === safeRead(tHaul);
  const hM2Title = vCSpecs && safeRead(iM1Type).length > 0;
  const hM3Title = vPSpecs && safeRead(iM1Type).length > 0;
  const hM4Title = safeRead(iM1Type).length > 0;
  const hM4Agency =
    safeRead(iM4Mode) === safeRead(q.tM4Mode.options[2]) ||
    safeRead(iM4Mode) === safeRead(q.tM4Mode.options[3]);
  const hM4Unused = safeRead(iM4PrsntCon) === q.tM4PrsntCon.options[1].label;
  const hM4Unser = safeRead(iM4PrsntCon) === q.tM4PrsntCon.options[2].label;

  const hM5Title = () => {
    var usage = safeRead(iM4Usage);
    return (
      usage === safeRead(q.tM4Usage.options[1]) ||
      usage === safeRead(q.tM4Usage.options[2])
    );
  };

  const hM5Ope2 = safeRead(iM5Ope1).length > 0;
  const hM5Ope3 = safeRead(iM5Ope2).length > 0;
  const hM5NonMember = safeRead(iM4Usage) === safeRead(q.tM4Usage.options[2]);
  const hM5Ope5 = safeRead(iM5Ope4).length > 0;
  const hM5Ope6 = safeRead(iM5Ope5).length > 0;
  const hM5SrvcLoc2P = safeRead(iM5SrvcLoc1M).length > 0;
  const hM5SrvcLoc3P = safeRead(iM5SrvcLoc2M).length > 0;
  const hM6Title = safeRead(iM1Type).length > 0;
  const hM7Title = safeRead(iM1Type).length > 0;
  const hM8Title = safeRead(iM8OthCrp) === q.tM8OthCrp.options[0].label;
  const hM8Rice = safeRead(iM8Rice).length > 0;
  const hM8CornHa = safeRead(iM8Corn).length > 0;
  const hM8Othr1Ha = iM8Othr1.length > 0;
  const hM8Othr2Ha = iM8Othr2.length > 0;
  const hM8Othr3Ha = iM8Othr3.length > 0;

  // Disabled //////////////////////////////////////////////////////////////////

  const dM1QRCode = () => {
    var noUser = !camUser;
    var qrIsUser = camUser === CameraUsers.QRCode;
    return !(noUser || qrIsUser);
  };

  const dM1FrontVw = () => {
    var noUser = !camUser;
    var frontIsUser = camUser === CameraUsers.FrontView;
    return !(noUser || frontIsUser);
  };

  const dM1SideVw = () => {
    var noUser = !camUser;
    var sideIsUser = camUser === CameraUsers.SideView;
    return !(noUser || sideIsUser);
  };

  const dM1SpecVw = () => {
    var noUser = !camUser;
    var specIsUser = camUser === CameraUsers.SpecView;
    return !(noUser || specIsUser);
  };

  const dM2LdCapDay = !safeRead(iM2LdCapUnit).includes(q.tM2LdCap.loadText);

  // Special Logic /////////////////////////////////////////////////////////////

  const sM1ResName = () => {
    const profiles = fxdRead(fxdProfile.profiles);
    if (profiles) {
      const profs = JSON.parse(profiles);
      var oUsernames = [];
      for (let i = 0; i < profs.length; i++) {
        oUsernames.push({ label: profs[i].sP1Name });
      }
      return oUsernames;
    } else return [{ label: pop.noProfile.title }];
  };

  const sM1QRCode = () => {
    if (camUser === CameraUsers.QRCode) {
      setCamUser(undefined);
      stopQR();
    } else {
      setCamUser(CameraUsers.QRCode);
      resetQR();
      againQR();
    }
  };

  const sM1QRCodeVideo = (e: any, val: any) => {
    resetQR();
    let v = qrCameras.find((o) => o.label === val)?.value;
    if (v !== undefined) {
      setQRDevice(v);
      resetQR();
      decodeOnce(qrReader, v);
    }
  };

  const sM1QRCodeVideoVal =
    qrCameras.find((o) => o.value === qrDevice)?.label || "";

  const sM1FrontVw = () => {
    if (camUser === CameraUsers.FrontView) {
      setCamUser(undefined);
    } else setCamUser(CameraUsers.FrontView);
  };

  const sM1SideVw = () => {
    if (camUser === CameraUsers.SideView) {
      setCamUser(undefined);
    } else setCamUser(CameraUsers.SideView);
  };

  const sM1SpecVw = () => {
    if (camUser === CameraUsers.SpecView) {
      setCamUser(undefined);
    } else setCamUser(CameraUsers.SpecView);
  };

  const sM1Acc = () => {
    var acc = num(iM1Acc);
    return acc === undefined
      ? "error"
      : isNum(acc) && acc <= 5
      ? "success"
      : isNum(acc) && acc <= 8
      ? "warning"
      : "error";
  };

  const sM1AccVal = () => {
    var acc = num(iM1Acc);
    return acc === undefined
      ? pop.accuracy.init
      : acc <= 5
      ? pop.accuracy.high
      : acc <= 8
      ? pop.accuracy.low
      : pop.accuracy.search;
  };

  const sM3Title =
    iM1Type && (iM1Type as any).label === tRGun.label
      ? q.tM3RainGun
      : q.tM3Title;

  const sM5Member =
    safeRead(iM4Usage) === safeRead(q.tM4Usage.options[2]) ||
    AppSettings.showAll
      ? 50
      : 100;

  const sM5OpeUnit = () => {
    return isTractor
      ? q.tM5OpeRate.options
      : q.tM5OpeRate.options.filter(
          (o) =>
            safeRead(o) !== safeRead(q.tM5OpeRate.options[4]) &&
            safeRead(o) !== safeRead(q.tM5OpeRate.options[5])
        );
  };

  // ///////////////////////////////////////////////////////////////////////////
  // LAYOUT ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  return (
    requireLoadProperly(AvzRoute.machine) ?? (
      <VBar class="main-bg-form form-design">
        <VBar class="v-bar">
          <TitleBar category={q.category} />
          <Card title={q.tM1Title} titleStyle="title">
            <AVDropdown
              id="m1ResName"
              text={q.tM1ResName}
              value={[iM1ResName, setIM1ResName]}
              validation={eM1ResName}
              backup={MCBKUP.bM1ResName}
              options={sM1ResName()}
            />
            <HBar>
              <AVButton
                position="left"
                icon={<QrCode2Icon />}
                disabled={dM1QRCode()}
                onClick={sM1QRCode}
                height={40}
              ></AVButton>
              <AVField
                id="m1QRCode"
                text={q.tM1QRCode}
                value={[iM1QRCode, setIM1QRCode]}
                validation={eM1QRCode}
                backup={MCBKUP.bM1QRCode}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hM1QRCodeVideo} class="spacer">
              <AVDropdown
                text={q.tM1QRCodeVideo}
                value={[sM1QRCodeVideoVal, null]}
                validation={null}
                backup={AVOVERRIDE}
                options={qrCameras}
                onInputChange={(e, val: any) => sM1QRCodeVideo}
              />
              <video id="macvideo" />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<CameraAltIcon />}
                disabled={dM1FrontVw()}
                onClick={sM1FrontVw}
                height={40}
              ></AVButton>
              <AVField
                id="m1FrontVw"
                text={q.tM1FrontVw}
                value={[iM1FrontVw, setIM1FrontVw]}
                validation={eM1FrontVw}
                backup={MCBKUP.bM1FrontVw}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hM1FrontVw} class="spacer">
              <WebCam setImage={setIM1FrontVw} />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<CameraAltIcon />}
                disabled={dM1SideVw()}
                onClick={sM1SideVw}
                height={40}
              ></AVButton>
              <AVField
                id="m1SideVw"
                text={q.tM1SideVw}
                value={[iM1SideVw, setIM1SideVw]}
                validation={eM1SideVw}
                backup={MCBKUP.bM1SideVw}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hM1SideVw} class="spacer">
              <WebCam setImage={setIM1SideVw} />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<CameraAltIcon />}
                disabled={dM1SpecVw()}
                onClick={sM1SpecVw}
                height={40}
              ></AVButton>
              <AVField
                id="m1SpecVw"
                text={q.tM1SpecVw}
                value={[iM1SpecVw, setIM1SpecVw]}
                validation={eM1SpecVw}
                backup={MCBKUP.bM1SpecVw}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hM1SpecVw} class="spacer">
              <WebCam setImage={setIM1SpecVw} />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<MyLocationIcon />}
                onClick={() => loadMachineMap()}
                height={40}
              ></AVButton>
              <AVField
                id="m1Acc"
                text={q.tM1Acc}
                value={[iM1Acc, setIM1Acc]}
                validation={eM1Acc}
                backup={MCBKUP.bM1Acc}
                disabled
                position="right"
              />
            </HBar>
            <VBar in={hM1Coor} class="spacer">
              <AVField
                id="m1Lat"
                text={q.tM1Lat}
                value={[iM1Lat, setIM1Lat]}
                validation={eM1Lat}
                backup={MCBKUP.bM1Lat}
                disabled
              />
              <AVField
                id="m1Lng"
                text={q.tM1Lng}
                value={[iM1Lng, setIM1Lng]}
                validation={eM1Lng}
                backup={MCBKUP.bM1Lng}
                disabled
              />
              <AVAlert severity={sM1Acc()} sx={{ width: "100%" }}>
                {sM1AccVal()}
              </AVAlert>
            </VBar>
            <GeoLocationPane />
            <AVDropdown
              id="m1Loc"
              text={q.tM1Loc}
              value={[iM1Loc, setIM1Loc]}
              validation={eM1Loc}
              backup={MCBKUP.bM1Loc}
              options={q.tM1Loc.options}
            />
            <hr />
            <AVText text={q.tM1Garage} />
            <Province
              id="m1Prov"
              text={q.tM1Prov}
              value={[iM1Prov, setIM1Prov]}
              validation={eM1Prov}
              backup={MCBKUP.bM1Prov}
              address={m1Adrs}
            />
            <Municipality
              id="m1Mun"
              text={q.tM1Mun}
              value={[iM1Mun, setIM1Mun]}
              validation={eM1Mun}
              backup={MCBKUP.bM1Mun}
              address={m1Adrs}
            />
            <Barangay
              id="m1Brgy"
              text={q.tM1Brgy}
              value={[iM1Brgy, setIM1Brgy]}
              validation={eM1Brgy}
              backup={MCBKUP.bM1Brgy}
              address={m1Adrs}
            />
            <hr />
            <AVDropdown
              id="m1Type"
              text={q.tM1Type}
              value={[iM1Type, setIM1Type]}
              validation={eM1Type}
              backup={MCBKUP.bM1Type}
              options={machines.types}
            />
            <HBar in={hM1Serial}>
              <AVField
                id="m1Serial"
                text={q.tM1Serial}
                value={[iM1Serial, setIM1Serial]}
                validation={eM1Serial}
                backup={MCBKUP.bM1Serial}
              />
            </HBar>
            <HBar in={hM1CovHlng}>
              <AVDropdown
                id="m1CovHlng"
                text={q.tM1CovHlng}
                value={[iM1CovHlng, setIM1CovHlng]}
                validation={eM1CovHlng}
                backup={MCBKUP.bM1CovHlng}
                options={q.tM1CovHlng.options}
              />
            </HBar>
          </Card>
          <Card in={hM2Title} title={q.tM2Title} titleStyle="title">
            <AVDropdown
              id="m2Brand"
              text={q.tM2Brand}
              value={[iM2Brand, setIM2Brand]}
              validation={eM2Brand}
              backup={MCBKUP.bM2Brand}
              options={oBrands}
              free
              disabled={dBrand}
            />
            <AVDropdown
              id="m2Model"
              text={q.tM2Model}
              value={[iM2Model, setIM2Model]}
              validation={eM2Model}
              backup={MCBKUP.bM2Model}
              options={oModels}
              free
              disabled={dModel}
            />
            <HBar gap={10}>
              <AVField
                id="m2RtdPwr"
                text={q.tM2RtdPwr}
                value={[iM2RtdPwr, setIM2RtdPwr]}
                validation={eM2RtdPwr}
                backup={MCBKUP.bM2RtdPwr}
              />
              <AVField
                id="m2TtlSrvArea"
                text={q.tM2TtlSrvArea}
                value={[iM2TtlSrvArea, setIM2TtlSrvArea]}
                validation={eM2TtlSrvArea}
                backup={MCBKUP.bM2TtlSrvArea}
              />
            </HBar>
            <AVField
              id="m2AvgOpHrDy"
              text={q.tM2AvgOpHrDy}
              value={[iM2AvgOpHrDy, setIM2AvgOpHrDy]}
              validation={eM2AvgOpHrDy}
              backup={MCBKUP.bM2AvgOpHrDy}
            />
            <AVField
              id="m2AvgOpDySsn"
              text={q.tM2AvgOpDySsn}
              value={[iM2AvgOpDySsn, setIM2AvgOpDySsn]}
              validation={eM2AvgOpDySsn}
              backup={MCBKUP.bM2AvgOpDySsn}
            />
            <HBar in={vCnHlr}>
              <AVField
                id="m2LdCap"
                text={q.tM2LdCap}
                value={[iM2LdCap, setIM2LdCap]}
                validation={eM2LdCap}
                backup={MCBKUP.bM2LdCap}
                position="left"
              />
              <AVDropdown
                id="m2LdCapUnit"
                text={q.tM2LdCapUnit}
                value={[iM2LdCapUnit, setIM2LdCapUnit]}
                validation={eM2LdCapUnit}
                backup={MCBKUP.bM2LdCapUnit}
                options={q.tM2LdCapUnit.options}
                position="center"
              />
              <AVField
                id="m2LdCapDay"
                text={q.tM2LdCapDay}
                value={[iM2LdCapDay, setIM2LdCapDay]}
                validation={eM2LdCapDay}
                backup={MCBKUP.bM2LdCapDay}
                position="right"
                disabled={dM2LdCapDay}
              />
            </HBar>
            <VBar in={vHrv || vCnHlr} class="spacer">
              <AVField
                id="m2TmCom1Ha"
                text={q.tM2TmCom1Ha}
                value={[iM2TmCom1Ha, setIM2TmCom1Ha]}
                validation={eM2TmCom1Ha}
                backup={MCBKUP.bM2TmCom1Ha}
              />
            </VBar>
            <VBar in={vHrv} class="spacer">
              <AVField
                id="m2SgrcnYld"
                text={q.tM2SgrcnYld}
                value={[iM2SgrcnYld, setIM2SgrcnYld]}
                validation={eM2SgrcnYld}
                backup={MCBKUP.bM2SgrcnYld}
              />
            </VBar>
          </Card>
          <Card in={hM3Title} title={sM3Title} titleStyle="title">
            <AVText text={q.tM3Specs} />
            <AVDropdown
              id="m3TpTbWlls"
              text={q.tM3TpTbWlls}
              value={[iM3TpTbWlls, setIM3TpTbWlls]}
              validation={eM3TpTbWlls}
              backup={MCBKUP.bM3TpTbWlls}
              options={q.tM3TpTbWlls.options2}
              free
            />
            <AVDropdown
              id="m3Brand"
              text={q.tM3Brand}
              value={[iM3Brand, setIM3Brand]}
              validation={eM3Brand}
              backup={MCBKUP.bM3Brand}
              options={[]}
              free
            />
            <AVDropdown
              id="m3Model"
              text={q.tM3Model}
              value={[iM3Model, setIM3Model]}
              validation={eM3Model}
              backup={MCBKUP.bM3Model}
              options={[]}
              free
            />
            <AVField
              id="m3SzPmp"
              text={q.tM3SzPmp}
              value={[iM3SzPmp, setIM3SzPmp]}
              validation={eM3SzPmp}
              backup={MCBKUP.bM3SzPmp}
            />
            <AVText text={q.tM3SPrime} />
            <AVDropdown
              id="m3SrcPwr"
              text={q.tM3SrcPwr}
              value={[iM3SrcPwr, setIM3SrcPwr]}
              validation={eM3SrcPwr}
              backup={MCBKUP.bM3SrcPwr}
              options={q.tM3SrcPwr.options}
            />
            <AVDropdown
              id="m3SrcBrand"
              text={q.tM3SrcBrand}
              value={[iM3SrcBrand, setIM3SrcBrand]}
              validation={eM3SrcBrand}
              backup={MCBKUP.bM3SrcBrand}
              options={[]}
              free
            />
            <AVDropdown
              id="m3SrcModel"
              text={q.tM3SrcModel}
              value={[iM3SrcModel, setIM3SrcModel]}
              validation={eM3SrcModel}
              backup={MCBKUP.bM3SrcModel}
              options={[]}
              free
            />
            <AVField
              id="m3RtdPwr"
              text={q.tM3RtdPwr}
              value={[iM3RtdPwr, setIM3RtdPwr]}
              validation={eM3RtdPwr}
              backup={MCBKUP.bM3RtdPwr}
            />
            <AVText text={q.tM3Output} />
            <HBar>
              <AVField
                id="m3DisCap"
                text={q.tM3DisCap}
                value={[iM3DisCap, setIM3DisCap]}
                validation={eM3DisCap}
                backup={MCBKUP.bM3DisCap}
                position="left"
              />
              <AVDropdown
                id="m3DisCapUnit"
                text={q.tM3DisCapUnit}
                value={[iM3DisCapUnit, setIM3DisCapUnit]}
                validation={eM3DisCapUnit}
                backup={MCBKUP.bM3DisCapUnit}
                options={q.tM3DisCap.options}
                free
                position="right"
              />
            </HBar>
            <AVField
              id="m3TtlSrvArea"
              text={q.tM3TtlSrvArea}
              value={[iM3TtlSrvArea, setIM3TtlSrvArea]}
              validation={eM3TtlSrvArea}
              backup={MCBKUP.bM3TtlSrvArea}
            />
            <AVField
              id="m3AvgOpHrDy"
              text={q.tM3AvgOpHrDy}
              value={[iM3AvgOpHrDy, setIM3AvgOpHrDy]}
              validation={eM3AvgOpHrDy}
              backup={MCBKUP.bM3AvgOpHrDy}
            />
            <AVField
              id="m3AvgOpDySsn"
              text={q.tM3AvgOpDySsn}
              value={[iM3AvgOpDySsn, setIM3AvgOpDySsn]}
              validation={eM3AvgOpDySsn}
              backup={MCBKUP.bM3AvgOpDySsn}
            />
          </Card>
          <Card in={hM4Title} title={q.tM4Title} titleStyle="title">
            <AVDropdown
              id="m4Usage"
              text={q.tM4Usage}
              value={[iM4Usage, setIM4Usage]}
              validation={eM4Usage}
              backup={MCBKUP.bM4Usage}
              options={q.tM4Usage.options}
            />
            <AVDropdown
              id="m4Mode"
              text={q.tM4Mode}
              value={[iM4Mode, setIM4Mode]}
              validation={eM4Mode}
              backup={MCBKUP.bM4Mode}
              options={q.tM4Mode.options}
            />
            <HBar in={hM4Agency}>
              <AVField
                id="m4Agency"
                text={q.tM4Agency}
                value={[iM4Agency, setIM4Agency]}
                validation={eM4Agency}
                backup={MCBKUP.bM4Agency}
              />
            </HBar>
            <AVField
              id="m4YrAcqrd"
              text={q.tM4YrAcqrd}
              value={[iM4YrAcqrd, setIM4YrAcqrd]}
              validation={eM4YrAcqrd}
              backup={MCBKUP.bM4YrAcqrd}
            />
            <AVDropdown
              id="m4ConAcqrd"
              text={q.tM4ConAcqrd}
              value={[iM4ConAcqrd, setIM4ConAcqrd]}
              validation={eM4ConAcqrd}
              backup={MCBKUP.bM4ConAcqrd}
              options={q.tM4ConAcqrd.options}
            />
            <AVDropdown
              id="m4PrsntCon"
              text={q.tM4PrsntCon}
              value={[iM4PrsntCon, setIM4PrsntCon]}
              validation={eM4PrsntCon}
              backup={MCBKUP.bM4PrsntCon}
              options={q.tM4PrsntCon.options}
            />
            <VBar in={hM4Unused} id="unused" class="spacer">
              <AVField
                id="m4YrUnused"
                text={q.tM4YrUnused}
                value={[iM4YrUnused, setIM4YrUnused]}
                validation={eM4YrUnused}
                backup={MCBKUP.bM4YrUnused}
              />
              <AVText text={q.tM4RsnUnused.label} />
              <AVTick
                id="m4RsnUnused"
                value={[iM4RsnUnused, setIM4RsnUnused]}
                backup={MCBKUP.bM4RsnUnused}
                options={q.tM4RsnUnused.options}
              />
              <AVField
                id="m4UuOthers"
                text={q.tM4UuOthers}
                value={[iM4UuOthers, setIM4UuOthers]}
                validation={eM4UuOthers}
                backup={MCBKUP.bM4UuOthers}
              />
            </VBar>
            <VBar in={hM4Unser} id="unserviceable" class="spacer">
              <AVField
                id="m4YrUnserv"
                text={q.tM4YrUnserv}
                value={[iM4YrUnserv, setIM4YrUnserv]}
                validation={eM4YrUnserv}
                backup={MCBKUP.bM4YrUnserv}
              />
              <AVText text={q.tM4RsnUnserv.label} />
              <AVTick
                id="m4RsnUnserv"
                value={[iM4RsnUnserv, setIM4RsnUnserv]}
                backup={MCBKUP.bM4RsnUnserv}
                options={q.tM4RsnUnserv.options}
              />
              <AVField
                id="m4UsOthers"
                text={q.tM4UsOthers}
                value={[iM4UsOthers, setIM4UsOthers]}
                validation={eM4UsOthers}
                backup={MCBKUP.bM4UsOthers}
              />
            </VBar>
            <AVField
              id="m4NoBrkdwn"
              text={q.tM4NoBrkdwn}
              value={[iM4NoBrkdwn, setIM4NoBrkdwn]}
              validation={eM4NoBrkdwn}
              backup={MCBKUP.bM4NoBrkdwn}
            />
            <AVDropdown
              id="m4CmnBrkdwn"
              text={q.tM4CmnBrkdwn}
              value={[iM4CmnBrkdwn, setIM4CmnBrkdwn]}
              validation={eM4CmnBrkdwn}
              backup={MCBKUP.bM4CmnBrkdwn}
              options={q.tM4CmnBrkdwn.options}
              max={3}
            />
          </Card>
          <Card in={hM5Title()} title={q.tM5Title} titleStyle="title">
            <AVText text={q.tM5Subtitle} />
            <AVTick
              id="m5SameOwn"
              value={[iM5SameOwn, setIM5SameOwn]}
              backup={MCBKUP.bM5SameOwn}
              options={q.tM5SameOwn}
            />
            <HBar>
              <AVField
                id="m5BusCont1"
                text={q.tM5BusCont1}
                value={[iM5BusCont1, setIM5BusCont1]}
                validation={eM5BusCont1}
                backup={MCBKUP.bM5BusCont1}
                width={50}
                position="left"
              />
              <AVField
                id="m5BusCont2"
                text={q.tM5BusCont2}
                value={[iM5BusCont2, setIM5BusCont2]}
                validation={eM5BusCont2}
                backup={MCBKUP.bM5BusCont2}
                width={50}
                position="right"
              />
            </HBar>
            <AVTick
              id="m5Consent"
              value={[iM5Consent, setIM5Consent]}
              backup={MCBKUP.bM5Consent}
              options={q.tM5Consent}
            />
            <HBar gap={10}>
              <VBar w={sM5Member}>
                <VBar gap={10}>
                  <p>
                    <strong>{q.tM5Member}</strong>
                  </p>
                  <VBar in={isTractor} gap={10}>
                    <AVText text={q.tM5Operation.first} />
                    <AVDropdown
                      id="m5Ope1"
                      text={q.tM5Ope}
                      value={[iM5Ope1, setIM5Ope1]}
                      validation={eM5Ope1}
                      backup={MCBKUP.bM5Ope1}
                      options={q.tM5Ope.options}
                    />
                  </VBar>
                  <AVField
                    id="m5Ope1Rate"
                    text={q.tM5OpeRate}
                    value={[iM5Ope1Rate, setIM5Ope1Rate]}
                    validation={eM5Ope1Rate}
                    backup={MCBKUP.bM5Ope1Rate}
                    unitPosition="start"
                  />
                  <AVDropdown
                    id="m5Ope1Unit"
                    text={q.tM5OpeUnit}
                    value={[iM5Ope1Unit, setIM5Ope1Unit]}
                    validation={eM5Ope1Unit}
                    backup={MCBKUP.bM5Ope1Unit}
                    options={sM5OpeUnit()}
                  />
                  <AVField
                    id="m5Ope1Fuel"
                    text={q.tM5OpeFuel}
                    value={[iM5Ope1Fuel, setIM5Ope1Fuel]}
                    validation={eM5Ope1Fuel}
                    backup={MCBKUP.bM5Ope1Fuel}
                  />
                </VBar>
                <VBar in={hM5Ope2} gap={10}>
                  <AVText text={q.tM5Operation.second} className="pt-2" />
                  <AVDropdown
                    id="m5Ope2"
                    text={q.tM5Ope}
                    value={[iM5Ope2, setIM5Ope2]}
                    validation={eM5Ope2}
                    backup={MCBKUP.bM5Ope2}
                    options={q.tM5Ope.options}
                  />
                  <AVField
                    id="m5Ope2Rate"
                    text={q.tM5OpeRate}
                    value={[iM5Ope2Rate, setIM5Ope2Rate]}
                    validation={eM5Ope2Rate}
                    backup={MCBKUP.bM5Ope2Rate}
                    unitPosition="start"
                  />
                  <AVDropdown
                    id="m5Ope2Unit"
                    text={q.tM5OpeUnit}
                    value={[iM5Ope2Unit, setIM5Ope2Unit]}
                    validation={eM5Ope2Unit}
                    backup={MCBKUP.bM5Ope2Unit}
                    options={q.tM5OpeRate.options}
                    free
                  />
                  <AVField
                    id="m5Ope2Fuel"
                    text={q.tM5OpeFuel}
                    value={[iM5Ope2Fuel, setIM5Ope2Fuel]}
                    validation={eM5Ope2Fuel}
                    backup={MCBKUP.bM5Ope2Fuel}
                  />
                </VBar>
                <VBar in={hM5Ope3} gap={10}>
                  <AVText text={q.tM5Operation.third} className="pt-2" />
                  <AVDropdown
                    id="m5Ope3"
                    text={q.tM5Ope}
                    value={[iM5Ope3, setIM5Ope3]}
                    validation={eM5Ope3}
                    backup={MCBKUP.bM5Ope3}
                    options={q.tM5Ope.options}
                  />
                  <AVField
                    id="m5Ope3Rate"
                    text={q.tM5OpeRate}
                    value={[iM5Ope3Rate, setIM5Ope3Rate]}
                    validation={eM5Ope3Rate}
                    backup={MCBKUP.bM5Ope3Rate}
                    unitPosition="start"
                  />
                  <AVDropdown
                    id="m5Ope3Unit"
                    text={q.tM5OpeUnit}
                    value={[iM5Ope3Unit, setIM5Ope3Unit]}
                    validation={eM5Ope3Unit}
                    backup={MCBKUP.bM5Ope3Unit}
                    options={q.tM5OpeRate.options}
                    free
                  />
                  <AVField
                    id="m5Ope3Fuel"
                    text={q.tM5OpeFuel}
                    value={[iM5Ope3Fuel, setIM5Ope3Fuel]}
                    validation={eM5Ope3Fuel}
                    backup={MCBKUP.bM5Ope3Fuel}
                  />
                </VBar>
              </VBar>
              <VBar w={100} in={hM5NonMember}>
                <VBar gap={10}>
                  <p>
                    <strong>{q.tM5NonMember}</strong>
                  </p>
                  <VBar in={isTractor} gap={10}>
                    <AVText text={q.tM5Operation.first} />
                    <AVDropdown
                      id="m5Ope4"
                      text={q.tM5Ope}
                      value={[iM5Ope4, setIM5Ope4]}
                      validation={eM5Ope4}
                      backup={MCBKUP.bM5Ope4}
                      options={q.tM5Ope.options}
                    />
                  </VBar>
                  <AVField
                    id="m5Ope4Rate"
                    text={q.tM5OpeRate}
                    value={[iM5Ope4Rate, setIM5Ope4Rate]}
                    validation={eM5Ope4Rate}
                    backup={MCBKUP.bM5Ope4Rate}
                    unitPosition="start"
                  />
                  <AVDropdown
                    id="m5Ope4Unit"
                    text={q.tM5OpeUnit}
                    value={[iM5Ope4Unit, setIM5Ope4Unit]}
                    validation={eM5Ope4Unit}
                    backup={MCBKUP.bM5Ope4Unit}
                    options={sM5OpeUnit()}
                  />
                  <AVField
                    id="m5Ope4Fuel"
                    text={q.tM5OpeFuel}
                    value={[iM5Ope4Fuel, setIM5Ope4Fuel]}
                    validation={eM5Ope4Fuel}
                    backup={MCBKUP.bM5Ope4Fuel}
                  />
                </VBar>
                <VBar in={hM5Ope5} gap={10}>
                  <AVText text={q.tM5Operation.second} className="pt-2" />
                  <AVDropdown
                    id="m5Ope5"
                    text={q.tM5Ope}
                    value={[iM5Ope5, setIM5Ope5]}
                    validation={eM5Ope5}
                    backup={MCBKUP.bM5Ope5}
                    options={q.tM5Ope.options}
                  />
                  <AVField
                    id="m5Ope5Rate"
                    text={q.tM5OpeRate}
                    value={[iM5Ope5Rate, setIM5Ope5Rate]}
                    validation={eM5Ope5Rate}
                    backup={MCBKUP.bM5Ope5Rate}
                    unitPosition="start"
                  />
                  <AVDropdown
                    id="m5Ope5Unit"
                    text={q.tM5OpeUnit}
                    value={[iM5Ope5Unit, setIM5Ope5Unit]}
                    validation={eM5Ope5Unit}
                    backup={MCBKUP.bM5Ope5Unit}
                    options={q.tM5OpeRate.options}
                    free
                  />
                  <AVField
                    id="m5Ope5Fuel"
                    text={q.tM5OpeFuel}
                    value={[iM5Ope5Fuel, setIM5Ope5Fuel]}
                    validation={eM5Ope5Fuel}
                    backup={MCBKUP.bM5Ope5Fuel}
                  />
                </VBar>
                <VBar in={hM5Ope6} gap={10}>
                  <AVText text={q.tM5Operation.third} className="pt-2" />
                  <AVDropdown
                    id="m5Ope6"
                    text={q.tM5Ope}
                    value={[iM5Ope6, setIM5Ope6]}
                    validation={eM5Ope6}
                    backup={MCBKUP.bM5Ope6}
                    options={q.tM5Ope.options}
                  />
                  <AVField
                    id="m5Ope6Rate"
                    text={q.tM5OpeRate}
                    value={[iM5Ope6Rate, setIM5Ope6Rate]}
                    validation={eM5Ope6Rate}
                    backup={MCBKUP.bM5Ope6Rate}
                    unitPosition="start"
                  />
                  <AVDropdown
                    id="m5Ope6Unit"
                    text={q.tM5OpeUnit}
                    value={[iM5Ope6Unit, setIM5Ope6Unit]}
                    validation={eM5Ope6Unit}
                    backup={MCBKUP.bM5Ope6Unit}
                    options={q.tM5OpeRate.options}
                    free
                  />
                  <AVField
                    id="m5Ope6Fuel"
                    text={q.tM5OpeFuel}
                    value={[iM5Ope6Fuel, setIM5Ope6Fuel]}
                    validation={eM5Ope6Fuel}
                    backup={MCBKUP.bM5Ope6Fuel}
                  />
                </VBar>
              </VBar>
            </HBar>
            <hr />
            <AVText text={q.tM5Service} />
            <HBar gap={10}>
              <Province
                id="m5SrvcLoc1P"
                text={q.tM1Prov}
                value={[iM5SrvcLoc1P, setIM5SrvcLoc1P]}
                validation={eM5SrvcLoc1P}
                backup={MCBKUP.bM5SrvcLoc1P}
                address={m5Adrs1}
              />
              <Municipality
                id="m5SrvcLoc1M"
                text={q.tM1Mun}
                value={[iM5SrvcLoc1M, setIM5SrvcLoc1M]}
                validation={eM5SrvcLoc1M}
                backup={MCBKUP.bM5SrvcLoc1M}
                address={m5Adrs1}
              />
            </HBar>
            <HBar gap={10} in={hM5SrvcLoc2P}>
              <Province
                id="m5SrvcLoc2P"
                text={q.tM1Prov}
                value={[iM5SrvcLoc2P, setIM5SrvcLoc2P]}
                validation={eM5SrvcLoc2P}
                backup={MCBKUP.bM5SrvcLoc2P}
                address={m5Adrs2}
              />
              <Municipality
                id="m5SrvcLoc2M"
                text={q.tM1Mun}
                value={[iM5SrvcLoc2M, setIM5SrvcLoc2M]}
                validation={eM5SrvcLoc2M}
                backup={MCBKUP.bM5SrvcLoc2M}
                address={m5Adrs2}
              />
            </HBar>
            <HBar gap={10} in={hM5SrvcLoc3P}>
              <Province
                id="m5SrvcLoc3P"
                text={q.tM1Prov}
                value={[iM5SrvcLoc3P, setIM5SrvcLoc3P]}
                validation={eM5SrvcLoc3P}
                backup={MCBKUP.bM5SrvcLoc3P}
                address={m5Adrs3}
              />
              <Municipality
                id="m5SrvcLoc3M"
                text={q.tM1Mun}
                value={[iM5SrvcLoc3M, setIM5SrvcLoc3M]}
                validation={eM5SrvcLoc3M}
                backup={MCBKUP.bM5SrvcLoc3M}
                address={m5Adrs3}
              />
            </HBar>
            <VBar id="yes" class="spacer"></VBar>
          </Card>
          <Card in={hM6Title} title={q.tM6Title} titleStyle="title">
            <AVText text={q.tM6TotalTitle} />
            <AVField
              id="m6Total"
              text={q.tM6Total}
              value={[iM6Total, setIM6Total]}
              validation={eM6Total}
              backup={MCBKUP.bM6Total}
            />
            <AVText text={q.tM6L2FormalTitle} />
            <AVField
              id="m6L2Formal"
              text={q.tM6L2Formal}
              value={[iM6L2Formal, setIM6L2Formal]}
              validation={eM6L2Formal}
              backup={MCBKUP.bM6L2Formal}
            />
            <AVField
              id="m6L2NtFrml"
              text={q.tM6L2NtFrml}
              value={[iM6L2NtFrml, setIM6L2NtFrml]}
              validation={eM6L2NtFrml}
              backup={MCBKUP.bM6L2NtFrml}
            />
            <AVField
              id="m6G2Formal"
              text={q.tM6G2Formal}
              value={[iM6G2Formal, setIM6G2Formal]}
              validation={eM6G2Formal}
              backup={MCBKUP.bM6G2Formal}
            />
            <AVField
              id="m6G2NtFrml"
              text={q.tM6G2NtFrml}
              value={[iM6G2NtFrml, setIM6G2NtFrml]}
              validation={eM6G2NtFrml}
              backup={MCBKUP.bM6G2NtFrml}
            />
          </Card>
          <Card in={hM7Title} title={q.tM7Title} titleStyle="title">
            <VBar in={vCSpecs} class="spacer">
              <HBar>
                <AVField
                  id="m7AirFltr"
                  text={q.tM7AirFltr}
                  value={[iM7AirFltr, setIM7AirFltr]}
                  validation={eM7AirFltr}
                  backup={MCBKUP.bM7AirFltr}
                  width={50}
                  position="left"
                />
                <AVDropdown
                  id="m7AirFltrUnit"
                  text={q.tM7Unit}
                  value={[iM7AirFltrUnit, setIM7AirFltrUnit]}
                  validation={eM7AirFltrUnit}
                  backup={MCBKUP.bM7AirFltrUnit}
                  options={q.tM7Units}
                  width={50}
                  position="right"
                />
              </HBar>
              <HBar>
                <AVField
                  id="m7EngOil"
                  text={q.tM7EngOil}
                  value={[iM7EngOil, setIM7EngOil]}
                  validation={eM7EngOil}
                  backup={MCBKUP.bM7EngOil}
                  width={50}
                  position="left"
                />
                <AVDropdown
                  id="m7EngOilUnit"
                  text={q.tM7Unit}
                  value={[iM7EngOilUnit, setIM7EngOilUnit]}
                  validation={eM7EngOilUnit}
                  backup={MCBKUP.bM7EngOilUnit}
                  options={q.tM7Units}
                  width={50}
                  position="right"
                />
              </HBar>
              <HBar>
                <AVField
                  id="m7EngOilFltr"
                  text={q.tM7EngOilFltr}
                  value={[iM7EngOilFltr, setIM7EngOilFltr]}
                  validation={eM7EngOilFltr}
                  backup={MCBKUP.bM7EngOilFltr}
                  width={50}
                  position="left"
                />
                <AVDropdown
                  id="m7EngOilFltrUnit"
                  text={q.tM7Unit}
                  value={[iM7EngOilFltrUnit, setIM7EngOilFltrUnit]}
                  validation={eM7EngOilFltrUnit}
                  backup={MCBKUP.bM7EngOilFltrUnit}
                  options={q.tM7Units}
                  width={50}
                  position="right"
                />
              </HBar>
              <HBar>
                <AVField
                  id="m7HydOil"
                  text={q.tM7HydOil}
                  value={[iM7HydOil, setIM7HydOil]}
                  validation={eM7HydOil}
                  backup={MCBKUP.bM7HydOil}
                  width={50}
                  position="left"
                />
                <AVDropdown
                  id="m7HydOilUnit"
                  text={q.tM7Unit}
                  value={[iM7HydOilUnit, setIM7HydOilUnit]}
                  validation={eM7HydOilUnit}
                  backup={MCBKUP.bM7HydOilUnit}
                  options={q.tM7Units}
                  width={50}
                  position="right"
                />
              </HBar>
              <HBar>
                <AVField
                  id="m7HydOilFltr"
                  text={q.tM7HydOilFltr}
                  value={[iM7HydOilFltr, setIM7HydOilFltr]}
                  validation={eM7HydOilFltr}
                  backup={MCBKUP.bM7HydOilFltr}
                  width={50}
                  position="left"
                />
                <AVDropdown
                  id="m7HydOilFltrUnit"
                  text={q.tM7Unit}
                  value={[iM7HydOilFltrUnit, setIM7HydOilFltrUnit]}
                  validation={eM7HydOilFltrUnit}
                  backup={MCBKUP.bM7HydOilFltrUnit}
                  options={q.tM7Units}
                  width={50}
                  position="right"
                />
              </HBar>
              <HBar>
                <AVField
                  id="m7Tire"
                  text={q.tM7Tire}
                  value={[iM7Tire, setIM7Tire]}
                  validation={eM7Tire}
                  backup={MCBKUP.bM7Tire}
                  width={50}
                  position="left"
                />
                <AVDropdown
                  id="m7TireUnit"
                  text={q.tM7Unit}
                  value={[iM7TireUnit, setIM7TireUnit]}
                  validation={eM7TireUnit}
                  backup={MCBKUP.bM7TireUnit}
                  options={q.tM7Units}
                  width={50}
                  position="right"
                />
              </HBar>
              <hr />
            </VBar>
            <AVText text={q.tM7SrvPrvdr} />
            <AVDropdown
              id="m7SrvcPrvdr"
              text={q.tM7SrvcPrvdr}
              value={[iM7SrvcPrvdr, setIM7SrvcPrvdr]}
              validation={eM7SrvcPrvdr}
              backup={MCBKUP.bM7SrvcPrvdr}
              options={q.tM7Options}
            />
            <VBar class="spacer indent">
              <Province
                id="m7Prov"
                text={q.tM7Prov}
                value={[iM7Prov, setIM7Prov]}
                validation={eM7Prov}
                backup={MCBKUP.bM7Prov}
                address={m7Adrs}
              />
              <Municipality
                id="m7Mun"
                text={q.tM7Mun}
                value={[iM7Mun, setIM7Mun]}
                validation={eM7Mun}
                backup={MCBKUP.bM7Mun}
                address={m7Adrs}
              />
              <Barangay
                id="m7Brgy"
                text={q.tM7Brgy}
                value={[iM7Brgy, setIM7Brgy]}
                validation={eM7Brgy}
                backup={MCBKUP.bM7Brgy}
                address={m7Adrs}
              />
            </VBar>
            <AVDropdown
              id="m7TimeRspnd"
              text={q.tM7TimeRspnd}
              value={[iM7TimeRspnd, setIM7TimeRspnd]}
              validation={eM7TimeRspnd}
              backup={MCBKUP.bM7TimeRspnd}
              options={q.tM7TimeRspnd.options}
            />
            <AVDropdown
              id="m7PrtsAvail"
              text={q.tM7PrtsAvail}
              value={[iM7PrtsAvail, setIM7PrtsAvail]}
              validation={eM7PrtsAvail}
              backup={MCBKUP.bM7PrtsAvail}
              options={q.tM7PrtsAvail.options}
            />
          </Card>
          <Card in={vOCrops} title={q.tM8Title} titleStyle="title">
            <AVDropdown
              id="m8OthCrp"
              text={q.tM8OthCrp}
              value={[iM8OthCrp, setIM8OthCrp]}
              validation={eM8OthCrp}
              backup={MCBKUP.bM8OthCrp}
              options={q.tM8OthCrp.options}
            />
            <VBar in={hM8Title} class="spacer">
              <HBar>
                <AVText text={q.tM8Label} />
              </HBar>
              <HBar gap={10}>
                <AVTick
                  id="m8Rice"
                  value={[iM8Rice, setIM8Rice]}
                  backup={MCBKUP.bM8Rice}
                  options={q.tM8Rice.option}
                  width={50}
                />
                <HBar in={hM8Rice}>
                  <AVField
                    id="m8RiceHa"
                    text={q.tM8Rice}
                    value={[iM8RiceHa, setIM8RiceHa]}
                    validation={eM8RiceHa}
                    backup={MCBKUP.bM8RiceHa}
                    open
                  />
                </HBar>
              </HBar>
              <HBar gap={10}>
                <AVTick
                  id="m8Corn"
                  value={[iM8Corn, setIM8Corn]}
                  backup={MCBKUP.bM8Corn}
                  options={q.tM8Corn.option}
                  width={50}
                />
                <HBar in={hM8CornHa}>
                  <AVField
                    id="m8CornHa"
                    text={q.tM8Corn}
                    value={[iM8CornHa, setIM8CornHa]}
                    validation={eM8CornHa}
                    backup={MCBKUP.bM8CornHa}
                    open
                  />
                </HBar>
              </HBar>
              <HBar gap={10}>
                <AVField
                  id="m8Othr1"
                  text={q.tM8Othr}
                  value={[iM8Othr1, setIM8Othr1]}
                  validation={eM8Othr1}
                  backup={MCBKUP.bM8Othr1}
                  width={50}
                />
                <HBar in={hM8Othr1Ha}>
                  <AVField
                    id="m8Othr1Ha"
                    text={q.tM8OthrHa}
                    value={[iM8Othr1Ha, setIM8Othr1Ha]}
                    validation={eM8Othr1Ha}
                    backup={MCBKUP.bM8Othr1Ha}
                    open
                  />
                </HBar>
              </HBar>
              <HBar in={hM8Othr1Ha} gap={10}>
                <AVField
                  id="m8Othr2"
                  text={q.tM8Othr}
                  value={[iM8Othr2, setIM8Othr2]}
                  validation={eM8Othr2}
                  backup={MCBKUP.bM8Othr2}
                  width={50}
                />
                <HBar in={hM8Othr2Ha}>
                  <AVField
                    id="m8Othr2Ha"
                    text={q.tM8OthrHa}
                    value={[iM8Othr2Ha, setIM8Othr2Ha]}
                    validation={eM8Othr2Ha}
                    backup={MCBKUP.bM8Othr2Ha}
                    open
                  />
                </HBar>
              </HBar>
              <HBar in={hM8Othr2Ha} gap={10}>
                <AVField
                  id="m8Othr3"
                  text={q.tM8Othr}
                  value={[iM8Othr3, setIM8Othr3]}
                  validation={eM8Othr3}
                  backup={MCBKUP.bM8Othr3}
                  width={50}
                />
                <HBar in={hM8Othr3Ha}>
                  <AVField
                    id="m8Othr3Ha"
                    text={q.tM8OthrHa}
                    value={[iM8Othr3Ha, setIM8Othr3Ha]}
                    validation={eM8Othr3Ha}
                    backup={MCBKUP.bM8Othr3Ha}
                    open
                  />
                </HBar>
              </HBar>
            </VBar>
          </Card>
          <Card>
            <AVField
              id="m9Notes"
              text={q.tM9Notes}
              value={[iM9Notes, setIM9Notes]}
              validation={eM9Notes}
              backup={MCBKUP.bM9Notes}
              rows={7}
            />
          </Card>
          <AVButton
            height={50}
            color="primary"
            label={action.save}
            icon={<SaveIcon />}
            onClick={async () => {
              saveEncodedMachine();
            }}
          />
          <div className="btn-group" role="group">
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.encode}
                as={NavLink}
                to={AvzRoute.encode}
                end
              >
                Go to Encode
              </Nav.Link>
            </button>
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.implement}
                as={NavLink}
                to={AvzRoute.implement}
                end
              >
                Go to Implement
              </Nav.Link>
            </button>
          </div>
        </VBar>
      </VBar>
    )
  );

  async function saveEncodedMachine() {
    const Question = (value: any) => {
      return QUESTIONS.find((q) => q.id === value);
    };

    var required = [
      Question("m1ResName"),
      Question("m1QRCode"),
      Question("m1FrontVw"),
      Question("m1SideVw"),
      // Question("m1SpecVw"),
      Question("m1Acc"),
      Question("m1Lat"),
      Question("m1Lng"),
      Question("m1Loc"),
      Question("m1Prov"),
      Question("m1Mun"),
      Question("m1Brgy"),
      Question("m1Type"),
    ];
    if (hM1Serial) {
      required.push(Question("m1Serial"));
    }
    if (hM1CovHlng) {
      required.push(Question("m1CovHlng"));
    }
    if (hM2Title) {
      required.push(Question("m2Brand"));
      // required.push(Question("m2Model"));
      required.push(Question("m2RtdPwr"));
      required.push(Question("m2TtlSrvArea"));
      required.push(Question("m2AvgOpHrDy"));
      required.push(Question("m2AvgOpDySsn"));
      if (vCnHlr) {
        required.push(Question("m2LdCap"));
        required.push(Question("m2LdCapUnit"));
      }
      if (!dM2LdCapDay) {
        required.push(Question("m2LdCapDay"));
      }
      if (vHrv || vCnHlr) {
        required.push(Question("m2TmCom1Ha"));
        if (vHrv) {required.push(Question("m2SgrcnYld"));}
      }
    }
    if (hM3Title) {
      required.push(Question("m3TpTbWlls"));
      required.push(Question("m3Brand"));
      required.push(Question("m3Model"));
      required.push(Question("m3SzPmp"));
      required.push(Question("m3SrcPwr"));
      required.push(Question("m3SrcBrand"));
      required.push(Question("m3SrcModel"));
      required.push(Question("m3RtdPwr"));
      required.push(Question("m3DisCap"));
      required.push(Question("m3DisCapUnit"));
      required.push(Question("m3TtlSrvArea"));
      required.push(Question("m3AvgOpHrDy"));
      required.push(Question("m3AvgOpDySsn"));
    }
    if (hM4Title) {
      required.push(Question("m4Usage"));
      required.push(Question("m4Mode"));
      if (hM4Agency) {
        required.push(Question("m4Agency"));
      }
      required.push(Question("m4YrAcqrd"));
      required.push(Question("m4ConAcqrd"));
      required.push(Question("m4PrsntCon"));
      if (hM4Unused) {
        required.push(Question("m4YrUnused"));
        required.push(Question("m4RsnUnused"));
        // required.push(Question("m4UuOthers"));
      }
      if (hM4Unser) {
        required.push(Question("m4YrUnserv"));
        required.push(Question("m4RsnUnserv"));
        // required.push(Question("m4UsOthers"));
      }
      required.push(Question("m4NoBrkdwn"));
      required.push(Question("m4CmnBrkdwn"));
    }
    if (hM5Title()) {
      required.push(Question("m5SameOwn"));
      required.push(Question("m5BusCont1"));
      required.push(Question("m5BusCont2"));
      required.push(Question("m5Consent"));
      if (isTractor){required.push(Question("m5Ope1"));}
      required.push(Question("m5Ope1Rate"));
      required.push(Question("m5Ope1Unit"));
      required.push(Question("m5Ope1Fuel"));
      // if (hM5Ope2) {
      //   required.push(Question("m5Ope2"));
      //   required.push(Question("m5Ope2Rate"));
      //   required.push(Question("m5Ope2Unit"));
      //   required.push(Question("m5Ope2Fuel"));
      // }
      // if (hM5Ope3) {
      //   required.push(Question("m5Ope3"));
      //   required.push(Question("m5Ope3Rate"));
      //   required.push(Question("m5Ope3Unit"));
      //   required.push(Question("m5Ope3Fuel"));
      // }
      if (hM5NonMember) {
        if (isTractor) {required.push(Question("m5Ope4"));}
        required.push(Question("m5Ope4Rate"));
        required.push(Question("m5Ope4Unit"));
        required.push(Question("m5Ope4Fuel"));
        if (hM5Ope5) {
          required.push(Question("m5Ope5"));
          required.push(Question("m5Ope5Rate"));
          required.push(Question("m5Ope5Unit"));
          required.push(Question("m5Ope5Fuel"));
        }
        if (hM5Ope6) {
          required.push(Question("m5Ope6"));
          required.push(Question("m5Ope6Rate"));
          required.push(Question("m5Ope6Unit"));
          required.push(Question("m5Ope6Fuel"));
        }
      }
      required.push(Question("m5SrvcLoc1P"));
      required.push(Question("m5SrvcLoc1M"));
      if (hM5SrvcLoc2P) {
        required.push(Question("m5SrvcLoc2P"));
        required.push(Question("m5SrvcLoc2M"));
      }
      if (hM5SrvcLoc3P) {
        required.push(Question("m5SrvcLoc3P"));
        required.push(Question("m5SrvcLoc3M"));
      }
    }
    if (hM6Title) {
      required.push(Question("m6Total"));
      required.push(Question("m6L2Formal"));
      required.push(Question("m6L2NtFrml"));
      required.push(Question("m6G2Formal"));
      required.push(Question("m6G2NtFrml"));
    }
    if (hM7Title) {
      if (vCSpecs) {
        required.push(Question("m7AirFltr"));
        required.push(Question("m7AirFltrUnit"));
        required.push(Question("m7EngOil"));
        required.push(Question("m7EngOilUnit"));
        required.push(Question("m7EngOilFltr"));
        required.push(Question("m7EngOilFltrUnit"));
        required.push(Question("m7HydOil"));
        required.push(Question("m7HydOilUnit"));
        required.push(Question("m7HydOilFltr"));
        required.push(Question("m7HydOilFltrUnit"));
        required.push(Question("m7Tire"));
        required.push(Question("m7TireUnit"));
      }
      required.push(Question("m7SrvcPrvdr"));
      required.push(Question("m7Prov"));
      required.push(Question("m7Mun"));
      required.push(Question("m7Brgy"));
      required.push(Question("m7TimeRspnd"));
      required.push(Question("m7PrtsAvail"));
    }
    if (vOCrops) {
      required.push(Question("m8OthCrp"));
      // TODO Notify that there must be something wrong
      // if (hM8Title) {
      //   required.push(Question("m8Rice"));
      //   if (hM8Rice) {
      //     required.push(Question("m8RiceHa"));
      //   }
      //   required.push(Question("m8Corn"));
      //   if (hM8CornHa) {
      //     required.push(Question("m8CornHa"));
      //   }
      //   required.push(Question("m8Othr1"));
      //   if (hM8Othr1Ha) {
      //     required.push(Question("m8Othr1Ha"));
      //   }
      //   required.push(Question("m8Othr2"));
      //   if (hM8Othr2Ha) {
      //     required.push(Question("m8Othr2Ha"));
      //   }
      //   required.push(Question("m8Othr3"));
      //   if (hM8Othr3Ha) {
      //     required.push(Question("m8Othr3Ha"));
      //   }
      // }
      // Version 2
      if (hM8Title) {
        //   required.push(Question("m8Rice"));
        if (safeRead(iM8Rice).length > 0) {
          required.push(Question("m8RiceHa"));
        }
        //   required.push(Question("m8Corn"));
        if (safeRead(iM8CornHa).length > 0) {
          required.push(Question("m8CornHa"));
        }
        //   required.push(Question("m8Othr1"));
        if (safeRead(iM8Othr1).length > 0) {
          required.push(Question("m8Othr1Ha"));
        }
        //   required.push(Question("m8Othr2"));
        if (safeRead(iM8Othr2).length > 0) {
          required.push(Question("m8Othr2Ha"));
        }
        //   required.push(Question("m8Othr3"));
        if (safeRead(iM8Othr3).length > 0) {
          required.push(Question("m8Othr3Ha"));
        }
      }
    }

    required.forEach((req) => {
      if (safeRead(req?.value) === "") {
        req?.setErrorMessage({
          ...req?.errorMessage,
          required: true,
        });
      }
    });

    var check: any[] = [];
    required.forEach((req) => {
      if (req?.errorMessage.condition === "none") {
        return;
      }

      var validation;

      if (
        req?.errorMessage.condition === "range" ||
        req?.errorMessage.condition === "year"
      ) {
        validation = {
          required: req?.errorMessage.required,
          condition: req?.errorMessage.condition,
          message: req?.errorMessage.message,
          range: req?.errorMessage.range ?? null,
        } as TValidation;
      } else {
        validation = {
          required: req?.errorMessage.required,
          condition: req?.errorMessage.condition,
          message: req?.errorMessage.message,
        } as TValidation;
      }

      if (
        ValidateRequired({
          value: req?.value,
          validation: validation,
        })
      ) {
        check.push(req);
      }
    });

    var qEmpty: TQuestion | undefined;
    var hasEmpty = required.some((req) => {
      if (req && (req.value === null || req.value === "")) {
        qEmpty = req;
        return true;
      }
      return false;
    });

    var gpsOK = true;
    if (parseFloat(iM1Acc) > AppSettings.MIN_ACCURACY) {
      AVPopup({
        type: "error",
        title: safeRead(q.tM1Acc),
        text: "Accuracy is too low. Kindly wait for the accuracy to meet atleast 8 meters.",
      }).then(() => {
        avScrollTo("m1Acc");
      });
      gpsOK = false;
    } else if (typeof JSON == "undefined") {
      AVPopup({
        type: "error",
        text: pop.saveErrors.browserError,
      });
    } else if (hasEmpty || qEmpty) {
      AVPopup({
        type: "error",
        title: qEmpty?.label,
        text: pop.saveErrors.missing,
        confirmButtonText: pop.saveErrors.button,
      }).then(() => {
        if (qEmpty) {
          avScrollTo(qEmpty.id);
        }
      });
    } else if (check.length > 0) {
      AVPopup({
        type: "error",
        title: check[0].label,
        text: pop.saveErrors.withError,
      }).then(() => {
        avScrollTo(check[0].id);
      });
    } else {
      AVPopup({
        type: "success",
        text: pop.saveErrors.saveIni,
      });
    }

    if (!hasEmpty && check.length === 0 && gpsOK) {
      let data = {
        sM1ResName: iM1ResName,
        sM1QRCode: iM1QRCode,
        sM1FrontVw: iM1FrontVw,
        sM1SideVw: iM1SideVw,
        sM1SpecVw: iM1SpecVw,
        sM1Acc: iM1Acc,
        sM1Lat: iM1Lat,
        sM1Lng: iM1Lng,
        sM1Loc: iM1Loc,
        sM1Prov: iM1Prov,
        sM1Mun: iM1Mun,
        sM1Brgy: iM1Brgy,
        sM1Type: iM1Type,
        sM1Serial: iM1Serial,
        sM1CovHlng: iM1CovHlng,
        sM2Brand: iM2Brand,
        sM2Model: iM2Model,
        sM2RtdPwr: iM2RtdPwr,
        sM2TtlSrvArea: iM2TtlSrvArea,
        sM2AvgOpHrDy: iM2AvgOpHrDy,
        sM2AvgOpDySsn: iM2AvgOpDySsn,
        sM2LdCap: iM2LdCap,
        sM2LdCapUnit: iM2LdCapUnit,
        sM2LdCapDay: iM2LdCapDay,
        sM2TmCom1Ha: iM2TmCom1Ha,
        sM2SgrcnYld: iM2SgrcnYld,
        sM3TpTbWlls: iM3TpTbWlls,
        sM3Brand: iM3Brand,
        sM3Model: iM3Model,
        sM3SzPmp: iM3SzPmp,
        sM3SrcPwr: iM3SrcPwr,
        sM3SrcBrand: iM3SrcBrand,
        sM3SrcModel: iM3SrcModel,
        sM3RtdPwr: iM3RtdPwr,
        sM3DisCap: iM3DisCap,
        sM3DisCapUnit: iM3DisCapUnit,
        sM3TtlSrvArea: iM3TtlSrvArea,
        sM3AvgOpHrDy: iM3AvgOpHrDy,
        sM3AvgOpDySsn: iM3AvgOpDySsn,
        sM4Usage: iM4Usage,
        sM4Mode: iM4Mode,
        sM4Agency: iM4Agency,
        sM4YrAcqrd: iM4YrAcqrd,
        sM4ConAcqrd: iM4ConAcqrd,
        sM4PrsntCon: iM4PrsntCon,
        sM4YrUnused: iM4YrUnused,
        sM4RsnUnused: iM4RsnUnused,
        sM4UuOthers: iM4UuOthers,
        sM4YrUnserv: iM4YrUnserv,
        sM4RsnUnserv: iM4RsnUnserv,
        sM4UsOthers: iM4UsOthers,
        sM4NoBrkdwn: iM4NoBrkdwn,
        sM4CmnBrkdwn: iM4CmnBrkdwn,
        sM5SameOwn: iM5SameOwn,
        sM5BusCont1: iM5BusCont1,
        sM5BusCont2: iM5BusCont2,
        sM5Consent: iM5Consent,
        sM5Ope1: iM5Ope1,
        sM5Ope1Rate: iM5Ope1Rate,
        sM5Ope1Unit: iM5Ope1Unit,
        sM5Ope1Fuel: iM5Ope1Fuel,
        sM5Ope2: iM5Ope2,
        sM5Ope2Rate: iM5Ope2Rate,
        sM5Ope2Unit: iM5Ope2Unit,
        sM5Ope2Fuel: iM5Ope2Fuel,
        sM5Ope3: iM5Ope3,
        sM5Ope3Rate: iM5Ope3Rate,
        sM5Ope3Unit: iM5Ope3Unit,
        sM5Ope3Fuel: iM5Ope3Fuel,
        sM5Ope4: iM5Ope4,
        sM5Ope4Rate: iM5Ope4Rate,
        sM5Ope4Unit: iM5Ope4Unit,
        sM5Ope4Fuel: iM5Ope4Fuel,
        sM5Ope5: iM5Ope5,
        sM5Ope5Rate: iM5Ope5Rate,
        sM5Ope5Unit: iM5Ope5Unit,
        sM5Ope5Fuel: iM5Ope5Fuel,
        sM5Ope6: iM5Ope6,
        sM5Ope6Rate: iM5Ope6Rate,
        sM5Ope6Unit: iM5Ope6Unit,
        sM5Ope6Fuel: iM5Ope6Fuel,
        sM5SrvcLoc1P: iM5SrvcLoc1P,
        sM5SrvcLoc1M: iM5SrvcLoc1M,
        sM5SrvcLoc2P: iM5SrvcLoc2P,
        sM5SrvcLoc2M: iM5SrvcLoc2M,
        sM5SrvcLoc3P: iM5SrvcLoc3P,
        sM5SrvcLoc3M: iM5SrvcLoc3M,
        sM6Total: iM6Total,
        sM6L2Formal: iM6L2Formal,
        sM6L2NtFrml: iM6L2NtFrml,
        sM6G2Formal: iM6G2Formal,
        sM6G2NtFrml: iM6G2NtFrml,
        sM7AirFltr: iM7AirFltr,
        sM7AirFltrUnit: iM7AirFltrUnit,
        sM7EngOil: iM7EngOil,
        sM7EngOilUnit: iM7EngOilUnit,
        sM7EngOilFltr: iM7EngOilFltr,
        sM7EngOilFltrUnit: iM7EngOilFltrUnit,
        sM7HydOil: iM7HydOil,
        sM7HydOilUnit: iM7HydOilUnit,
        sM7HydOilFltr: iM7HydOilFltr,
        sM7HydOilFltrUnit: iM7HydOilFltrUnit,
        sM7Tire: iM7Tire,
        sM7TireUnit: iM7TireUnit,
        sM7SrvcPrvdr: iM7SrvcPrvdr,
        sM7Prov: iM7Prov,
        sM7Mun: iM7Mun,
        sM7Brgy: iM7Brgy,
        sM7TimeRspnd: iM7TimeRspnd,
        sM7PrtsAvail: iM7PrtsAvail,
        sM8OthCrp: iM8OthCrp,
        sM8Rice: iM8Rice,
        sM8RiceHa: iM8RiceHa,
        sM8Corn: iM8Corn,
        sM8CornHa: iM8CornHa,
        sM8Othr1: iM8Othr1,
        sM8Othr1Ha: iM8Othr1Ha,
        sM8Othr2: iM8Othr2,
        sM8Othr2Ha: iM8Othr2Ha,
        sM8Othr3: iM8Othr3,
        sM8Othr3Ha: iM8Othr3Ha,
        sM9Notes: iM9Notes,
        sM0Uname: fxdRead(fxdUser.name),
        sM0Encoded: Date.now().toString(),
        sM0Status: DATA_STATUS.local,
        sM0Version: "41",
      } as MachineData;

      var zipname = safeRead(iM1QRCode) + ".zip";
      var filename = safeRead(iM1QRCode) + ".json";

      createZIP(zipname, AppSettings.ZIPPASS, [
        { filename: filename, content: JSON.stringify(data, undefined, 2) },
      ]);

      fxdWrite(safeRead(iM1QRCode), JSON.stringify(data));

      clearFields();
      deleteMachineBackups();

      AVPopup({
        icon: "success",
        title: pop.save.title,
        text: pop.save.message,
        confirmButtonColor: pop.save.button,
      }).then(() => {
        GOTO(AvzRoute.implement);
      });
    }
  }

  function clearFields() {
    QUESTIONS.forEach((question) => {
      if (question.setValue) question.setValue("");
    });
  }
}

// /////////////////////////////////////////////////////////////////////////////
// MACHINE FORMAT //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface MachineData {
  sM1ResName: string;
  sM1QRCode: string;
  sM1FrontVw: string;
  sM1SideVw: string;
  sM1SpecVw: string;
  sM1Acc: string;
  sM1Lat: string;
  sM1Lng: string;
  sM1Loc: string;
  sM1Prov: string;
  sM1Mun: string;
  sM1Brgy: string;
  sM1Type: string;
  sM1Serial: string;
  sM1CovHlng: string;

  sM2Brand: string;
  sM2Model: string;
  sM2RtdPwr: string;
  sM2TtlSrvArea: string;
  sM2AvgOpHrDy: string;
  sM2AvgOpDySsn: string;
  sM2LdCap: string;
  sM2LdCapUnit: string;
  sM2LdCapDay: string;
  sM2TmCom1Ha: string;
  sM2SgrcnYld: string;

  sM3TpTbWlls: string;
  sM3Brand: string;
  sM3Model: string;
  sM3SzPmp: string;
  sM3SrcPwr: string;
  sM3SrcBrand: string;
  sM3SrcModel: string;
  sM3RtdPwr: string;
  sM3DisCap: string;
  sM3DisCapUnit: string;
  sM3TtlSrvArea: string;
  sM3AvgOpHrDy: string;
  sM3AvgOpDySsn: string;

  sM4Usage: string;
  sM4Mode: string;
  sM4Agency: string;
  sM4YrAcqrd: string;
  sM4ConAcqrd: string;
  sM4PrsntCon: string;
  sM4YrUnused: string;
  sM4RsnUnused: string;
  sM4UuOthers: string;
  sM4YrUnserv: string;
  sM4RsnUnserv: string;
  sM4UsOthers: string;
  sM4NoBrkdwn: string;
  sM4CmnBrkdwn: string;

  sM5SameOwn: string;
  sM5BusCont1: string;
  sM5BusCont2: string;
  sM5Consent: string;
  sM5Ope1: string;
  sM5Ope1Rate: string;
  sM5Ope1Unit: string;
  sM5Ope1Fuel: string;
  sM5Ope2: string;
  sM5Ope2Rate: string;
  sM5Ope2Unit: string;
  sM5Ope2Fuel: string;
  sM5Ope3: string;
  sM5Ope3Rate: string;
  sM5Ope3Unit: string;
  sM5Ope3Fuel: string;
  sM5Ope4: string;
  sM5Ope4Rate: string;
  sM5Ope4Unit: string;
  sM5Ope4Fuel: string;
  sM5Ope5: string;
  sM5Ope5Rate: string;
  sM5Ope5Unit: string;
  sM5Ope5Fuel: string;
  sM5Ope6: string;
  sM5Ope6Rate: string;
  sM5Ope6Unit: string;
  sM5Ope6Fuel: string;
  sM5SrvcLoc1P: string;
  sM5SrvcLoc1M: string;
  sM5SrvcLoc2P: string;
  sM5SrvcLoc2M: string;
  sM5SrvcLoc3P: string;
  sM5SrvcLoc3M: string;

  sM6Total: string;
  sM6L2Formal: string;
  sM6L2NtFrml: string;
  sM6G2Formal: string;
  sM6G2NtFrml: string;

  sM7AirFltr: string;
  sM7AirFltrUnit: string;
  sM7EngOil: string;
  sM7EngOilUnit: string;
  sM7EngOilFltr: string;
  sM7EngOilFltrUnit: string;
  sM7HydOil: string;
  sM7HydOilUnit: string;
  sM7HydOilFltr: string;
  sM7HydOilFltrUnit: string;
  sM7Tire: string;
  sM7TireUnit: string;
  sM7SrvcPrvdr: string;
  sM7Prov: string;
  sM7Mun: string;
  sM7Brgy: string;
  sM7TimeRspnd: string;
  sM7PrtsAvail: string;

  sM8OthCrp: string;
  sM8Rice: string;
  sM8RiceHa: string;
  sM8Corn: string;
  sM8CornHa: string;
  sM8Othr1: string;
  sM8Othr1Ha: string;
  sM8Othr2: string;
  sM8Othr2Ha: string;
  sM8Othr3: string;
  sM8Othr3Ha: string;

  sM9Notes: string;
  
  sM0Uname: string;
  sM0Encoded: string;
  sM0Status: string;
  sM0Version: string;
}
