// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { ReactNode } from "react";
import { Collapse } from "@mui/material";
import { TNumber, TPosition } from "./AType";
import { AppSettings } from "../../app/Settings";

// /////////////////////////////////////////////////////////////////////////////
// CONSTANT ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const BORDER_RADIUS = 6;

// /////////////////////////////////////////////////////////////////////////////
// FUNCTION ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Assist Border Radius ////////////////////////////////////////////////////////

/**
 * @param borderRadius The border radius to apply for the component.
 * @param horPosition  The horizontal position of this component.
 * @returns The border radius value to apply for the component.
 */
export function assistBorderRadius(props: {
  borderRadius?: number;
  horPosition?: TPosition;
}) {
  const RADIUS =
    props.borderRadius === undefined
      ? BORDER_RADIUS + "px"
      : props.borderRadius + "px";

  let VALUE;
  switch (props.horPosition) {
    case "center":
      VALUE = "0";
      break;
    case "left":
      VALUE = `${RADIUS} 0 0 ${RADIUS}`;
      break;
    case "right":
      VALUE = `0 ${RADIUS} ${RADIUS} 0`;
      break;
    default:
      VALUE =
        props.borderRadius !== null ? props.borderRadius : `${BORDER_RADIUS}px`;
  }
  return VALUE;
}

// Assist Width ////////////////////////////////////////////////////////////////

/**
 * @param width The width to translate, %.
 * @returns The width value in %.
 */
export function assistWidth(props: { width?: TNumber }) {
  return props.width + "%";
}

// Assist Collapse /////////////////////////////////////////////////////////////

/**
 * @param child The child component to collapse.
 * @param tranIN The transition in value.
 * @param force Whether to force the collapse to show child component.
 * @returns The collapsible child component.
 */
export function assistCollapse(
  child: ReactNode,
  tranIN: boolean,
  force?: boolean
) {
  return (
    <Collapse in={show(tranIN, force)} timeout="auto" unmountOnExit>
      {child}
    </Collapse>
  );
}

// Show ////////////////////////////////////////////////////////////////////////

/**
 * @param showThis The value to whether show or hide a component.
 * @param force The value to whether force the value in ShowThis.
 * @returns True if the value of showAll in the Application settings is set to
 *          true, else return ShowThis value.
 */
export function show(showThis: boolean, force?: boolean) {
  return force ? showThis : AppSettings.showAll ?? showThis ?? false;
}
