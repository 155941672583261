import { ReactNode } from "react";
import { Collapse } from "@mui/material";
import { AppSettings } from "../../app/Settings";

// /////////////////////////////////////////////////////////////////////////////

export function collapseAssist(
  child: ReactNode,
  tranIN: boolean,
  force?: boolean
) {
  return (
    <Collapse in={checkShow(tranIN, force)} timeout="auto" unmountOnExit>
      {child}
    </Collapse>
  );
}

export function checkShow(showThis: boolean, force?: boolean) {
  // If Not showAll return ShowThis value else return true
  return force
    ? showThis
    : AppSettings.showAll
    ? true
    : showThis
    ? true
    : false;
}
