import { ReactNode } from "react";
import { collapseAssist } from "./Box";

export function Card(props: {
  id?: string;
  style?: string;
  children?: ReactNode;
  title?: string;
  titleID?: string;
  titleStyle?: string;
  /** Transition IN */
  in?: boolean;
  force?: boolean;
}) {
  const t = props.title ? (
    <p id={props.titleID} className={props.titleStyle}>
      {props.title.toUpperCase()}
    </p>
  ) : null;

  const style = props.style ? props.style + " " : "";

  const d = (
    <div id={props.id} className={style + "card"}>
      <div className="card-body">
        {t}
        {props.children}
      </div>
    </div>
  );

  return props.in != null ? collapseAssist(d, props.in, props.force) : d;
}
