// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

/**
 * This manages the IDs of the elements in the HTML to avoid recurrences.
 */
export const AvzID = {
  /**
   * value: webcam
   */
  WEBCAM: "webcam",
};
