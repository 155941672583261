import addSplashScreen from "../splash/SplashScreen";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
  useFetcher,
  useLocation,
  useOutlet,
  useRouteLoaderData,
} from "react-router-dom";
import { ID } from "../../app/ElementID";
import { AvzRoute, AVZROUTES } from "../../app/Routes";
import { Container } from "react-bootstrap";
import { SwitchTransition } from "react-transition-group";
import { CSSTransition } from "react-transition-group";
import { AUTH_PROVIDER } from "../../hooks/auth/auth";
import ErrorPage from "../../arv/err/ErrorPage";
import TX from "./App.json";

import "./App.css";

// TODO Check if these imports are being used, delete if not.
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// /////////////////////////////////////////////////////////////////////////////
// Application /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export default addSplashScreen(
  () => (
    <RouterProvider
      router={createBrowserRouter([
        {
          id: ID.HTMLRoot,
          path: AvzRoute.home,
          element: <Layout />,
          children: AVZROUTES.map((childRoute) => ({
            index: childRoute.path === AvzRoute.home,
            path:
              childRoute.path === AvzRoute.home ? undefined : childRoute.path,
            loader: childRoute.loader ?? undefined,
            action: childRoute.action ?? undefined,
            element: childRoute.element,
          })),
          loader() {
            return { user: AUTH_PROVIDER.username };
          },
          errorElement: ErrorPage(),
        },
        {
          path: AvzRoute.logout,
          async action() {
            await AUTH_PROVIDER.signout();
            return redirect(AvzRoute.home);
          },
        },
      ])}
      fallbackElement={<div />}
    />
  ),
  window.location.pathname === AvzRoute.home ? 1400 : 1400
);

// /////////////////////////////////////////////////////////////////////////////
// Application Layout //////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

function Layout() {
  const PATH = useLocation().pathname;
  const ROUTE = AVZROUTES.find((route) => route.path === PATH);
  const { nodeRef } = ROUTE ?? {};

  const EREF = nodeRef as React.RefObject<HTMLElement>;
  const DIVREF = nodeRef as React.RefObject<HTMLDivElement>;

  const CURRENT_OUTLET = useOutlet();

  return (
    <Container id={ID.PageTransition}>
      <SwitchTransition>
        <CSSTransition
          unmountOnExit
          timeout={300}
          classNames="page"
          key={PATH}
          nodeRef={EREF}
        >
          {(state) => (
            <div className="page" ref={DIVREF}>
              {CURRENT_OUTLET}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// Authentication Status ///////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function AuthStatusButton(props: { border?: "center" | "right" }) {
  const text = TX.app.auth;
  var FETCHER = useFetcher();

  type User = { user: string | null };
  var { user } = useRouteLoaderData(ID.HTMLRoot) as User;
  if (!user) return <p>{text.notLoggedIn}</p>;

  var isLoggingOut = FETCHER.formData != null;
  var borderval = "btn-logout-center";
  if (props.border === "right") borderval = "btn-logout-right";

  return (
    <FETCHER.Form method="post" action={AvzRoute.logout}>
      <button
        type="submit"
        disabled={isLoggingOut}
        className={"btn btn-secondary " + borderval}
      >
        {isLoggingOut ? text.signingOut : text.signOut}
      </button>
    </FETCHER.Form>
  );
}
