export const oVarities = [
  { label: "PHL 91-1091" },
  { label: "PHL 92-0051" },
  { label: "PHL 92-0577" },
  { label: "PHL 92-0751" },
  { label: "PHL 93-1601" },
  { label: "PHL 93-2349" },
  { label: "PHL 93-3155S" },
  { label: "PHL 93-3727" },
  { label: "PHL 93-3849" },
  { label: "PHL 94-0913" },
  { label: "PHL 97-0693" },
  { label: "PHL 97-1123" },
  { label: "PHL 97-1793" },
  { label: "PHL 97-2041" },
  { label: "PHL 7544" },
  { label: "VMC 84-524" },
  { label: "VMC 85-524" },
  { label: "PSR 01-105" },
  { label: "PHIL 93-236-3301" },
  { label: "PHIL 8477" },
  { label: "PHIL 90-0343" },
  { label: "PHIL 92-3-0023" },
  { label: "PHIL 88-626-1691" },
  { label: "PHIL 91-110-0807" },
  { label: "Q 102" },
  { label: "PHIL 84-438-5799" },
  { label: "PHIL 81-341-3415" },
  { label: "PHIL 89-660-1511" },
  { label: "Laredo 52-604" },
  { label: "PHIL 80-4-0213" },
  { label: "PHIL 89-127-0815" },
  { label: "PHIL 8709" },
  { label: "PHIL 90-85-0343" },
  { label: "PHIL 8013" },
  { label: "PHIL79-0019" },
  { label: "PHIL 64-2227" },
  { label: "CO 467" },
  { label: "CP 50-28" },
  { label: "PHIL 80-2287" },
  { label: "PHIL 86-1119" },
  { label: "PHIL 81-3415" },
  { label: "PHIL 79-1915" },
  { label: "PHIL 79-3032" },
  { label: "PHIL 2006-2289" },
  { label: "PSR 02-272" },
  { label: "PSR 07-195" },
  { label: "VMC 88-354" },
];
