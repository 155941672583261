// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import "./LoginPage.css";
import { useLocation, useNavigation } from "react-router-dom";
import { useActionData, Form } from "react-router-dom";
import { AvzRoute } from "../../app/Routes";
import TX from "./LoginPage.json";
import { AVPopup } from "../../arv/com/AVPopup";

export default function LoginPage() {
  var location = useLocation();
  var params = new URLSearchParams(location.search);
  var from = params.get("from") || AvzRoute.home;

  var navigation = useNavigation();
  var isLoggingIn = navigation.formData?.get("username") != null;
  var actionData = useActionData() as { error: string } | undefined;

  return (
    <div id="login-page" className="avz-fullscreen main-bg-animated">
      <div className="form-box">
        <Form method="post" className="form" replace>
          <input type="hidden" name="redirectTo" value={from} />
          <span className="title">{TX.title}</span>
          <span className="subtitle">{TX.subtitle}</span>
          <div className="form-container">
            <input
              type="text"
              className="input"
              placeholder="Username"
              name="username"
            />
            <input
              type="password"
              className="input"
              placeholder="Password"
              name="password"
            />
          </div>
          <button
            type="submit"
            disabled={isLoggingIn}
            onClick={() => {
              if (actionData && actionData.error) {
                AVPopup({
                  text: actionData.error,
                  type: "error",
                });
              }
            }}
          >
            {isLoggingIn ? TX.logging : TX.login}
          </button>
        </Form>
        <div className="form-section">
          <p className="teamlink">
            {TX.check}
            <a
              href="https://www.facebook.com/m3dasproject"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              {TX.here}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
