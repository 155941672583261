// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import "../../assets/scss/Forms.css";
import TX from "./Camera.json";
import Webcam from "react-webcam";
import { useRef, useState, useCallback } from "react";
import { VBar } from "../box/VBar";
import { HBar } from "../box/HBar";
import { AVButton } from "../com/AVButton";
import { AvzID } from "./AvzID";

// TODO Make the camera button text customizable
// TODO Make the camera button color customizable
// TODO Make the camera button width customizable
export default function WebCam({ setImage }) {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setImage(imageSrc);
  }, [webcamRef, setImage]);

  const retake = () => {
    setImgSrc(null);
  };

  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };

  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const handleClick = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  return (
    <VBar className="spacer">
      {imgSrc ? (
        <img src={imgSrc} alt={AvzID.WEBCAM} />
      ) : (
        <Webcam
          id={AvzID.WEBCAM}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          screenshotQuality={0.8}
          videoConstraints={{
            ...videoConstraints,
            facingMode,
          }}
        />
      )}
      <div className="h-2"></div>
      <HBar>
        {imgSrc ? (
          <AVButton
            width={50}
            position="left"
            onClick={retake}
            label={TX.retake}
          ></AVButton>
        ) : (
          <AVButton
            width={50}
            position="left"
            onClick={capture}
            label={TX.capture}
          ></AVButton>
        )}
        <AVButton
          width={50}
          position="right"
          color="secondary"
          onClick={handleClick}
          label={TX.switch}
        ></AVButton>
      </HBar>
    </VBar>
  );
}
