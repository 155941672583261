import { Radio, Checkbox } from "@mui/material";
import { RadioGroup, FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import { assistWidth } from "../util/AFunc";
import { avBackup } from "./AVGeneral";
import { TAny, GET, SET } from "../util/AType";
import { TString, AnyState } from "../util/AType";
import { IAVRequired } from "./AVGeneral";
import { safeRead } from "../../app/GenFunctions";

type TAVTick = "radio" | "checkbox" | null;
type TAVTickOptions = { label: string; key: any; value?: any }[];

interface IAVTickProps extends Omit<IAVRequired, "text"> {
  /**  */
  type?: TAVTick;
  /**  */
  options: TAVTickOptions;
  /**  */
  row?: boolean;
  /**  */
  width?: number;
  /**  */
  id?: string;
  /**  */
  // className?: string;
}

export function AVTick(props: IAVTickProps) {
  // TODO AVTick unclickable after restore
  // console.log("A");
  // console.log(props.value);
  const style = {
    width: assistWidth({ width: props.width }),
    minWidth: assistWidth({ width: props.width }),
  };

  const options = () => {
    return props.options.map((options: any, index: any) => (
      <AVFormLabel
        type={props.type}
        label={options.label}
        value={props.value}
        backup={props.backup}
        options={props.options}
        key={options.key}
      />
    ));
  };

  return props.type === "radio" ? (
    <RadioGroup row={props.row} style={style} id={props.id}>
      {options()}
    </RadioGroup>
  ) : (
    <FormGroup row={props.row} style={style} id={props.id}>
      {options()}
    </FormGroup>
  );
}

interface IAVFormControlLabelProps {
  /**  */
  type?: TAVTick;
  /**  */
  label: TString;
  /**  */
  value: AnyState;
  /**  */
  backup: TString;
  /**  */
  // index: TGetNumber;
  /**  */
  options: TAny;
}

function AVFormLabel(props: IAVFormControlLabelProps) {
  const [checked, setChecked] = useState(
    Array.isArray(props.value[GET])
      ? props.value[GET].some((item: any) => safeRead(item) === safeRead(props))
      : false
  );

  useEffect(() => {
    setChecked(
      Array.isArray(props.value[GET])
        ? props.value[GET].some(
            (item: any) => safeRead(item) === safeRead(props)
          )
        : false
    );
  }, [props, props.label, props.value]);

  return (
    <FormControlLabel
      label={props.label}
      // value={props.value == null ? props.label : props.value}
      value={props.value[GET]}
      control={
        props.type === "radio" ? (
          <Radio />
        ) : (
          <Checkbox
            // TODO Auto Restore Fuctionality
            // checked={(() => {
            //   var checked =
            //     props.backup && fxdRead(props.backup)?.includes(props.label);
            //   if (props.backup && checked) {
            //     props.value[SET]?.(fxdRead(props.backup));
            //     return true;
            //   } else return false;
            // })()}
            checked={checked}
            onChange={(event) => {
              var val = Array.isArray(props.value[GET]) ? props.value[GET] : [];
              var option = props.options.find(
                (option: any) => option.label === props.label
              );
              if (event.target.checked) {
                if (!val.some((item: any) => item === option)) {
                  val.push(option);
                  setChecked(true);
                }
              } else {
                val = val.filter((item: any) => item !== option);
                setChecked(false);
              }
              props.value[SET]?.(val);
              avBackup(props.backup, JSON.stringify(val));
            }}
          />
        )
      }
    />
  );
}
