export const TX = {
  pop: {
    noProfile: {
      title: "No Profile Found",
      message: "Please select the respondent first.",
      button: "Okay",
    },
    noMachine: {
      title: "No Machine Found",
      message:
        "You need to create a profile and a machine before you can proceed with the implement survey.",
      button: "Create Machine",
    },
    backup: {
      title: "Edit / Restore",
      message:
        "Would you like to continue with the unsaved implement? Or proceed with editing.",
        button: "Recover",
        deny: "Edit",
    },
    qrError: {
      title: "QR Code Error",
      message: "The QR code is not valid. Please scan a valid QR code.",
      button: "Close",
    },
    geoloc: {
      init: {
        title: "Initializing Geolocation",
        message:
          "You can proceed with the survey while waiting for the location.",
        button: "Okay",
      },
      initError: {
        title: "Geolocation Error",
        message:
          "There was an error initializing the geolocation. Your browser doesn't support geolocation feature!",
        button: "Exit",
      },
      success: {
        title: "Geolocation Found",
        button: "Close",
        lat: "Your coordinate is: Lat: ",
        lng: " Long: ",
        acc: " Accuracy: ",
      },
    },
    accuracy: {
      init: "Initializing . . .",
      high: "High Accuracy",
      low: "Low Accuracy",
      search: "Searching for location . . .",
    },
    loadContact: {
      title: "Contact Information",
      message1: "Set Primary Contact:",
      message2: "Secondary Contact:",
      message3: "from",
      message4: ".",
      button: "Okay",
      deny: "Cancel",
    },
    saveErrors: {
      browserError: "Upgrade your browser.",
      missing: "Kindly fill out the missing fields above.",
      button: "Go to Error",
      saveIni: "Initializing for saving . . .",
      withError: "There are errors in the form. Correct them before saving.",
    },
    save: {
      title: "Save Implement",
      message: "Implement has been saved successfully",
      center: "Close",
    },
  },
  question: {
    category: "Implement",
    tI2Type: {
      label: "Type of Implement",
      placeholder: "Choose an Option",
      options: [
        {
          label: "Moldboard Plow",
          type: "Land Preparation",
          anchored: true,
        },
        { label: "Disc Plow", type: "Land Preparation", anchored: true },
        {
          label: "Disc Harrow",
          type: "Land Preparation",
          anchored: true,
        },
        { label: "Furrower", type: "Land Preparation", anchored: true },
        { label: "Subsoiler", type: "Land Preparation", anchored: true },
        {
          label: "Chisel Plow",
          type: "Land Preparation",
          anchored: true,
        },
        {
          label: "Power Harrow",
          type: "Land Preparation",
          anchored: true,
        },
        {
          label: "Spike-tooth Harrow",
          type: "Land Preparation",
          anchored: true,
        },
        {
          label: "Rotary Tiller",
          type: "Land Preparation",
          anchored: true,
        },
        { label: "Ditcher", type: "Land Preparation", anchored: true },
        { label: "Dozer", type: "Land Preparation", anchored: true },
        { label: "Excavator", type: "Land Preparation", anchored: true },
        { label: "Mechanical Planter", type: "Planting", anchored: true },
        {
          label: "Field Cultivator",
          type: "Ratoon Management",
          anchored: true,
        },
        {
          label: "Ratoon Manager/Stubble shaver",
          type: "Ratoon Management",
          anchored: true,
        },
        {
          label: "Trash Rake",
          type: "Ratoon Management",
          anchored: true,
        },
        { label: "Mulcher", type: "Ratoon Management", anchored: true },
        {
          label: "Inter-row Cultivator",
          type: "Ratoon Management",
          anchored: true,
        },
        {
          label: "Cut-away Cultivator",
          type: "Ratoon Management",
          anchored: true,
        },
        {
          label: "Trash Incorporator",
          type: "Ratoon Management",
          anchored: true,
        },
        {
          label: "Granular Fertilizer Applicator",
          type: "Ratoon Management",
          anchored: true,
        },
        {
          label: "Fertilizer Applicator w/ Cut-away",
          type: "Ratoon Management",
          anchored: true,
        },
        { label: "Weeder", type: "Ratoon Management", anchored: true },
        {
          label: "Boom Sprayer",
          type: "Ratoon Management",
          anchored: true,
        },
        {
          label: "Mechanical Harvester",
          type: "Harvesting",
          anchored: true,
        },
        { label: "Cane Grab Loader", type: "Hauling", anchored: true },
        { label: "Trailer", type: "Hauling", anchored: true },
        {
          label: "Others, specify in Survey Notes",
          type: "Others",
          anchored: true,
        },
      ],
    },
    tI1Title: "Basic Information",
    tI1ResName: {
      label: "Name of Respondent",
      placeholder: "First Name MI. Last Name",
    },
    tI1Machine: {
      label: "Machine using the Implement",
      placeholder: "Choose a Machine",
    },
    tI1QRCode: {
      label: "Scan Implement QR Code",
      placeholder: "Click to Start Scanning",
    },
    tI1QRCodeVideo: {
      label: "QRCode Camera",
      placeholder: "Select Camera",
    },
    tI1FrontVw: {
      label: "Capture Front View",
      placeholder: "Click to Capture Picture",
    },
    tI1SideVw: {
      label: "Capture Side View",
      placeholder: "Click to Capture Picture",
    },
    tI1SpecVw: {
      label: "Capture Specification Plate",
      placeholder: "Click to Capture Picture",
    },
    tI1Acc: {
      label: "Accuracy",
      placeholder: "0.000000",
    },
    tI1Lat: {
      label: "Latitude",
      placeholder: "0.000000",
    },
    tI1Lng: {
      label: "Longitude",
      placeholder: "0.000000",
    },
    tI1Loc: {
      label: "Location during Survey",
      placeholder: "Choose an Option",
      options: [
        { label: "Garage" },
        { label: "Field" },
        { label: "Transloading Zone" },
      ],
    },
    tI1Garage: "Garage Location",
    tI1Prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    tI1Mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    tI1Bgry: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    tI2Title: "SPECIFICATIONS",
    tI2Brand: {
      label: "Brand",
      placeholder: "Select Brand",
    },
    tI2Model: {
      label: "Model",
      placeholder: "Select Model",
    },
    tI2NoBtm: {
      label: "Number of Bottoms",
      placeholder: "0",
      unit: "bottoms",
    },
    tI2SzDk: {
      label: "Size of Disk",
      placeholder: "0.00",
      unit: "in",
    },
    tI2DisRow: {
      label: "Distance between Rows",
      placeholder: "0.00",
      unit: "cm",
    },
    tI2WdCut: {
      label: "Width of Cut",
      placeholder: "0.00",
      unit: "cm",
    },
    tI2LdCap: {
      label: "Loading Capacity",
      placeholder: "0.00",
    },
    tI2LdCapUnit: {
      label: "Unit",
      placeholder: "Choose",
      options: [{ label: "Kg / Day" }, { label: "Tons / Day" }],
    },
    tI2NoRows: {
      label: "Number of Rows",
      placeholder: "0",
    },
    tI2NoNzls: {
      label: "Number of Nozzles",
      placeholder: "0",
    },
    tI2DisNzls: {
      label: "Distance between Nozzles",
      placeholder: "0",
      unit: "cm",
    },
    tI2TnkCap: {
      label: "Tank Capacity",
      placeholder: "0",
      unit: "Liters",
    },
    tI2NoOutlets: {
      label: "Number of Outlets",
      placeholder: "0",
    },
    tI2DisOutlets: {
      label: "Distance between Outlets",
      placeholder: "0",
      unit: "cm",
    },
    tI2TnkCap2: {
      label: "Tank Capacity",
      placeholder: "0.00",
    },
    tI2TnkCap2Unit: {
      label: "Unit",
      placeholder: "Choose",
      options: [{ label: "Liters / Day" }, { label: "Gallons / Day" }],
    },
    tI2TpPlntr: {
      label: "Type of Planter",
      placeholder: "Choose an Option",
      options: [{ label: "Billet" }, { label: "Whole Stalk" }],
    },
    tI2TpCnGrbAttch: {
      label: "Type",
      placeholder: "Choose an Option",
      options: [{ label: "Digger" }, { label: "Grapple" }],
    },
    tI3Title: "OPERATIONS",
    tI3Options: [
      { label: "Land Preparation" },
      { label: "Planting" },
      { label: "Ratoon Management" },
      { label: "Harvesting" },
      { label: "Hauling" },
    ],
    tI3Prim: {
      label: "Primary Operation",
      placeholder: "Select Operation",
    },
    tI3Sec: {
      label: "Secondary Operation",
      placeholder: "Select Operation",
    },
    tI3Ter: {
      label: "Tertiary Operation",
      placeholder: "Select Operation",
    },
    tI4ImpUsage: "Implement Usage",
    tI4Usage: {
      label: "Implement Usage",
      placeholder: "Select usage",
      options: [
        { label: "Private Use" },
        { label: "For Rental to Members only", anchored: true },
        { label: "For Rental to Customers", anchored: true },
      ],
    },
    tI4Mode: {
      label: "Mode of Acquisition",
      placeholder: "Select Mode of Acquisition",
      options: [
        { label: "Cash-purchased" },
        { label: "Loan-purchased" },
        { label: "Grant", anchored: true },
        { label: "Donation", anchored: true },
      ],
    },
    tI4Agency: {
      label: "Name of Agency",
      placeholder: "Name of Agency",
    },
    tI4YrAcqrd: { label: "Year Acquired" },
    tI4ConAcqrd: {
      label: "Condition when Acquired",
      placeholder: "Select Condition",
      options: [
        { label: "Locally Fabricated" },
        { label: "Brand New" },
        { label: "Secondhand" },
        { label: "Reconditioned" },
        { label: "Data to be acquired" },
      ],
    },
    tI4PrsntCon: {
      label: "Present Condition",
      placeholder: "Select Condition",
      options: [
        { label: "Functional and Used" },
        { label: "Functional but Unused" },
        { label: "Non-functional" },
        { label: "Data to be acquired" },
      ],
    },
    tI4YrUnused: { label: "Year this Became Unused" },
    tI4Unused: "Why is this unused?",
    tI4RsnUnused: [
      { label: "Newly Awarded", key: 0 },
      {
        label: "This is not suitable to the condition of the area",
        key: 1,
      },
      { label: "No one is skilled enough to operate this", key: 2 },
      { label: "Custom hiring rate for this is too high", key: 3 },
      { label: "This was not turned over to the beneficiaries", key: 4 },
    ],
    tI4UuOthers: {
      label: "Others, please specify",
      placeholder: "Input Reason",
    },
    tI4YrUnserv: { label: "Year this Became Unserviceable" },
    tI4Unserv: "Why is this unserviceable?",
    tI4RsnUnserv: [
      { label: "For Repair", key: 0 },
      { label: "Too Old Unrepairable", key: 1 },
      { label: "Obsolete", key: 2 },
    ],
    tI4UsOthers: {
      label: "Others, please specify",
      placeholder: "Input Reason",
    },
    tI4RntDetails: "Renting Details",
    tI4WithTractor: {
      label: "With Tractor or Implement only?",
      placeholder: "Select Option",
      options: [{ label: "With Tractor" }, { label: "Implement only" }],
    },
    tI4SvcRtHa: {
      label: "Rental Rate For members",
      placeholder: "0.00",
      unit: "₱",
    },
    tI4SvcRtHaNon: {
      label: "Rental Rate For non-members",
      placeholder: "0.00",
      unit: "₱",
    },
    tI4Title: "Contact Information of Service Provider",
    tI4SameOwn: [{ label: "Same as Owner's Information", key: 0 }],
    tI4BusCont1: {
      label: "Business Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    tI4BusCont2: {
      label: "Business Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    tI4Consent: [
      {
        label:
          "I hereby consent to the inclusion of my contact number in the M3DAS Web Map and understand that it may be accessible to the public for mapping purposes.",
        key: 0,
      },
    ],
    tI4Service: "Scope of Service based on Location",
    tI5Title: "Tractor-Implement Performance",
    tI5TmCom1Ha: {
      label: "Time to Complete One Hectare",
      placeholder: "0",
      unit: "hr",
    },
    tI5NoPasses: {
      label: "Number of Passes",
      placeholder: "0",
    },
    tI5GrnFrtWt: {
      label: "Granular Fertilizer Weight",
      placeholder: "0.00",
    },
    tI5GrnFrtWtUnit: {
      label: "Unit",
      placeholder: "Choose",
      options: [{ label: "Kg / Day" }, { label: "Tons / Day" }],
    },
    tI5FrtAppEffA: {
      label: "Fertilizer Application Eff. Area",
      placeholder: "0.00",
      unit: "ha",
    },
    tI5FrtDelRate: {
      label: "Fertilizer Delivery Rate",
      placeholder: "0.00",
      unit: "unit/ha",
    },
    tI5SgrYld: {
      label: "Sugarcane Yield",
      placeholder: "0.00",
      unit: "tons/ha",
    },
    tI5LdCap: {
      label: "Loading Capacity",
      placeholder: "0.00",
    },
    tI5LdCapUnit: {
      label: "Unit",
      placeholder: "Choose",
      options: [{ label: "Tons / Day" }, { label: "Kg / Day" }],
    },
    tI5NoBrkdwn: {
      label: "Number of Breakdowns",
      placeholder: "0",
      unit: "times",
    },
    tI5CmnBrkdwn: {
      label: "Common Breakdowns",
      placeholder: "Select Breakdowns",
      options: [
        { label: "Broken Tire", anchored: false },
        { label: "Damaged Parts", anchored: false },
        { label: "Clogged Nozzles", anchored: false },
        { label: "Leakage", anchored: false },
        { label: "All", anchored: true },
        { label: "Not Applicable", anchored: true },
      ],
    },
    tI5MntcDone: {
      label: "Maintenance Done",
      placeholder: "Select Maintenance",
      options: [
        { label: "Cleaning of Parts" },
        { label: "Oil Application" },
        { label: "Repaint" },
        { label: "Bolt Adjustments" },
        { label: "All" },
        { label: "Not Applicable" },
      ],
    },
    tI6Notes: {
      label: "Survey Notes",
      placeholder: "Enter some notes here",
    },
  },
  validation: {
    bottoms: "Please input a valid number of bottoms",
    disk: "Please input a valid number of disk size",
    distance: "Please input a valid distance",
    wcut: "Please input a valid width of cut",
    capacity: "Please input a valid capacity",
    rows: "Please input a valid number of rows",
    nozzle: "Please input a valid number of nozzle",
    dnozzle: "Please input a valid distance between nozzle",
    outlet: "Please input a valid number of outlet",
    doutlet: "Please input a valid distance between outlet",
    svcRate: "Please input a valid service rate",
    time: "Please input a valid time",
    passes: "Please input a valid number of passes",
    rate: "Please input a valid rate",
    area: "Please input a valid area",
    yield: "Please input a valid yield.",
    breakdown: "Please input a valid number of breakdown",
  },
  action: {
    save: "Save Data",
  },
};
