// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { SweetAlertIcon } from "sweetalert2";

// /////////////////////////////////////////////////////////////////////////////
// CONSTANT ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

/**
 * Used for useState array [get].
 */
export const GET = 0;

/**
 * Used for useState array [set].
 */
export const SET = 1;

// /////////////////////////////////////////////////////////////////////////////
// Arvouz Types ////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// SetStateAction Shortener ////////////////////////////////////////////////////

export type SSA<T> = React.Dispatch<React.SetStateAction<T>>;

// Any /////////////////////////////////////////////////////////////////////////

export type TAny = any;
export type TSetAny = SSA<TAny>;
export type AnyState = [TAny, TSetAny | null];
export let SetAny = (a: TAny) => [a, null] as AnyState;

// String //////////////////////////////////////////////////////////////////////

export type TString = string;
export type TSetString = SSA<TString | undefined>;
export type StringState = [TString, TSetString | null];
export let SetString = (s: TString) => [s, null] as StringState;

// Boolean /////////////////////////////////////////////////////////////////////

export type TBoolean = boolean;
export type TSetBoolean = SSA<TBoolean>;
export type BooleanState = [TBoolean, TSetBoolean | null];
export let SetBoolean = (b: TBoolean) => [b, null] as BooleanState;

// Number //////////////////////////////////////////////////////////////////////

export type TNumber = number;
export type TSetNumber = SSA<TNumber>;
export type NumberState = [TNumber, TSetNumber | null];
export let SetNumber = (n: TNumber) => [n, null] as NumberState;

// Bigint //////////////////////////////////////////////////////////////////////

export type TBigint = bigint;
export type TSetBigint = SSA<TBigint>;
export type BigintState = [TBigint, TSetBigint | null];
export let SetBigint = (bi: TBigint) => [bi, null] as BigintState;

// /////////////////////////////////////////////////////////////////////////////
// Other Types /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Options /////////////////////////////////////////////////////////////////////

export type TOptions = { label: StringState }[];
export type TSetOptions = SSA<TOptions>;
export type OptionsState = [TOptions, TSetOptions | null];
export let SetOptions = (o: TOptions) => [o, null] as OptionsState;

// Position ////////////////////////////////////////////////////////////////////

export type TPosition = "left" | "center" | "right";
export type TSetPosition = SSA<TPosition>;
export type PositionState = [TPosition, TSetPosition | null];
export let SetPosition = (p: TPosition) => [p, null] as PositionState;

// Bootstrap Color /////////////////////////////////////////////////////////////

export type TBTColor =
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | "danger"
  | "success"
  | "light"
  | "dark"
  | "muted"
  | undefined;
export type TSetBTColor = SSA<TBTColor>;
export type BTColorState = [TBTColor, TSetBTColor | null];
export let SetBTColor = (p: TBTColor) => [p, null] as BTColorState;

// Material UI Color ///////////////////////////////////////////////////////////

export type TMUIColor =
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | "error"
  | "success"
  | "inherit"
  | undefined;
export type TSetMUIColor = SSA<TMUIColor>;
export type MUIColorState = [TMUIColor, TSetMUIColor | null];
export let SetMUIColor = (p: TMUIColor) => [p, null] as MUIColorState;

// Pop /////////////////////////////////////////////////////////////////////////

export type TPop = SweetAlertIcon | "primary" | "secondary" | "light" | "dark";
export type TSetPop = SSA<TPop>;
export type PopState = [TPop, TSetPop | null];
export let SetPop = (p: TPop) => [p, null] as PopState;

// Severity ////////////////////////////////////////////////////////////////////

export type TSeverity = "success" | "info" | "warning" | "error";
export type TSetSeverity = SSA<TSeverity>;
export type SeverityState = [TSeverity, TSetSeverity | null];
export let SetSeverity = (p: TSeverity) => [p, null] as SeverityState;

// Variant /////////////////////////////////////////////////////////////////////

export type TVariant = "filled" | "outlined" | "standard";
export type TSetVariant = SSA<TVariant>;
export type VariantState = [TVariant, TSetVariant | null];
export let SetVariant = (p: TVariant) => [p, null] as VariantState;

// Button Variant //////////////////////////////////////////////////////////////

export type TVariantButton = "text" | "outlined" | "contained";
export type TSetVariantButton = SSA<TVariantButton>;
export type VariantButtonState = [TVariantButton, TSetVariantButton | null];
export let SetVariantButton = (p: TVariantButton) =>
  [p, null] as VariantButtonState;

// Date ////////////////////////////////////////////////////////////////////////

export type TDate = "year" | "month" | "day";
export type TSetDate = SSA<TDate>;
export type DateState = [TDate, TSetDate | null];
export let SetDate = (p: TDate) => [p, null] as DateState;

// Validations /////////////////////////////////////////////////////////////////

export type TValidationResults = { status: boolean; message: string } | null;

export type TValidationConditions =
  | "none"
  | "name"
  | "duplicate"
  | "range"
  | "contact"
  | "year";

export type TValidation =
  | TValidationNone
  | TValidationName
  | TValidationRange
  | TValidationContact
  | TValidationYear;

export type TValidationNone = {
  required: boolean;
  condition: "none";
  message: { label: string };
};

export type TValidationName = {
  required: boolean;
  condition: "name" | "duplicate";
  message: { label: string; duplicate?: string };
};

export type TValidationRange = {
  required: boolean;
  condition: "range";
  message: { label: string };
  range: { min: number; max: number };
};

export type TValidationContact = {
  required: boolean;
  condition: "contact";
  message: { label: string };
};

export type TValidationYear = {
  required: boolean;
  condition: "year";
  message: { label: string };
  range: { min: number; max: number };
};

export type TSetValidation = SSA<TValidation>;

// Text ////////////////////////////////////////////////////////////////////////

export type TText = {
  label: TString;
  placeholder?: TString;
  helperText?: TString;
  unit?: TString;
};
export type TSetText = SSA<TText>;
export type TextState = [TText, TSetText | null];
export let SetText = (t: TText) => [t, null] as TextState;

// Render Input ////////////////////////////////////////////////////////////////

export type TRenderInput = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLLIElement> &
    React.LiHTMLAttributes<HTMLLIElement>,
  option: {
    label:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
  },
  { selected }: any
) => JSX.Element;
