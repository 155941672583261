// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { safeRead } from "../../app/GenFunctions";
import { ToBeAcquired } from "../../app/GenEnums";
import { TValidationResults } from "../util/AType";

interface ICheckName {
  value: any;
  message: { label: string; duplicate?: string };
  checkIfItAlreadyExists?: boolean;
}

export function CheckName(props: ICheckName): TValidationResults {
  if (safeRead(props.value) !== "") {
    var value = safeRead(props.value);
    var indexOfSpace = value.indexOf(" ");
    var length = value.length;
    var testIsOneWord = indexOfSpace === -1 || indexOfSpace === length - 1;
    var isToBeAcquired = String(value) === ToBeAcquired;

    if (!isToBeAcquired) {
      if (testIsOneWord) {
        return { status: true, message: props.message.label };
      }
    }
  }
  return { status: false, message: "" };
}

