import { ReactNode } from "react";
import { assistWidth } from "../util/AFunc";
import { collapseAssist } from "./Box";

export function VBar(props: {
  w?: number;
  id?: string;
  class?: string;
  gap?: number;
  children?: ReactNode;
  /** Transition IN */
  in?: boolean;
  force?: boolean;
}) {
  const bar = (
    <div
      id={props.id}
      className={props.class}
      style={{
        width: assistWidth({ width: props.w }),
        minWidth: assistWidth({ width: props.w }),
        display: "flex",
        flexDirection: "column",
        gap: props.gap,
      }}
    >
      {props.children}
    </div>
  );

  return props.in != null ? collapseAssist(bar, props.in, props.force) : bar;
}
