// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { Button } from "@mui/material";
import { assistWidth, assistBorderRadius } from "../util/AFunc";
import { TVariantButton, TMUIColor, TPosition } from "../util/AType";

export interface IAVButtonProps {
  /**  */
  label?: string;
  /**  */
  variant?: TVariantButton;
  /**  */
  icon?: JSX.Element;
  /**  */
  onClick?: () => void;
  /**  */
  color?: TMUIColor;
  /**  */
  width?: number;
  /**  */
  height?: number;
  /**  */
  borderRadius?: number;
  /**  */
  position?: TPosition;
  /**  */
  disabled?: boolean;
  /**  */
  fullWidth?: boolean;
}

export function AVButton(props: IAVButtonProps) {
  return (
    <Button
      variant={props.variant ?? "contained"}
      startIcon={props.icon}
      onClick={props.onClick}
      color={props.color}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
        height: props.height,
        maxHeight: props.height,
      }}
      sx={{
        boxShadow: 0,
        borderRadius: assistBorderRadius({
          borderRadius: props.borderRadius,
          horPosition: props.position,
        }),
      }}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
    >
      {props.label}
    </Button>
  );
}
