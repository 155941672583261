// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { safeRead } from "../../app/GenFunctions";
import { ToBeAcquired } from "../../app/GenEnums";
import { TValidationResults } from "../util/AType";

interface ICheckRange {
  value: any;
  message: { label: string };
  range: { min: number; max: number };
}

export function CheckRange(props: ICheckRange): TValidationResults {
  if (props.value !== "") {
    var value = safeRead(props.value);
    var valAsNum = parseInt(value);
    var valIsNotNumber = isNaN(valAsNum);
    var isToBeAcquired = String(value) === ToBeAcquired;

    if (!isToBeAcquired) {
      if (
        valIsNotNumber ||
        valAsNum < props.range.min ||
        valAsNum > props.range.max
      ) {
        return {
          status: true,
          message:
            props.message.label +
            " (" +
            props.range.min +
            " - " +
            props.range.max +
            ")",
        };
      }
    }
  }
  return { status: false, message: "" };
}
