import TX from "./AVGeneral.json";
import { GET, SET } from "../util/AType";
import { TPosition } from "../util/AType";
import { useEffect, useState } from "react";
import { REQUIRED, Validate } from "../valid/Validations";
import { safeRead } from "../../app/GenFunctions";
import { avBackup, IAVRequired } from "./AVGeneral";
import { assistBorderRadius, assistWidth } from "../util/AFunc";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export interface IAVDropdownProps extends IAVRequired {
  /**  */
  rows?: number;
  /**  */
  options: any[];
  /**  */
  max?: number;
  /**  */
  multiple?: boolean;
  /**  */
  free?: boolean;
  /**  */
  disabled?: boolean;
  /**  */
  onInputChange?: (e: any, value: any) => void;
  /**  */
  position?: TPosition;
  /**  */
  borderRadius?: number;
  /**  */
  group?: (option: any) => string;
  /**  */
  open?: boolean;
  /**  */
  width?: number;
  /**  */
  id?: string;
  /**  */
  // className?: string;
  /**  */
  // onFocus?: (e: any) => void;
  /**  */
  // ! Check if this is being used, remove if not
  // onChange?: (e: any, value: any) => void;
  /**  */
  /**  */
  // unitPosition?: "start" | "end";
}

export function AVDropdown(props: IAVDropdownProps) {
  const [errorStatus, setErrorStatus] = useState(false);
  const [helperText, setHelperText] = useState(props.text.helperText);

  /** Handle max inputs */
  useEffect(() => {
    if (props.max) {
      if (Array.isArray(props.value[GET])) {
        if (props.value[GET].length > props.max) {
          setErrorStatus(true);
          setHelperText(TX.max + " (" + props.max + ").");
        } else {
          setErrorStatus(false);
          setHelperText(props.text.helperText);
        }
      }
    }
  }, [props.max, props.text.helperText, props.value]);

  const onChange = (e: any, value: any) => {
    if (value === undefined) value = [];
    props.value[SET]?.(value);
    avBackup(props.backup, JSON.stringify(value));
    if (props.validation) {
      Validate({
        value: value,
        validation: props.validation,
        setStatus: setErrorStatus,
        setText: setHelperText,
      });
    }
  };

  // TODO May cause logging
  useEffect(() => {
    onChange(null, props.value[GET]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const isEqual = (opt: any, val: any) => safeRead(opt) === safeRead(val);

  const onInputChange = (e: any, value: any) => {
    if (props.onInputChange) props.onInputChange(e, value);
    if (e && props.free) {
      if (e.key === "Enter") onChange(e, value);
      if (!props.max && !props.multiple) onChange(e, value);
    }
  };

  useEffect(() => {
    if (props.validation?.required) {
      if (safeRead(props.value[GET]) === "") {
        setErrorStatus(true);
        setHelperText(REQUIRED);
      } else {
        setErrorStatus(false);
        setHelperText(props.text.helperText);
      }
    }
  }, [
    props.text.helperText,
    props.validation?.message?.label,
    props.validation?.required,
    props.value,
  ]);

  return (
    <Autocomplete
      fullWidth
      id={props.id}
      onChange={onChange}
      groupBy={props.group}
      forcePopupIcon={true}
      freeSolo={props.free}
      options={props.options}
      disabled={props.disabled}
      onInputChange={onInputChange}
      isOptionEqualToValue={isEqual}
      multiple={props.multiple ?? props.max ? true : false}
      value={(() => {
        if (props.value[GET] === null || props.value[GET] === "")
          return props.max || props.multiple ? [] : null;
        return props.value[GET];
      })()}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          rows={props.rows}
          error={errorStatus}
          helperText={helperText}
          label={props.text.label}
          placeholder={props.text.placeholder}
          multiline={props.rows ? true : false}
        />
      )}
      sx={{
        "& fieldset": {
          borderRadius: assistBorderRadius({
            borderRadius: props.borderRadius,
            horPosition: props.position,
          }),
        },
      }}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
      }}
      renderOption={(lprops, option, { selected }) =>
        props.max || props.multiple ? (
          <li {...lprops}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        ) : (
          <li {...lprops}>{option.label}</li>
        )
      }
    />
  );
}
