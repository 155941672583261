import "leaflet/dist/leaflet.css";
import "../../assets/scss/Forms.css";
import { TX } from "./ImplementPageJSON";

import { NavLink, redirect, useNavigate } from "react-router-dom";
import { LoaderFunctionArgs } from "react-router-dom";
import { AvzRoute, gotoLogin, requireLoadProperly } from "../../app/Routes";
import { fxdDelete, fxdGen } from "../../app/Storage";
import { fxdRead, fxdUser, fxdWrite } from "../../app/Storage";
import { fxdImplement, fxdProfile } from "../../app/Storage";
import { fxdImplementBackup } from "../../app/Storage";
import { AVButton } from "../../arv/com/AVButton";
import { AVTick } from "../../arv/com/AVTick";
import { AVAlert } from "../../arv/com/AVPopup";
import { AVPopup } from "../../arv/com/AVPopup";
import { AVText } from "../../arv/com/AVText";
import { AVOVERRIDE, avScrollTo } from "../../arv/com/AVGeneral";
import { useEffect, useState } from "react";
import { TString, TValidation } from "../../arv/util/AType";
import { TSetAny, TSetString, TSetValidation } from "../../arv/util/AType";
import { Card } from "../../arv/box/Card";
import { VBar } from "../../arv/box/VBar";
import { HBar } from "../../arv/box/HBar";
import { TitleBar } from "../encode/EncodePage";
import { GeoLocationPane } from "../../app/GeoLocation";
import WebCam from "../../arv/util/Camera";
import { Address, CreateNewAddress } from "../../arv/util/Address";
import { Province, Municipality, Barangay } from "../../arv/util/Address";
import { BrowserQRCodeReader } from "@zxing/library";
import L from "leaflet";
import { AppSettings } from "../../app/Settings";
import iImplement from "../../assets/img/icon/map/implement.png";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { createZIP, safeRead } from "../../app/GenFunctions";
import { ID } from "../../app/ElementID";
import { DATA_STATUS, NotApplicableAsOption } from "../../app/GenEnums";
import {
  NameValidation,
  NoValidation,
  Rng,
  ValidateRequired,
  YearValidation,
} from "../../arv/valid/Validations";
import { ContactValidation } from "../../arv/valid/Validations";
import SaveIcon from "@mui/icons-material/Save";
import { Nav } from "react-bootstrap";
import { AVDropdown } from "../../arv/com/AVDropdown";
import { AVField } from "../../arv/com/AVField";

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const pop = TX.pop;
const vldtn = TX.validation;
const q = TX.question;
const action = TX.action;

const IMBKUP = fxdImplementBackup as Record<string, any>;
export function ImplementPageLoader({ request }: LoaderFunctionArgs) {
  var keys = Object.keys(localStorage);
  var enlistedMachinesFromThisProfile = () =>
    keys.filter((key) => key.charAt(0) === "R" && key.charAt(6) === "M");
  var mach = enlistedMachinesFromThisProfile();
  var machNo = mach === undefined ? 0 : mach?.length;

  if (machNo < 1) {
    AVPopup({
      type: "error",
      title: pop.noMachine.title,
      text: pop.noMachine.message,
      confirmButtonText: pop.noMachine.button,
    });
    return redirect(AvzRoute.machine);
  } else {
    return gotoLogin(request.url) ?? null;
  }
}

// /////////////////////////////////////////////////////////////////////////////
// CONSTANTS ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const tMolBrdPlow = q.tI2Type.options[0].label;
const tDiscPlow = q.tI2Type.options[1].label;
const tDiscHarrow = q.tI2Type.options[2].label;
const tFurrower = q.tI2Type.options[3].label;
const tSubsoiler = q.tI2Type.options[4].label;
const tChiselPlow = q.tI2Type.options[5].label;
const tPowerHarrow = q.tI2Type.options[6].label;
const tSpkTthHarrow = q.tI2Type.options[7].label;
const tRotTiller = q.tI2Type.options[8].label;
const tDitcher = q.tI2Type.options[9].label;
const tDozer = q.tI2Type.options[10].label;
const tExcavator = q.tI2Type.options[11].label;
const tMechPlanter = q.tI2Type.options[12].label;
const tFldCltvtr = q.tI2Type.options[13].label;
const tRtnManager = q.tI2Type.options[14].label;
const tTrashRake = q.tI2Type.options[15].label;
const tMulcher = q.tI2Type.options[16].label;
const tItrRowCltvtr = q.tI2Type.options[17].label;
const tCutAwayCltvtr = q.tI2Type.options[18].label;
const tTrashInc = q.tI2Type.options[19].label;
const tGranFertAppl = q.tI2Type.options[20].label;
const tFertApplCutAway = q.tI2Type.options[21].label;
const tWeeder = q.tI2Type.options[22].label;
const tBoomSprayer = q.tI2Type.options[23].label;
const tMechHarvester = q.tI2Type.options[24].label;
const tCaneGrabLoader = q.tI2Type.options[25].label;
const tTrailer = q.tI2Type.options[26].label;
// const tNa = q.type.options[27].label;

export default function ImplementPage() {
  // ///////////////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  const restoreFromLocalStorage = () => {
    var restoreFromBackup: Record<string, any> = {};
    Object.keys(IMBKUP).forEach((key) => {
      restoreFromBackup[key] = fxdRead(IMBKUP[key])
        ? JSON.parse(fxdRead(IMBKUP[key]) || "")
        : "";
    });
    return restoreFromBackup;
  };

  const isAllEmpty = (backup: Record<string, any>) => {
    return Object.values(backup).every((x) => safeRead(x) === "");
  };

  const backup = restoreFromLocalStorage();

  const restoreImplementBackups = (from: Record<string, any>) => {
    for (let i = 0; i < QUESTIONS.length; i++) {
      var val = from["b" + QUESTIONS[i].backup.slice(3)];
      if (val && typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(val);
      }
    }
  };

  function deleteMachineBackups() {
    Object.values(IMBKUP).forEach((val) => fxdDelete(val));
    fxdDelete(fxdImplement.backup);
  }

  useEffect(() => {
    console.log("I1");
    if (!isAllEmpty(backup)) {
      fxdWrite(fxdImplement.backup, JSON.stringify(backup));
    }

    var restored = JSON.parse(fxdRead(fxdImplement.backup) || "{}");
    var edit = JSON.parse(fxdRead(fxdGen.impEdit) || "{}");

    if (!isAllEmpty(restored) || fxdRead(fxdGen.impEdit)) {
      AVPopup({
        icon: "info",
        title: pop.backup.title,
        text: pop.backup.message,

        showConfirmButton: fxdRead(fxdGen.impEdit) ? true : false,
        confirmButtonText: pop.backup.deny,
        showDenyButton: fxdRead(fxdImplement.backup) ? true : false,
        denyButtonText: pop.backup.button,
        showCancelButton: true,
        allowOutsideClick: true,
      }).then((result) => {
        if (result.isConfirmed) {
          restoreImplementBackups(edit);
        } else if (result.isDenied) {
          restoreImplementBackups(backup);
        } else if (result.isDismissed) {
          deleteMachineBackups();
          fxdDelete(fxdGen.impEdit);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ///////////////////////////////////////////////////////////////////////////
  // STATE /////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const [iI1ResName, setII1ResName] = useState<any>("");
  const [eI1ResName, setEI1ResName] = useState(NoValidation);

  const [iI1QRCode, setII1QRCode] = useState<any>("");
  const [eI1QRCode, setEI1QRCode] = useState(NoValidation);

  const [iI1Machine, setII1Machine] = useState<any>("");
  const [eI1Machine, setEI1Machine] = useState(NoValidation);

  const [iI1FrontVw, setII1FrontVw] = useState<any>("");
  const [eI1FrontVw, setEI1FrontVw] = useState(NoValidation);

  const [iI1SideVw, setII1SideVw] = useState<any>("");
  const [eI1SideVw, setEI1SideVw] = useState(NoValidation);

  const [iI1SpecVw, setII1SpecVw] = useState<any>("");
  const [eI1SpecVw, setEI1SpecVw] = useState(NoValidation);

  const [iI1Acc, setII1Acc] = useState<any>("");
  const [eI1Acc, setEI1Acc] = useState(NoValidation);

  const [iI1Lat, setII1Lat] = useState<any>("");
  const [eI1Lat, setEI1Lat] = useState(NoValidation);

  const [iI1Lng, setII1Lng] = useState<any>("");
  const [eI1Lng, setEI1Lng] = useState(NoValidation);

  const [iI1Loc, setII1Loc] = useState<any>("");
  const [eI1Loc, setEI1Loc] = useState(NoValidation);

  const [iI1Prov, setII1Prov] = useState<any>("");
  const [eI1Prov, setEI1Prov] = useState(NoValidation);

  const [iI1Mun, setII1Mun] = useState<any>("");
  const [eI1Mun, setEI1Mun] = useState(NoValidation);

  const [iI1Bgry, setII1Bgry] = useState<any>("");
  const [eI1Bgry, setEI1Bgry] = useState(NoValidation);

  const [iI2Type, setII2Type] = useState<any>("");
  const [eI2Type, setEI2Type] = useState(NoValidation);

  const [iI2Brand, setII2Brand] = useState<any>("");
  const [eI2Brand, setEI2Brand] = useState(NoValidation);

  const [iI2Model, setII2Model] = useState<any>("");
  const [eI2Model, setEI2Model] = useState(NoValidation);

  const tI2NoBtm = Rng(0, 99, vldtn.bottoms);
  const [iI2NoBtm1, setII2NoBtm1] = useState<any>("");
  const [eI2NoBtm1, setEI2NoBtm1] = useState(tI2NoBtm);

  const [iI2NoBtm2, setII2NoBtm2] = useState<any>("");
  const [eI2NoBtm2, setEI2NoBtm2] = useState(tI2NoBtm);

  const tI2SzDk = Rng(0, 99, vldtn.disk);
  const [iI2SzDk, setII2SzDk] = useState<any>("");
  const [eI2SzDk, setEI2SzDk] = useState(tI2SzDk);

  const [iI2NoBtm3, setII2NoBtm3] = useState<any>("");
  const [eI2NoBtm3, setEI2NoBtm3] = useState(tI2NoBtm);

  const tI2DisRow = Rng(0, 999, vldtn.distance);
  const [iI2DisRow1, setII2DisRow1] = useState<any>("");
  const [eI2DisRow1, setEI2DisRow1] = useState(tI2DisRow);

  const tI2WdCut = Rng(0, 999, vldtn.wcut);
  const [iI2WdCut, setII2WdCut] = useState<any>("");
  const [eI2WdCut, setEI2WdCut] = useState(tI2WdCut);

  const tI2LdCap = Rng(0, 9999, vldtn.capacity);
  const [iI2LdCap, setII2LdCap] = useState<any>("");
  const [eI2LdCap, setEI2LdCap] = useState(tI2LdCap);

  const [iI2LdCapUnit, setII2LdCapUnit] = useState<any>("");
  const [eI2LdCapUnit, setEI2LdCapUnit] = useState(NoValidation);

  const tI2NoRows = Rng(0, 99, vldtn.rows);
  const [iI2NoRows1, setII2NoRows1] = useState<any>("");
  const [eI2NoRows1, setEI2NoRows1] = useState(tI2NoRows);

  const [iI2DisRow2, setII2DisRow2] = useState<any>("");
  const [eI2DisRow2, setEI2DisRow2] = useState(tI2DisRow);

  const tI2NoNzls = Rng(0, 999, vldtn.nozzle);
  const [iI2NoNzls, setII2NoNzls] = useState<any>("");
  const [eI2NoNzls, setEI2NoNzls] = useState(tI2NoNzls);

  const tI2DisNzls = Rng(0, 999, vldtn.dnozzle);
  const [iI2DisNzls, setII2DisNzls] = useState<any>("");
  const [eI2DisNzls, setEI2DisNzls] = useState(tI2DisNzls);

  const tI2TnkCap = Rng(0, 999, vldtn.capacity);
  const [iI2TnkCap1, setII2TnkCap1] = useState<any>("");
  const [eI2TnkCap1, setEI2TnkCap1] = useState(tI2TnkCap);

  const tI2NoOutlets = Rng(0, 99, vldtn.outlet);
  const [iI2NoOutlets, setII2NoOutlets] = useState<any>("");
  const [eI2NoOutlets, setEI2NoOutlets] = useState(tI2NoOutlets);

  const tI2DisOutlets = Rng(0, 999, vldtn.doutlet);
  const [iI2DisOutlets, setII2DisOutlets] = useState<any>("");
  const [eI2DisOutlets, setEI2DisOutlets] = useState(tI2DisOutlets);

  const [iI2TnkCap2, setII2TnkCap2] = useState<any>("");
  const [eI2TnkCap2, setEI2TnkCap2] = useState(tI2TnkCap);

  const [iI2TnkCap2Unit, setII2TnkCap2Unit] = useState<any>("");
  const [eI2TnkCap2Unit, setEI2TnkCap2Unit] = useState(NoValidation);

  const [iI2TpPlntr, setII2TpPlntr] = useState<any>("");
  const [eI2TpPlntr, setEI2TpPlntr] = useState(NoValidation);

  const [iI2NoRows2, setII2NoRows2] = useState<any>("");
  const [eI2NoRows2, setEI2NoRows2] = useState(tI2NoRows);

  const [iI2TpCnGrbAttch, setII2TpCnGrbAttch] = useState<any>("");
  const [eI2TpCnGrbAttch, setEI2TpCnGrbAttch] = useState(NoValidation);

  const [iI3Prim, setII3Prim] = useState<any>("");
  const [eI3Prim, setEI3Prim] = useState(NoValidation);

  const [iI3Sec, setII3Sec] = useState<any>("");
  const [eI3Sec, setEI3Sec] = useState(NoValidation);

  const [iI3Ter, setII3Ter] = useState<any>("");
  const [eI3Ter, setEI3Ter] = useState(NoValidation);

  const [iI4Usage, setII4Usage] = useState<any>("");
  const [eI4Usage, setEI4Usage] = useState(NoValidation);

  const [iI4Mode, setII4Mode] = useState<any>("");
  const [eI4Mode, setEI4Mode] = useState(NoValidation);

  const [iI4Agency, setII4Agency] = useState<any>("");
  const [eI4Agency, setEI4Agency] = useState(NameValidation);

  const [iI4YrAcqrd, setII4YrAcqrd] = useState<any>("");
  const [eI4YrAcqrd, setEI4YrAcqrd] = useState(YearValidation);

  const [iI4ConAcqrd, setII4ConAcqrd] = useState<any>("");
  const [eI4ConAcqrd, setEI4ConAcqrd] = useState(NoValidation);

  const [iI4PrsntCon, setII4PrsntCon] = useState<any>("");
  const [eI4PrsntCon, setEI4PrsntCon] = useState(NoValidation);

  const [iI4YrUnused, setII4YrUnused] = useState<any>("");
  const [eI4YrUnused, setEI4YrUnused] = useState(YearValidation);

  const [iI4RsnUnused, setII4RsnUnused] = useState<any>("");
  const [eI4RsnUnused, setEI4RsnUnused] = useState(NoValidation);

  const [iI4UuOthers, setII4UuOthers] = useState<any>("");
  const [eI4UuOthers, setEI4UuOthers] = useState(NoValidation);

  const [iI4YrUnserv, setII4YrUnserv] = useState<any>("");
  const [eI4YrUnserv, setEI4YrUnserv] = useState(YearValidation);

  const [iI4RsnUnserv, setII4RsnUnserv] = useState<any>("");
  const [eI4RsnUnserv, setEI4RsnUnserv] = useState(NoValidation);

  const [iI4UsOthers, setII4UsOthers] = useState<any>("");
  const [eI4UsOthers, setEI4UsOthers] = useState(NoValidation);

  const [iI4WithTractor, setII4WithTractor] = useState<any>("");
  const [eI4WithTractor, setEI4WithTractor] = useState(NoValidation);

  const tI4SvcRtHa = Rng(0, 9999999, vldtn.svcRate);
  const [iI4SvcRtHa, setII4SvcRtHa] = useState<any>("");
  const [eI4SvcRtHa, setEI4SvcRtHa] = useState(tI4SvcRtHa);

  const [iI4SvcRtHaNon, setII4SvcRtHaNon] = useState<any>("");
  const [eI4SvcRtHaNon, setEI4SvcRtHaNon] = useState(tI4SvcRtHa);

  const [iI4SameOwn, setII4SameOwn] = useState<any>("");
  const [eI4SameOwn, setEI4SameOwn] = useState(NoValidation);

  const [iI4BusCont1, setII4BusCont1] = useState<any>("");
  const [eI4BusCont1, setEI4BusCont1] = useState(ContactValidation);

  const [iI4BusCont2, setII4BusCont2] = useState<any>("");
  const [eI4BusCont2, setEI4BusCont2] = useState(ContactValidation);

  const [iI4Consent, setII4Consent] = useState<any>("");
  const [eI4Consent, setEI4Consent] = useState(NoValidation);

  const [iI4SrvcLoc1P, setII4SrvcLoc1P] = useState<any>("");
  const [eI4SrvcLoc1P, setEI4SrvcLoc1P] = useState(NoValidation);

  const [iI4SrvcLoc1M, setII4SrvcLoc1M] = useState<any>("");
  const [eI4SrvcLoc1M, setEI4SrvcLoc1M] = useState(NoValidation);

  const [iI4SrvcLoc2P, setII4SrvcLoc2P] = useState<any>("");
  const [eI4SrvcLoc2P, setEI4SrvcLoc2P] = useState(NoValidation);

  const [iI4SrvcLoc2M, setII4SrvcLoc2M] = useState<any>("");
  const [eI4SrvcLoc2M, setEI4SrvcLoc2M] = useState(NoValidation);

  const [iI4SrvcLoc3P, setII4SrvcLoc3P] = useState<any>("");
  const [eI4SrvcLoc3P, setEI4SrvcLoc3P] = useState(NoValidation);

  const [iI4SrvcLoc3M, setII4SrvcLoc3M] = useState<any>("");
  const [eI4SrvcLoc3M, setEI4SrvcLoc3M] = useState(NoValidation);

  const tI5TmCom1Ha = Rng(0, 24, vldtn.time);
  const [iI5TmCom1Ha, setII5TmCom1Ha] = useState<any>("");
  const [eI5TmCom1Ha, setEI5TmCom1Ha] = useState(tI5TmCom1Ha);

  const tI5NoPasses = Rng(0, 10, vldtn.passes);
  const [iI5NoPasses, setII5NoPasses] = useState<any>("");
  const [eI5NoPasses, setEI5NoPasses] = useState(tI5NoPasses);

  const tI5GrnFrtWt = Rng(0, 9999, vldtn.rate);
  const [iI5GrnFrtWt, setII5GrnFrtWt] = useState<any>("");
  const [eI5GrnFrtWt, setEI5GrnFrtWt] = useState(tI5GrnFrtWt);

  const [iI5GrnFrtWtUnit, setII5GrnFrtWtUnit] = useState<any>("");
  const [eI5GrnFrtWtUnit, setEI5GrnFrtWtUnit] = useState(NoValidation);

  const tI5FrtAppEffA = Rng(0, 99, vldtn.area);
  const [iI5FrtAppEffA, setII5FrtAppEffA] = useState<any>("");
  const [eI5FrtAppEffA, setEI5FrtAppEffA] = useState(tI5FrtAppEffA);

  const tI5FrtDelRate = Rng(0, 99, vldtn.rate);
  const [iI5FrtDelRate, setII5FrtDelRate] = useState<any>("");
  const [eI5FrtDelRate, setEI5FrtDelRate] = useState(tI5FrtDelRate);

  const tI5SgrYld = Rng(0, 99, vldtn.yield);
  const [iI5SgrYld, setII5SgrYld] = useState<any>("");
  const [eI5SgrYld, setEI5SgrYld] = useState(tI5SgrYld);

  const tI5LdCap = Rng(0, 999, vldtn.capacity);
  const [iI5LdCap, setII5LdCap] = useState<any>("");
  const [eI5LdCap, setEI5LdCap] = useState(tI5LdCap);

  const [iI5LdCapUnit, setII5LdCapUnit] = useState<any>("");
  const [eI5LdCapUnit, setEI5LdCapUnit] = useState(NoValidation);

  const tI5NoBrkdwn = Rng(0, 999, vldtn.breakdown);
  const [iI5NoBrkdwn, setII5NoBrkdwn] = useState<any>("");
  const [eI5NoBrkdwn, setEI5NoBrkdwn] = useState(tI5NoBrkdwn);

  const [iI5CmnBrkdwn, setII5CmnBrkdwn] = useState<any>("");
  const [eI5CmnBrkdwn, setEI5CmnBrkdwn] = useState(NoValidation);

  const [iI5MntcDone, setII5MntcDone] = useState<any>("");
  const [eI5MntcDone, setEI5MntcDone] = useState(NoValidation);

  const [iI6Notes, setII6Notes] = useState<any>("");
  const [eI6Notes, setEI6Notes] = useState(NoValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // Questions /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  type TQuestion = {
    id: string;
    value: string | undefined;
    setValue: TSetString | TSetAny;
    errorMessage: TValidation;
    setErrorMessage: TSetValidation;
    backup: string;
    label: string;
  };

  const QUESTIONS: TQuestion[] = [
    {
      id: "i1ResName",
      value: iI1ResName,
      setValue: setII1ResName,
      errorMessage: eI1ResName,
      setErrorMessage: setEI1ResName,
      backup: IMBKUP.bI1ResName,
      label: q.tI1ResName.label,
    },
    {
      id: "i1Machine",
      value: iI1Machine,
      setValue: setII1Machine,
      errorMessage: eI1Machine,
      setErrorMessage: setEI1Machine,
      backup: IMBKUP.bI1Machine,
      label: q.tI1Machine.label,
    },
    {
      id: "i1QRCode",
      value: iI1QRCode,
      setValue: setII1QRCode,
      errorMessage: eI1QRCode,
      setErrorMessage: setEI1QRCode,
      backup: IMBKUP.bI1QRCode,
      label: q.tI1QRCode.label,
    },
    {
      id: "i1FrontVw",
      value: iI1FrontVw,
      setValue: setII1FrontVw,
      errorMessage: eI1FrontVw,
      setErrorMessage: setEI1FrontVw,
      backup: IMBKUP.bI1FrontVw,
      label: q.tI1FrontVw.label,
    },
    {
      id: "i1SideVw",
      value: iI1SideVw,
      setValue: setII1SideVw,
      errorMessage: eI1SideVw,
      setErrorMessage: setEI1SideVw,
      backup: IMBKUP.bI1SideVw,
      label: q.tI1SideVw.label,
    },
    {
      id: "i1SpecVw",
      value: iI1SpecVw,
      setValue: setII1SpecVw,
      errorMessage: eI1SpecVw,
      setErrorMessage: setEI1SpecVw,
      backup: IMBKUP.bI1SpecVw,
      label: q.tI1SpecVw.label,
    },
    {
      id: "i1Acc",
      value: iI1Acc,
      setValue: setII1Acc,
      errorMessage: eI1Acc,
      setErrorMessage: setEI1Acc,
      backup: IMBKUP.bI1Acc,
      label: q.tI1Acc.label,
    },
    {
      id: "i1Lat",
      value: iI1Lat,
      setValue: setII1Lat,
      errorMessage: eI1Lat,
      setErrorMessage: setEI1Lat,
      backup: IMBKUP.bI1Lat,
      label: q.tI1Lat.label,
    },
    {
      id: "i1Lng",
      value: iI1Lng,
      setValue: setII1Lng,
      errorMessage: eI1Lng,
      setErrorMessage: setEI1Lng,
      backup: IMBKUP.bI1Lng,
      label: q.tI1Lng.label,
    },
    {
      id: "i1Loc",
      value: iI1Loc,
      setValue: setII1Loc,
      errorMessage: eI1Loc,
      setErrorMessage: setEI1Loc,
      backup: IMBKUP.bI1Loc,
      label: q.tI1Loc.label,
    },
    {
      id: "i1Prov",
      value: iI1Prov,
      setValue: setII1Prov,
      errorMessage: eI1Prov,
      setErrorMessage: setEI1Prov,
      backup: IMBKUP.bI1Prov,
      label: q.tI1Prov.label,
    },
    {
      id: "i1Mun",
      value: iI1Mun,
      setValue: setII1Mun,
      errorMessage: eI1Mun,
      setErrorMessage: setEI1Mun,
      backup: IMBKUP.bI1Mun,
      label: q.tI1Mun.label,
    },
    {
      id: "i1Bgry",
      value: iI1Bgry,
      setValue: setII1Bgry,
      errorMessage: eI1Bgry,
      setErrorMessage: setEI1Bgry,
      backup: IMBKUP.bI1Bgry,
      label: q.tI1Bgry.label,
    },
    {
      id: "i2Type",
      value: iI2Type,
      setValue: setII2Type,
      errorMessage: eI2Type,
      setErrorMessage: setEI2Type,
      backup: IMBKUP.bI2Type,
      label: q.tI2Type.label,
    },
    {
      id: "i2Brand",
      value: iI2Brand,
      setValue: setII2Brand,
      errorMessage: eI2Brand,
      setErrorMessage: setEI2Brand,
      backup: IMBKUP.bI2Brand,
      label: q.tI2Brand.label,
    },
    {
      id: "i2Model",
      value: iI2Model,
      setValue: setII2Model,
      errorMessage: eI2Model,
      setErrorMessage: setEI2Model,
      backup: IMBKUP.bI2Model,
      label: q.tI2Model.label,
    },
    {
      id: "i2NoBtm1",
      value: iI2NoBtm1,
      setValue: setII2NoBtm1,
      errorMessage: eI2NoBtm1,
      setErrorMessage: setEI2NoBtm1,
      backup: IMBKUP.bI2NoBtm1,
      label: q.tI2NoBtm.label,
    },
    {
      id: "i2NoBtm2",
      value: iI2NoBtm2,
      setValue: setII2NoBtm2,
      errorMessage: eI2NoBtm2,
      setErrorMessage: setEI2NoBtm2,
      backup: IMBKUP.bI2NoBtm2,
      label: q.tI2NoBtm.label,
    },
    {
      id: "i2SzDk",
      value: iI2SzDk,
      setValue: setII2SzDk,
      errorMessage: eI2SzDk,
      setErrorMessage: setEI2SzDk,
      backup: IMBKUP.bI2SzDk,
      label: q.tI2SzDk.label,
    },
    {
      id: "i2NoBtm3",
      value: iI2NoBtm3,
      setValue: setII2NoBtm3,
      errorMessage: eI2NoBtm3,
      setErrorMessage: setEI2NoBtm3,
      backup: IMBKUP.bI2NoBtm3,
      label: q.tI2NoBtm.label,
    },
    {
      id: "i2DisRow1",
      value: iI2DisRow1,
      setValue: setII2DisRow1,
      errorMessage: eI2DisRow1,
      setErrorMessage: setEI2DisRow1,
      backup: IMBKUP.bI2DisRow1,
      label: q.tI2DisRow.label,
    },
    {
      id: "i2WdCut",
      value: iI2WdCut,
      setValue: setII2WdCut,
      errorMessage: eI2WdCut,
      setErrorMessage: setEI2WdCut,
      backup: IMBKUP.bI2WdCut,
      label: q.tI2WdCut.label,
    },
    {
      id: "i2LdCap",
      value: iI2LdCap,
      setValue: setII2LdCap,
      errorMessage: eI2LdCap,
      setErrorMessage: setEI2LdCap,
      backup: IMBKUP.bI2LdCap,
      label: q.tI2LdCap.label,
    },
    {
      id: "i2LdCapUnit",
      value: iI2LdCapUnit,
      setValue: setII2LdCapUnit,
      errorMessage: eI2LdCapUnit,
      setErrorMessage: setEI2LdCapUnit,
      backup: IMBKUP.bI2LdCapUnit,
      label: q.tI5LdCap.label + " " + q.tI5LdCapUnit.label,
    },
    {
      id: "i2NoRows1",
      value: iI2NoRows1,
      setValue: setII2NoRows1,
      errorMessage: eI2NoRows1,
      setErrorMessage: setEI2NoRows1,
      backup: IMBKUP.bI2NoRows1,
      label: q.tI2NoRows.label,
    },
    {
      id: "i2DisRow2",
      value: iI2DisRow2,
      setValue: setII2DisRow2,
      errorMessage: eI2DisRow2,
      setErrorMessage: setEI2DisRow2,
      backup: IMBKUP.bI2DisRow2,
      label: q.tI2DisRow.label,
    },
    {
      id: "i2NoNzls",
      value: iI2NoNzls,
      setValue: setII2NoNzls,
      errorMessage: eI2NoNzls,
      setErrorMessage: setEI2NoNzls,
      backup: IMBKUP.bI2NoNzls,
      label: q.tI2NoNzls.label,
    },
    {
      id: "i2DisNzls",
      value: iI2DisNzls,
      setValue: setII2DisNzls,
      errorMessage: eI2DisNzls,
      setErrorMessage: setEI2DisNzls,
      backup: IMBKUP.bI2DisNzls,
      label: q.tI2DisNzls.label,
    },
    {
      id: "i2TnkCap1",
      value: iI2TnkCap1,
      setValue: setII2TnkCap1,
      errorMessage: eI2TnkCap1,
      setErrorMessage: setEI2TnkCap1,
      backup: IMBKUP.bI2TnkCap1,
      label: q.tI2TnkCap.label,
    },
    {
      id: "i2NoOutlets",
      value: iI2NoOutlets,
      setValue: setII2NoOutlets,
      errorMessage: eI2NoOutlets,
      setErrorMessage: setEI2NoOutlets,
      backup: IMBKUP.bI2NoOutlets,
      label: q.tI2NoOutlets.label,
    },
    {
      id: "i2DisOutlets",
      value: iI2DisOutlets,
      setValue: setII2DisOutlets,
      errorMessage: eI2DisOutlets,
      setErrorMessage: setEI2DisOutlets,
      backup: IMBKUP.bI2DisOutlets,
      label: q.tI2DisOutlets.label,
    },
    {
      id: "i2TnkCap2",
      value: iI2TnkCap2,
      setValue: setII2TnkCap2,
      errorMessage: eI2TnkCap2,
      setErrorMessage: setEI2TnkCap2,
      backup: IMBKUP.bI2TnkCap2,
      label: q.tI2TnkCap2.label,
    },
    {
      id: "i2TnkCap2Unit",
      value: iI2TnkCap2Unit,
      setValue: setII2TnkCap2Unit,
      errorMessage: eI2TnkCap2Unit,
      setErrorMessage: setEI2TnkCap2Unit,
      backup: IMBKUP.bI2TnkCap2Unit,
      label: q.tI2TnkCap2.label + " " + q.tI2TnkCap2Unit.label,
    },
    {
      id: "i2TpPlntr",
      value: iI2TpPlntr,
      setValue: setII2TpPlntr,
      errorMessage: eI2TpPlntr,
      setErrorMessage: setEI2TpPlntr,
      backup: IMBKUP.bI2TpPlntr,
      label: q.tI2TpPlntr.label,
    },
    {
      id: "i2NoRows2",
      value: iI2NoRows2,
      setValue: setII2NoRows2,
      errorMessage: eI2NoRows2,
      setErrorMessage: setEI2NoRows2,
      backup: IMBKUP.bI2NoRows2,
      label: q.tI2NoRows.label,
    },
    {
      id: "i2TpCnGrbAttch",
      value: iI2TpCnGrbAttch,
      setValue: setII2TpCnGrbAttch,
      errorMessage: eI2TpCnGrbAttch,
      setErrorMessage: setEI2TpCnGrbAttch,
      backup: IMBKUP.bI2TpCnGrbAttch,
      label: q.tI2TpCnGrbAttch.label,
    },
    {
      id: "i3Prim",
      value: iI3Prim,
      setValue: setII3Prim,
      errorMessage: eI3Prim,
      setErrorMessage: setEI3Prim,
      backup: IMBKUP.bI3Prim,
      label: q.tI3Prim.label,
    },
    {
      id: "i3Sec",
      value: iI3Sec,
      setValue: setII3Sec,
      errorMessage: eI3Sec,
      setErrorMessage: setEI3Sec,
      backup: IMBKUP.bI3Sec,
      label: q.tI3Sec.label,
    },
    {
      id: "i3Ter",
      value: iI3Ter,
      setValue: setII3Ter,
      errorMessage: eI3Ter,
      setErrorMessage: setEI3Ter,
      backup: IMBKUP.bI3Ter,
      label: q.tI3Ter.label,
    },
    {
      id: "i4Usage",
      value: iI4Usage,
      setValue: setII4Usage,
      errorMessage: eI4Usage,
      setErrorMessage: setEI4Usage,
      backup: IMBKUP.bI4Usage,
      label: q.tI4Usage.label,
    },
    {
      id: "i4Mode",
      value: iI4Mode,
      setValue: setII4Mode,
      errorMessage: eI4Mode,
      setErrorMessage: setEI4Mode,
      backup: IMBKUP.bI4Mode,
      label: q.tI4Mode.label,
    },
    {
      id: "i4Agency",
      value: iI4Agency,
      setValue: setII4Agency,
      errorMessage: eI4Agency,
      setErrorMessage: setEI4Agency,
      backup: IMBKUP.bI4Agency,
      label: q.tI4Agency.label,
    },
    {
      id: "i4YrAcqrd",
      value: iI4YrAcqrd,
      setValue: setII4YrAcqrd,
      errorMessage: eI4YrAcqrd,
      setErrorMessage: setEI4YrAcqrd,
      backup: IMBKUP.bI4YrAcqrd,
      label: q.tI4YrAcqrd.label,
    },
    {
      id: "i4ConAcqrd",
      value: iI4ConAcqrd,
      setValue: setII4ConAcqrd,
      errorMessage: eI4ConAcqrd,
      setErrorMessage: setEI4ConAcqrd,
      backup: IMBKUP.bI4ConAcqrd,
      label: q.tI4ConAcqrd.label,
    },
    {
      id: "i4PrsntCon",
      value: iI4PrsntCon,
      setValue: setII4PrsntCon,
      errorMessage: eI4PrsntCon,
      setErrorMessage: setEI4PrsntCon,
      backup: IMBKUP.bI4PrsntCon,
      label: q.tI4PrsntCon.label,
    },
    {
      id: "i4YrUnused",
      value: iI4YrUnused,
      setValue: setII4YrUnused,
      errorMessage: eI4YrUnused,
      setErrorMessage: setEI4YrUnused,
      backup: IMBKUP.bI4YrUnused,
      label: q.tI4YrUnused.label,
    },
    {
      id: "i4RsnUnused",
      value: iI4RsnUnused,
      setValue: setII4RsnUnused,
      errorMessage: eI4RsnUnused,
      setErrorMessage: setEI4RsnUnused,
      backup: IMBKUP.bI4RsnUnused,
      label: q.tI4Unused,
    },
    {
      id: "i4UuOthers",
      value: iI4UuOthers,
      setValue: setII4UuOthers,
      errorMessage: eI4UuOthers,
      setErrorMessage: setEI4UuOthers,
      backup: IMBKUP.bI4UuOthers,
      label: q.tI4Unused + " " + q.tI4UuOthers.label,
    },
    {
      id: "i4YrUnserv",
      value: iI4YrUnserv,
      setValue: setII4YrUnserv,
      errorMessage: eI4YrUnserv,
      setErrorMessage: setEI4YrUnserv,
      backup: IMBKUP.bI4YrUnserv,
      label: q.tI4YrUnserv.label,
    },
    {
      id: "i4RsnUnserv",
      value: iI4RsnUnserv,
      setValue: setII4RsnUnserv,
      errorMessage: eI4RsnUnserv,
      setErrorMessage: setEI4RsnUnserv,
      backup: IMBKUP.bI4RsnUnserv,
      label: q.tI4Unserv,
    },
    {
      id: "i4UsOthers",
      value: iI4UsOthers,
      setValue: setII4UsOthers,
      errorMessage: eI4UsOthers,
      setErrorMessage: setEI4UsOthers,
      backup: IMBKUP.bI4UsOthers,
      label: q.tI4Unserv + " " + q.tI4UsOthers.label,
    },
    {
      id: "i4WithTractor",
      value: iI4WithTractor,
      setValue: setII4WithTractor,
      errorMessage: eI4WithTractor,
      setErrorMessage: setEI4WithTractor,
      backup: IMBKUP.bI4WithTractor,
      label: q.tI4WithTractor.label,
    },
    {
      id: "i4SvcRtHa",
      value: iI4SvcRtHa,
      setValue: setII4SvcRtHa,
      errorMessage: eI4SvcRtHa,
      setErrorMessage: setEI4SvcRtHa,
      backup: IMBKUP.bI4SvcRtHa,
      label: q.tI4SvcRtHa.label,
    },
    {
      id: "i4SvcRtHaNon",
      value: iI4SvcRtHaNon,
      setValue: setII4SvcRtHaNon,
      errorMessage: eI4SvcRtHaNon,
      setErrorMessage: setEI4SvcRtHaNon,
      backup: IMBKUP.bI4SvcRtHaNon,
      label: q.tI4SvcRtHaNon.label,
    },
    {
      id: "i4SameOwn",
      value: iI4SameOwn,
      setValue: setII4SameOwn,
      errorMessage: eI4SameOwn,
      setErrorMessage: setEI4SameOwn,
      backup: IMBKUP.bI4SameOwn,
      label: q.tI4SameOwn[0].label,
    },
    {
      id: "i4BusCont1",
      value: iI4BusCont1,
      setValue: setII4BusCont1,
      errorMessage: eI4BusCont1,
      setErrorMessage: setEI4BusCont1,
      backup: IMBKUP.bI4BusCont1,
      label: q.tI4BusCont1.label,
    },
    {
      id: "i4BusCont2",
      value: iI4BusCont2,
      setValue: setII4BusCont2,
      errorMessage: eI4BusCont2,
      setErrorMessage: setEI4BusCont2,
      backup: IMBKUP.bI4BusCont2,
      label: q.tI4BusCont2.label,
    },
    {
      id: "i4Consent",
      value: iI4Consent,
      setValue: setII4Consent,
      errorMessage: eI4Consent,
      setErrorMessage: setEI4Consent,
      backup: IMBKUP.bI4Consent,
      label: q.tI4Consent[0].label,
    },
    {
      id: "i4SrvcLoc1P",
      value: iI4SrvcLoc1P,
      setValue: setII4SrvcLoc1P,
      errorMessage: eI4SrvcLoc1P,
      setErrorMessage: setEI4SrvcLoc1P,
      backup: IMBKUP.bI4SrvcLoc1P,
      label: q.tI4Service,
    },
    {
      id: "i4SrvcLoc1M",
      value: iI4SrvcLoc1M,
      setValue: setII4SrvcLoc1M,
      errorMessage: eI4SrvcLoc1M,
      setErrorMessage: setEI4SrvcLoc1M,
      backup: IMBKUP.bI4SrvcLoc1M,
      label: q.tI4Service,
    },
    {
      id: "i4SrvcLoc2P",
      value: iI4SrvcLoc2P,
      setValue: setII4SrvcLoc2P,
      errorMessage: eI4SrvcLoc2P,
      setErrorMessage: setEI4SrvcLoc2P,
      backup: IMBKUP.bI4SrvcLoc2P,
      label: q.tI4Service,
    },
    {
      id: "i4SrvcLoc2M",
      value: iI4SrvcLoc2M,
      setValue: setII4SrvcLoc2M,
      errorMessage: eI4SrvcLoc2M,
      setErrorMessage: setEI4SrvcLoc2M,
      backup: IMBKUP.bI4SrvcLoc2M,
      label: q.tI4Service,
    },
    {
      id: "i4SrvcLoc3P",
      value: iI4SrvcLoc3P,
      setValue: setII4SrvcLoc3P,
      errorMessage: eI4SrvcLoc3P,
      setErrorMessage: setEI4SrvcLoc3P,
      backup: IMBKUP.bI4SrvcLoc3P,
      label: q.tI4Service,
    },
    {
      id: "i4SrvcLoc3M",
      value: iI4SrvcLoc3M,
      setValue: setII4SrvcLoc3M,
      errorMessage: eI4SrvcLoc3M,
      setErrorMessage: setEI4SrvcLoc3M,
      backup: IMBKUP.bI4SrvcLoc3M,
      label: q.tI4Service,
    },
    {
      id: "i5TmCom1Ha",
      value: iI5TmCom1Ha,
      setValue: setII5TmCom1Ha,
      errorMessage: eI5TmCom1Ha,
      setErrorMessage: setEI5TmCom1Ha,
      backup: IMBKUP.bI5TmCom1Ha,
      label: q.tI5TmCom1Ha.label,
    },
    {
      id: "i5NoPasses",
      value: iI5NoPasses,
      setValue: setII5NoPasses,
      errorMessage: eI5NoPasses,
      setErrorMessage: setEI5NoPasses,
      backup: IMBKUP.bI5NoPasses,
      label: q.tI5NoPasses.label,
    },
    {
      id: "i5GrnFrtWt",
      value: iI5GrnFrtWt,
      setValue: setII5GrnFrtWt,
      errorMessage: eI5GrnFrtWt,
      setErrorMessage: setEI5GrnFrtWt,
      backup: IMBKUP.bI5GrnFrtWt,
      label: q.tI5GrnFrtWt.label,
    },
    {
      id: "i5GrnFrtWtUnit",
      value: iI5GrnFrtWtUnit,
      setValue: setII5GrnFrtWtUnit,
      errorMessage: eI5GrnFrtWtUnit,
      setErrorMessage: setEI5GrnFrtWtUnit,
      backup: IMBKUP.bI5GrnFrtWtUnit,
      label: q.tI5GrnFrtWt.label + " " + q.tI5GrnFrtWtUnit.label,
    },
    {
      id: "i5FrtAppEffA",
      value: iI5FrtAppEffA,
      setValue: setII5FrtAppEffA,
      errorMessage: eI5FrtAppEffA,
      setErrorMessage: setEI5FrtAppEffA,
      backup: IMBKUP.bI5FrtAppEffA,
      label: q.tI5FrtAppEffA.label,
    },
    {
      id: "i5FrtDelRate",
      value: iI5FrtDelRate,
      setValue: setII5FrtDelRate,
      errorMessage: eI5FrtDelRate,
      setErrorMessage: setEI5FrtDelRate,
      backup: IMBKUP.bI5FrtDelRate,
      label: q.tI5FrtDelRate.label,
    },
    {
      id: "i5SgrYld",
      value: iI5SgrYld,
      setValue: setII5SgrYld,
      errorMessage: eI5SgrYld,
      setErrorMessage: setEI5SgrYld,
      backup: IMBKUP.bI5SgrYld,
      label: q.tI5SgrYld.label,
    },
    {
      id: "i5LdCap",
      value: iI5LdCap,
      setValue: setII5LdCap,
      errorMessage: eI5LdCap,
      setErrorMessage: setEI5LdCap,
      backup: IMBKUP.bI5LdCap,
      label: q.tI5LdCap.label,
    },
    {
      id: "i5LdCapUnit",
      value: iI5LdCapUnit,
      setValue: setII5LdCapUnit,
      errorMessage: eI5LdCapUnit,
      setErrorMessage: setEI5LdCapUnit,
      backup: IMBKUP.bI5LdCapUnit,
      label: q.tI5LdCap.label + " " + q.tI5LdCapUnit.label,
    },
    {
      id: "i5NoBrkdwn",
      value: iI5NoBrkdwn,
      setValue: setII5NoBrkdwn,
      errorMessage: eI5NoBrkdwn,
      setErrorMessage: setEI5NoBrkdwn,
      backup: IMBKUP.bI5NoBrkdwn,
      label: q.tI5NoBrkdwn.label,
    },
    {
      id: "i5CmnBrkdwn",
      value: iI5CmnBrkdwn,
      setValue: setII5CmnBrkdwn,
      errorMessage: eI5CmnBrkdwn,
      setErrorMessage: setEI5CmnBrkdwn,
      backup: IMBKUP.bI5CmnBrkdwn,
      label: q.tI5CmnBrkdwn.label,
    },
    {
      id: "i5MntcDone",
      value: iI5MntcDone,
      setValue: setII5MntcDone,
      errorMessage: eI5MntcDone,
      setErrorMessage: setEI5MntcDone,
      backup: IMBKUP.bI5MntcDone,
      label: q.tI5MntcDone.label,
    },
    {
      id: "i6Notes",
      value: iI6Notes,
      setValue: setII6Notes,
      errorMessage: eI6Notes,
      setErrorMessage: setEI6Notes,
      backup: IMBKUP.bI6Notes,
      label: q.tI6Notes.label,
    },
  ];

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const i1Addr: Address = CreateNewAddress();
  const i4Addr1: Address = CreateNewAddress();
  const i4Addr2: Address = CreateNewAddress();
  const i4Addr3: Address = CreateNewAddress();

  // Camera ////////////////////////////////////////////////////////////////////

  const [camUser, setCamUser] = useState<CameraUsers>();

  enum CameraUsers {
    QRCode,
    FrontView,
    SideView,
    SpecView,
  }

  const [qrDevice, setQRDevice] = useState<any>("");
  const [qrVideos, setQRVideos] = useState([]);
  const [qrCameras, setQRCameras] = useState<
    { label: string; value: string }[]
  >([]);

  const [qrReader, setQRReader] = useState(new BrowserQRCodeReader());

  useEffect(() => {
    console.log("I2");
    setQRCameras(
      qrVideos.map((element: any) => ({
        label: element.label,
        value: element.deviceId,
      }))
    );
  }, [qrVideos]);

  function resetQR() {
    qrReader.reset();
    setII1QRCode("");
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function stopQR() {
    qrReader.reset();
    setQRReader(new BrowserQRCodeReader());
  }

  function againQR() {
    qrReader
      .getVideoInputDevices()
      .then((qrVideo) => {
        setupDevices(qrVideo);
      })
      .catch((err) => {
        console.error(err);
      });
    decodeOnce(qrReader, qrDevice);
  }

  function setupDevices(videos: any) {
    setQRDevice(videos[0].deviceId);
    if (videos.length >= 1) setQRVideos(videos);
  }

  function decodeOnce(codeReader: any, selectedDeviceId: any) {
    codeReader
      .decodeFromInputVideoDevice(selectedDeviceId, "impvideo")
      .then((result: any) => {
        let res = result!.getText();
        res = res.substring(res.lastIndexOf("/") + 1);
        if (
          res.charAt(6) === "I" &&
          res.length === 12 &&
          res.charAt(0) === "R"
        ) {
          setII1QRCode(res);
        } else {
          AVPopup({
            title: pop.qrError.title,
            text: pop.qrError.message,
            denyButtonText: pop.qrError.button,
          });
          setTimeout(() => {
            resetQR();
            againQR();
          }, 5000);
        }
      })
      .catch((err: any) => {
        if (err.name === "NotFoundException") {
          // Video stream has ended before any code could be detected.
        } else console.error(err);
      });
  }

  useEffect(() => {
    console.log("I3");
    if (iI1QRCode.length > 0) stopQR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iI1QRCode]);

  // Geolocation ///////////////////////////////////////////////////////////////

  var gLat: any, gLong: any, gAcc: any;
  const str = (n: any) => String(n);
  const isNum = (n: any) => !isNaN(n);
  const num = (n: any) => Number(n);

  function loadImplementMap() {
    AVPopup({
      icon: "info",
      title: pop.geoloc.init.title,
      text: pop.geoloc.init.message,
      confirmButtonText: pop.geoloc.init.button,
    });
    var map = L.map(ID.GeoLocationPaneMap).setView([14.16255, 121.248497], 6);
    // var lyr = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    var lyr = "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
    var osm = L.tileLayer(lyr);
    osm.addTo(map);

    var refreshMap: any;
    if (!navigator.geolocation) {
      AVPopup({
        icon: "error",
        title: pop.geoloc.initError.title,
        text: pop.geoloc.initError.message,
        confirmButtonText: pop.geoloc.initError.button,
      });
    } else {
      refreshMap = setInterval(() => {
        if (isNum(gLat) && isNum(gLong) && isNum(gAcc)) {
          setII1Lat(str(gLat));
          setII1Lng(str(gLong));
          setII1Acc(str(gAcc));
        }

        var dev = AppSettings.SERVER === AppSettings.DEVSERVER;
        var locValid = isNum(gLat) && isNum(gLong) && isNum(gAcc);
        var accValid = gAcc <= AppSettings.MIN_ACCURACY;

        if (locValid && (accValid || dev)) {
          clearInterval(refreshMap);
          AVPopup({
            icon: "success",
            title: pop.geoloc.success.title,
            text:
              pop.geoloc.success.lat +
              gLat +
              pop.geoloc.success.lng +
              gLong +
              pop.geoloc.success.acc +
              gAcc,
            confirmButtonText: pop.geoloc.success.button,
          }).then((res) => {
            if (res.isConfirmed) {
              setII1Acc(str(gAcc));
              setII1Lat(str(gLat));
              setII1Lng(str(gLong));
            }
          });;
          setII1Lat(str(gLat));
          setII1Lng(str(gLong));
          setII1Acc(str(gAcc));
        } else {
          navigator.geolocation.getCurrentPosition(getPosition);
        }
      }, 3000);
    }

    var marker: any, circle: any;
    function getPosition(position: any) {
      gLat = position.coords.latitude;
      gLong = position.coords.longitude;
      gAcc = position.coords.accuracy / 3;
      if (marker) map.removeLayer(marker);
      if (circle) map.removeLayer(circle);

      var machineIcon = L.icon({
        iconUrl: iImplement,
        iconSize: [32, 32],
        popupAnchor: [0, -16],
      });

      marker = L.marker([gLat, gLong], { icon: machineIcon }).addTo(map);
      circle = L.circle([gLat, gLong], { radius: gAcc });
      var featureGroup = L.featureGroup([circle]).addTo(map);
      map.fitBounds(featureGroup.getBounds());
    }
  }

  // Implement type based visibility ///////////////////////////////////////////

  const [, setVTypeGrp] = useState(0);
  const [v01, setV01] = useState(false);
  const [v02, setV02] = useState(false);
  const [v03, setV03] = useState(false);
  const [v04, setV04] = useState(false);
  const [v05, setV05] = useState(false);
  const [v06, setV06] = useState(false);
  const [v07, setV07] = useState(false);
  const [v08, setV08] = useState(false);
  const [v09, setV09] = useState(false);
  const [v10, setV10] = useState(false);
  const [v11, setV11] = useState(false);

  function hideSpecsCard() {
    setV01(false);
    setV02(false);
    setV03(false);
    setV04(false);
    setV05(false);
    setV06(false);
    setV07(false);
    setV08(false);
    setV09(false);
    setV10(false);
    setV11(false);
  }

  function oICI2Type(e: any, val: any) {
    if (val === tMolBrdPlow || val === tSubsoiler || val === tChiselPlow) {
      setVTypeGrp(1);
      hideSpecsCard();
      setV01(true);
    } else if (val === tDiscPlow || val === tDiscHarrow) {
      setVTypeGrp(2);
      hideSpecsCard();
      setV02(true);
    } else if (
      val === tFurrower ||
      val === tItrRowCltvtr ||
      val === tCutAwayCltvtr ||
      val === tTrashInc ||
      val === tDitcher
    ) {
      setVTypeGrp(3);
      hideSpecsCard();
      setV03(true);
    } else if (
      val === tPowerHarrow ||
      val === tSpkTthHarrow ||
      val === tFldCltvtr ||
      val === tRtnManager ||
      val === tTrashRake ||
      val === tRotTiller ||
      val === tMulcher ||
      val === tDozer
    ) {
      setVTypeGrp(4);
      hideSpecsCard();
      setV04(true);
    } else if (val === tTrailer || val === tExcavator) {
      setVTypeGrp(5);
      hideSpecsCard();
      setV05(true);
    } else if (val === tWeeder) {
      setVTypeGrp(6);
      hideSpecsCard();
      setV06(true);
    } else if (val === tBoomSprayer) {
      setVTypeGrp(7);
      hideSpecsCard();
      setV07(true);
    } else if (val === tGranFertAppl || val === tFertApplCutAway) {
      setVTypeGrp(8);
      hideSpecsCard();
      setV08(true);
    } else if (val === tMechPlanter) {
      setVTypeGrp(9);
      hideSpecsCard();
      setV09(true);
    } else if (val === tMechHarvester) {
      setVTypeGrp(10);
      hideSpecsCard();
      setV10(true);
    } else if (val === tCaneGrabLoader) {
      setVTypeGrp(11);
      hideSpecsCard();
      setV11(true);
    } else {
      setVTypeGrp(0);
      hideSpecsCard();
    }
  }

  // Load Profiles and Machines ////////////////////////////////////////////////

  const [oProfiles, setOProfiles] = useState<{ label: string }[]>([]);
  const [oMachines, setOMachines] = useState<{ label: string }[]>([]);

  useEffect(() => {
    console.log("I4.2");
    // Load Profiles ///////////////////////////////////////////////////////////
    const profiles = fxdRead(fxdProfile.profiles);
    if (profiles) {
      const profs = JSON.parse(profiles);
      var oUsernames = [];
      for (let i = 0; i < profs.length; i++) {
        oUsernames.push({ label: profs[i].sP1Name });
      }
      setOProfiles(oUsernames);
    } else setOProfiles([{ label: pop.noProfile.title }]);
  }, []);

  useEffect(() => {
    console.log("I4.1");
    
    // Load Machines ///////////////////////////////////////////////////////////
    var keys = Object.keys(localStorage);
    var enlistedMachinesFromThisProfile = (name: string) =>
      keys.filter((key) => {
        const item = fxdRead(key);
        if (item) {
          let parsedItem;
          try {
            parsedItem = JSON.parse(item);
          } catch (e) {
            parsedItem = "";
          }
          return (
            key.charAt(0) === "R" &&
            key.charAt(6) === "M" &&
            parsedItem.sM1ResName?.label === name
          );
        }
        return false;
      });

    if (enlistedMachinesFromThisProfile(safeRead(iI1ResName)).length === 0) {
      setOMachines([{ label: pop.noMachine.title }]);
    } else {
      setOMachines(
        enlistedMachinesFromThisProfile(safeRead(iI1ResName)).map((key) => {
          const item = fxdRead(key);
          if (item) {
            let parsedItem;
            try {
              parsedItem = JSON.parse(item);
            } catch (e) {
              parsedItem = "";
            }
            return {
              label:
                parsedItem.sM1QRCode +
                " | " +
                parsedItem.sM1Type.label +
                " | " +
                parsedItem.sM1ResName.label,
            };
          }
          return { label: pop.noMachine.title };
        })
      );
    }
  }, [iI1ResName]);

  // Dropdown Logic ////////////////////////////////////////////////////////////

  useEffect(() => {
    console.log("I5");
    var options = q.tI5CmnBrkdwn.options;
    const lastSelected = Array.isArray(iI5CmnBrkdwn)
      ? iI5CmnBrkdwn[iI5CmnBrkdwn.length - 1]
      : null;

    if (lastSelected === options[4]) {
      setII5CmnBrkdwn([options[0], options[1], options[2], options[3]]);
    } else if (lastSelected === options[5]) {
      setII5CmnBrkdwn([options[5]]);
    } else if (
      Array.isArray(iI5CmnBrkdwn) &&
      (lastSelected !== options[4] || lastSelected !== options[5])
    ) {
      var newVal = iI5CmnBrkdwn.filter(
        (o) => o !== options[4] && o !== options[5]
      );
      setII5CmnBrkdwn(newVal);
    }
  }, [iI5CmnBrkdwn]);

  useEffect(() => {
    console.log("I6");
    var options = q.tI5MntcDone.options;
    const lastSelected = Array.isArray(iI5MntcDone)
      ? iI5MntcDone[iI5MntcDone.length - 1]
      : null;

    if (lastSelected === options[4]) {
      setII5MntcDone([options[0], options[1], options[2], options[3]]);
    } else if (lastSelected === options[5]) {
      setII5MntcDone([options[5]]);
    } else if (
      Array.isArray(iI5MntcDone) &&
      (lastSelected !== options[4] || lastSelected !== options[5])
    ) {
      var newVal = iI5MntcDone.filter(
        (o) => o !== options[4] && o !== options[5]
      );
      setII5MntcDone(newVal);
    }
  }, [iI5MntcDone]);

  useEffect(() => {
    console.log("I7");
    if (iI4SameOwn.length > 0 && iI4BusCont1 === "") {
      if (!iI1ResName) {
        AVPopup({
          type: "error",
          title: pop.noProfile.title,
          text: pop.noProfile.message,
          confirmButtonText: pop.noProfile.button,
        });
        setII4SameOwn("");
      } else {
        var profiles = JSON.parse(fxdRead(fxdProfile.profiles) || "");
        var name, cont1: any, cont2: any;
        if (Array.isArray(profiles)) {
          var prof = profiles.find((o) => o.sP1Name === safeRead(iI1ResName));
          if (prof) {
            name = prof.sP1Name;
            cont1 = prof.sP1Cont1;
            cont2 = prof.sP1Cont2;
          }
        }

        var message = `${pop.loadContact.message1} ${cont1}`;
        if (cont2) message += `\n${pop.loadContact.message2} ${cont2}`;
        message += `\n${pop.loadContact.message3} ${name}`;
        message += `${pop.loadContact.message4}`;

        AVPopup({
          type: "info",
          title: pop.loadContact.title,
          text: message,
          confirmButtonText: pop.loadContact.button,
          showDenyButton: true,
          denyButtonText: pop.loadContact.deny,
        }).then((result) => {
          if (result.isConfirmed) {
            setII4BusCont1(cont1);
            setII4BusCont2(cont2);
          } else if (result.isDenied) {
            setII4SameOwn("");
          }
        });
      }
    } else if (iI4SameOwn.length === 0 && iI4BusCont1 !== "") {
      setII4BusCont1("");
      setII4BusCont2("");
    }
  }, [iI1ResName, iI4BusCont1, iI4SameOwn.length]);

  // Hide and Show /////////////////////////////////////////////////////////////

  const hI1QRCodeVideo =
    camUser === CameraUsers.QRCode && String(iI1QRCode).length === 0;
  const hI1FrontVw = camUser === CameraUsers.FrontView;
  const hI1SideVw = camUser === CameraUsers.SideView;
  const hI1SpecVw = camUser === CameraUsers.SpecView;
  const hI1Coor = iI1Acc.length > 0;
  const hI2Title = safeRead(iI2Type).length > 0;
  const hI3Title = safeRead(iI2Type).length > 0;
  const hI3Sec = safeRead(iI3Prim).length > 0;
  const hI3Ter = safeRead(iI3Sec).length > 0;
  const hI4ImpUsage = safeRead(iI2Type).length > 0;
  const hI4Agency =
    safeRead(iI4Mode) === safeRead(q.tI4Mode.options[2]) ||
    safeRead(iI4Mode) === safeRead(q.tI4Mode.options[3]);
  const hI4Unused = safeRead(iI4PrsntCon) === q.tI4PrsntCon.options[1].label;
  const hI4Unserv = safeRead(iI4PrsntCon) === q.tI4PrsntCon.options[2].label;
  const hI4RntDetails =
    (safeRead(iI4Usage) === q.tI4Usage.options[1].label ||
      safeRead(iI4Usage) === q.tI4Usage.options[2].label) &&
    safeRead(iI2Type).length > 0;
  const hI4SvcRtHa =
    safeRead(iI4WithTractor) === q.tI4WithTractor.options[1].label;
  const hI4SvcRtHaNon = safeRead(iI4Usage) === q.tI4Usage.options[2].label;
  const hI4SrvcLoc2P = safeRead(iI4SrvcLoc1M).length > 0;
  const hI4SrvcLoc3P = safeRead(iI4SrvcLoc2M).length > 0;
  const hI5Title = safeRead(iI2Type).length > 0;

  // Disabled //////////////////////////////////////////////////////////////////

  const dI1QRCode = () => {
    var noUser = !camUser;
    var qrIsUser = camUser === CameraUsers.QRCode;
    return !(noUser || qrIsUser);
  };

  const dI1FrontVw = () => {
    var noUser = !camUser;
    var frontIsUser = camUser === CameraUsers.FrontView;
    return !(noUser || frontIsUser);
  };

  const dI1SideVw = () => {
    var noUser = !camUser;
    var sideIsUser = camUser === CameraUsers.SideView;
    return !(noUser || sideIsUser);
  };

  const dI1SpecVw = () => {
    var noUser = !camUser;
    var specIsUser = camUser === CameraUsers.SpecView;
    return !(noUser || specIsUser);
  };

  // Special Logic /////////////////////////////////////////////////////////////

  const sI1QRCode = () => {
    if (camUser === CameraUsers.QRCode) {
      setCamUser(undefined);
      stopQR();
    } else {
      setCamUser(CameraUsers.QRCode);
      resetQR();
      againQR();
    }
  };

  const sI1QRCodeVideo = (e: any, val: any) => {
    resetQR();
    let v = qrCameras.find((o) => o.label === val)?.value;
    if (v !== undefined) {
      setQRDevice(v);
      resetQR();
      decodeOnce(qrReader, v);
    }
  };

  const sI1QRCodeVideoVal =
    qrCameras.find((o) => o.value === qrDevice)?.label || "";

  const sI1FrontVw = () => {
    if (camUser === CameraUsers.FrontView) {
      setCamUser(undefined);
    } else setCamUser(CameraUsers.FrontView);
  };

  const sI1SideVw = () => {
    if (camUser === CameraUsers.SideView) {
      setCamUser(undefined);
    } else setCamUser(CameraUsers.SideView);
  };

  const sI1SpecVw = () => {
    if (camUser === CameraUsers.SpecView) {
      setCamUser(undefined);
    } else setCamUser(CameraUsers.SpecView);
  };

  const sI1Acc = () => {
    var acc = num(iI1Acc);
    return acc === undefined
      ? "error"
      : isNum(acc) && acc <= 5
      ? "success"
      : isNum(acc) && acc <= 8
      ? "warning"
      : "error";
  };

  const sI1AccVal = () => {
    var acc = num(iI1Acc);
    return acc === undefined
      ? pop.accuracy.init
      : acc <= 5
      ? pop.accuracy.high
      : acc <= 8
      ? pop.accuracy.low
      : pop.accuracy.search;
  };

  // ///////////////////////////////////////////////////////////////////////////
  // LAYOUT ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  return (
    requireLoadProperly(AvzRoute.implement) ?? (
      <VBar class="main-bg-form form-design">
        <VBar class="v-bar">
          <TitleBar category={q.category} />
          <Card title={q.tI1Title} titleStyle="title">
            <AVDropdown
              id="i1ResName"
              text={q.tI1ResName}
              value={[iI1ResName, setII1ResName]}
              validation={eI1ResName}
              backup={IMBKUP.bI1ResName}
              options={oProfiles}
            />
            <AVDropdown
              id="i1Machine"
              text={q.tI1Machine}
              value={[iI1Machine, setII1Machine]}
              validation={eI1Machine}
              backup={IMBKUP.bI1Machine}
              options={oMachines}
            />
            <HBar>
              <AVButton
                position="left"
                icon={<QrCode2Icon />}
                disabled={dI1QRCode()}
                onClick={sI1QRCode}
                height={40}
              ></AVButton>
              <AVField
                id="i1QRCode"
                text={q.tI1QRCode}
                value={[iI1QRCode, setII1QRCode]}
                validation={eI1QRCode}
                backup={IMBKUP.bI1QRCode}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hI1QRCodeVideo} class="spacer">
              <AVDropdown
                text={q.tI1QRCodeVideo}
                value={[sI1QRCodeVideoVal, null]}
                validation={null}
                backup={AVOVERRIDE}
                options={qrCameras}
                onInputChange={(e, val: any) => sI1QRCodeVideo}
              />
              <video id="impvideo" />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<CameraAltIcon />}
                disabled={dI1FrontVw()}
                onClick={sI1FrontVw}
                height={40}
              ></AVButton>
              <AVField
                id="i1FrontVw"
                text={q.tI1FrontVw}
                value={[iI1FrontVw, setII1FrontVw]}
                validation={eI1FrontVw}
                backup={IMBKUP.bI1FrontVw}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hI1FrontVw} class="spacer">
              <WebCam setImage={setII1FrontVw} />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<CameraAltIcon />}
                disabled={dI1SideVw()}
                onClick={sI1SideVw}
                height={40}
              ></AVButton>
              <AVField
                id="i1SideVw"
                text={q.tI1SideVw}
                value={[iI1SideVw, setII1SideVw]}
                validation={eI1SideVw}
                backup={IMBKUP.bI1SideVw}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hI1SideVw} class="spacer">
              <WebCam setImage={setII1SideVw} />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<CameraAltIcon />}
                disabled={dI1SpecVw()}
                onClick={sI1SpecVw}
                height={40}
              ></AVButton>
              <AVField
                id="i1SpecVw"
                text={q.tI1SpecVw}
                value={[iI1SpecVw, setII1SpecVw]}
                validation={eI1SpecVw}
                backup={IMBKUP.bI1SpecVw}
                disabled
                position="right"
              />
            </HBar>
            <VBar force in={hI1SpecVw} class="spacer">
              <WebCam setImage={setII1SpecVw} />
            </VBar>
            <HBar>
              <AVButton
                position="left"
                icon={<MyLocationIcon />}
                onClick={() => loadImplementMap()}
                height={40}
              ></AVButton>
              <AVField
                id="i1Acc"
                text={q.tI1Acc}
                value={[iI1Acc, setII1Acc]}
                validation={eI1Acc}
                backup={IMBKUP.bI1Acc}
                disabled
                position="right"
              />
            </HBar>
            <VBar in={hI1Coor} class="spacer">
              <AVField
                id="i1Lat"
                text={q.tI1Lat}
                value={[iI1Lat, setII1Lat]}
                validation={eI1Lat}
                backup={IMBKUP.bI1Lat}
                disabled
              />
              <AVField
                id="i1Lng"
                text={q.tI1Lng}
                value={[iI1Lng, setII1Lng]}
                validation={eI1Lng}
                backup={IMBKUP.bI1Lng}
                disabled
              />
              <AVAlert severity={sI1Acc()} sx={{ width: "100%" }}>
                {sI1AccVal()}
              </AVAlert>
            </VBar>
            <GeoLocationPane />
            <AVDropdown
              id="i1Loc"
              text={q.tI1Loc}
              value={[iI1Loc, setII1Loc]}
              validation={eI1Loc}
              backup={IMBKUP.bI1Loc}
              options={q.tI1Loc.options}
            />
            <hr />
            <AVText text={q.tI1Garage} />
            <Province
              id="i1Prov"
              text={q.tI1Prov}
              value={[iI1Prov, setII1Prov]}
              validation={eI1Prov}
              backup={IMBKUP.bI1Prov}
              address={i1Addr}
            />
            <Municipality
              id="i1Mun"
              text={q.tI1Mun}
              value={[iI1Mun, setII1Mun]}
              validation={eI1Mun}
              backup={IMBKUP.bI1Mun}
              address={i1Addr}
            />
            <Barangay
              id="i1Bgry"
              text={q.tI1Bgry}
              value={[iI1Bgry, setII1Bgry]}
              validation={eI1Bgry}
              backup={IMBKUP.bI1Bgry}
              address={i1Addr}
            />
            <hr />
            <AVDropdown
              id="i2Type"
              text={q.tI2Type}
              value={[iI2Type, setII2Type]}
              validation={eI2Type}
              backup={IMBKUP.bI2Type}
              options={q.tI2Type.options}
              onInputChange={oICI2Type}
              group={(o) => o.type}
            />
          </Card>
          <Card in={hI2Title} title={q.tI2Title} titleStyle="title">
            <AVDropdown
              id="i2Brand"
              text={q.tI2Brand}
              value={[iI2Brand, setII2Brand]}
              validation={eI2Brand}
              backup={IMBKUP.bI2Brand}
              options={NotApplicableAsOption}
              free
            />
            <AVDropdown
              id="i2Model"
              text={q.tI2Model}
              value={[iI2Model, setII2Model]}
              validation={eI2Model}
              backup={IMBKUP.bI2Model}
              options={NotApplicableAsOption}
              free
            />
            <HBar in={v01}>
              <AVField
                id="i2NoBtm1"
                text={q.tI2NoBtm}
                value={[iI2NoBtm1, setII2NoBtm1]}
                validation={eI2NoBtm1}
                backup={IMBKUP.bI2NoBtm1}
              />
            </HBar>
            <HBar in={v02} gap={10}>
              <AVField
                id="i2NoBtm2"
                text={q.tI2NoBtm}
                value={[iI2NoBtm2, setII2NoBtm2]}
                validation={eI2NoBtm2}
                backup={IMBKUP.bI2NoBtm2}
              />
              <AVField
                id="i2SzDk"
                text={q.tI2SzDk}
                value={[iI2SzDk, setII2SzDk]}
                validation={eI2SzDk}
                backup={IMBKUP.bI2SzDk}
              />
            </HBar>
            <HBar in={v03} gap={10}>
              <AVField
                id="i2NoBtm3"
                text={q.tI2NoBtm}
                value={[iI2NoBtm3, setII2NoBtm3]}
                validation={eI2NoBtm3}
                backup={IMBKUP.bI2NoBtm3}
              />
              <AVField
                id="i2DisRow1"
                text={q.tI2DisRow}
                value={[iI2DisRow1, setII2DisRow1]}
                validation={eI2DisRow1}
                backup={IMBKUP.bI2DisRow1}
              />
            </HBar>
            <HBar in={v04 || v10}>
              <AVField
                id="i2WdCut"
                text={q.tI2WdCut}
                value={[iI2WdCut, setII2WdCut]}
                validation={eI2WdCut}
                backup={IMBKUP.bI2WdCut}
              />
            </HBar>
            <HBar in={v05}>
              <AVField
                id="i2LdCap"
                text={q.tI2LdCap}
                value={[iI2LdCap, setII2LdCap]}
                validation={eI2LdCap}
                backup={IMBKUP.bI2LdCap}
                position="left"
              />
              <AVDropdown
                id="i2LdCapUnit"
                text={q.tI2LdCapUnit}
                value={[iI2LdCapUnit, setII2LdCapUnit]}
                validation={eI2LdCapUnit}
                backup={IMBKUP.bI2LdCapUnit}
                options={q.tI2LdCapUnit.options}
                position="right"
              />
            </HBar>
            <HBar in={v06} gap={10}>
              <AVField
                id="i2NoRows1"
                text={q.tI2NoRows}
                value={[iI2NoRows1, setII2NoRows1]}
                validation={eI2NoRows1}
                backup={IMBKUP.bI2NoRows1}
              />
              <AVField
                id="i2DisRow2"
                text={q.tI2DisRow}
                value={[iI2DisRow2, setII2DisRow2]}
                validation={eI2DisRow2}
                backup={IMBKUP.bI2DisRow2}
              />
            </HBar>
            <VBar in={v07} class="spacer">
              <HBar gap={10}>
                <AVField
                  id="i2NoNzls"
                  text={q.tI2NoNzls}
                  value={[iI2NoNzls, setII2NoNzls]}
                  validation={eI2NoNzls}
                  backup={IMBKUP.bI2NoNzls}
                />
                <AVField
                  id="i2DisNzls"
                  text={q.tI2DisNzls}
                  value={[iI2DisNzls, setII2DisNzls]}
                  validation={eI2DisNzls}
                  backup={IMBKUP.bI2DisNzls}
                />
              </HBar>
              <AVField
                id="i2TnkCap1"
                text={q.tI2TnkCap}
                value={[iI2TnkCap1, setII2TnkCap1]}
                validation={eI2TnkCap1}
                backup={IMBKUP.bI2TnkCap1}
              />
            </VBar>
            <VBar in={v08} class="spacer">
              <HBar gap={10}>
                <AVField
                  id="i2NoOutlets"
                  text={q.tI2NoOutlets}
                  value={[iI2NoOutlets, setII2NoOutlets]}
                  validation={eI2NoOutlets}
                  backup={IMBKUP.bI2NoOutlets}
                />
                <AVField
                  id="i2DisOutlets"
                  text={q.tI2DisOutlets}
                  value={[iI2DisOutlets, setII2DisOutlets]}
                  validation={eI2DisOutlets}
                  backup={IMBKUP.bI2DisOutlets}
                />
              </HBar>
              <HBar>
                <AVField
                  id="i2TnkCap2"
                  text={q.tI2TnkCap2}
                  value={[iI2TnkCap2, setII2TnkCap2]}
                  validation={eI2TnkCap2}
                  backup={IMBKUP.bI2TnkCap2}
                  position="left"
                />
                <AVDropdown
                  id="i2TnkCap2Unit"
                  text={q.tI2TnkCap2Unit}
                  value={[iI2TnkCap2Unit, setII2TnkCap2Unit]}
                  validation={eI2TnkCap2Unit}
                  backup={IMBKUP.bI2TnkCap2Unit}
                  options={q.tI2TnkCap2Unit.options}
                  position="right"
                />
              </HBar>
            </VBar>
            <VBar in={v09} class="spacer">
              <AVDropdown
                id="i2TpPlntr"
                text={q.tI2TpPlntr}
                value={[iI2TpPlntr, setII2TpPlntr]}
                validation={eI2TpPlntr}
                backup={IMBKUP.bI2TpPlntr}
                options={q.tI2TpPlntr.options}
              />
              <AVField
                id="i2NoRows2"
                text={q.tI2NoRows}
                value={[iI2NoRows2, setII2NoRows2]}
                validation={eI2NoRows2}
                backup={IMBKUP.bI2NoRows2}
              />
            </VBar>
            <VBar in={v11} class="spacer">
              <AVDropdown
                id="i2TpCnGrbAttch"
                text={q.tI2TpCnGrbAttch}
                value={[iI2TpCnGrbAttch, setII2TpCnGrbAttch]}
                validation={eI2TpCnGrbAttch}
                backup={IMBKUP.bI2TpCnGrbAttch}
                options={q.tI2TpCnGrbAttch.options}
              />
            </VBar>
          </Card>
          <Card in={hI3Title} title={q.tI3Title} titleStyle="title">
            <AVDropdown
              id="i3Prim"
              text={q.tI3Prim}
              value={[iI3Prim, setII3Prim]}
              validation={eI3Prim}
              backup={IMBKUP.bI3Prim}
              options={q.tI3Options}
            />
            <HBar in={hI3Sec}>
              <AVDropdown
                id="i3Sec"
                text={q.tI3Sec}
                value={[iI3Sec, setII3Sec]}
                validation={eI3Sec}
                backup={IMBKUP.bI3Sec}
                options={q.tI3Options}
              />
            </HBar>
            <HBar in={hI3Ter}>
              <AVDropdown
                id="i3Ter"
                text={q.tI3Ter}
                value={[iI3Ter, setII3Ter]}
                validation={eI3Ter}
                backup={IMBKUP.bI3Ter}
                options={q.tI3Options}
              />
            </HBar>
          </Card>
          <Card in={hI4ImpUsage} title={q.tI4ImpUsage} titleStyle="title">
            <AVDropdown
              id="i4Usage"
              text={q.tI4Usage}
              value={[iI4Usage, setII4Usage]}
              validation={eI4Usage}
              backup={IMBKUP.bI4Usage}
              options={q.tI4Usage.options}
            />
            <AVDropdown
              id="i4Mode"
              text={q.tI4Mode}
              value={[iI4Mode, setII4Mode]}
              validation={eI4Mode}
              backup={IMBKUP.bI4Mode}
              options={q.tI4Mode.options}
            />
            <HBar in={hI4Agency}>
              <AVField
                id="i4Agency"
                text={q.tI4Agency}
                value={[iI4Agency, setII4Agency]}
                validation={eI4Agency}
                backup={IMBKUP.bI4Agency}
              />
            </HBar>
            <AVField
              id="i4YrAcqrd"
              text={q.tI4YrAcqrd}
              value={[iI4YrAcqrd, setII4YrAcqrd]}
              validation={eI4YrAcqrd}
              backup={IMBKUP.bI4YrAcqrd}
            />
            <AVDropdown
              id="i4ConAcqrd"
              text={q.tI4ConAcqrd}
              value={[iI4ConAcqrd, setII4ConAcqrd]}
              validation={eI4ConAcqrd}
              backup={IMBKUP.bI4ConAcqrd}
              options={q.tI4ConAcqrd.options}
            />
            <AVDropdown
              id="i4PrsntCon"
              text={q.tI4PrsntCon}
              value={[iI4PrsntCon, setII4PrsntCon]}
              validation={eI4PrsntCon}
              backup={IMBKUP.bI4PrsntCon}
              options={q.tI4PrsntCon.options}
            />
            <VBar in={hI4Unused} id="unused" class="spacer">
              <AVField
                id="i4YrUnused"
                text={q.tI4YrUnused}
                value={[iI4YrUnused, setII4YrUnused]}
                validation={eI4YrUnused}
                backup={IMBKUP.bI4YrUnused}
              />
              <AVText text={q.tI4Unused} />
              <AVTick
                id="i4RsnUnused"
                value={[iI4RsnUnused, setII4RsnUnused]}
                backup={IMBKUP.bI4RsnUnused}
                options={q.tI4RsnUnused}
              />
              <AVField
                id="i4UuOthers"
                text={q.tI4UuOthers}
                value={[iI4UuOthers, setII4UuOthers]}
                validation={eI4UuOthers}
                backup={IMBKUP.bI4UuOthers}
              />
            </VBar>
            <VBar in={hI4Unserv} id="unserviceable" class="spacer">
              <AVField
                id="i4YrUnserv"
                text={q.tI4YrUnserv}
                value={[iI4YrUnserv, setII4YrUnserv]}
                validation={eI4YrUnserv}
                backup={IMBKUP.bI4YrUnserv}
              />
              <AVText text={q.tI4Unserv} />
              <AVTick
                id="i4RsnUnserv"
                value={[iI4RsnUnserv, setII4RsnUnserv]}
                backup={IMBKUP.bI4RsnUnserv}
                options={q.tI4RsnUnserv}
              />
              <AVField
                id="i4UsOthers"
                text={q.tI4UsOthers}
                value={[iI4UsOthers, setII4UsOthers]}
                validation={eI4UsOthers}
                backup={IMBKUP.bI4UsOthers}
              />
            </VBar>
          </Card>
          <Card in={hI4RntDetails} title={q.tI4RntDetails} titleStyle="title">
            <AVDropdown
              id="i4WithTractor"
              text={q.tI4WithTractor}
              value={[iI4WithTractor, setII4WithTractor]}
              validation={eI4WithTractor}
              backup={IMBKUP.bI4WithTractor}
              options={q.tI4WithTractor.options}
            />
            <VBar gap={10} in={hI4SvcRtHa}>
              <AVField
                id="i4SvcRtHa"
                text={q.tI4SvcRtHa}
                value={[iI4SvcRtHa, setII4SvcRtHa]}
                validation={eI4SvcRtHa}
                backup={IMBKUP.bI4SvcRtHa}
                unitPosition="start"
              />
              <HBar in={hI4SvcRtHaNon}>
                <AVField
                  id="i4SvcRtHaNon"
                  text={q.tI4SvcRtHaNon}
                  value={[iI4SvcRtHaNon, setII4SvcRtHaNon]}
                  validation={eI4SvcRtHaNon}
                  backup={IMBKUP.bI4SvcRtHaNon}
                  unitPosition="start"
                />
              </HBar>
              <AVText text={q.tI4Title} />
              <AVTick
                id="i4SameOwn"
                value={[iI4SameOwn, setII4SameOwn]}
                backup={IMBKUP.bI4SameOwn}
                options={q.tI4SameOwn}
              />
              <HBar>
                <AVField
                  id="i4BusCont1"
                  text={q.tI4BusCont1}
                  value={[iI4BusCont1, setII4BusCont1]}
                  validation={eI4BusCont1}
                  backup={IMBKUP.bI4BusCont1}
                  width={50}
                  position="left"
                />
                <AVField
                  id="i4BusCont2"
                  text={q.tI4BusCont2}
                  value={[iI4BusCont2, setII4BusCont2]}
                  validation={eI4BusCont2}
                  backup={IMBKUP.bI4BusCont2}
                  width={50}
                  position="right"
                />
              </HBar>
              <AVTick
                id="i4Consent"
                value={[iI4Consent, setII4Consent]}
                backup={IMBKUP.bI4Consent}
                options={q.tI4Consent}
              />
              <hr />
              <AVText text={q.tI4Service} />
              <HBar gap={10}>
                <Province
                  id="i4SrvcLoc1P"
                  text={q.tI1Prov}
                  value={[iI4SrvcLoc1P, setII4SrvcLoc1P]}
                  validation={eI4SrvcLoc1P}
                  backup={IMBKUP.bI4SrvcLoc1P}
                  address={i4Addr1}
                />
                <Municipality
                  id="i4SrvcLoc1M"
                  text={q.tI1Mun}
                  value={[iI4SrvcLoc1M, setII4SrvcLoc1M]}
                  validation={eI4SrvcLoc1M}
                  backup={IMBKUP.bI4SrvcLoc1M}
                  address={i4Addr1}
                />
              </HBar>
              <HBar in={hI4SrvcLoc2P} gap={10}>
                <Province
                  id="i4SrvcLoc2P"
                  text={q.tI1Prov}
                  value={[iI4SrvcLoc2P, setII4SrvcLoc2P]}
                  validation={eI4SrvcLoc2P}
                  backup={IMBKUP.bI4SrvcLoc2P}
                  address={i4Addr2}
                />
                <Municipality
                  id="i4SrvcLoc2M"
                  text={q.tI1Mun}
                  value={[iI4SrvcLoc2M, setII4SrvcLoc2M]}
                  validation={eI4SrvcLoc2M}
                  backup={IMBKUP.bI4SrvcLoc2M}
                  address={i4Addr2}
                />
              </HBar>
              <HBar in={hI4SrvcLoc3P} gap={10}>
                <Province
                  id="i4SrvcLoc3P"
                  text={q.tI1Prov}
                  value={[iI4SrvcLoc3P, setII4SrvcLoc3P]}
                  validation={eI4SrvcLoc3P}
                  backup={IMBKUP.bI4SrvcLoc3P}
                  address={i4Addr3}
                />
                <Municipality
                  id="i4SrvcLoc3M"
                  text={q.tI1Mun}
                  value={[iI4SrvcLoc3M, setII4SrvcLoc3M]}
                  validation={eI4SrvcLoc3M}
                  backup={IMBKUP.bI4SrvcLoc3M}
                  address={i4Addr3}
                />
              </HBar>
            </VBar>
          </Card>
          <Card in={hI5Title} title={q.tI5Title} titleStyle="title">
            <AVField
              id="i5TmCom1Ha"
              text={q.tI5TmCom1Ha}
              value={[iI5TmCom1Ha, setII5TmCom1Ha]}
              validation={eI5TmCom1Ha}
              backup={IMBKUP.bI5TmCom1Ha}
            />
            <VBar
              in={v01 || v02 || v03 || v04 || v06 || v07 || v08 || v09}
              class="spacer"
            >
              <HBar in={v01 || v02 || v03 || v04 || v06}>
                <AVField
                  id="i5NoPasses"
                  text={q.tI5NoPasses}
                  value={[iI5NoPasses, setII5NoPasses]}
                  validation={eI5NoPasses}
                  backup={IMBKUP.bI5NoPasses}
                />
              </HBar>
            </VBar>
            <VBar in={v08} class="spacer">
              <HBar>
                <AVField
                  id="i5GrnFrtWt"
                  text={q.tI5GrnFrtWt}
                  value={[iI5GrnFrtWt, setII5GrnFrtWt]}
                  validation={eI5GrnFrtWt}
                  backup={IMBKUP.bI5GrnFrtWt}
                  position="left"
                />
                <AVDropdown
                  id="i5GrnFrtWtUnit"
                  text={q.tI5GrnFrtWtUnit}
                  value={[iI5GrnFrtWtUnit, setII5GrnFrtWtUnit]}
                  validation={eI5GrnFrtWtUnit}
                  backup={IMBKUP.bI5GrnFrtWtUnit}
                  options={q.tI5GrnFrtWtUnit.options}
                  position="right"
                />
              </HBar>
              <AVField
                id="i5FrtAppEffA"
                text={q.tI5FrtAppEffA}
                value={[iI5FrtAppEffA, setII5FrtAppEffA]}
                validation={eI5FrtAppEffA}
                backup={IMBKUP.bI5FrtAppEffA}
              />
              <AVField
                id="i5FrtDelRate"
                text={q.tI5FrtDelRate}
                value={[iI5FrtDelRate, setII5FrtDelRate]}
                validation={eI5FrtDelRate}
                backup={IMBKUP.bI5FrtDelRate}
              />
            </VBar>
            <VBar in={v10} class="spacer">
              <AVField
                id="i5SgrYld"
                text={q.tI5SgrYld}
                value={[iI5SgrYld, setII5SgrYld]}
                validation={eI5SgrYld}
                backup={IMBKUP.bI5SgrYld}
              />
            </VBar>
            <VBar in={v11} class="spacer">
              <HBar>
                <AVField
                  id="i5LdCap"
                  text={q.tI5LdCap}
                  value={[iI5LdCap, setII5LdCap]}
                  validation={eI5LdCap}
                  backup={IMBKUP.bI5LdCap}
                  position="left"
                />
                <AVDropdown
                  id="i5LdCapUnit"
                  text={q.tI5LdCapUnit}
                  value={[iI5LdCapUnit, setII5LdCapUnit]}
                  validation={eI5LdCapUnit}
                  backup={IMBKUP.bI5LdCapUnit}
                  options={q.tI5LdCapUnit.options}
                  position="right"
                />
              </HBar>
            </VBar>
            <AVField
              id="i5NoBrkdwn"
              text={q.tI5NoBrkdwn}
              value={[iI5NoBrkdwn, setII5NoBrkdwn]}
              validation={eI5NoBrkdwn}
              backup={IMBKUP.bI5NoBrkdwn}
            />
            <AVDropdown
              id="i5CmnBrkdwn"
              text={q.tI5CmnBrkdwn}
              value={[iI5CmnBrkdwn, setII5CmnBrkdwn]}
              validation={eI5CmnBrkdwn}
              backup={IMBKUP.bI5CmnBrkdwn}
              options={q.tI5CmnBrkdwn.options}
              multiple
            />
            <AVDropdown
              id="i5MntcDone"
              text={q.tI5MntcDone}
              value={[iI5MntcDone, setII5MntcDone]}
              validation={eI5MntcDone}
              backup={IMBKUP.bI5MntcDone}
              options={q.tI5MntcDone.options}
              free
              max={q.tI5MntcDone.options.length - 1}
            />
          </Card>
          <Card>
            <AVField
              id="i6Notes"
              text={q.tI6Notes}
              value={[iI6Notes, setII6Notes]}
              validation={eI6Notes}
              backup={IMBKUP.bI6Notes}
              rows={7}
            />
          </Card>
          <AVButton
            label={action.save}
            color="secondary"
            icon={<SaveIcon />}
            onClick={async () => {
              saveEncodedImplement();
            }}
            height={50}
          />
          <div className="btn-group" role="group">
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.encode}
                as={NavLink}
                to={AvzRoute.encode}
                end
              >
                Go to Encode
              </Nav.Link>
            </button>
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.machine}
                as={NavLink}
                to={AvzRoute.machine}
                end
              >
                Go to Machine
              </Nav.Link>
            </button>
          </div>
        </VBar>
      </VBar>
    )
  );

  async function saveEncodedImplement() {
    const Question = (value: any) => {
      return QUESTIONS.find((q) => q.id === value);
    };

    var required = [
      Question("i1ResName"),
      Question("i1QRCode"),
      Question("i1Machine"),
      Question("i1FrontVw"),
      Question("i1SideVw"),
      Question("i1SpecVw"),
      Question("i1Acc"),
      Question("i1Lat"),
      Question("i1Lng"),
      Question("i1Loc"),
      Question("i1Prov"),
      Question("i1Mun"),
      Question("i1Bgry"),
      Question("i2Type"),
    ];
    if (hI2Title) {
      required.push(Question("i2Brand"));
      required.push(Question("i2Model"));
      if (v01) {
        required.push(Question("i2NoBtm1"));
      }
      if (v02) {
        required.push(Question("i2NoBtm2"));
        required.push(Question("i2SzDk"));
      }
      if (v03) {
        required.push(Question("i2NoBtm3"));
        required.push(Question("i2DisRow1"));
      }
      if (v04 || v10) {
        required.push(Question("i2WdCut"));
      }
      if (v05) {
        required.push(Question("i2LdCap"));
        required.push(Question("i2LdCapUnit"));
      }
      if (v06) {
        required.push(Question("i2NoRows1"));
        required.push(Question("i2DisRow2"));
      }
      if (v07) {
        required.push(Question("i2NoNzls"));
        required.push(Question("i2DisNzls"));
        required.push(Question("i2TnkCap1"));
      }
      if (v08) {
        required.push(Question("i2NoOutlets"));
        required.push(Question("i2DisOutlets"));
        required.push(Question("i2TnkCap2"));
        required.push(Question("i2TnkCap2Unit"));
      }
      if (v09) {
        required.push(Question("i2TpPlntr"));
        required.push(Question("i2NoRows2"));
      }
      if (v11) {
        required.push(Question("i2TpCnGrbAttch"));
      }
    }
    if (hI3Title) {
      required.push(Question("i3Prim"));
      if (hI3Sec) {
        required.push(Question("i3Sec"));
      }
      if (hI3Ter) {
        required.push(Question("i3Ter"));
      }
    }
    if (hI4ImpUsage) {
      required.push(Question("i4Usage"));
      required.push(Question("i4Mode"));
      if (hI4Agency) {
        required.push(Question("i4Agency"));
      }
      required.push(Question("i4YrAcqrd"));
      required.push(Question("i4ConAcqrd"));
      required.push(Question("i4PrsntCon"));
      if (hI4Unused) {
        required.push(Question("i4YrUnused"));
        required.push(Question("i4RsnUnused"));
        required.push(Question("i4UuOthers"));
      }
      if (hI4Unserv) {
        required.push(Question("i4YrUnserv"));
        required.push(Question("i4RsnUnserv"));
        required.push(Question("i4UsOthers"));
      }
    }
    if (hI4RntDetails) {
      required.push(Question("i4WithTractor"));
      if (hI4SvcRtHa) {
        required.push(Question("i4SvcRtHa"));
        if (hI4SvcRtHaNon) {
          required.push(Question("i4SvcRtHaNon"));
        }
        required.push(Question("i4SameOwn"));
        required.push(Question("i4BusCont1"));
        required.push(Question("i4BusCont2"));
        required.push(Question("i4Consent"));

        required.push(Question("i4SrvcLoc1P"));
        required.push(Question("i4SrvcLoc1M"));
        if (hI4SrvcLoc2P) {
          required.push(Question("i4SrvcLoc2P"));
          required.push(Question("i4SrvcLoc2M"));
        }
        if (hI4SrvcLoc3P) {
          required.push(Question("i4SrvcLoc3P"));
          required.push(Question("i4SrvcLoc3M"));
        }
      }
    }
    if (hI5Title) {
      required.push(Question("i5TmCom1Ha"));
      if (v01 || v02 || v03 || v04 || v06 || v07 || v08 || v09) {
        required.push(Question("i5NoPasses"));
      }
      if (v08) {
        required.push(Question("i5GrnFrtWt"));
        required.push(Question("i5GrnFrtWtUnit"));
        required.push(Question("i5FrtAppEffA"));
        required.push(Question("i5FrtDelRate"));
      }
      if (v10) {
        required.push(Question("i5SgrYld"));
      }
      if (v11) {
        required.push(Question("i5LdCap"));
        required.push(Question("i5LdCapUnit"));
      }
      required.push(Question("i5NoBrkdwn"));
      required.push(Question("i5CmnBrkdwn"));
      required.push(Question("i5MntcDone"));
    }

    required.forEach((req) => {
      if (safeRead(req?.value) === "") {
        req?.setErrorMessage({
          ...req?.errorMessage,
          required: true,
        });
      }
    });

    var check: any[] = [];
    required.forEach((req) => {
      if (req?.errorMessage.condition === "none") {
        return;
      }

      var validation;

      if (
        req?.errorMessage.condition === "range" ||
        req?.errorMessage.condition === "year"
      ) {
        validation = {
          required: req?.errorMessage.required,
          condition: req?.errorMessage.condition,
          message: req?.errorMessage.message,
          range: req?.errorMessage.range ?? null,
        } as TValidation;
      } else {
        validation = {
          required: req?.errorMessage.required,
          condition: req?.errorMessage.condition,
          message: req?.errorMessage.message,
        } as TValidation;
      }

      if (
        ValidateRequired({
          value: req?.value,
          validation: validation,
        })
      ) {
        check.push(req);
      }
    });

    var qEmpty: TQuestion | undefined;
    var hasEmpty = required.some((req) => {
      if (req && (req.value === null || req.value === "")) {
        qEmpty = req;
        return true;
      }
      return false;
    });

    var gpsOK = true;
    if (parseFloat(iI1Acc) > AppSettings.MIN_ACCURACY) {
          AVPopup({
            type: "error",
            title: safeRead(q.tI1Acc),
            text: "Accuracy is too low. Kindly wait for the accuracy to meet atleast 8 meters.",
          }).then(() => {
            avScrollTo("i1Acc");
          });
          gpsOK = false;
    } else if (typeof JSON == "undefined") {
      AVPopup({
        type: "error",
        text: pop.saveErrors.browserError,
      });
    } else if (hasEmpty || qEmpty) {
      AVPopup({
        type: "error",
        title: qEmpty?.label,
        text: pop.saveErrors.missing,
        confirmButtonText: pop.saveErrors.button,
      }).then(() => {
        if (qEmpty) {
          avScrollTo(qEmpty.id);
        }
      });
    } else if (check.length > 0) {
      AVPopup({
        type: "error",
        title: check[0].label,
        text: pop.saveErrors.withError,
      }).then(() => {
        avScrollTo(check[0].id);
      });
    } else {
      AVPopup({
        type: "success",
        text: pop.saveErrors.saveIni,
      });
    }

    if (!hasEmpty && check.length === 0 && gpsOK) {
      let data = {
        sI1ResName: iI1ResName,
        sI1QRCode: iI1QRCode,
        sI1Machine: iI1Machine,
        sI1FrontVw: iI1FrontVw,
        sI1SideVw: iI1SideVw,
        sI1SpecVw: iI1SpecVw,
        sI1Acc: iI1Acc,
        sI1Lat: iI1Lat,
        sI1Lng: iI1Lng,
        sI1Loc: iI1Loc,
        sI1Prov: iI1Prov,
        sI1Mun: iI1Mun,
        sI1Bgry: iI1Bgry,
        sI2Type: iI2Type,
        sI2Brand: iI2Brand,
        sI2Model: iI2Model,
        sI2NoBtm1: iI2NoBtm1,
        sI2NoBtm2: iI2NoBtm2,
        sI2SzDk: iI2SzDk,
        sI2NoBtm3: iI2NoBtm3,
        sI2DisRow1: iI2DisRow1,
        sI2WdCut: iI2WdCut,
        sI2LdCap: iI2LdCap,
        sI2LdCapUnit: iI2LdCapUnit,
        sI2NoRows1: iI2NoRows1,
        sI2DisRow2: iI2DisRow2,
        sI2NoNzls: iI2NoNzls,
        sI2DisNzls: iI2DisNzls,
        sI2TnkCap1: iI2TnkCap1,
        sI2NoOutlets: iI2NoOutlets,
        sI2DisOutlets: iI2DisOutlets,
        sI2TnkCap2: iI2TnkCap2,
        sI2TnkCap2Unit: iI2TnkCap2Unit,
        sI2TpPlntr: iI2TpPlntr,
        sI2NoRows2: iI2NoRows2,
        sI2TpCnGrbAttch: iI2TpCnGrbAttch,
        sI3Prim: iI3Prim,
        sI3Sec: iI3Sec,
        sI3Ter: iI3Ter,
        sI4Usage: iI4Usage,
        sI4Mode: iI4Mode,
        sI4Agency: iI4Agency,
        sI4YrAcqrd: iI4YrAcqrd,
        sI4ConAcqrd: iI4ConAcqrd,
        sI4PrsntCon: iI4PrsntCon,
        sI4YrUnused: iI4YrUnused,
        sI4RsnUnused: iI4RsnUnused,
        sI4UuOthers: iI4UuOthers,
        sI4YrUnserv: iI4YrUnserv,
        sI4RsnUnserv: iI4RsnUnserv,
        sI4UsOthers: iI4UsOthers,
        sI4WithTractor: iI4WithTractor,
        sI4SvcRtHa: iI4SvcRtHa,
        sI4SvcRtHaNon: iI4SvcRtHaNon,
        sI4SameOwn: iI4SameOwn,
        sI4BusCont1: iI4BusCont1,
        sI4BusCont2: iI4BusCont2,
        sI4Consent: iI4Consent,
        sI4SrvcLoc1P: iI4SrvcLoc1P,
        sI4SrvcLoc1M: iI4SrvcLoc1M,
        sI4SrvcLoc2P: iI4SrvcLoc2P,
        sI4SrvcLoc2M: iI4SrvcLoc2M,
        sI4SrvcLoc3P: iI4SrvcLoc3P,
        sI4SrvcLoc3M: iI4SrvcLoc3M,
        sI5TmCom1Ha: iI5TmCom1Ha,
        sI5NoPasses: iI5NoPasses,
        sI5GrnFrtWt: iI5GrnFrtWt,
        sI5GrnFrtWtUnit: iI5GrnFrtWtUnit,
        sI5FrtAppEffA: iI5FrtAppEffA,
        sI5FrtDelRate: iI5FrtDelRate,
        sI5SgrYld: iI5SgrYld,
        sI5LdCap: iI5LdCap,
        sI5LdCapUnit: iI5LdCapUnit,
        sI5NoBrkdwn: iI5NoBrkdwn,
        sI5CmnBrkdwn: iI5CmnBrkdwn,
        sI5MntcDone: iI5MntcDone,
        sI6Notes: iI6Notes,
        sI0Uname: fxdRead(fxdUser.name),
        sI0Encoded: Date.now().toString(),
        sI0Status: DATA_STATUS.local,
        sI0Version: "41",
      } as ImplementData;

      var zipname = safeRead(iI1QRCode) + ".zip";
      var filename = safeRead(iI1QRCode) + ".json";

      createZIP(zipname, AppSettings.ZIPPASS, [
        { filename: filename, content: JSON.stringify(data, undefined, 2) },
      ]);

      fxdWrite(safeRead(iI1QRCode), JSON.stringify(data));

      clearFields();
      deleteMachineBackups();

      AVPopup({
        icon: "success",
        title: pop.save.title,
        text: pop.save.message,
        confirmButtonColor: pop.save.center,
      }).then(() => {
        GOTO(AvzRoute.encode);
      });
    }
  }

  function clearFields() {
    QUESTIONS.forEach((question) => {
      if (question.setValue) question.setValue("");
    });
  }
}

// /////////////////////////////////////////////////////////////////////////////
// IMPLEMENT FORMAT ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ImplementData {
  sI1ResName: string;
  sI1QRCode: string;
  sI1Machine: string;
  sI1FrontVw: string;
  sI1SideVw: string;
  sI1SpecVw: string;
  sI1Acc: string;
  sI1Lat: string;
  sI1Lng: string;
  sI1Loc: string;
  sI1Prov: string;
  sI1Mun: string;
  sI1Bgry: string;

  sI2Type: string;
  sI2Brand: string;
  sI2Model: string;
  sI2NoBtm1: string;
  sI2NoBtm2: string;
  sI2SzDk: string;
  sI2NoBtm3: string;
  sI2DisRow1: string;
  sI2WdCut: string;
  sI2LdCap: string;
  sI2LdCapUnit: string;
  sI2NoRows1: string;
  sI2DisRow2: string;
  sI2NoNzls: string;
  sI2DisNzls: string;
  sI2TnkCap1: string;
  sI2NoOutlets: string;
  sI2DisOutlets: string;
  sI2TnkCap2: string;
  sI2TnkCap2Unit: string;
  sI2TpPlntr: string;
  sI2NoRows2: string;
  sI2TpCnGrbAttch: string;

  sI3Prim: string;
  sI3Sec: string;
  sI3Ter: string;

  sI4Usage: string;
  sI4Mode: string;
  sI4Agency: string;
  sI4YrAcqrd: string;
  sI4ConAcqrd: string;
  sI4PrsntCon: string;
  sI4YrUnused: string;
  sI4RsnUnused: string;
  sI4UuOthers: string;
  sI4YrUnserv: string;
  sI4RsnUnserv: string;
  sI4UsOthers: string;
  sI4WithTractor: string;
  sI4SvcRtHa: string;
  sI4SvcRtHaNon: string;
  sI4SameOwn: string;
  sI4BusCont1: string;
  sI4BusCont2: string;
  sI4Consent: string;
  sI4SrvcLoc1P: string;
  sI4SrvcLoc1M: string;
  sI4SrvcLoc2P: string;
  sI4SrvcLoc2M: string;
  sI4SrvcLoc3P: string;
  sI4SrvcLoc3M: string;

  sI5TmCom1Ha: string;
  sI5NoPasses: string;
  sI5GrnFrtWt: string;
  sI5GrnFrtWtUnit: string;
  sI5FrtAppEffA: string;
  sI5FrtDelRate: string;
  sI5SgrYld: string;
  sI5LdCap: string;
  sI5LdCapUnit: string;
  sI5NoBrkdwn: string;
  sI5CmnBrkdwn: string;
  sI5MntcDone: string;
  
  sI6Notes: string;

  sI0Uname: string;
  sI0Encoded: string;
  sI0Status: string;
  sI0Version: string;
}
