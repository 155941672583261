import TX from "../util/Validations.json";
import { ToBeAcquired } from "../../app/GenEnums";
import { safeRead } from "../../app/GenFunctions";
import { TValidation, TSetBoolean, TSetString } from "../util/AType";

import { CURRENT_YEAR } from "./CheckYear";

import { CheckName } from "./CheckName";
import { CheckYear } from "./CheckYear";
import { CheckRange } from "./CheckRange";
import { CheckContact } from "./CheckContact";

// /////////////////////////////////////////////////////////////////////////////
// Default Validation //////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const NoValidation: TValidation = {
  required: false,
  condition: "none",
  message: { label: "" },
};

export const NameValidation: TValidation = {
  required: false,
  condition: "name",
  message: {
    label: TX.ext.name,
    duplicate: TX.ext.duplicate,
  },
};

export const AgeValidation: TValidation = {
  required: false,
  condition: "range",
  range: { min: 15, max: 150 },
  message: { label: TX.ext.age },
};

export const ContactValidation: TValidation = {
  required: false,
  condition: "contact",
  message: { label: TX.ext.contact },
};

export const YearValidation: TValidation = {
  required: false,
  condition: "year",
  range: { min: 1949, max: CURRENT_YEAR },
  message: { label: TX.ext.year },
};

export const Rng = (min: number, max: number, msg: string): TValidation => {
  return {
    required: false,
    condition: "range",
    range: { min: min, max: max },
    message: { label: msg },
  };
};

// /////////////////////////////////////////////////////////////////////////////
// Genral Function /////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const REQUIRED = TX.required;

interface IValidation {
  value: any;
  validation: TValidation;
  setStatus: TSetBoolean;
  setText: TSetString;
}

/**
 * Validations:
 * none, name, duplicate, range, contact, and year
 */
export function Validate(props: IValidation) {
  if (props.validation && props.validation?.message) {
    var a = String(safeRead(props.value)).toUpperCase();
    var b = String(ToBeAcquired).toUpperCase();
    var DataToBeAcquired = a === b;

    if (DataToBeAcquired) {
      props.setStatus(false);
      props.setText("");
      return;
    }

    var result;

    // if (props.validation.required && safeRead(props.value).length === 0) {
    //   result = { status: true, message: TX.required };
    // }

    if (props.validation.condition === "name") {
      result = CheckName({
        value: props.value,
        message: props.validation?.message,
      });
    }

    if (props.validation.condition === "range") {
      if (props.validation?.range) {
        result = CheckRange({
          value: props.value,
          message: props.validation?.message,
          range: props.validation?.range,
        });
      }
    }

    if (props.validation.condition === "contact") {
      result = CheckContact({
        value: props.value,
        message: props.validation?.message,
      });
    }

    if (props.validation.condition === "year") {
      result = CheckYear({
        value: props.value,
        message: props.validation?.message,
        range: props.validation?.range,
      });
    }

    if (result) {
      props.setStatus(result.status);
      props.setText(result.message);
    }
  } else if (props.value.length === 0) {
    props.setStatus(false);
    props.setText("");
  }
}

export function ValidateRequired(props: {
  value: any;
  validation: TValidation;
}): boolean {
  var val = safeRead(props.value);

  if (val.length === 0) {
    return true;
  }

  var a = String(val).toUpperCase();
  var b = String(ToBeAcquired).toUpperCase();
  var DataToBeAcquired = a === b;

  if (DataToBeAcquired) {
    return false;
  }

  if (props.validation.condition === "name") {
    const result = CheckName({
      value: val,
      message: { label: "" },
    });
    return result ? result.status : true;
  }

  if (props.validation.condition === "range") {
    if (props.validation?.range) {
      const result = CheckRange({
        value: val,
        message: { label: "" },
        range: props.validation?.range,
      });
      return result ? result.status : true;
    }
  }

  if (props.validation.condition === "contact") {
    const result = CheckContact({
      value: val,
      message: { label: "" },
    });
    return result ? result.status : true;
  }

  if (props.validation.condition === "year") {
    const result = CheckYear({
      value: val,
      message: { label: "" },
      range: props.validation?.range,
    });
    return result ? result.status : true;
  }

  return false;
}
