// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import TX from "./AVGeneral.json";
import { fxdDelete, fxdWrite } from "../../app/Storage";
import { TText, AnyState, TSeverity } from "../util/AType";

// /////////////////////////////////////////////////////////////////////////////
// CONSTANT ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const AVOVERRIDE = "override";

export const AVMONTHS = [
  { label: "January", intgr: 1 },
  { label: "February", intgr: 2 },
  { label: "March", intgr: 3 },
  { label: "April", intgr: 4 },
  { label: "May", intgr: 5 },
  { label: "June", intgr: 6 },
  { label: "July", intgr: 7 },
  { label: "August", intgr: 8 },
  { label: "September", intgr: 9 },
  { label: "October", intgr: 10 },
  { label: "November", intgr: 11 },
  { label: "December", intgr: 12 },
];

// /////////////////////////////////////////////////////////////////////////////
// INTERFACE ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface IAVCommon {
  /**  */
  text: TText;
  /**  */
  value: AnyState;
  /**  */
  backup: string;
  /**  */
  validation?: any;
}

export interface IAVError {
  /**  */
  // error?: { status: boolean; msg: string };
  /**  */
  color?: TSeverity;
}

export interface IAVRequired extends IAVCommon, IAVError {}

// /////////////////////////////////////////////////////////////////////////////
// GENERAL FUNCTIONS ///////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function avBackup(storage: string, val: any) {
  if (storage === "") {
    console.warn("Tried to backup to an empty storage.");
    return;
  } else if (storage === AVOVERRIDE) return;
  var isEmpty = (val && !JSON.parse(val)) || val === "[]";
  if (storage) isEmpty ? fxdDelete(storage) : fxdWrite(storage, val);
  else console.error("{" + storage + "} " + TX.error.backup);
}

export function avScrollTo(id: string) {
  setTimeout(() => {
    const element = id
      ? document.getElementById(id)
      : null;
    if (element) {
      const yOffset = -30;
      const y =
        element.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, 500);
}
