import saveAs from "file-saver";
import {
  ZipWriter,
  BlobWriter,
  TextReader,
  ZipReader,
  BlobReader,
  TextWriter,
} from "@zip.js/zip.js";

export const INPUTSEPARATOR = ",";

const TX = {
  error: {
    convert: "Error converting input to string: ",
  },
};

export function safeRead(text: any) {
  return !text ? "" : text.label ?? text;
}

export const inputToString = (input: any) => {
  if (typeof input === "string" || input.label) {
    return safeRead(input);
  } else if (input instanceof Array) {
    return input.map((i: any) => i.label).join(INPUTSEPARATOR);
  } else {
    console.error(TX.error.convert + input);
  }
};

/**
 * @param date
 * @returns the date in the format DDMMYYYYHHMM like 150520240800
 */
export function comDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  return `${day}${month}${year}${hour}${minute}`;
}

export function dateOnly(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}${month}${year}`;
}

export function timeOnly(date: Date): string {
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  return `${hour}${minute}`;
}

// Month only
export function monthOnly(date: string): string {
  const d = new Date(date);
  const month = (d.getMonth() + 1).toString().padStart(2, "0");

  // Month number to string
  var monthString = "";
  switch (month) {
    case "01":
      monthString = "January";
      break;
    case "02":
      monthString = "February";
      break;
    case "03":
      monthString = "March";
      break;
    case "04":
      monthString = "April";
      break;
    case "05":
      monthString = "May";
      break;
    case "06":
      monthString = "June";
      break;
    case "07":
      monthString = "July";
      break;
    case "08":
      monthString = "August";
      break;
    case "09":
      monthString = "September";
      break;
    case "10":
      monthString = "October";
      break;
    case "11":
      monthString = "November";
      break;
    case "12":
      monthString = "December";
      break;
    default:
      monthString = "Invalid month";
  }

  return `${monthString}`;
}

export function yearOnly(date: string): string {
  const d = new Date(date);
  const year = d.getFullYear();
  return `${year}`;
}

// A working but not used example of how to use JSZip
// import { saveAs } from "file-saver";
// import JSZip from "jszip";

// var zip = new JSZip();
// var json = {
//   val1: "One",
//   val2: "Two",
// };
// zip.file("Hello.json", JSON.stringify(json));
// zip.generateAsync({ type: "blob" }).then(function (content) {
//   saveAs(content, "example.zip");
// });

export type ZIPFile = { filename: string; content: any };

export async function createZIP(
  zipname: string,
  password: string,
  zipfile: ZIPFile[]
) {
  const writer = new ZipWriter(new BlobWriter("application/zip"), {
    password: password,
  });

  for (const file of zipfile) {
    await writer.add(file.filename, new TextReader(file.content));
  }

  await writer.close().then(function (blob) {
    saveAs(blob, zipname);
  });
}





export async function readZIP(blob: File, password: string) {
  const reader = new ZipReader(new BlobReader(blob), {
    password: password,
  });

  const entries = await reader.getEntries();

  var files: ZIPFile[] = [];
  entries.forEach(async (entry) => {
    if (entry.getData) {
      const content = await entry.getData(new TextWriter());
      files.push({ filename: entry.filename, content: content });
    }
  });

  await reader.close();
  return files;
}
