// Machine Types ///////////////////////////////////////////////////////////////

export const machines = {
  types: [
    { label: "Two-wheel Tractor", key: 0, anchored: true },
    { label: "Four-wheel Tractor", key: 1, anchored: true },
    { label: "Cane Grab Loader", key: 2, anchored: true },
    { label: "Sugarcane Harvester (Self-Propelled)", key: 3, anchored: true },
    { label: "Water Pump", key: 4, anchored: true },
    { label: "Hauling truck", key: 5, anchored: true },
    { label: "Rain Gun", key: 6, anchored: true },
    { label: "Not Applicable", key: 7, anchored: true },
  ],
};

export const tTrc2 = machines.types[0];
export const tTrc4 = machines.types[1];
export const tCane = machines.types[2];
export const tHrvt = machines.types[3];
export const tPump = machines.types[4];
export const tHaul = machines.types[5];
export const tRGun = machines.types[6];
// export const NA = machine.type[7];

// /////////////////////////////////////////////////////////////////////////////
// MACHINE BRANDS AND MODELS ///////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Brands and Models ///////////////////////////////////////////////////////////

const oBTrc2Brand1 = "ACT";
const oBTrc2Brand2 = "AIMS";
const oBTrc2Brand3 = "APT";
const oBTrc2Brand4 = "BOWA";
const oBTrc2Brand5 = "BUFFALO";
const oBTrc2Brand6 = "D.U.A";
const oBTrc2Brand7 = "FARM MASTER";
const oBTrc2Brand8 = "KAPITAN";
const oBTrc2Brand9 = "KASAMA HARABAS";
const oBTrc2Brand10 = "KATO";
const oBTrc2Brand11 = "KELLY";
const oBTrc2Brand12 = "KULIGLIG";
const oBTrc2Brand13 = "LONG FOONG";
const oBTrc2Brand14 = "MITSUBOMAR";
const oBTrc2Brand15 = "NICHINO";
const oBTrc2Brand16 = "SUMO PLUS";
const oBTrc2Brand17 = "SUPER";
const oBTrc2Brand18 = "TIBAY KULIGLIG";
const oBTrc2Brand19 = "TRIPLE J";
const oBTrc2Brand20 = "WEST WIND";
const oBTrc2Brand21 = "Not Applicable";

const oBTrc4Brand1 = "AGRINDO";
const oBTrc4Brand2 = "BRANSON";
const oBTrc4Brand3 = "CASE IH";
const oBTrc4Brand4 = "DEUTZ-FAHR";
const oBTrc4Brand5 = "EUROTRAC";
const oBTrc4Brand6 = "FARM ROVER";
const oBTrc4Brand7 = "FIAT";
const oBTrc4Brand8 = "FORD";
const oBTrc4Brand9 = "FORDSON";
const oBTrc4Brand10 = "INTERNATIONAL HARVESTER IH";
const oBTrc4Brand11 = "ISEKI";
const oBTrc4Brand12 = "ITMCO";
const oBTrc4Brand13 = "JOHN DEERE";
const oBTrc4Brand14 = "KAMOL";
const oBTrc4Brand15 = "KIOTI";
const oBTrc4Brand16 = "KUBOTA";
const oBTrc4Brand17 = "LANDINI";
const oBTrc4Brand18 = "LEYLAND";
const oBTrc4Brand19 = "LOVOL";
const oBTrc4Brand20 = "LS";
const oBTrc4Brand21 = "MAHINDRA";
const oBTrc4Brand22 = "MASSEY FERGUSON";
const oBTrc4Brand23 = "MC CORMICK";
const oBTrc4Brand24 = "NEW HOLLAND";
const oBTrc4Brand25 = "PREET";
const oBTrc4Brand26 = "SHUHE";
const oBTrc4Brand27 = "SAME";
const oBTrc4Brand28 = "SONALIKA";
const oBTrc4Brand29 = "VALTRA";
const oBTrc4Brand30 = "WEITAI";
const oBTrc4Brand31 = "YANMAR";
const oBTrc4Brand32 = "Not Applicable";

const oBCaneBrand1 = "JING GONG III";
const oBCaneBrand2 = "APACHE";
const oBCaneBrand3 = "Not Applicable";

const oBHrvtBrand1 = "CAMECO";
const oBHrvtBrand2 = "CASE IH";
const oBHrvtBrand3 = "CATERPILLAR";
const oBHrvtBrand4 = "ESMECH";
const oBHrvtBrand5 = "FORD";
const oBHrvtBrand6 = "JOHN DEERE";
const oBHrvtBrand7 = "JING GONG";
const oBHrvtBrand8 = "KUBOTA";
const oBHrvtBrand9 = "SHAKTIMAN";
const oBHrvtBrand10 = "TOFT";
const oBHrvtBrand11 = "WORLD";
const oBHrvtBrand12 = "YANMAR ";
const oBHrvtBrand13 = "SMKY";
const oBHrvtBrand14 = "Not Applicable";

const oBPumpBrand1 = "KATO";
const oBPumpBrand2 = "MAXIPRO";
const oBPumpBrand3 = "INNOVA";
const oBPumpBrand4 = "KENBO";
const oBPumpBrand5 = "GAUDI";
const oBPumpBrand6 = "HINOKI";
const oBPumpBrand7 = "TARO";
const oBPumpBrand8 = "ROBIN";
const oBPumpBrand9 = "MIYATA";
const oBPumpBrand10 = "EAGLE";
const oBPumpBrand11 = "KITACO";
const oBPumpBrand12 = "SAKAI";
const oBPumpBrand13 = "EXTREME";
const oBPumpBrand14 = "TAY";
const oBPumpBrand15 = "BLUMAX";
const oBPumpBrand16 = "KAMA";
const oBPumpBrand17 = "SOLAR PUMP";
const oBPumpBrand18 = "YAMADA";
const oBPumpBrand19 = "SUPER KASAMA";
const oBPumpBrand20 = "COMET";
const oBPumpBrand21 = "GRUNDFOS";
const oBPumpBrand22 = "OKAMA";
const oBPumpBrand23 = "YAMAHA";
const oBPumpBrand24 = "KAIAO";
const oBPumpBrand25 = "HONDA";
const oBPumpBrand26 = "TSURUMI";
const oBPumpBrand27 = "LAUNTOP";
const oBPumpBrand28 = "MOTORSTAR";
const oBPumpBrand29 = "KAITO";
const oBPumpBrand30 = "NITTOO";
const oBPumpBrand31 = "PLATINUM";
const oBPumpBrand32 = "LORENTZ";
const oBPumpBrand33 = "BRIGGS AND STRATTON";
const oBPumpBrand34 = "HAKATA";
const oBPumpBrand35 = "SUMO";
const oBPumpBrand36 = "VIKYNO";
const oBPumpBrand37 = "ISHII";
const oBPumpBrand38 = "TOKOYAMA";
const oBPumpBrand39 = "BALLENA";
const oBPumpBrand40 = "BLACK HAWK";
const oBPumpBrand41 = "WEIMA";
const oBPumpBrand42 = "Not Applicable";

// Brands //////////////////////////////////////////////////////////////////////

export const oOBrands = [
  { label: oBTrc2Brand1, type: tTrc2 },
  { label: oBTrc2Brand2, type: tTrc2 },
  { label: oBTrc2Brand3, type: tTrc2 },
  { label: oBTrc2Brand4, type: tTrc2 },
  { label: oBTrc2Brand5, type: tTrc2 },
  { label: oBTrc2Brand6, type: tTrc2 },
  { label: oBTrc2Brand7, type: tTrc2 },
  { label: oBTrc2Brand8, type: tTrc2 },
  { label: oBTrc2Brand9, type: tTrc2 },
  { label: oBTrc2Brand10, type: tTrc2 },
  { label: oBTrc2Brand11, type: tTrc2 },
  { label: oBTrc2Brand12, type: tTrc2 },
  { label: oBTrc2Brand13, type: tTrc2 },
  { label: oBTrc2Brand14, type: tTrc2 },
  { label: oBTrc2Brand15, type: tTrc2 },
  { label: oBTrc2Brand16, type: tTrc2 },
  { label: oBTrc2Brand17, type: tTrc2 },
  { label: oBTrc2Brand18, type: tTrc2 },
  { label: oBTrc2Brand19, type: tTrc2 },
  { label: oBTrc2Brand20, type: tTrc2 },
  { label: oBTrc2Brand21, type: tTrc2 },

  { label: oBTrc4Brand1, type: tTrc4 },
  { label: oBTrc4Brand2, type: tTrc4 },
  { label: oBTrc4Brand3, type: tTrc4 },
  { label: oBTrc4Brand4, type: tTrc4 },
  { label: oBTrc4Brand5, type: tTrc4 },
  { label: oBTrc4Brand6, type: tTrc4 },
  { label: oBTrc4Brand7, type: tTrc4 },
  { label: oBTrc4Brand8, type: tTrc4 },
  { label: oBTrc4Brand9, type: tTrc4 },
  { label: oBTrc4Brand10, type: tTrc4 },
  { label: oBTrc4Brand11, type: tTrc4 },
  { label: oBTrc4Brand12, type: tTrc4 },
  { label: oBTrc4Brand13, type: tTrc4 },
  { label: oBTrc4Brand14, type: tTrc4 },
  { label: oBTrc4Brand15, type: tTrc4 },
  { label: oBTrc4Brand16, type: tTrc4 },
  { label: oBTrc4Brand17, type: tTrc4 },
  { label: oBTrc4Brand18, type: tTrc4 },
  { label: oBTrc4Brand19, type: tTrc4 },
  { label: oBTrc4Brand20, type: tTrc4 },
  { label: oBTrc4Brand21, type: tTrc4 },
  { label: oBTrc4Brand22, type: tTrc4 },
  { label: oBTrc4Brand23, type: tTrc4 },
  { label: oBTrc4Brand24, type: tTrc4 },
  { label: oBTrc4Brand25, type: tTrc4 },
  { label: oBTrc4Brand26, type: tTrc4 },
  { label: oBTrc4Brand27, type: tTrc4 },
  { label: oBTrc4Brand28, type: tTrc4 },
  { label: oBTrc4Brand29, type: tTrc4 },
  { label: oBTrc4Brand30, type: tTrc4 },
  { label: oBTrc4Brand31, type: tTrc4 },
  { label: oBTrc4Brand32, type: tTrc4 },

  { label: oBCaneBrand1, type: tCane },
  { label: oBCaneBrand2, type: tCane },
  { label: oBCaneBrand3, type: tCane },

  { label: oBHrvtBrand1, type: tHrvt },
  { label: oBHrvtBrand2, type: tHrvt },
  { label: oBHrvtBrand3, type: tHrvt },
  { label: oBHrvtBrand4, type: tHrvt },
  { label: oBHrvtBrand5, type: tHrvt },
  { label: oBHrvtBrand6, type: tHrvt },
  { label: oBHrvtBrand7, type: tHrvt },
  { label: oBHrvtBrand8, type: tHrvt },
  { label: oBHrvtBrand9, type: tHrvt },
  { label: oBHrvtBrand10, type: tHrvt },
  { label: oBHrvtBrand11, type: tHrvt },
  { label: oBHrvtBrand12, type: tHrvt },
  { label: oBHrvtBrand13, type: tHrvt },
  { label: oBHrvtBrand14, type: tHrvt },

  { label: oBPumpBrand1, type: tPump },
  { label: oBPumpBrand2, type: tPump },
  { label: oBPumpBrand3, type: tPump },
  { label: oBPumpBrand4, type: tPump },
  { label: oBPumpBrand5, type: tPump },
  { label: oBPumpBrand6, type: tPump },
  { label: oBPumpBrand7, type: tPump },
  { label: oBPumpBrand8, type: tPump },
  { label: oBPumpBrand9, type: tPump },
  { label: oBPumpBrand10, type: tPump },
  { label: oBPumpBrand11, type: tPump },
  { label: oBPumpBrand12, type: tPump },
  { label: oBPumpBrand13, type: tPump },
  { label: oBPumpBrand14, type: tPump },
  { label: oBPumpBrand15, type: tPump },
  { label: oBPumpBrand16, type: tPump },
  { label: oBPumpBrand17, type: tPump },
  { label: oBPumpBrand18, type: tPump },
  { label: oBPumpBrand19, type: tPump },
  { label: oBPumpBrand20, type: tPump },
  { label: oBPumpBrand21, type: tPump },
  { label: oBPumpBrand22, type: tPump },
  { label: oBPumpBrand23, type: tPump },
  { label: oBPumpBrand24, type: tPump },
  { label: oBPumpBrand25, type: tPump },
  { label: oBPumpBrand26, type: tPump },
  { label: oBPumpBrand27, type: tPump },
  { label: oBPumpBrand28, type: tPump },
  { label: oBPumpBrand29, type: tPump },
  { label: oBPumpBrand30, type: tPump },
  { label: oBPumpBrand31, type: tPump },
  { label: oBPumpBrand32, type: tPump },
  { label: oBPumpBrand33, type: tPump },
  { label: oBPumpBrand34, type: tPump },
  { label: oBPumpBrand35, type: tPump },
  { label: oBPumpBrand36, type: tPump },
  { label: oBPumpBrand37, type: tPump },
  { label: oBPumpBrand38, type: tPump },
  { label: oBPumpBrand39, type: tPump },
  { label: oBPumpBrand40, type: tPump },
  { label: oBPumpBrand41, type: tPump },
  { label: oBPumpBrand42, type: tPump },
];

// Models //////////////////////////////////////////////////////////////////////

export const oOModels = [
  { label: "Isabelo - Diesel", brand: oBTrc2Brand1 },
  { label: "Isabelo - Gasoline", brand: oBTrc2Brand1 },
  { label: "Mega Isabelo", brand: oBTrc2Brand1 },
  { label: "Supermasa - Diesel-AC", brand: oBTrc2Brand1 },
  { label: "Supermasa - Diesel-WC", brand: oBTrc2Brand1 },
  { label: "Supermasa - Gasoline", brand: oBTrc2Brand1 },
  { label: "Masa - Diesel-AC", brand: oBTrc2Brand1 },
  { label: "Masa - Diesel-WC", brand: oBTrc2Brand1 },
  { label: "Masa - Gasoline", brand: oBTrc2Brand1 },
  { label: "No model specified", brand: oBTrc2Brand2 },
  { label: "WR652", brand: oBTrc2Brand3 },
  { label: "WR653", brand: oBTrc2Brand3 },
  { label: "MT-921G", brand: oBTrc2Brand4 },
  { label: "BHT-001", brand: oBTrc2Brand5 },
  { label: "No model specified", brand: oBTrc2Brand6 },
  { label: "FMHT-60C", brand: oBTrc2Brand7 },
  { label: "1001", brand: oBTrc2Brand8 },
  { label: "No model specified", brand: oBTrc2Brand9 },
  { label: "KPT86-B", brand: oBTrc2Brand10 },
  { label: "KHT", brand: oBTrc2Brand11 },
  { label: "SK-106 - Gasoline", brand: oBTrc2Brand12 },
  { label: "SK-106 - Diesel", brand: oBTrc2Brand12 },
  { label: "WR801", brand: oBTrc2Brand13 },
  { label: "WR652", brand: oBTrc2Brand13 },
  { label: "No model specified", brand: oBTrc2Brand14 },
  { label: "IE-102", brand: oBTrc2Brand15 },
  { label: "SPHT-60", brand: oBTrc2Brand16 },
  { label: "SFT01", brand: oBTrc2Brand17 },
  { label: "TK-01", brand: oBTrc2Brand18 },
  { label: "No model specified", brand: oBTrc2Brand19 },
  { label: "STA-201", brand: oBTrc2Brand20 },

  { label: "TA3504", brand: oBTrc4Brand1 },
  { label: "TA3704", brand: oBTrc4Brand1 },
  { label: "TA3304", brand: oBTrc4Brand1 },
  { label: "6225R", brand: oBTrc4Brand2 },
  { label: "5825R", brand: oBTrc4Brand2 },
  { label: "5025R", brand: oBTrc4Brand2 },
  { label: "F50R", brand: oBTrc4Brand2 },
  { label: "F47R", brand: oBTrc4Brand2 },
  { label: "F42R", brand: oBTrc4Brand2 },
  { label: "F36R", brand: oBTrc4Brand2 },
  { label: "5520R", brand: oBTrc4Brand2 },
  { label: "4820R", brand: oBTrc4Brand2 },
  { label: "3620R", brand: oBTrc4Brand2 },
  { label: "FARMALL 100JX", brand: oBTrc4Brand3 },
  { label: "FARMALL 90JX", brand: oBTrc4Brand3 },
  { label: "JX55T", brand: oBTrc4Brand3 },
  { label: "JX75T", brand: oBTrc4Brand3 },
  { label: "FARMALL 110A", brand: oBTrc4Brand3 },
  { label: "FARMALL 120A", brand: oBTrc4Brand3 },
  { label: "FARMALL 125A", brand: oBTrc4Brand3 },
  { label: "JX95", brand: oBTrc4Brand3 },
  { label: "AGROFARM TB415", brand: oBTrc4Brand4 },
  { label: "AGROFARM TB425", brand: oBTrc4Brand4 },
  { label: "AGROFARM G85", brand: oBTrc4Brand4 },
  { label: "AGROFARM G100", brand: oBTrc4Brand4 },
  { label: "AGROFARM G115", brand: oBTrc4Brand4 },
  { label: "AGROFARM C75", brand: oBTrc4Brand4 },
  { label: "AGROFARM C80", brand: oBTrc4Brand4 },
  { label: "AGROFARM C85", brand: oBTrc4Brand4 },
  { label: "AGROFARM C90", brand: oBTrc4Brand4 },
  { label: "AGROFARM C95", brand: oBTrc4Brand4 },
  { label: "AGROFARM C100", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 100", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 110", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 115", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 1130", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 150", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 170", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 3035E", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 3040E", brand: oBTrc4Brand4 },
  { label: "AGROTRAC 3042E", brand: oBTrc4Brand4 },
  { label: "AGROLUX 80", brand: oBTrc4Brand4 },
  { label: "AGROLUX 85", brand: oBTrc4Brand4 },
  { label: "AGROLUX 90", brand: oBTrc4Brand4 },
  { label: "AGROLUX 95", brand: oBTrc4Brand4 },
  { label: "AGROLUX 100", brand: oBTrc4Brand4 },
  { label: "AGROLUX 310", brand: oBTrc4Brand4 },
  { label: "AGROLUX 320", brand: oBTrc4Brand4 },
  { label: "AGROLUX 410", brand: oBTrc4Brand4 },
  { label: "YTO MF504", brand: oBTrc4Brand5 },
  { label: "YTO MF554", brand: oBTrc4Brand5 },
  { label: "YTO MF500", brand: oBTrc4Brand5 },
  { label: "YTO MF550", brand: oBTrc4Brand5 },
  { label: "YTO X454", brand: oBTrc4Brand5 },
  { label: "YTO X904", brand: oBTrc4Brand5 },
  { label: "YTO X1204", brand: oBTrc4Brand5 },
  { label: "YTO 1604", brand: oBTrc4Brand5 },
  { label: "FR4135", brand: oBTrc4Brand6 },
  { label: "FR4145", brand: oBTrc4Brand6 },
  { label: "500", brand: oBTrc4Brand7 },
  { label: "540", brand: oBTrc4Brand7 },
  { label: "550", brand: oBTrc4Brand7 },
  { label: "600", brand: oBTrc4Brand7 },
  { label: "640", brand: oBTrc4Brand7 },
  { label: "TD  90", brand: oBTrc4Brand8 },
  { label: "TW 5", brand: oBTrc4Brand8 },
  { label: "TW 10", brand: oBTrc4Brand8 },
  { label: "TW 15", brand: oBTrc4Brand8 },
  { label: "TW 20", brand: oBTrc4Brand8 },
  { label: "TW 25", brand: oBTrc4Brand8 },
  { label: "TW 30", brand: oBTrc4Brand8 },
  { label: "TW 35", brand: oBTrc4Brand8 },
  { label: "COUNTY 754", brand: oBTrc4Brand8 },
  { label: "1164", brand: oBTrc4Brand8 },
  { label: "1660", brand: oBTrc4Brand8 },
  { label: "3930", brand: oBTrc4Brand8 },
  { label: "4000", brand: oBTrc4Brand8 },
  { label: "5000", brand: oBTrc4Brand8 },
  { label: "5600", brand: oBTrc4Brand8 },
  { label: "6000", brand: oBTrc4Brand8 },
  { label: "6610", brand: oBTrc4Brand8 },
  { label: "6640", brand: oBTrc4Brand8 },
  { label: "6600", brand: oBTrc4Brand8 },
  { label: "7000", brand: oBTrc4Brand8 },
  { label: "7210", brand: oBTrc4Brand8 },
  { label: "7610", brand: oBTrc4Brand8 },
  { label: "7710", brand: oBTrc4Brand8 },
  { label: "7810", brand: oBTrc4Brand8 },
  { label: "8100", brand: oBTrc4Brand8 },
  { label: "8210", brand: oBTrc4Brand8 },
  { label: "8700", brand: oBTrc4Brand8 },
  { label: "9210", brand: oBTrc4Brand8 },
  { label: "ALL AROUND 25HP", brand: oBTrc4Brand9 },
  { label: "DEXTA 32HP", brand: oBTrc4Brand9 },
  { label: "FARM MAJOR 42HP", brand: oBTrc4Brand9 },
  { label: "FORDSON F 20HP", brand: oBTrc4Brand9 },
  { label: "FORDSON N 26HP", brand: oBTrc4Brand9 },
  { label: "MAJOR 27HP", brand: oBTrc4Brand9 },
  { label: "MAJOR 45HP", brand: oBTrc4Brand9 },
  { label: "NEW MAJOR 38HP", brand: oBTrc4Brand9 },
  { label: "POWER MAJOR 52HP", brand: oBTrc4Brand9 },
  { label: "SUPER DEXTA 45HP", brand: oBTrc4Brand9 },
  { label: "SUPER MAJOR 54HP", brand: oBTrc4Brand9 },
  { label: "824", brand: oBTrc4Brand10 },
  { label: "T9000", brand: oBTrc4Brand11 },
  { label: "SX65", brand: oBTrc4Brand11 },
  { label: "SX75", brand: oBTrc4Brand11 },
  { label: "SX95", brand: oBTrc4Brand11 },
  { label: "T10510", brand: oBTrc4Brand11 },
  { label: "T10520", brand: oBTrc4Brand11 },
  { label: "TS1610", brand: oBTrc4Brand11 },
  { label: "TS1700", brand: oBTrc4Brand11 },
  { label: "TL3700", brand: oBTrc4Brand11 },
  { label: "ITM 240", brand: oBTrc4Brand12 },
  { label: "ITM 285", brand: oBTrc4Brand12 },
  { label: "ITM 299", brand: oBTrc4Brand12 },
  { label: "ITM 399", brand: oBTrc4Brand12 },
  { label: "ITM 475", brand: oBTrc4Brand12 },
  { label: "ITM 485", brand: oBTrc4Brand12 },
  { label: "ITM 800", brand: oBTrc4Brand12 },
  { label: "ITM 950", brand: oBTrc4Brand12 },
  { label: "ITM 1050", brand: oBTrc4Brand12 },
  { label: "JD5045D", brand: oBTrc4Brand13 },
  { label: "JD504SE", brand: oBTrc4Brand13 },
  { label: "JD5715", brand: oBTrc4Brand13 },
  { label: "JD6603", brand: oBTrc4Brand13 },
  { label: "JD6605", brand: oBTrc4Brand13 },
  { label: "JD6615", brand: oBTrc4Brand13 },
  { label: "JD6525", brand: oBTrc4Brand13 },
  { label: "JD6110B", brand: oBTrc4Brand13 },
  { label: "JD2140", brand: oBTrc4Brand13 },
  { label: "JD 804", brand: oBTrc4Brand13 },
  { label: "6125M", brand: oBTrc4Brand13 },
  { label: "3036E", brand: oBTrc4Brand13 },
  { label: "6630", brand: oBTrc4Brand13 },
  { label: "5400", brand: oBTrc4Brand13 },
  { label: "5525", brand: oBTrc4Brand13 },
  { label: "6615", brand: oBTrc4Brand13 },
  { label: "6425", brand: oBTrc4Brand13 },
  { label: "6510", brand: oBTrc4Brand13 },
  { label: "6215", brand: oBTrc4Brand13 },
  { label: "6420", brand: oBTrc4Brand13 },
  { label: "5400", brand: oBTrc4Brand13 },
  { label: "6410", brand: oBTrc4Brand13 },
  { label: "120", brand: oBTrc4Brand13 },
  { label: "1640", brand: oBTrc4Brand13 },
  { label: "3140", brand: oBTrc4Brand13 },
  { label: "8606", brand: oBTrc4Brand13 },
  { label: "3120", brand: oBTrc4Brand13 },
  { label: "3520", brand: oBTrc4Brand13 },
  { label: "2130", brand: oBTrc4Brand13 },
  { label: "5705", brand: oBTrc4Brand13 },
  { label: "5095", brand: oBTrc4Brand14 },
  { label: "5077", brand: oBTrc4Brand14 },
  { label: "CK22", brand: oBTrc4Brand15 },
  { label: "DS4110-HS", brand: oBTrc4Brand15 },
  { label: "DS4510-HS", brand: oBTrc4Brand15 },
  { label: "DS3510", brand: oBTrc4Brand15 },
  { label: "DK901", brand: oBTrc4Brand15 },
  { label: "M95400", brand: oBTrc4Brand16 },
  { label: "M1085", brand: oBTrc4Brand16 },
  { label: "M7040", brand: oBTrc4Brand16 },
  { label: "M9000", brand: oBTrc4Brand16 },
  { label: "L3500", brand: oBTrc4Brand16 },
  { label: "L3608", brand: oBTrc4Brand16 },
  { label: "B2140S", brand: oBTrc4Brand16 },
  { label: "B2420D", brand: oBTrc4Brand16 },
  { label: "B2440S", brand: oBTrc4Brand16 },
  { label: "B6000", brand: oBTrc4Brand16 },
  { label: "L140", brand: oBTrc4Brand16 },
  { label: "L1501", brand: oBTrc4Brand16 },
  { label: "L1801", brand: oBTrc4Brand16 },
  { label: "B1820", brand: oBTrc4Brand16 },
  { label: "L2002", brand: oBTrc4Brand16 },
  { label: "L2201", brand: oBTrc4Brand16 },
  { label: "L2202", brand: oBTrc4Brand16 },
  { label: "L4018", brand: oBTrc4Brand16 },
  { label: "L4740", brand: oBTrc4Brand16 },
  { label: "L5018", brand: oBTrc4Brand16 },
  { label: "L2402", brand: oBTrc4Brand16 },
  { label: "L1500", brand: oBTrc4Brand16 },
  { label: "MISTRAL 40", brand: oBTrc4Brand17 },
  { label: "MISTRAL 45", brand: oBTrc4Brand17 },
  { label: "MISTRAL 50", brand: oBTrc4Brand17 },
  { label: "LANDFORCE 115", brand: oBTrc4Brand17 },
  { label: "LANDFORCE 125", brand: oBTrc4Brand17 },
  { label: "DT 8860", brand: oBTrc4Brand17 },
  { label: "GLOBALFARM 90", brand: oBTrc4Brand17 },
  { label: "GLOBALFARM 100", brand: oBTrc4Brand17 },
  { label: "LANDPOWER 115", brand: oBTrc4Brand17 },
  { label: "LANDPOWER 130", brand: oBTrc4Brand17 },
  { label: "LANDPOWER 160", brand: oBTrc4Brand17 },
  { label: "245", brand: oBTrc4Brand18 },
  { label: "255", brand: oBTrc4Brand18 },
  { label: "270", brand: oBTrc4Brand18 },
  { label: "272", brand: oBTrc4Brand18 },
  { label: "279", brand: oBTrc4Brand18 },
  { label: "TB 504", brand: oBTrc4Brand19 },
  { label: "TD 904", brand: oBTrc4Brand19 },
  { label: "TB 454", brand: oBTrc4Brand19 },
  { label: "LK-354", brand: oBTrc4Brand19 },
  { label: "LK-454", brand: oBTrc4Brand19 },
  { label: "LK-904", brand: oBTrc4Brand19 },
  { label: "XU6158", brand: oBTrc4Brand20 },
  { label: "XU6168", brand: oBTrc4Brand20 },
  { label: "MT573", brand: oBTrc4Brand20 },
  { label: "XP8084", brand: oBTrc4Brand20 },
  { label: "XP8101", brand: oBTrc4Brand20 },
  { label: "9500", brand: oBTrc4Brand21 },
  { label: "MF445 XTRA", brand: oBTrc4Brand22 },
  { label: "MF455 XTRA", brand: oBTrc4Brand22 },
  { label: "MF460 XTRA", brand: oBTrc4Brand22 },
  { label: "MF470 XTRA", brand: oBTrc4Brand22 },
  { label: "MF 390", brand: oBTrc4Brand22 },
  { label: "MF 7614", brand: oBTrc4Brand22 },
  { label: "MF 7615", brand: oBTrc4Brand22 },
  { label: "MF 4708", brand: oBTrc4Brand22 },
  { label: "MF 5709", brand: oBTrc4Brand22 },
  { label: "MF 5710", brand: oBTrc4Brand22 },
  { label: "MF 6712", brand: oBTrc4Brand22 },
  { label: "MF 6713", brand: oBTrc4Brand22 },
  { label: "MF 2515", brand: oBTrc4Brand22 },
  { label: "MF 2615", brand: oBTrc4Brand22 },
  { label: "MF 290T", brand: oBTrc4Brand22 },
  { label: "1552", brand: oBTrc4Brand22 },
  { label: "165", brand: oBTrc4Brand22 },
  { label: "185", brand: oBTrc4Brand22 },
  { label: "399", brand: oBTrc4Brand22 },
  { label: "440", brand: oBTrc4Brand22 },
  { label: "454", brand: oBTrc4Brand22 },
  { label: "460", brand: oBTrc4Brand22 },
  { label: "590", brand: oBTrc4Brand22 },
  { label: "595", brand: oBTrc4Brand22 },
  { label: "599", brand: oBTrc4Brand22 },
  { label: "B100 MAX.CM 50", brand: oBTrc4Brand23 },
  { label: "C95 MAX", brand: oBTrc4Brand23 },
  { label: "C100MAX", brand: oBTrc4Brand23 },
  { label: "C110MAX", brand: oBTrc4Brand23 },
  { label: "T110MAX", brand: oBTrc4Brand23 },
  { label: "TC28R", brand: oBTrc4Brand24 },
  { label: "TC38R", brand: oBTrc4Brand24 },
  { label: "TC48R", brand: oBTrc4Brand24 },
  { label: "TDS.75", brand: oBTrc4Brand24 },
  { label: "TDS.90", brand: oBTrc4Brand24 },
  { label: "TDS.100", brand: oBTrc4Brand24 },
  { label: "TDS.110", brand: oBTrc4Brand24 },
  { label: "TD 80D", brand: oBTrc4Brand24 },
  { label: "TD 90D", brand: oBTrc4Brand24 },
  { label: "TT3.35", brand: oBTrc4Brand24 },
  { label: "TT3.50", brand: oBTrc4Brand24 },
  { label: "TT35", brand: oBTrc4Brand24 },
  { label: "TT55", brand: oBTrc4Brand24 },
  { label: "TT75", brand: oBTrc4Brand24 },
  { label: "TS 6030", brand: oBTrc4Brand24 },
  { label: "TS6140", brand: oBTrc4Brand24 },
  { label: "TS6.120", brand: oBTrc4Brand24 },
  { label: "TS6.125", brand: oBTrc4Brand24 },
  { label: "TS6.110", brand: oBTrc4Brand24 },
  { label: "TS 90", brand: oBTrc4Brand24 },
  { label: "TS 110", brand: oBTrc4Brand24 },
  { label: "TS 115", brand: oBTrc4Brand24 },
  { label: "TS 120", brand: oBTrc4Brand24 },
  { label: "TS 125", brand: oBTrc4Brand24 },
  { label: "TS 135", brand: oBTrc4Brand24 },
  { label: "6610", brand: oBTrc4Brand24 },
  { label: "6640", brand: oBTrc4Brand24 },
  { label: "8260", brand: oBTrc4Brand24 },
  { label: "9010", brand: oBTrc4Brand24 },
  { label: "9049", brand: oBTrc4Brand25 },
  { label: "6549", brand: oBTrc4Brand25 },
  { label: "6049", brand: oBTrc4Brand25 },
  { label: "SH404", brand: oBTrc4Brand26 },
  { label: "EXPLORER 95", brand: oBTrc4Brand27 },
  { label: "EXPLORER 100", brand: oBTrc4Brand27 },
  { label: "EXPLORER 105", brand: oBTrc4Brand27 },
  { label: "EXPLORER 115", brand: oBTrc4Brand27 },
  { label: "EXPLORER 125", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 60", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 70", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 80", brand: oBTrc4Brand27 },
  { label: "EXPLORER II 90", brand: oBTrc4Brand27 },
  { label: "WORLDTRAC 90RX", brand: oBTrc4Brand28 },
  { label: "DI-740 III", brand: oBTrc4Brand28 },
  { label: "DI-730 III", brand: oBTrc4Brand28 },
  { label: "DI=60", brand: oBTrc4Brand28 },
  { label: "DI-60RX", brand: oBTrc4Brand28 },
  { label: "DI-745 III", brand: oBTrc4Brand28 },
  { label: "DI-745 RX III", brand: oBTrc4Brand28 },
  { label: "DI-750", brand: oBTrc4Brand28 },
  { label: "DI-26", brand: oBTrc4Brand28 },
  { label: "DI-35", brand: oBTrc4Brand28 },
  { label: "DI-42", brand: oBTrc4Brand28 },
  { label: "DI-47", brand: oBTrc4Brand28 },
  { label: "DI-50", brand: oBTrc4Brand28 },
  { label: "DI-55", brand: oBTrc4Brand28 },
  { label: "DI 750 III", brand: oBTrc4Brand28 },
  { label: "WORLDTRAC 75 RX", brand: oBTrc4Brand28 },
  { label: "WORLDTRAC 60 RX", brand: oBTrc4Brand28 },
  { label: "RX 35", brand: oBTrc4Brand28 },
  { label: "RX 42", brand: oBTrc4Brand28 },
  { label: "RX 47", brand: oBTrc4Brand28 },
  { label: "RX 50", brand: oBTrc4Brand28 },
  { label: "RX 60", brand: oBTrc4Brand28 },
  { label: "RX 750 III DLX", brand: oBTrc4Brand28 },
  { label: "A950", brand: oBTrc4Brand29 },
  { label: "TS354", brand: oBTrc4Brand30 },
  { label: "TS404", brand: oBTrc4Brand30 },
  { label: "TS454", brand: oBTrc4Brand30 },
  { label: "TS504", brand: oBTrc4Brand30 },
  { label: "T854", brand: oBTrc4Brand30 },
  { label: "TT904", brand: oBTrc4Brand30 },
  { label: "TT1004", brand: oBTrc4Brand30 },
  { label: "TT1104", brand: oBTrc4Brand30 },
  { label: "TT1204", brand: oBTrc4Brand30 },
  { label: "EF393T", brand: oBTrc4Brand31 },
  { label: "EF514T", brand: oBTrc4Brand31 },
  { label: "EF725T", brand: oBTrc4Brand31 },
  { label: "YM351A", brand: oBTrc4Brand31 },
  { label: "YM357A", brand: oBTrc4Brand31 },

  { label: "JG90Z", brand: oBCaneBrand1 },
  { label: "ASZ-7600", brand: oBCaneBrand2 },

  { label: "3510", brand: oBHrvtBrand1 },
  { label: "A4000", brand: oBHrvtBrand2 },
  { label: "A7000", brand: oBHrvtBrand2 },
  { label: "A7700", brand: oBHrvtBrand2 },
  { label: "A8000", brand: oBHrvtBrand2 },
  { label: "A8800", brand: oBHrvtBrand2 },
  { label: "A8010", brand: oBHrvtBrand2 },
  { label: "A8810", brand: oBHrvtBrand2 },
  { label: "No model specified", brand: oBHrvtBrand3 },
  { label: "7000", brand: oBHrvtBrand4 },
  { label: "No model specified", brand: oBHrvtBrand5 },
  { label: "CH 570", brand: oBHrvtBrand6 },
  { label: "CH 330", brand: oBHrvtBrand6 },
  { label: "3520", brand: oBHrvtBrand6 },
  { label: "JG 4GL-1", brand: oBHrvtBrand7 },
  { label: "SH95", brand: oBHrvtBrand8 },
  { label: "3737", brand: oBHrvtBrand9 },
  { label: "4000", brand: oBHrvtBrand10 },
  { label: "6000", brand: oBHrvtBrand10 },
  { label: "4GQ-1", brand: oBHrvtBrand11 },
  { label: "HS2000", brand: oBHrvtBrand12 },
  { label: "SM200", brand: oBHrvtBrand13 },
  { label: "SM200 GIANT", brand: oBHrvtBrand13 },

  { label: "KC-40", brand: oBPumpBrand1 },
  { label: "KCS40-E", brand: oBPumpBrand1 },
  { label: "KDP100", brand: oBPumpBrand1 },
  { label: "KC40", brand: oBPumpBrand1 },
  { label: "KSP40", brand: oBPumpBrand1 },
  { label: "KSP30", brand: oBPumpBrand1 },
  { label: "KC30 3X3", brand: oBPumpBrand1 },
  { label: "KDP50 2X2", brand: oBPumpBrand1 },
  { label: "KT50", brand: oBPumpBrand1 },
  { label: "CP-200L", brand: oBPumpBrand2 },
  { label: "NVC-15", brand: oBPumpBrand3 },
  { label: "NVC-22", brand: oBPumpBrand3 },
  { label: "KBP20", brand: oBPumpBrand4 },
  { label: "KBP30", brand: oBPumpBrand4 },
  { label: "GDW-9295-6", brand: oBPumpBrand5 },
  { label: "HP80D", brand: oBPumpBrand6 },
  { label: "HP50D", brand: oBPumpBrand6 },
  { label: "4X4 MS", brand: oBPumpBrand7 },
  { label: "4X4 PT", brand: oBPumpBrand7 },
  { label: "3X3 MS", brand: oBPumpBrand7 },
  { label: "3X3 PT", brand: oBPumpBrand7 },
  { label: "2X2 MS", brand: oBPumpBrand7 },
  { label: "2X2 PT", brand: oBPumpBrand7 },
  { label: "RPX206", brand: oBPumpBrand8 },
  { label: "RP205", brand: oBPumpBrand8 },
  { label: "RP203", brand: oBPumpBrand8 },
  { label: "PTG207", brand: oBPumpBrand8 },
  { label: "RP305", brand: oBPumpBrand8 },
  { label: "RP306", brand: oBPumpBrand8 },
  { label: "PTG305", brand: oBPumpBrand8 },
  { label: "NS100", brand: oBPumpBrand9 },
  { label: "NS80", brand: oBPumpBrand9 },
  { label: "NS50", brand: oBPumpBrand9 },
  { label: "RBP-305", brand: oBPumpBrand9 },
  { label: "NS100", brand: oBPumpBrand10 },
  { label: "EC100", brand: oBPumpBrand10 },
  { label: "NS-80", brand: oBPumpBrand10 },
  { label: "EC80", brand: oBPumpBrand10 },
  { label: "NS-50", brand: oBPumpBrand10 },
  { label: "EC50", brand: oBPumpBrand10 },
  { label: "TS-100", brand: oBPumpBrand11 },
  { label: "4X4 PACKING TYPE", brand: oBPumpBrand11 },
  { label: "4X4 MS", brand: oBPumpBrand11 },
  { label: "NS100", brand: oBPumpBrand11 },
  { label: "3X3 MS", brand: oBPumpBrand11 },
  { label: "NS80", brand: oBPumpBrand12 },
  { label: "2X2 MECHANICAL TYPE", brand: oBPumpBrand12 },
  { label: "3X3 PACKING STYLE", brand: oBPumpBrand12 },
  { label: "4X4 MECHANICAL SEAL", brand: oBPumpBrand12 },
  { label: "4X4 PACKING TYPE", brand: oBPumpBrand13 },
  { label: "NS100", brand: oBPumpBrand14 },
  { label: "TS100", brand: oBPumpBrand14 },
  { label: "BXD-100-P", brand: oBPumpBrand15 },
  { label: "BXD-80P", brand: oBPumpBrand15 },
  { label: "KDP20", brand: oBPumpBrand16 },
  { label: "KDP30", brand: oBPumpBrand16 },
  { label: "KDP40", brand: oBPumpBrand16 },
  { label: "4PSP2-3", brand: oBPumpBrand17 },
  { label: "DPW50", brand: oBPumpBrand18 },
  { label: "DHP50", brand: oBPumpBrand18 },
  { label: "DPW80", brand: oBPumpBrand18 },
  { label: "DHP80", brand: oBPumpBrand18 },
  { label: "DPW-100", brand: oBPumpBrand18 },
  { label: "KDP40", brand: oBPumpBrand19 },
  { label: "KDP30", brand: oBPumpBrand19 },
  { label: "KDP20", brand: oBPumpBrand19 },
  { label: "TCM-4B", brand: oBPumpBrand20 },
  { label: "TCM-4A", brand: oBPumpBrand20 },
  { label: "TCM 4H", brand: oBPumpBrand20 },
  { label: "TDCV-4", brand: oBPumpBrand20 },
  { label: "CRIF15-01", brand: oBPumpBrand21 },
  { label: "SQF 2.2-2", brand: oBPumpBrand21 },
  { label: "KDP3", brand: oBPumpBrand22 },
  { label: "YP30G", brand: oBPumpBrand23 },
  { label: "KDP40", brand: oBPumpBrand24 },
  { label: "KDP20", brand: oBPumpBrand24 },
  { label: "KDP30", brand: oBPumpBrand24 },
  { label: "WB30XH", brand: oBPumpBrand25 },
  { label: "WB20XH", brand: oBPumpBrand25 },
  { label: "TE5-80R", brand: oBPumpBrand26 },
  { label: "TE5-50R", brand: oBPumpBrand26 },
  { label: "LTP50C", brand: oBPumpBrand27 },
  { label: "LTP80C", brand: oBPumpBrand27 },
  { label: "MS50 2X2", brand: oBPumpBrand28 },
  { label: "MS50 3X3", brand: oBPumpBrand28 },
  { label: "LXT100", brand: oBPumpBrand29 },
  { label: "LXT80", brand: oBPumpBrand29 },
  { label: "TS100", brand: oBPumpBrand30 },
  { label: "NS100", brand: oBPumpBrand30 },
  { label: "PT5.5 HP", brand: oBPumpBrand31 },
  { label: "PT6.5 HP", brand: oBPumpBrand31 },
  { label: "PS 1200HR-14", brand: oBPumpBrand32 },
  { label: "WP3-65", brand: oBPumpBrand33 },
  { label: "D50KB", brand: oBPumpBrand34 },
  { label: "D80KB", brand: oBPumpBrand34 },
  { label: "D100KB", brand: oBPumpBrand34 },
  { label: "SD100M", brand: oBPumpBrand35 },
  { label: "DT53", brand: oBPumpBrand36 },
  { label: "IW-407", brand: oBPumpBrand37 },
  { label: "WP30C", brand: oBPumpBrand38 },
  { label: "WP-30", brand: oBPumpBrand39 },
  { label: "WP20X", brand: oBPumpBrand40 },
  { label: "CGZ100-30", brand: oBPumpBrand41 },
  { label: "KCS-40", brand: oBPumpBrand1 },
];
