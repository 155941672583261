// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { MUI_THEME } from "./Themes";
import pkgJson from "../../package.json";

// /////////////////////////////////////////////////////////////////////////////
// Application Settings ////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const AppSettings = {
  /**
   * "M3DAS Web App"
   * The title of this web application.
   */
  TITLE: pkgJson.title,

  /**
   * "#.#.#-###"
   * The version of this web application.
   */
  VERSION: pkgJson.version,

  /**
   * "M3DAS Web App v#.#.#-###"
   * The title and version of this web application.
   */
  TITLE_VERSION: pkgJson.title + " v" + pkgJson.version,

  /**
   * !!! Update before deployment.
   * Toggle to show or hide questions in survey forms.
   * Default: false
   */
  showAll: false,
  // showAll: true,

  /**
   * !!! Update before deployment.
   * The server URL of the backend API.
   */
  // ? Look for the possibility of using docker
  DEVSERVER: "http://127.0.0.1:7002",
  SERVER: "https://m3das.avzx.cloud",
  // SERVER: "http://127.0.0.1:7002",
  // SERVER: "https://202.92.144.27:4433",
  // SERVER: "https://202.92.144.27:4434",

  /**
   * The minimum accuracy of the GPS location.
   */
  MIN_ACCURACY: 8,

  /**
   * !!! Update before deployment.
   */
  SHOWCONSENT: true,
  // SHOWCONSENT: false,

  /**
   * Theme for the MUI components of this web application.
   */
  MUI_THEME: MUI_THEME,

  /**
   * The width breakpoint for the responsive design of this web application.
   */
  WIDTHBREAKPOINT: 768,

  /**
   *
   */
  DATABASES: ["ley", "ner", "nob", "nol"],

  /**
   *
   */
  ZIPPASS: "M3das1.0",
};
