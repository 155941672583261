import "./WebMapPage.css";
import { Await, LoaderFunctionArgs } from "react-router-dom";
import { AvzRoute, requireLoadProperly } from "../../app/Routes";
import axios from "axios";
import React from "react";
import { GetImage } from "../../app/Server";
import { fxdRead, fxdUser } from "../../app/Storage";
// import { AUTH_PROVIDER } from "../../hooks/auth/auth";
import { AVPopup } from "../../arv/com/AVPopup";
// import { VBar } from "../../custom/afx/ABox";
// import { GenerateTable } from "../verify/M3Tablex";
import m3dasLogo from "../../assets/img/icon/m3das/logo512.png";
// import DataArrayIcon from "@mui/icons-material/DataArray";
// import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import CopyrightIcon from "@mui/icons-material/Copyright";
// import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import TX from "../../../package.json";
import L from "leaflet";

import iMachine from "./assets/svg/machine.png";
import iImplement from "./assets/svg/implement.png";
import iSRAOffice from "./assets/svg/sraoffice.png";
import iMillDistrict from "./assets/svg/milldistrict.png";

import { Implements, Machines } from "./DataLoader";
import { SRA_OFFICES, SUGAR_MILL } from "./assets/geojson/SRADistrictOffices";
// import { SampleLandUse } from "./assets/geojson/SampleLandUse";

import "../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
import "../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
import "../../../node_modules/leaflet.markercluster/dist/leaflet.markercluster.js";
import { MachineData } from "../machine/MachinePage";
// import { dateOnly } from "../../app/GenFunctions";

export function WebMapPageLoader({ request }: LoaderFunctionArgs) {
  return null;
}

export default function WebMapPage() {
  return (
    requireLoadProperly(AvzRoute.map) ?? (
      <Await
        resolve={[Machines(), Implements()]}
        errorElement={<div>Failed to load data</div>}
        children={(res) => <WebMapUI machines={res[0]} implements={res[1]} />}
      ></Await>
    )
  );
}

function WebMapUI(props: { profile?: any; machines?: any; implements?: any }) {
  // const [open, setopen] = useState(true);
  const open = false;
  // const [visArea, setVisArea] = useState(false);

  // const toggleOpen = () => {
  //   setopen(!open);
  //   if (open) setVisArea(false);
  // };

  // const [ct, setCT] = useState<JSX.Element>(<div></div>);

  const LogoBig = (props: { open: boolean }) => {
    var fname = fxdRead(fxdUser.frNm);
    var lname = fxdRead(fxdUser.lsNm);
    var name = fname + " " + lname;
    var off = fxdRead(fxdUser.offc);
    var pos = fxdRead(fxdUser.role);

    return (
      <div hidden={!props.open} className="user-info">
        <img
          alt="m3das-logo"
          src={m3dasLogo}
          className="big-icon"
          onClick={() => (window.location.href = AvzRoute.home)}
        />
        <p className="text-center">{name.toUpperCase()}</p>
        <p className="text-center">{off + " " + pos}</p>
      </div>
    );
  };

  const LogoSmall = (props: { open: boolean }) => (
    <div hidden={props.open} className="user-info">
      <img
        alt="m3das-logo"
        src={m3dasLogo}
        className="small-icon"
        onClick={() => (window.location.href = AvzRoute.home)}
      />
    </div>
  );

  const Copyright = (props: { open: boolean }) => {
    return props.open ? (
      <p className="text-copyright">
        Copyright © 2024
        <br /> All rights reserved <br /> UPLB BIOMECH M3DAS
      </p>
    ) : (
      <button
        className={"btn-side"}
        onClick={() => {
          // APopup version
          AVPopup({
            icon: "info",
            text: "M3DAS Web App Version " + TX.version,
            confirmButtonText: "Close",
          });
        }}
      >
        <CopyrightIcon />
        <span className={"link-text"}></span>
      </button>
    );
  };

  setTimeout(() => {
    try {
      var sraOfficeIcon = L.icon({
        iconUrl: iSRAOffice,
        iconSize: [32, 32],
        popupAnchor: [0, -16],
      });

      var millDistrictIcon = L.icon({
        iconUrl: iMillDistrict,
        iconSize: [32, 32],
        popupAnchor: [0, -16],
      });

      let myMapElement = document.getElementById("webmap");
      var map = L.map(myMapElement!).setView([14.16255, 121.248497], 6);

      // ORIGINAL https://tile.openstreetmap.org/{z}/{x}/{y}.png
      // OpenStreetMap
      // http://tile.openstreetmap.org/{z}/{x}/{y}.png
      // OpenTopoMap
      // https://tile.opentopomap.org/{z}/{x}/{y}.png
      // Google Hybrid
      // https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}
      // Google Satellite
      // https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}
      // Google Road
      // https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}
      // Bing Aerial
      // http://ecn.t3.tiles.virtualearth.net/tiles/a{q}.jpeg?g=1

      L.tileLayer("https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
        maxZoom: 19,
        // attribution:
        //   '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map);

      // var sugarMillStyle = {
      //   color: "#ff0000",
      //   weight: 5,
      //   opacity: 0.65,
      // };

      var sugarMillCluster = L.markerClusterGroup({
        // iconCreateFunction: function (cluster) {
        //   return L.divIcon({ html: "<b>" + cluster.getChildCount() + "</b>" });
        // },
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            html:
              '<div class="d-flex justify-content-center" style="background: url(' +
              iMillDistrict +
              ');min-width: 32px;max-width: 32px;min-height: 32px;max-height: 32px;background-size: cover;"><div class="align-self-end" style="background: var(--bs-red);min-width: 20px;max-width: 20px;min-height: 20px;max-height: 20px;border-radius: 10px;box-shadow: 0px 0px 9px var(--bs-emphasis-color);"><p class="align-self-end" style="font-size: 12px;font-weight: bold;color: white;text-align: center;margin-top: 2px;">' +
              cluster.getChildCount() +
              "</p></div></div>",
          });
        },
      });

      L.geoJSON(
        SUGAR_MILL,
        // { style: sugarMillStyle }
        {
          pointToLayer: (feature, latlng) =>
            // L.marker(latlng, { icon: tractorIcon }),
            sugarMillCluster.addLayer(
              L.marker(latlng, { icon: millDistrictIcon })
                // .bindAPopup(
                //   "<p> Mill District: " + feature.properties.NAME_MILL + "</p>"
                // )
                .on("click", function (e) {
                  AVPopup({
                    icon: "info",
                    title: feature.properties.NAME_MILL,
                    text: "Mill District",
                    confirmButtonText: "Close",
                  });
                })
            ),
        }
        // ).addTo(map);
      );
      map.addLayer(sugarMillCluster);

      var SRAOfficeCluster = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            html:
              '<div class="d-flex justify-content-center" style="background: url(' +
              iSRAOffice +
              ');min-width: 32px;max-width: 32px;min-height: 32px;max-height: 32px;background-size: cover;"><div class="align-self-end" style="background: gray;min-width: 20px;max-width: 20px;min-height: 20px;max-height: 20px;border-radius: 10px;box-shadow: 0px 0px 9px var(--bs-emphasis-color);"><p class="align-self-end" style="font-size: 12px;font-weight: bold;color: white;text-align: center;margin-top: 2px;">' +
              cluster.getChildCount() +
              "</p></div></div>",
          });
        },
      });

      L.geoJSON(SRA_OFFICES, {
        pointToLayer: (feature, latlng) =>
          // L.marker(latlng, { icon: tractorIcon }),
          SRAOfficeCluster.addLayer(
            L.marker(latlng, { icon: sraOfficeIcon })
              // .bindAPopup("<p> SRA Office: " + feature.properties.Name + "</p>")
              .on("click", function (e) {
                AVPopup({
                  icon: "info",
                  title: feature.properties.Name,
                  text: "SRA Office",
                  confirmButtonText: "Close",
                });
              })
          ),
      });
      // .addTo(map);
      // L.geoJSON(SampleLandUse).addTo(map);
      map.addLayer(SRAOfficeCluster);

      var MachineCluster = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            html:
              '<div class="d-flex justify-content-center" style="background: url(' +
              iMachine +
              ');min-width: 32px;max-width: 32px;min-height: 32px;max-height: 32px;background-size: cover;"><div class="align-self-end" style="background: gray;min-width: 20px;max-width: 20px;min-height: 20px;max-height: 20px;border-radius: 10px;box-shadow: 0px 0px 9px var(--bs-emphasis-color);"><p class="align-self-end" style="font-size: 12px;font-weight: bold;color: white;text-align: center;margin-top: 2px;">' +
              cluster.getChildCount() +
              "</p></div></div>",
          });
        },
      });

      props.machines.then((machines: any) => {
        machines.forEach((machine: MachineData | any) =>
          LoadMachinePoint(machine, MachineCluster, map)
        );
      });

      var ImplementCluster = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            html:
              '<div class="d-flex justify-content-center" style="background: url(' +
              iImplement +
              ');min-width: 32px;max-width: 32px;min-height: 32px;max-height: 32px;background-size: cover;"><div class="align-self-end" style="background: gray;min-width: 20px;max-width: 20px;min-height: 20px;max-height: 20px;border-radius: 10px;box-shadow: 0px 0px 9px var(--bs-emphasis-color);"><p class="align-self-end" style="font-size: 12px;font-weight: bold;color: white;text-align: center;margin-top: 2px;">' +
              cluster.getChildCount() +
              "</p></div></div>",
          });
        },
      });

      props.implements.then((implementss: any) => {
        implementss.forEach((implement: any) =>
          LoadImplementPoint(implement, ImplementCluster, map)
        );
      });
    } catch (e) {
      // console.error(e);
    }
  }, 5000);

  return (
    <div className="webmap-page">
      <div
        className={`main-bg-animated side-nav ${open ? "" : "side-nav-close"}`}
      >
        <div>
          <LogoBig open={open} />
          <LogoSmall open={open} />
          {open ? <p></p> : null}
          <div className="btn-side">
            <button
              className={`btn-menu ${open ? "" : "btn-menu-close"}`}
              // onClick={toggleOpen}
            >
              {open ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <KeyboardDoubleArrowRightIcon />
              )}
              {open ? <p className="link-text">Hide</p> : null}
            </button>
          </div>
          {/* <button className="btn-side" onClick={() => loadMap()}>
              <BarChartIcon />
              <span hidden={!open} className="link-text">
                LoadMap
              </span>
              <ArrowDown hidden={!open} />
            </button> */}
          {/* <Areas open={visArea} title={AreasList} /> */}
          <a className="btn-side" href="#settings">
            <SettingsIcon />
            <span hidden={!open} className="link-text">
              Settings
            </span>
          </a>
        </div>
        <Copyright open={open} />
      </div>
      <div className={`main-pane ${open ? "" : "main-pane-wide"}`}>
        <div className="table-set">
          {" "}
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
            integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
            crossOrigin="anonymous"
          />
          <script
            src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
            integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
            crossOrigin="anonymous"
          ></script>
          <div id={"webmap"}></div>
        </div>
      </div>
    </div>
  );
}

// /////////////////////////////////////////////////////////////////////////////

function getCleanValue(value: string) {
  if (value.charAt(0) === '"') {
    return value.substring(1, value.length - 1);
  } else if (value.charAt(0) === "{") {
    return JSON.parse(value).label ?? JSON.parse(value);
  } else return value;
}

function isNullNA(value: string) {
  if (value === "" || value === "NA" || value === "N/A") return true;
  else return false;
}

// Machine /////////////////////////////////////////////////////////////////////

var machineIcon = L.icon({
  iconUrl: iMachine,
  // shadowUrl: "leaf-shadow.png",

  iconSize: [32, 32], // size of the icon
  // shadowSize: [50, 64], // size of the shadow
  // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  // shadowAnchor: [4, 62], // the same for the shadow
  popupAnchor: [0, -16], // point from which the Apopup should open relative to the iconAnchor
});

function LoadMachinePoint(
  machine: MachineData | any,
  MachineCluster: any,
  map: any
) {
  if (machine.qrCode.charAt(0) !== "X") {
    MachineCluster.addLayer(
      L.marker(
        [
          parseFloat(machine.lat.replace('"', "")),
          parseFloat(machine.lng.replace('"', "")),
        ],
        {
          icon: machineIcon,
        }
      ).on("click", async function (e) {
        await axios
          .get(
            GetImage(
              "data_" + machine.db + "_machine",
              "qrCode",
              machine.qrCode,
              "imageFront"
            )
          )
          .then((res) => {
            var machineHTML =
              '<div id="info-card">' +
              '<div class="card">' +
              DisplayImage(res.data) +
              '<div id="info-body" class="card-body">' +
              DisplayQRCode(machine.qrCode) +
              DisplayText("Machine Type", machine.macType) +
              DisplayText("Machine Brand", machine.brand) +
              DisplayText("Machine Model", machine.model) +
              DisplayText("Rated Power", machine.rPower) +
              DisplayDate("Year Acquired", machine.acqYear) +
              DisplayText("For rental", machine.rental) +
              DisplayText("Service Center", machine.sName) +
              DisplayText("Present Condition", machine.pCond) +
              "</div>" +
              "</div>" +
              "</div>";
            AVPopup({
              title: machineHTML,
              confirmButtonText: "Close",
              allowOutsideClick: true,
              showConfirmButton: false,
              position: "center-right",
              background: "transparent",
              backdrop: "transparent",
            });
          });
      })
    );
  }

  map.addLayer(MachineCluster);
}

function DisplayImage(value: string) {
  return isNullNA(getCleanValue(value))
    ? ""
    : '<img id="info-img" class="card-img-top pic" />' +
        "<style>" +
        ".pic {" +
        "background: url(" +
        value +
        ") no-repeat;" +
        "background-size: cover;" +
        "display: flex;" +
        "flex-direction: column;" +
        "align-items: center;" +
        "justify-content: center;" +
        "min-height: 500px" +
        "}" +
        "</style>";
}

function DisplayQRCode(value: string) {
  return isNullNA(getCleanValue(value))
    ? ""
    : "<div>" +
        '<p class="info-text qr-code">' +
        getCleanValue(value) +
        "</p>" +
        "</div>";
}

function DisplayText(key: string, value: string) {
  return isNullNA(getCleanValue(value))
    ? ""
    : '<div class="row">' +
        '<div class="col" style="margin: 0px;padding: 0px;">' +
        '<p class="info-text specs">' +
        key +
        "</p>" +
        "</div>" +
        '<div class="col" style="margin: 0px;padding: 0px;">' +
        '<p class="info-text specs-info">' +
        getCleanValue(value).toUpperCase() +
        "</p>" +
        "</div>" +
        "</div>";
}

function DisplayDate(key: string, value: string) {
  return DisplayText(
    key,
    new Date(getCleanValue(value)).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );
}

// Implement ///////////////////////////////////////////////////////////////////

var implementIcon = L.icon({
  iconUrl: iImplement,
  iconSize: [32, 32],
  popupAnchor: [0, -16],
});

function LoadImplementPoint(implement: any, ImplementCluster: any, map: any) {
  if (implement.qrCode.charAt(0) !== "X") {
    ImplementCluster.addLayer(
      L.marker(
        [
          parseFloat(implement.lat.replace('"', "")),
          parseFloat(implement.lng.replace('"', "")),
        ],
        {
          icon: implementIcon,
        }
      ).on("click", async function (e) {
        await axios
          .get(
            GetImage(
              "data_" + implement.db + "_implement",
              "qrCode",
              implement.qrCode,
              "front"
            )
          )
          .then((res) => {
            var implementHTML =
              '<div id="info-card">' +
              '<div class="card">' +
              DisplayImage(res.data) +
              '<div id="info-body" class="card-body">' +
              DisplayQRCode(implement.qrCode) +
              DisplayText("Implement Type", implement.type) +
              DisplayText("Implement Brand", implement.brand) +
              DisplayText("Implement Model", implement.model) +
              DisplayText("Implement Function", implement.rFrtOpe) +
              DisplayText("Effective Area", implement.pEffArea) +
              DisplayDate("Year Acquired", implement.oAYear) +
              DisplayText("For rental", implement.rental) +
              DisplayText("Present Condition", implement.presCon) +
              DisplayText("Connected to Machine", implement.machine) +
              "</div>" +
              "</div>" +
              "</div>";
            AVPopup({
              title: implementHTML,
              allowOutsideClick: true,
              showConfirmButton: false,
              position: "center-right",
              background: "transparent",
              backdrop: "transparent",
            });
          });
      })
    );
  }

  map.addLayer(ImplementCluster);
}
