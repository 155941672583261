export const TX = {
  pop: {
    noProfile: {
      title: "No Profile Found",
      message:
        "You need to create a profile first before you can proceed with the machine survey.",
      button: "Create Profile",
      message2: "Please select the respondent first.",
      button2: "Okay",
    },
    backup: {
      title: "Edit / Restore",
      message:
        "Would you like to continue with the unsaved machine? Or proceed with editing.",
      button: "Recover",
      deny: "Edit",
    },
    qrError: {
      title: "QR Code Error",
      message: "The QR code is not valid. Please scan a valid QR code.",
      button: "Close",
    },
    geoLoc: {
      init: {
        title: "Initializing Geolocation",
        message:
          "You can proceed with the survey while waiting for the location.",
        button: "Okay",
      },
      initError: {
        title: "Geolocation Error",
        message:
          "There was an error initializing the geolocation. Your browser doesn't support geolocation feature!",
        button: "Exit",
      },
      success: {
        title: "Geolocation Found",
        button: "Close",
        lat: "Your coordinate is: Lat: ",
        lng: " Long: ",
        acc: " Accuracy: ",
      },
    },
    saveErrors: {
      browserError: "Upgrade your browser.",
      missing: "Kindly fill out the missing fields above.",
      button: "Go to Error",
      saveIni: "Initializing for saving . . .",
      withError: "There are errors in the form. Correct them before saving.",
    },
    save: {
      title: "Data Saved",
      message: "The machine data has been saved successfully.",
      button: "Close",
    },
    operator: {
      title: "Operator count does not match",
      message:
        "The number of machine operator for this machine only should match the sum of operators with formal training and without formal training.",
      button: "Okay",
      deny: "Clear",
    },
    accuracy: {
      init: "Initializing . . .",
      high: "High Accuracy",
      low: "Low Accuracy",
      search: "Searching for location . . .",
    },
    loadContact: {
      title: "Contact Information",
      message1: "Set Primary Contact:",
      message2: "Secondary Contact:",
      message3: "from",
      message4: ".",
      button: "Okay",
      deny: "Cancel",
    },
    lowAcc: {
      message:
        "Accuracy is too low. Kindly wait for the accuracy to meet atleast 8 meters.",
    },
  },
  question: {
    category: "Machine",
    tM1Title: "Basic Information",
    tM1ResName: {
      label: "Name of Respondent",
      placeholder: "First Name MI. Last Name",
    },
    tM1QRCode: {
      label: "Scan Machine QR Code",
      placeholder: "Click to Start Scanning",
    },
    tM1QRCodeVideo: { label: "QRCode Camera", placeholder: "Select Camera" },
    tM1FrontVw: {
      label: "Capture Front View",
      placeholder: "Click to Capture Picture",
    },
    tM1SideVw: {
      label: "Capture Side View",
      placeholder: "Click to Capture Picture",
    },
    tM1SpecVw: {
      label: "Capture Specification Plate",
      placeholder: "Click to Capture Picture",
    },
    tM1Acc: {
      label: "Accuracy",
      placeholder: "0.000000, 0.000000",
    },
    tM1Lat: {
      label: "Latitude",
      placeholder: "0.000000",
    },
    tM1Lng: {
      label: "Longitude",
      placeholder: "0.000000",
    },
    tM1Loc: {
      label: "Location during Survey",
      placeholder: "Choose an Option",
      options: [
        { label: "Garage" },
        { label: "Field" },
        { label: "Transloading Zone" },
      ],
    },
    tM1Garage: "Garage Location",
    tM1Prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    tM1Mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    tM1Brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    tM1Type: {
      label: "Machine Type",
      placeholder: "Select Machine Type",
    },
    tM1Serial: {
      label: "Engine Serial Number",
      placeholder: "Input the Engine Serial Number",
    },
    tM1CovHlng: {
      label: "Coverage of Hauling",
      placeholder: "Select Coverage of Hauling",
      options: [
        { label: "Farm to Transloading Zone only", key: 0 },
        { label: "Farm to Sugar Mill (No Transloading Zone)", key: 1 },
        { label: "Farm to Transloading Zone to Sugar Mill", key: 2 },
        { label: "Transloading Zone to Sugar Mill", key: 3 },
      ],
    },
    tM2Title: "Machine Specifications",
    tM2Brand: { label: "Brand", placeholder: "Select Machine Brand" },
    tM2Model: { label: "Model", placeholder: "Select Machine Model" },
    tM2RtdPwr: {
      label: "Rated Power",
      placeholder: "0.00",
      unit: "hp",
    },
    tM2TtlSrvArea: {
      label: "Total Service Area",
      placeholder: "0.00",
      unit: "ha",
    },
    tM2AvgOpHrDy: {
      label: "Ave. Operating Hours per Day",
      placeholder: "0.0",
      unit: "hrs/day",
    },
    tM2AvgOpDySsn: {
      label: "Ave. Operating Days per Season",
      placeholder: "0",
      unit: "days/season",
    },
    tM2LdCap: {
      label: "Loading Capacity",
      placeholder: "0.0",
      loadText: "load",
    },
    tM2LdCapUnit: {
      label: "Unit",
      placeholder: "Choose",
      options: [
        { label: "ton/load" },
        { label: "ton/day" },
        { label: "kg/load" },
        { label: "kg/day" },
      ],
    },
    tM2LdCapDay: {
      label: "Load per Day",
      placeholder: "0",
      unit: "load/day",
    },
    tM2TmCom1Ha: {
      label: "Time to Complete One Hectare",
      placeholder: "0.0",
      unit: "hr",
    },
    tM2SgrcnYld: {
      label: "Sugarcane Yield",
      placeholder: "0.0",
      unit: "tons/ha",
    },
    tM3Title: "MACHINE SPECIFICATIONS",
    tM3RainGun: "RAIN GUN",
    tM3Specs: "Pump Specifications",
    tM3TpTbWlls: {
      label: "Type of Tube Well",
      placeholder: "Choose an Option",
      options2: [
        { label: "Shallow tubewell (0 to 20 ft)" },
        { label: "Deep well (more than 20 ft)" },
        { label: "Open Source (River, Lake, Canal, Reservoir)" },
      ],
    },
    tM3Brand: {
      label: "Brand",
      placeholder: "Select Brand",
    },
    tM3Model: {
      label: "Model",
      placeholder: "Select Model",
    },
    tM3SzPmp: {
      label: "Size of Pump",
      placeholder: "0.00",
      unit: "in",
    },
    tM3SPrime: "Prime Mover Specifications",
    tM3SrcPwr: {
      label: "Source of Power",
      placeholder: "Choose Source of Power",
      options: [
        { label: "Engine" },
        { label: "Solar-powered Electric Motor" },
        { label: "Tractor PTO" },
      ],
    },
    tM3SrcBrand: {
      label: "Brand",
      placeholder: "Select Brand",
    },
    tM3SrcModel: {
      label: "Model",
      placeholder: "Select Model",
    },
    tM3RtdPwr: {
      label: "Rated Power",
      placeholder: "0.00",
      unit: "hp",
    },
    tM3Output: "Water Pump Output",
    tM3DisCap: {
      label: "Discharge Capacity",
      placeholder: "0.0",
      options: [
        { label: "cubic meter / sec" },
        { label: "gallon / sec" },
        { label: "liters / sec" },
      ],
    },
    tM3DisCapUnit: {
      label: "Unit",
      placeholder: "Choose",
    },
    tM3TtlSrvArea: {
      label: "Total Service Area",
      placeholder: "0.00",
      unit: "ha",
    },
    tM3AvgOpHrDy: {
      label: "Ave. Operating Hours per Day",
      placeholder: "0.0",
      unit: "hrs/day",
    },
    tM3AvgOpDySsn: {
      label: "Ave. Operating Days per Season",
      placeholder: "0",
      unit: "days/season",
    },
    tM4Title: "Ownership Details",
    tM4Usage: {
      label: "Machine Usage",
      placeholder: "Select Usage",
      options: [
        { label: "Private Use" },
        { label: "For Rental to Members only", anchored: true },
        { label: "For Rental to Customers", anchored: true },
      ],
    },
    tM4Mode: {
      label: "Mode of Acquisition",
      placeholder: "Select Mode of Acquisition",
      options: [
        { label: "Cash-purchased" },
        { label: "Loan-purchased" },
        { label: "Grant", anchored: true },
        { label: "Donation", anchored: true },
      ],
    },
    tM4Agency: {
      label: "Name of Agency",
      placeholder: "Name of Agency",
    },
    tM4YrAcqrd: { label: "Year Acquired" },
    tM4ConAcqrd: {
      label: "Condition when Acquired",
      placeholder: "Select Condition",
      options: [
        { label: "Brand New" },
        { label: "Secondhand" },
        { label: "Reconditioned" },
        { label: "Data to be acquired" },
      ],
    },
    tM4PrsntCon: {
      label: "Present Condition",
      placeholder: "Select Condition",
      options: [
        { label: "Functional and Used" },
        { label: "Functional but Unused", anchored: true },
        { label: "Non-functional", anchored: true },
        { label: "Data to be acquired" },
      ],
    },
    tM4YrUnused: { label: "Year this Became Unused" },
    tM4RsnUnused: {
      label: "Why is this unused?",
      options: [
        { label: "Newly Awarded", key: 0 },
        {
          label: "This is not suitable to the condition of the area",
          key: 1,
        },
        { label: "No one is skilled enough to operate this", key: 2 },
        { label: "Custom hiring rate for this is too high", key: 3 },
        { label: "This was not turned over to the beneficiaries", key: 4 },
      ],
    },
    tM4UuOthers: {
      label: "Others, please specify",
      placeholder: "Input Reason",
    },
    tM4YrUnserv: { label: "Year this Became Unserviceable" },
    tM4RsnUnserv: {
      label: "Why is this unserviceable?",
      options: [
        { label: "For Repair", key: 0 },
        { label: "Too Old Unrepairable", key: 1 },
        { label: "Obsolete", key: 2 },
      ],
    },
    tM4UsOthers: {
      label: "Others, please specify",
      placeholder: "Input Reason",
    },
    tM4NoBrkdwn: {
      label: "Number of Breakdowns",
      placeholder: "0",
      unit: "times",
    },
    tM4CmnBrkdwn: {
      label: "Common Breakdowns",
      placeholder: "Choose up to three",
      options: [
        { label: "Overheating" },
        { label: "Deformed, Broken or Worn-out Replaceable Parts" },
        { label: "Detachment, Deformation or Absence of Vital Parts" },
        { label: "Faulty Electrical System, Spark Plug" },
        { label: "Leakage" },
        { label: "Faulty Hydraulic System" },
        { label: "Clogged Parts" },
        { label: "Others, specify in Survey Notes", anchored: true },
        { label: "Not applicable", anchored: true },
      ],
    },
    tM5Title: "Renting Details",
    tM5Subtitle: "Contact Information if Service Provider",
    tM5SameOwn: [{ label: "Same as Owner's Contact Number", key: 0 }],
    tM5BusCont1: {
      label: "Business Contact No. 1",
      placeholder: "Mobile or Telephone",
    },
    tM5BusCont2: {
      label: "Business Contact No. 2",
      placeholder: "Mobile or Telephone",
    },
    tM5Consent: [
      {
        label:
          "I hereby consent to the inclusion of my contact number in the M3DAS Web Map and understand that it may be accessible to the public for mapping purposes.",
        key: 0,
      },
    ],
    tM5Member: "For Members",
    tM5NonMember: "For Non-members",
    tM5Operation: {
      first: "Implement 1",
      second: "Implement 2",
      third: "Implement 3",
    },
    tM5Ope: {
      label: "Implement",
      placeholder: "Select an Implement",
      options: [
        { label: "Land Preparation" },
        { label: "Planting" },
        { label: "Ratoon Management" },
        { label: "Harvesting" },
        { label: "Hauling" },
      ],
    },
    tM5OpeRate: {
      label: "Service Rate",
      placeholder: "0.00",
      unit: "₱",
      options: [
        { label: "ha" },
        { label: "hr" },
        { label: "day" },
        { label: "per operation" },
        { label: "tractor only", anchored: true },
        { label: "tractor and implement", anchored: true },
      ],
    },
    tM5OpeUnit: {
      label: "Unit",
      placeholder: "Choose",
    },
    tM5OpeFuel: {
      label: "Fuel Consumption",
      placeholder: "0.00",
      unit: "L/ha",
    },
    tM5Service: "Scope of Service based on Location",
    tM6Title: "Machine Operator",
    tM6TotalTitle: "No. of Operators for this Machine only",
    tM6Total: {
      label: "Total",
      placeholder: "0",
      unit: "operators",
    },
    tM6L2FormalTitle: "No. of Operators based on Years of Experience",
    tM6L2Formal: {
      label: "Has formal training but LESS than 2 yrs exp.",
      placeholder: "0",
      unit: "operators",
    },
    tM6L2NtFrml: {
      label: "Has NO formal training and LESS than 2 yrs exp.",
      placeholder: "0",
      unit: "operators",
    },
    tM6G2Formal: {
      label: "Has formal training and MORE than 2 yrs exp.",
      placeholder: "0",
      unit: "operators",
    },
    tM6G2NtFrml: {
      label: "Has NO formal training but MORE than 2 yrs exp.",
      placeholder: "0",
      unit: "operators",
    },
    tM7Title: "MAINTENANCE SERVICES",
    tM7Unit: {
      label: "Unit",
      placeholder: "Select",
    },
    tM7Units: [{ label: "yr" }, { label: "km" }, { label: "month" }],
    tM7AirFltr: {
      label: "Change Air Filter",
      placeholder: "0",
    },
    tM7EngOil: {
      label: "Change Engine Oil",
      placeholder: "0",
    },
    tM7EngOilFltr: {
      label: "Change Engine Oil Filter",
      placeholder: "0",
    },
    tM7HydOil: {
      label: "Change Hydraulic Oil",
      placeholder: "0",
    },
    tM7HydOilFltr: {
      label: "Change Hydraulic Oil Filter",
      placeholder: "0",
    },
    tM7Tire: {
      label: "Change Tire or Crawler",
      placeholder: "0",
    },
    tM7SrvPrvdr: "Service Provider",
    tM7Options: [
      { label: "Local Mechanic" },
      { label: "In-house Mechanic" },
      { label: "Supplier" },
    ],
    tM7SrvcPrvdr: {
      label: "Service Provider",
      placeholder: "Pick Service Provider",
    },
    tM7Prov: {
      label: "Province",
      placeholder: "Select Province",
    },
    tM7Mun: {
      label: "Municipality",
      placeholder: "Select Municipality",
    },
    tM7Brgy: {
      label: "Barangay",
      placeholder: "Select Barangay",
    },
    tM7TimeRspnd: {
      label: "Time to Respond to Concerns",
      placeholder: "Select an Option",
      options: [
        { label: "Responds within 24 hours" },
        { label: "Responds after 1-2 days" },
        { label: "Responds after 3 or more days" },
        { label: "Does not respond" },
      ],
    },
    tM7PrtsAvail: {
      label: "Parts Availability",
      placeholder: "Select Option",
      options: [
        { label: "Always available / within 24 hours" },
        { label: "Available after 1-2 days" },
        { label: "Available after 3 or more days" },
        { label: "Not available" },
      ],
    },
    tM8Title: "Other Crops",
    tM8OthCrp: {
      label: "Is this machine used for other crops?",
      placeholder: "Choose an Option",
      options: [
        { label: "Yes", key: 0, anchored: true },
        { label: "No", key: 1 },
      ],
    },
    tM8Label: "Service Area",
    tM8Rice: {
      option: [{ label: "Rice", key: 0 }],
      label: "",
      placeholder: "0.00",
      unit: "ha",
    },
    tM8Corn: {
      option: [{ label: "Corn", key: 0 }],
      label: "",
      placeholder: "0.00",
      unit: "ha",
    },
    tM8Othr: {
      label: "Others, specify crop",
      placeholder: "Crop Name",
    },
    tM8OthrHa: {
      label: "",
      placeholder: "0.00",
      unit: "ha",
    },
    tM9Notes: {
      label: "Survey Notes",
      placeholder: "Enter some notes here",
    },
  },
  validation: {
    power: "Please input a valid power rating",
    area: "Please input a valid area",
    value: "Please input a valid value",
    capacity: "Please input a valid capacity",
    load: "Please input a valid load",
    time: "Please input a valid time",
    yield: "Please input a valid yield.",
    size: "Please input a valid size",
    discharge: "Please input a valid discharge",
    breakdown: "Please input a valid number of breakdown",
    rate: "Please input a valid rate",
    fuel: "Please input a valid fuel consumption",
    operator: "Please input a valid number of operator",
  },
  action: {
    save: "Save Data",
  },
};
