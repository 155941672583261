import Swal from "sweetalert2";
import { SweetAlertIcon } from "sweetalert2";
import { SweetAlertOptions } from "sweetalert2";
import { SweetAlertPosition } from "sweetalert2";
import { TPop } from "../util/AType";
import { AppSettings } from "../../app/Settings";
import { AlertProps, Alert } from "@mui/material";

export function AVAlert(props: AlertProps) {
  return <Alert {...props} />;
}

export function AVToast(props: SweetAlertOptions & { type?: TPop }) {
  var isMobile = window.innerWidth < AppSettings.WIDTHBREAKPOINT;
  var position: SweetAlertPosition = isMobile ? "bottom" : "top-right";

  const toast = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: props.timer ?? 7000,
    icon: props.icon ?? getAVIcon(props.type),
    iconColor: props.iconColor ?? "white",
    timerProgressBar: props.timerProgressBar ?? true,
    customClass: {
      popup: getAVBackgroundColor(props.type),
    },
    didOpen: (toast: any) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const { type, ...restProps } = props;
  return toast.fire(restProps);
}

export function AVPopup(props: SweetAlertOptions & { type?: TPop }) {
  props.type = props.icon ? getAVStyleByIcon(props.icon) : props.type;
  var confirmBtn = getAVStyle(props.type ?? "primary");

  const toast = Swal.mixin({
    buttonsStyling: false,
    allowEscapeKey: props.allowEscapeKey ?? false,
    allowOutsideClick: props.allowOutsideClick ?? false,
    customClass: {
      confirmButton: props.confirmButtonColor ?? confirmBtn,
      cancelButton: props.cancelButtonColor ?? "btn btn-white",
      denyButton: props.denyButtonColor ?? "btn btn-white",
    },
  });

  const { type, ...restProps } = props;
  return toast.fire(restProps);
}

export function getAVIcon(type?: TPop): SweetAlertIcon | undefined {
  switch (type) {
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "error";
    case "success":
      return "success";
    case "question":
      return "question";
    default:
      return undefined;
  }
}

export function getAVBackgroundColor(type?: TPop) {
  switch (type) {
    case "primary":
      return "bg-primary text-light";
    case "secondary":
      return "bg-secondary text-light";
    case "info":
      return "bg-info text-light";
    case "warning":
      return "bg-warning text-light";
    case "error":
      return "bg-danger text-light";
    case "success":
      return "bg-success text-light";
    case "question":
      return "bg-info text-light";
    case "light":
      return "bg-light text-dark";
    case "dark":
      return "bg-dark text-light";
    default:
      return "bg-primary text-light";
  }
}

export function getAVStyleByIcon(icon: string) {
  switch (icon) {
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "error";
    case "success":
      return "success";
    case "question":
      return "info";
  }
}

export function getAVStyle(type: TPop) {
  switch (type) {
    case "primary":
      return "btn btn-primary";
    case "secondary":
      return "btn btn-secondary";
    case "info":
      return "btn btn-info";
    case "warning":
      return "btn btn-warning";
    case "error":
      return "btn btn-danger";
    case "success":
      return "btn btn-success";
    case "question":
      return "btn btn-info";
    case "light":
      return "btn btn-light";
    case "dark":
      return "btn btn-dark";
    default:
      return "btn btn-primary";
  }
}
