// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

export const ToBeAcquired = "Data to be acquired";
export const ToBeAcquiredAsOption = [{ label: ToBeAcquired }];

export const NotApplicable = "Not Applicable";
export const NotApplicableAsOption = [{ label: NotApplicable }];

export const enum DATA_STATUS {
  local = "Saved Locally",
  online = "Uploaded to Database",
  returned = "Returned by Admin",
  pending = "Pending Approval",
  verified = "Verified",
}

// export const enum FORMS {
//   profile = "profile",
//   machine = "machine",
//   implement = "implement",
//   null = "null",
// }

export const enum LOG {
  login = "Login",
  loginCode = "1",
  logout = "Logout",
  logoutCode = "0",
}
